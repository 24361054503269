import React, { useEffect, useState } from 'react'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useAsyncFunction } from '../hooks/useAsyncFunction'
import { useExternalSessionForVitecNOOrder } from '../hooks/useExternalSessionForVitecNOOrder'
import { ShortLongDescriptionRequest } from '../../../../pure/leopard/JaguarTypesDerived'
import { useDefaultSectionFormValues } from '../libs/AdCreatorFormHelper'
import { getEstateForVitecNOOrder, getFileSetResults, shortLongDescription } from '../libs/CloudFunctionsApiHandler'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import { SectionInfo } from '../libs/SectionInfo'
import FigmaText from './FigmaText'
import { createUniqueId } from '../../../../pure/libs/Common'
import Images from '../../../../pure/libs/AppImages'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../../pure/libs/AppTexts'
import { getDefaultPromptForEstate, getKeywordMapItemsForEstate } from '../../../../pure/libs/VitecNOCreateAdHelper'
import { JaguarType } from '../../../../pure/types/types'
import { VitecNextOrderProductPageState } from '../types/VitecNextProductTypes'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { UploadLoading } from './UploadLoading'
import {
  LongShortDescriptionType,
  VitecNextOrderProductLongShortDescriptionItem
} from './VitecNextOrderProductLongShortDescriptionItem'
import { useGooglePlaceQueryForExternalSession } from './useGooglePlaceQueryForExternalSession'
import config from '../Config'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'

export const VitecNextOrderProductLongShortDescription: React.FC<VitecNextOrderProductPageState> = ({
  vitecNOOrder
}) => {
  const [type, setType] = useState<LongShortDescriptionType>('floating')
  const [requestId] = useState(createUniqueId())
  const { data: externalSession, isLoading: isLoadingExternalSession } = useExternalSessionForVitecNOOrder(vitecNOOrder)
  const { state } = useAppState()
  const [shortLongDescriptionResponse, setShortLongDescriptionResponse] = useState<object | null>(null)
  const [hasCalledShortLongDescription, setHasCalledShortLongDescription] = useState(false)

  const { data: estate, isLoading: isLoadingEstate } = useAsyncFunction(() => {
    return getEstateForVitecNOOrder(vitecNOOrder, state)
  })

  // eslint-disable-next-line prefer-const
  let { loading: isLoadingGooglePlace, data: googlePlace } = useGooglePlaceQueryForExternalSession(externalSession)

  if (!config.enableGooglePlace) isLoadingGooglePlace = false
  const loading = isLoadingGooglePlace || isLoadingExternalSession || isLoadingEstate

  const keywordMapItems = getKeywordMapItemsForEstate(estate)

  const formValues = useDefaultSectionFormValues({
    sectionInfo: SectionInfo[JaguarType.OVERVIEW],
    state,
    adCreatorPageProps: {
      enableUpsell: false,
      enableUpsellOnAdCreator: false,
      enableMenu: false,
      enableIsOverQuotaCheck: false,
      enableCopyAd: false,
      enableTutorial: false,
      extra: { externalSession },
      googlePlace: googlePlace,
      houseType: externalSession?.houseType,
      enableLatestAds: false,
      enableRightBottomImage: false,
      enableUpdateAd: false,
      propertyInfo: estate ? getDefaultPromptForEstate(estate) : '',
      keywordMapItems: keywordMapItems,
      showKeywords: false,
      logAdInJaguar: false,
      enableAdCreatorForm: false
    }
  })

  const req = { ...(formValues as ShortLongDescriptionRequest), requestId }

  useEffect(() => {
    if (!estate) return
    if (hasCalledShortLongDescription) return
    setHasCalledShortLongDescription(true)
    shortLongDescription(req, state)
      .then((res) =>
        getFileSetResults({ requestId }, state)
          .then((res) => setShortLongDescriptionResponse(res))
          .catch((e) => ({}))
      )
      .catch(captureAndNotifyError)
  }, [estate, hasCalledShortLongDescription])

  //@ts-ignore
  const result = shortLongDescriptionResponse?.fileResults?.[0]?.result || {}
  const { longDescription, shortDescription, longDescriptionPinpoints } = result

  if (!longDescription || !shortDescription || loading)
    return <UploadLoading uploadedFiles={[]} onClickUploadFile={console.log} uploadFileRequest={null as any} />

  return (
    <Box fullWidth>
      <Box fullWidth fullPadding direction="row" justify="space-between">
        <VitecNextOrderProductBackButton />
        <Box width="900px">
          <FigmaText textKey={Texts.longShortHeader} />
          <Box top fullWidth>
            <Box fullWidth direction="row" align="center" top>
              <FigmaImageContainer imageKey={Images.adCreatorAdGazelle} />
              <Box left spacing={smallSpacing}>
                <FigmaTextWithStyle type="body4" textKey={Texts.adCreatorAdFreshlyNewAd} color="white" />
              </Box>
            </Box>
          </Box>
          <Box fullWidth top>
            <VitecNextOrderProductLongShortDescriptionItem
              header={getFigmaText(Texts.longShortShortHeadline)}
              description={shortDescription}
            />
          </Box>

          <Box fullWidth top spacing={bigSpacing}>
            <VitecNextOrderProductLongShortDescriptionItem
              header="Kort om eiendomen"
              description={type === 'floating' ? longDescription : longDescriptionPinpoints}
              onClickPinPoints={setType}
            />
          </Box>
          <Box fullWidth top spacing={bigSpacing}></Box>
        </Box>
        <Box />
      </Box>
    </Box>
  )
}
