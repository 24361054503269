import { TextKey } from '../types/Antiloop.js'
import { Texts } from './AppTexts.js'
import React from 'react'

export enum AdLength {
  SHORT = 'short',
  MEDIUM = 'medium',
  LONG = 'long',
  CUSTOM = 'CUSTOM'
}

export type AdLengthInfoType = {
  textKey: TextKey
  value: number
  Component?: React.FC<{
    setValue
  }>
  text?: string
  isSelected?: boolean
}

export const AdLengthInfo: {
  [property in AdLength]: AdLengthInfoType
} = {
  short: { textKey: Texts.adCreatorAdLengthShort, value: 100 },
  medium: { textKey: Texts.adCreatorAdLengthMedium, value: 200 },
  long: { textKey: Texts.adCreatorAdLengthLong, value: 700 },
  CUSTOM: { textKey: Texts.adLengthCustom, value: 0 }
}

export function getTypedAdLength(adLength: number): number {
  return (
    (Object.entries(AdLengthInfo)
      .filter(([key, a]) => a.value === adLength)
      .map(([key]) => key)[0] as unknown as number) || adLength
  )
}
