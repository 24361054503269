import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'
import { littleSpacing, tinySpacing } from '../enums/Spacings'
import Colors from '../../../../pure/libs/Colors'
import { KeywordMapItem, getEntries, getIsSelectedKeyword, onClickKeyword } from '../libs/KeywordMapHelper'
import { getFigmaText } from '../libs/TextRepository'
import { getTextKeyIdForKeywordMapItem } from '../../../../pure/libs/getTextKeyIdForKeywordMapItem'
import { TextKey } from '../../../../pure/types/Antiloop'
import { JaguarRequestForm } from '../../../../pure/types/types'
import Box from './Box'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { AdCreatorArrowDownIcon, AdCreatorArrowUpIcon } from './Icons'
import { KeywordsDrawerRowSectionButtonItems } from './KeywordsDrawerRowSectionButtonItems'

export type Props = {
  keywordId: string
  keywordMapItem: KeywordMapItem
  formProps: UseFormReturn<JaguarRequestForm>
}

export function KeywordsDrawerRowSectionButton(props: Props) {
  const { keywordId, keywordMapItem } = props
  const isSelected = getIsSelectedKeyword(props)
  const [open, setOpen] = useState(isSelected)

  const houseType = useWatch({ control: props.formProps.control, name: 'houseType' })
  const text = getFigmaText({ name: getTextKeyIdForKeywordMapItem(keywordId) } as TextKey)
  const Icon = open ? AdCreatorArrowUpIcon : AdCreatorArrowDownIcon

  const entries = getEntries(keywordMapItem)
  const hasEntries = entries.length > 0

  const onClick = () => {
    onClickKeyword(props)

    if (!isSelected && !open) setOpen(true)
    if (!!isSelected && !!open) setOpen(false)
  }

  useEffect(() => {
    setOpen(getIsSelectedKeyword(props))
  }, [houseType])

  const onClickArrow = () => setOpen(!open)
  return (
    <Box fullWidth>
      <Container isSelected={isSelected} direction="row" align="center">
        <Box onClick={onClickArrow} pointer>
          <Icon />
        </Box>
        <Box onClick={onClick} direction="row" align="center" pointer>
          <Box spacing={tinySpacing} left>
            <FigmaTextWithStyle type="body3" text={text} textTransform="uppercase" />
          </Box>
          <Box spacing={tinySpacing} left>
            <FigmaTextWithStyle type="body3" text={entries.length.toString()} color="primary60" />
          </Box>
        </Box>
      </Container>
      {hasEntries && open && <KeywordsDrawerRowSectionButtonItems {...props} />}
    </Box>
  )
}

const Container = styled(Box)<{ isSelected?: boolean }>`
  border-radius: ${({ isSelected }) => (isSelected ? '12px 12px 0px 0px' : '61px')};
  background-color: ${({ isSelected }) => (isSelected ? Colors.primary20 : Colors.neutral10)};
  padding: ${tinySpacing} ${littleSpacing};
`
