import { useState } from 'react'
import { Shadows } from '../enums/Shadows'
import ZIndices from '../enums/Zindices'
import Colors from '../../../../pure/libs/Colors'
import Images from '../../../../pure/libs/AppImages'
import { captureException } from '../libs/SentryHelper'
import { Texts } from '../../../../pure/libs/AppTexts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { MenuItem, MenuItemProps } from './MenuItem'
import { PopperContainer } from './Popper'

export function AdCreatorAdSectionResponseWithSelectionMenuView({
  onClickRephrase,
  onClickExpand,
  onClickShorten,
  onClickEdit,
  loading
}: {
  onClickRephrase?: (event: unknown) => void
  onClickExpand?: (event: unknown) => void
  onClickShorten?: (event: unknown) => void
  onClickEdit?: (event: unknown) => void
  loading?: boolean
}) {
  return (
    <PopperContainer position="relative" boxShadow={Shadows.large} style={{ border: `1px solid ${Colors.neutral40}` }}>
      <Box fullWidth align="center">
        <Box position="absolute" style={{ top: 36 }} zIndex={ZIndices.high}>
          <FigmaImageContainer imageKey={Images.selectionMenuArrow} />
        </Box>
      </Box>
      <Box direction="row" align="center" position="relative">
        <MenuItemWithLoader
          textKey={Texts.adCreatorSelectionMenuRephrase}
          onClick={onClickRephrase}
          disabled={loading}
        />
        <Divider />
        <MenuItemWithLoader textKey={Texts.adCreatorSelectionMenuExpand} onClick={onClickExpand} disabled={loading} />
        <Divider />
        <MenuItemWithLoader textKey={Texts.adCreatorSelectionMenuShorten} onClick={onClickShorten} disabled={loading} />
        <Divider />
        <MenuItemWithLoader textKey={Texts.adCreatorSelectionMenuEdit} onClick={onClickEdit} disabled={loading} />
      </Box>
    </PopperContainer>
  )
}
export function Divider() {
  return (
    <Box
      style={{
        width: 1,
        height: Texts.adCreatorSelectionMenuRephrase.style.fontSize,
        background: Colors.primary100,
        opacity: 0.3
      }}
    />
  )
}
const MenuItemWithLoader = (props: MenuItemProps) => {
  const [loading, setLoading] = useState(false)

  const onClick = (e) =>
    Promise.resolve(setLoading(true))
      .then(() => props.onClick?.(e))
      .catch(captureException)
      .finally(() => setLoading(false))

  return <MenuItem {...props} loading={loading} onClick={onClick} />
}
