import { useState } from 'react'
import config from '../Config'
import useIsMobile from '../hooks/useIsMobile'
import Box from './Box'
import { PricingItemInfoRow } from './PricingItemInfoRow'
import { PricingItemInfoRowMobile } from './PricingItemInfoRowMobile'
import { ProductInfo, ProductType } from '../libs/ProductInfo'
import { PricingProductToggle } from './PricingProductToggle'

export default function PricingWebIframePage() {
  const isMobile = useIsMobile()
  const onClick = () => window.open(config.appUrl, '_blank')
  const [productType, setProductType] = useState<ProductType>(ProductInfo.ANNUAL_MEMBERSHIP)

  if (isMobile) return <PricingItemInfoRowMobile onClick={onClick} productType={productType} />

  return (
    <Box align="center" fullWidth>
      <Box top>
        <PricingProductToggle productType={productType} setProductType={setProductType} />
      </Box>
      <Box top>
        <PricingItemInfoRow onClick={onClick} productType={productType} />
      </Box>
    </Box>
  )
}
