import { useEffect } from 'react'
import SignIn from '../components/RootPage'
import useAppState from '../hooks/useAppState'
import useQueryParams from '../libs/useQueryParams'
import { createAuthenticationTokenForUid } from '../libs/CloudFunctionsApiHandler'
import { useNavigate } from 'react-router-dom'
import { signInWithCustomToken } from '../libs/FirebaseHelper'
import { LoginWithCustomerPageQueryParams } from '../../../../pure/types/QueryParamTypes'
import RoutePath from '../../../../pure/libs/RoutePath'

export const LoginWithCustomerPage = () => {
  const { uid } = useQueryParams<LoginWithCustomerPageQueryParams>()
  const { state } = useAppState()
  const navigate = useNavigate()
  useEffect(() => {
    if (!uid) return
    createAuthenticationTokenForUid(uid, state)
      .then((customAuthToken) => signInWithCustomToken(customAuthToken))
      .then(() => navigate(RoutePath.ROOT))
  }, [])

  return <SignIn />
}
