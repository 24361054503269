import { KeywordHouseType } from '../../../../pure/libs/KeyWordsHelper'
import { ExternalSession } from '../../../../pure/types/types'

export function getUnpayedSession(fasasdSessions: ExternalSession[], currentSession?: ExternalSession) {
  if (!currentSession) return
  // TODO wrute test, activeSessions can apparently be null
  return fasasdSessions.find((s) => s.status === 'active' && !!s.adId && currentSession._id !== s._id)
}

export const useHouseTypeFromExternalSession = (s?: ExternalSession): KeywordHouseType | undefined => {
  // TODO WRUTE TEST, should support VitecNO sessions
  if (!!s?.houseType) return s.houseType

  switch (s?.objectMetaData?.objectType) {
    case 'Bostadsrätt - Lägenhet':
    case 'Friköpt - Lägenhet':
      return KeywordHouseType.Apartment
    case 'Tomträtt - Småhus':
    case 'Arrende - Småhus':
    case 'Friköpt - Småhus':
    case 'Friköpt - Gård':
    case 'Arrende - Gård':
    case 'Friköpt - Townhouse':
      return KeywordHouseType.Villa
    default:
      return undefined
  }
}

export function formatHeadline(externalSession?: ExternalSession) {
  if (!externalSession?.objectMetaData) return undefined
  const { address, zipArea } = externalSession.objectMetaData
  if (!!address && !!zipArea) return `${address}, ${zipArea}`
  if (!!address) return address
  if (!!zipArea) return zipArea
  return undefined
}
