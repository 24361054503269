import { ClickAwayListener } from '@mui/base'
import { useState } from 'react'
import Box from './Box'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { VitecLandingImageCaptionsResultsTextField } from './VitecLandingImageCaptionsResultsTextField'
import { ENTER_KEYCODE, ESC_KEYCODE } from '../../../../pure/libs/Consts'

export const VitecLandingImageCaptionsResultsMenu: React.FC<{
  text: string
  onClickDone?: (text: string) => void
}> = ({ text, onClickDone }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(text)

  const onClose = () => {
    //TODO WRITE TEST, should only call onClickDone when editing because parent component will be fired on all items and aprent component will send a req per item
    !!isEditing && onClickDone?.(value)
    setIsEditing(false)
  }
  const onClickEdit = () => setIsEditing(true)

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box fullWidth pointer>
        {isEditing && (
          <VitecLandingImageCaptionsResultsTextField
            value={value}
            onChange={({ target: { value } }) => setValue(value)}
            onKeyDown={(e) => [ENTER_KEYCODE, ESC_KEYCODE].includes(e.keyCode) && onClose()}
            fullWidth
          />
        )}
        {!isEditing && (
          <Box onClick={onClickEdit} fullWidth>
            <VitecLandingSummarizeResultsText text={value} />
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  )
}

export function VitecLandingSummarizeResultsText({ text }: { text: string }) {
  return (
    <Box fullWidth>
      <FigmaTextWithStyle type="body3" text={text} color={'primary100'} />
    </Box>
  )
}
