import styled from '@emotion/styled'
import { Shadows } from '../enums/Shadows'
import Colors from '../../../../pure/libs/Colors'
import { Texts } from '../../../../pure/libs/AppTexts'
import { Divider } from './AdCreatorAdSectionResponseWithSelectionMenuView'
import Box from './Box'
import { UploadLoadingCheckIcon } from './Icons'
import { MenuItem } from './MenuItem'
import { PopperContainer } from './Popper'

export function AdCreatorAdSectionTextFieldMenu({
  onClickCancel,
  onClickDone
}: {
  onClickCancel?: () => unknown
  onClickDone: () => unknown
}) {
  return (
    <Container boxShadow={Shadows.large}>
      <Box direction="row" align="center">
        <MenuItem
          leftElement={<UploadLoadingCheckIcon />}
          textKey={Texts.adCreatorSelectionMenuDone}
          onClick={onClickDone}
        />
        {onClickCancel && (
          <>
            <Divider />
            <MenuItem textKey={Texts.keywordsCancelButton} onClick={onClickCancel} />
          </>
        )}
      </Box>
    </Container>
  )
}

const Container = styled(PopperContainer)({ border: `1px solid ${Colors.neutral40}` })
