import { smallSpacing, tinySpacing } from '../enums/Spacings'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import Images from '../../../../pure/libs/AppImages'
import { Texts } from '../../../../pure/libs/AppTexts'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaTextWithStyleOverrides from './FigmaTextWithStyleOverrides'
import { onClickContactUs } from './MenuButtonsDesktop'
import { ModalFasadMachineViewProps } from './ModalExternalSessionMachineHelper'

export function ModalExternalSessionMachineSendToFasadFailure(props: ModalFasadMachineViewProps) {
  return (
    <Box fullWidth align="center">
      <Box top>
        <FigmaImageContainer imageKey={Images.pngGazelleArrowFasad} />
      </Box>
      <Box top alignText="center" spacing={smallSpacing}>
        <FigmaTextWithLinebreaks textKey={Texts.modalFasadMachineSendToFasadFailureTitle} />
      </Box>

      <Box top fullWidth>
        <Button onClick={props.onClickContinue} fullWidth>
          <FigmaText textKey={Texts.modalFasadMachineSendToFasadFailureButton} />
        </Button>
      </Box>
      <Box top spacing={tinySpacing} onClick={onClickContactUs}>
        <FigmaTextWithStyleOverrides
          textKeys={{
            en: Texts.modalFasadMachineSendToFasadFailureFineprint,
            sv: Texts.modalFasadMachineSendToFasadFailureFineprintSv
          }}
        />
      </Box>
    </Box>
  )
}
