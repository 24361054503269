import { Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { minSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { useForm } from '../hooks/useForm'
import { EmailValidation } from '../libs/ValidationHelper'
import { ENTER_KEYCODE } from '../../../../pure/libs/Consts'
import { getFigmaText } from '../libs/TextRepository'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { SignInViewProps } from '../../../../pure/types/SignInTypes'
import Box from './Box'
import Button from './Button'
import FigmaTextLoader from './FigmaTextLoader'
import { TextFieldOutlined } from './TextFieldOutlined'
import { TextFieldWithLabelForm } from './TextFieldWithLabelForm'
import { Texts } from '../../../../pure/libs/AppTexts'
import styled from '@emotion/styled'
import { Input, TextField } from '@mui/material'
import FigmaText from './FigmaText'
import { BorderRadixes } from '../enums/BorderRadixes'
import Email from '@mui/icons-material/Email'

export const ValidationSchema = Yup.object().shape({
  email: EmailValidation('email', TextsDeprecated.onboardingEmailErrorNotValidEmail.characters)
})

export default function LoginWithEmailForm(props: SignInViewProps) {
  const { signInState } = props
  const { data } = signInState
  const { email = '' } = data

  const _onClick = (values) => props.onPressContinue({ ...signInState, data: { ...signInState.data, ...values } })

  const { control, handleSubmit } = useForm(ValidationSchema, { defaultValues: { email } })

  const onSubmit = handleSubmit(_onClick)
  return (
    <form onSubmit={_onClick}>
      <Box fullWidth align="center">
        <Box fullWidth top bottom spacing={tinySpacing}>
          <Box bottom spacing={smallSpacing}>
            <FigmaText textKey={Texts.gazelleLandingEmailTextfieldHeader} />
          </Box>
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                fullWidth
                disableUnderline
                startAdornment={
                  <Box right spacing={smallSpacing}>
                    <Box bottom spacing={tinySpacing}>
                      <Email color={'white' as any} />
                    </Box>
                  </Box>
                }
                sx={{ border: '1px solid white', borderRadius: BorderRadixes.regular, padding: smallSpacing }}
                onKeyDown={(e) => e.keyCode === ENTER_KEYCODE && onSubmit(e)}
                size="small"
                placeholder={getFigmaText(Texts.signInEmailFieldPlaceholder)}
                inputProps={{ style: { color: 'white', borderColor: 'white' } }}
              />
            )}
          />
        </Box>
        <Box top fullWidth spacing={smallSpacing}>
          <Button fullWidth onClick={onSubmit} textKey={TextsDeprecated.continueButtonText} spacing={minSpacing}>
            <FigmaTextLoader
              textKey={Texts.premiumPricingButtonText}
              text={getFigmaText(Texts.signInContinueWithEmail)}
              loading={props.isLoading}
            />
          </Button>
        </Box>
      </Box>
    </form>
  )
}

const StyledTextField = styled(TextField)`
  & .MuiFormControl-root {
    border-color: white;
  }
`
