import { smallSpacing, tinySpacing } from '../enums/Spacings'
import FigmaText from './FigmaText'
import { Texts } from '../../../../pure/libs/AppTexts'
import Box from './Box'
import { KeyWordsDrawerInfoIcon } from './Icons'

export function KeywordsDrawerInfoHeader() {
  return (
    <Box fullWidth direction="row" justify="space-between">
      <Box top spacing={tinySpacing}>
        <KeyWordsDrawerInfoIcon />
      </Box>
      <Box left spacing={smallSpacing}>
        <i>
          <FigmaText textKey={Texts.adCreatorSectionFormKeywordsDescription} />
        </i>
      </Box>
    </Box>
  )
}
