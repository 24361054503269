import { smallSpacing, tinySpacing } from '../enums/Spacings'
import FigmaText from './FigmaText'
import { Texts } from '../../../../pure/libs/AppTexts'
import Box from './Box'
import Button from './Button'
import { AdCreatorFileCopy } from './Icons'
import { ButtonProps } from './ButtonPrimary'

export function ButtonCopyAd(props: ButtonProps) {
  return (
    <Button variant="outlined" spacing={smallSpacing} color={'primary40' as any} {...props}>
      <Box direction="row" align="center">
        <AdCreatorFileCopy />
        <Box left spacing={tinySpacing}>
          <FigmaText textKey={Texts.adCreatorAdSectionCopyAdButton} />
        </Box>
      </Box>
    </Button>
  )
}
