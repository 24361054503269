import { useForm } from 'react-hook-form'
import Box from '../components/Box'
import FigmaImage from '../components/FigmaImage'
import { InfoMapLocationButton } from '../components/InfoMapLocationButton'
import { Layout } from '../components/LayoutExperimental'
import { useDefaultSectionFormValues } from '../libs/AdCreatorFormHelper'
import { SectionInfo } from '../libs/SectionInfo'
import useAppState from '../hooks/useAppState'
import Images from '../../../../pure/libs/AppImages'
import { Texts } from '../../../../pure/libs/AppTexts'
import { JaguarRequestForm } from '../../../../pure/types/types'
import FigmaTextWithLinebreaks from '../components/FigmaTextWithLinebreaks'

export const InfomapPage = () => {
  const { state } = useAppState()
  const defaultValues: JaguarRequestForm = useDefaultSectionFormValues({ sectionInfo: SectionInfo.overview, state })

  const formProps = useForm({
    defaultValues
  })

  return (
    <Layout enableMenu={false} enableFooter={false}>
      <Box fullWidth align="center" alignText="center">
        <Box top fullWidth align="center">
          <FigmaImage imageKey={Images.companyMixedLogoWhite} />
        </Box>
        <Box top>
          <FigmaTextWithLinebreaks textKey={Texts.infomapVillaHeader} />
        </Box>
        <Box top>
          <FigmaTextWithLinebreaks textKey={Texts.infomapVillaDescription} />
        </Box>
        <Box top>
          <InfoMapLocationButton formProps={formProps} />
        </Box>
      </Box>
    </Layout>
  )
}
