import { useEffect, useState } from 'react'
import { ONE_SECOND } from '../../../../pure/libs/Consts'
import { Texts } from '../../../../pure/libs/AppTexts'
import { TextKey } from '../../../../pure/types/Antiloop'
import { UploadedFileProcessingStep, UploadedFileStatus } from '../../../../pure/types/JaguarTypes'
import UploadProcessingStepsJson from '../data/UploadProcessingSteps.json' 

type UploadStatusRowInfoTypeItem = {
  ordo: number
  textKey?: TextKey
}

export type UploadStatusRowInfoType = {
  [property in UploadedFileProcessingStep]: UploadStatusRowInfoTypeItem
}

export const UploadStatusRowInfo: UploadStatusRowInfoType = {
  None: {
    ordo: 0,
    textKey: Texts.uploadLoadingUploadFiles
  },
  LoadingAIModel: {
    ordo: 1,
    textKey: Texts.uploadLoadingAiTextModel
  },
  ClassifyingFile: {
    ordo: 2,
    textKey: Texts.uploadLoadingClassifyingFile
  },
  ExtractingDataFromFile: {
    ordo: 3,
    textKey: Texts.uploadLoadingExtractingDataFromPdf
  },
  GeneratingSummary: {
    ordo: 4,
    textKey: Texts.uploadLoadingGeneratingSummary
  },
  Done: {
    ordo: 5
  }
}

export type StatusRowStatus = UploadedFileStatus | 'not_started'

export const getStatusRows = (
  step?: UploadedFileProcessingStep
): { textKey?: TextKey; status: StatusRowStatus; step: UploadedFileProcessingStep }[] => {
  if (!step) return []

  return Object.values(UploadStatusRowInfo).map((row) => ({
    textKey: row.textKey,
    status: getStatus(row, step),
    step
  }))
}

function getStatus(row: UploadStatusRowInfoTypeItem, step: UploadedFileProcessingStep): StatusRowStatus {
  const rowInfo = UploadStatusRowInfo[step]
  if (!rowInfo) return 'not_started'
  if (row.ordo === rowInfo.ordo) return 'processing'
  if (row.ordo < rowInfo.ordo) return 'done'
  return 'not_started'
}

export function useProcessingStep(): UploadedFileProcessingStep {
  const processingSteps = Object.values(UploadProcessingStepsJson).filter(
    (s) => s !== 'Done'
  ) as UploadedFileProcessingStep[]
  const [index, setIndex] = useState<number>(0)

  useEffect(() => {
    const fn = () =>
      setIndex((i) => {
        if (i >= processingSteps.length - 1) return i
        return i + 1
      })
    const interval = setInterval(fn, 30 * ONE_SECOND * Math.random())
    return () => clearInterval(interval)
  }, [])

  return processingSteps[index]
}
