import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { smallSpacing } from '../enums/Spacings'
import Images from '../../../../pure/libs/AppImages'
import { HomeFormGooglePlace } from '../../../../pure/types/GooglePlace'
import { JaguarRequestForm } from '../../../../pure/types/types'
import Box from './Box'
import { ModalLocation } from './ModalLocation'
import Tooltip from './Tooltip'

const MAX_LENGTH = 20
export function InfoMapLocationButton({ formProps }: { formProps: UseFormReturn<JaguarRequestForm> }) {
  const [showModal, setShowModal] = useState(false)

  const value = formProps.watch('googlePlace') as HomeFormGooglePlace
  const defaultText = 'Sjöstigen 4, Lidingö, Sweden'

  const description = value?.value?.description
  const text = description || defaultText
  return (
    <Box fullWidth>
      {showModal && <ModalLocation onClose={() => setShowModal(false)} formProps={formProps} />}
      {InfoMapLocationButtonView({ onClick: () => setShowModal(true), text })}
    </Box>
  )
}
export function InfoMapLocationButtonView({ onClick, text }: { onClick?: () => void; text: string }) {
  const tooltipTitle = (text || '').length > MAX_LENGTH ? text : ''
  return (
    <Tooltip title={tooltipTitle}>
      <Button buttonType="white" onClick={onClick}>
        <Box direction="row">
          <Box>
            <FigmaImageContainer imageKey={Images.locationOn} />
          </Box>
          <Box left spacing={smallSpacing}>
            <FigmaTextWithStyle type="body2" text={text} color="primary100" />
          </Box>
        </Box>
      </Button>
    </Tooltip>
  )
}
