import { JaguarSession, User } from '../../../../pure/types/types.js'
import { SlackChannels } from '../../../../pure/types/SlackChannels.js'
import { slack } from '../SlackHelper.js'
import {User as SentryUser} from '../Sentry.js'

export const error = (error: Error, user?: SentryUser) =>
  slack(`user: ${user?.email || ''}\n${error.name} ${error.message} ${error.stack}`, SlackChannels.ERRORS)

export const logUsesSessions = (user: User) =>
  slack(`user.id: ${user._id}, used sessions: ${user.usedSessions}`, SlackChannels.OPS_USED_SESSIONS)

export const logFinishedJaguarSession = (s: JaguarSession) =>
  slack(
    `request: 
    
    ${Object.keys(s)
      .map((key) => `${key}: ${s[key]}`)
      .join('\n')}
      
    response:
    
    ${s.response}
      `,
    SlackChannels.OPS_USED_SESSIONS
  )
