import styled from '@emotion/styled'
import { BorderRadixes } from '../enums/BorderRadixes'
import { tinySpacing } from '../enums/Spacings'
import Colors from '../../../../pure/libs/Colors'
import Box from './Box'
import { ThumbUp } from './Icons'
import Tooltip from './Tooltip'

export function AdCreatorAdThumbViewEmptyState() {
  return (
    <Box>
      <Tooltip title={'Use Ad for finetuning you AI writer'} placement="top">
        <Container direction="row" fullPadding spacing={tinySpacing} align="center">
          <Box pointer>
            <ThumbUp />
          </Box>
        </Container>
      </Tooltip>
    </Box>
  )
}
const Container = styled(Box)`
  border-radius: ${BorderRadixes.regular};
  border: 1px dashed ${Colors.primary100};
`
