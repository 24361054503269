import { formatText } from '../libs/TextRepository'
import { SectionInfoItem } from './SectionInfo'
import { StatusInfoItem } from './AdCreatorFormSectionStatusHelper'
import { KeywordItem } from '../../../../pure/libs/KeyWordsHelper'
import { KeywordMapItem } from './KeywordMapHelper'
import { JaguarKeywordMapItem } from '../../../../pure/types/JaguarTypes'

export function getText({
  statusInfo,
  propertyInfo,
  section,
  keywordMapItems
}: {
  statusInfo: StatusInfoItem
  propertyInfo: string
  section: SectionInfoItem
  keywordMapItems: JaguarKeywordMapItem[]
}) {
  if (!statusInfo.text) return undefined
  if (propertyInfo.length >= section.propertyInfoNoOfCharsMin) return undefined
  const length = propertyInfo.length + keywordMapItems.reduce((a, b) => a + b.id.length, 0)

  return formatText(statusInfo.text, [length, section.propertyInfoNoOfCharsMin])
}
