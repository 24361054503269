import { ModalProps } from './Modal'
import { ModalExternalSessionMachineSendToFasad } from './ModalExternalSessionMachineSendToFasad'
import { ModalExternalSessionMachineSendToFasadSuccess } from './ModalExternalSessionMachineSendToFasadSuccess'
import { ModalExternalSessionMachineSendToFasadFailure } from './ModalExternalSessionMachineSendToFasadFailure'
import { ExternalSession } from '../../../../pure/types/types'
import { AdCreatorAdProps } from './AdCreatorAd'
import invariant from 'invariant'

export enum ModalFasadMachineSteps {
  SEND_TO_FASAD,
  SEND_TO_FASAD_SUCCESS,
  SEND_TO_FASAD_FAILURE
}

export type ModalFasadMachineViewProps = ModalProps & {
  onClickContinue: () => unknown
  loading: boolean
}
export const useComponent = (
  step: ModalFasadMachineSteps
): { Component: React.FC<ModalFasadMachineViewProps>; enableClose?: boolean } => {
  switch (step) {
    case ModalFasadMachineSteps.SEND_TO_FASAD:
      return { Component: ModalExternalSessionMachineSendToFasad }
    case ModalFasadMachineSteps.SEND_TO_FASAD_SUCCESS:
      return { Component: ModalExternalSessionMachineSendToFasadSuccess, enableClose: false }
    case ModalFasadMachineSteps.SEND_TO_FASAD_FAILURE:
      return { Component: ModalExternalSessionMachineSendToFasadFailure }
    default:
      throw new Error('Invalid step' + step)
  }
}

export function getExternalSessionWithActiveSessions(props: AdCreatorAdProps): ExternalSession {
  const { adCreatorPageProps, ad } = props
  const _externalSession = adCreatorPageProps?.extra?.externalSession
  const { sessions: activeSessions } = props
  invariant(_externalSession, 'externalSession is undefined')
  const externalSession: ExternalSession = {
    ..._externalSession,
    adId: ad._id,
    activeSessions: { ..._externalSession.activeSessions, ...activeSessions }
  }
  return externalSession
}
