import { smallSpacing, tinySpacing } from '../enums/Spacings'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import Images from '../../../../pure/libs/AppImages'
import { Texts } from '../../../../pure/libs/AppTexts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { ModalFasadMachineViewProps } from './ModalExternalSessionMachineHelper'

export function ModalExternalSessionMachineSendToFasadSuccess(props: ModalFasadMachineViewProps) {
  return (
    <Box fullWidth align="center" alignText="center">
      <Box top>
        <FigmaImageContainer imageKey={Images.pngGazelleArrowFasad} />
      </Box>
      <Box top alignText="center" spacing={smallSpacing}>
        <FigmaTextWithLinebreaks textKey={Texts.modalFasadMachineSendToFasadSuccessTitle} />
      </Box>
      <Box top spacing={tinySpacing} alignText="center">
        <FigmaTextWithLinebreaks textKey={Texts.modalFasadMachineSendToFasadSuccessDescription} />
      </Box>
    </Box>
  )
}
