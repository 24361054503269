import type { UseFormReturn } from 'react-hook-form'
import { getTextForKeywordId } from '../components/KeywordsDrawerRowSectionButtonItemsItem'
import KeyWordsMapJson from '../data/KeywordsMap.json' 
import { JaguarKeywordMapItem } from '../../../../pure/types/JaguarTypes.js'
import { JaguarRequestForm } from '../../../../pure/types/types.js'
import { IGNORED_KEYWORD_KEYS } from '../../../../pure/libs/Consts'
import { KeywordHouseType } from '../../../../pure/libs/KeyWordsHelper'

export type KeywordButtonProps = {
  keywordId: string
  keywordMapItem: KeywordMapItem
  formProps: UseFormReturn<JaguarRequestForm>
}

export type KeywordMapItem = object & { meta?: { selectable: boolean } }

export const KeyWordsMap = KeyWordsMapJson

export function onClickKeyword(props: KeywordButtonProps) {
  const { keywordId, formProps, keywordMapItem } = props
  if (keywordMapItem.meta?.selectable === false) return
  const keywordMapItems = formProps.getValues('keywordMapItems') || []
  const isSelected = getIsSelectedKeyword(props)
  const res: JaguarKeywordMapItem[] = isSelected
    ? keywordMapItems.filter((k) => k.id !== keywordId)
    : [...keywordMapItems, { id: keywordId, localizedName: getTextForKeywordId(keywordId) }]

  formProps.setValue('keywordMapItems', res, { shouldDirty: true })
}

const HouseTypeValues = Object.values(KeywordHouseType)

export function getIsSelectedKeyword(props: KeywordButtonProps) {
  const { keywordId, formProps } = props
  const keywordMapItems = formProps.getValues('keywordMapItems') || []
  const houseType = formProps.getValues('houseType')
  if (keywordId === houseType) return true
  return keywordMapItems.some((k) => k.id === keywordId)
}

export function getKeyWordSections(
  keyWordsMap: typeof KeyWordsMap,
  formProps: UseFormReturn<JaguarRequestForm>
): [string, KeywordMapItem][] {
  const houseType = formProps.getValues('houseType')
  const section = formProps.getValues('type')

  if (section !== 'overview') return [[section, keyWordsMap[section]]]

  return Object.entries(keyWordsMap).map((entry) => {
    const [key, value] = entry
    if (IGNORED_KEYWORD_KEYS.includes(key)) return entry
    if (key !== 'overview') return entry

    return ['overview', getOverviewKeywordMapItems(value, houseType)]
  })
}

function getOverviewKeywordMapItems(value, houseType?: KeywordHouseType): {} {
  return Object.keys(value)
    .filter((k) => {
      if (!HouseTypeValues.includes(k as KeywordHouseType)) return true
      if (!houseType) return false

      return houseType === k
    })
    .reduce((a, k) => ({ ...a, [k]: value[k] }), {})
}

export function getEntries(keywordMapItem: KeywordMapItem) {
  return Object.entries(keywordMapItem).filter(([key]) => IGNORED_KEYWORD_KEYS.includes(key) === false)
}
