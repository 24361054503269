import Drawer from '@mui/material/Drawer/Drawer'
import { regularSpacing, smallSpacing } from '../enums/Spacings'
import { useIsDesktop } from '../hooks/useIsMobile'
import { KeywordDrawerWidth, KeywordDrawerWidthMobile, MENU_HEIGHT } from '../libs/HardCodedSizes'
import FigmaText from './FigmaText'
import { Texts } from '../../../../pure/libs/AppTexts'
import Box from './Box'
import { ModalCloseIcon } from './Icons'
import { KeywordsDrawerProps } from './KeywordsDrawer'
import { KeywordsDrawerInfoHeader } from './KeywordsDrawerInfoHeader'
import { KeywordsDrawerRows } from './KeywordsDrawerRows'

export function KeywordsDrawerExternalSession({ onClose, formProps, open }: KeywordsDrawerProps) {
  const isDesktop = useIsDesktop()
  if (!open) return null

  const width = isDesktop ? KeywordDrawerWidth : KeywordDrawerWidthMobile

  return (
    <Drawer onClose={onClose} anchor="right" open={open} hideBackdrop>
      <Box fullPadding spacing={regularSpacing} style={{ width, overflowY: 'scroll' }}>
        {onClose && (
          <Box direction="row" fullWidth justify="space-between" align="center">
            <FigmaText textKey={Texts.addKeyWordsPopperHeader} />
            <Box fullPadding spacing={smallSpacing} onClick={onClose} pointer>
              <ModalCloseIcon />
            </Box>
          </Box>
        )}
        <KeywordsDrawerInfoHeader />

        <KeywordsDrawerRows formProps={formProps} />
      </Box>
    </Drawer>
  )
}
