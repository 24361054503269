import { TextKey } from '../types/Antiloop.js'
import { TargetAudience } from '../types/JaguarTypes.js'
import { Texts } from './AppTexts.js'

export const TargetAudienceInfo: { [property in TargetAudience]: TextKey } = {
  [TargetAudience.families]: Texts.targetAudienceFamilies,
  [TargetAudience.DINK]: Texts.targetAudienceDink,
  [TargetAudience.retirees]: Texts.targetAudienceRetirees,
  [TargetAudience.singlesWithKids]: Texts.targetAudienceSingleWithKids,
  [TargetAudience.singles]: Texts.targetAudienceSingles,
  [TargetAudience.general]: Texts.adSelectionTitle // all (hardcoded textkey, plz fix)
}
