import styled from '@emotion/styled'
import { smallSpacing } from '../enums/Spacings'
import useLoggedInConfig from '../hooks/useLoggedInConfig'
import { HOME_FORM_MAX_WIDTH } from '../libs/HardCodedSizes'
import FigmaText from './FigmaText'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import Box from './Box'
import Layout from './Layout'
import { TypeOfAdSectionInfoRow } from './ProductsSectionInfoRow'
import { SectionInfoArray } from '../libs/SectionInfo'

export default function TypeOfAdPageMobile() {
  const config = useLoggedInConfig()

  return (
    <Layout>
      <Box top fullWidth>
        <Box fullWidth align="center" fullPadding spacing={smallSpacing}>
          <Container fullWidth>
            <FigmaText textKey={TextsDeprecated.typeOfAdHeaderMobile} />
            <Box fullWidth>
              {SectionInfoArray.map((sectionInfoItem, key) => (
                <TypeOfAdSectionInfoRow key={key} sectionInfoItem={sectionInfoItem} />
              ))}
            </Box>
          </Container>
        </Box>
      </Box>
    </Layout>
  )
}

const Container = styled(Box)`
  max-width: ${HOME_FORM_MAX_WIDTH}px;
`
