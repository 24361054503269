import { smallSpacing } from '../enums/Spacings'
import useIsMobile, { useIsDesktop } from '../hooks/useIsMobile'
import { Texts } from '../../../../pure/libs/AppTexts'
import Box from './Box'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'

export function SignInLandingUpsell() {
  const isMobile = useIsMobile()
  const textKey = Texts.signInLandingDescription
  return (
    <Box fullWidth align="center" style={isMobile ? { transform: `scale(0.8)` } : undefined} alignText="center">
      <FigmaTextWithStyle type="h2" textKey={Texts.signInLandingHeader} color="white" />
      <Box top fullWidth alignText="center">
        <FigmaTextWithStyle type="body5" textKey={textKey} color="white" opacity={0.7} />
      </Box>
    </Box>
  )
}
