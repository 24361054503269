import styled from '@emotion/styled'
import { BorderRadixes } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import { JaguarSessions } from '../libs/AdCreatorFormHelper'
import { createFeedback } from '../libs/ApiHandler'
import { captureException } from '../libs/SentryHelper'
import FigmaText from './FigmaText'
import Colors from '../../../../pure/libs/Colors'
import { TextKey } from '../../../../pure/types/Antiloop'
import Box from './Box'
import { Rating } from '../enums/Rating'
import { AdFeedbackRequest } from '../../../../pure/types/types'

export function RatingButton({
  textKey,
  rating,
  onClick: propsOnClick
}: {
  textKey: TextKey
  rating: Rating
  onClick?: ({ rating }: Partial<AdFeedbackRequest>) => unknown
}) {
  const onClick = () => propsOnClick?.({ rating })

  return (
    <Container
      backgroundColor={Colors.neutral20}
      fullPadding
      spacing={smallSpacing}
      fullWidth
      onClick={onClick}
      pointer
    >
      <FigmaText textKey={textKey} />
    </Container>
  )
}

const Container = styled(Box)`
  border-radius: ${BorderRadixes.regular};

  :hover {
    background-color: ${Colors.neutral30};
  }
`
