import { Texts } from '../../../../pure/libs/AppTexts'
import config from '../Config'
import { smallSpacing } from '../enums/Spacings'
import { getFigmaText } from '../libs/TextRepository'
import Box from './Box'
import { MenuItem } from './MenuItem'
import { MenuLogo } from './MenuLogo'

export default function MenuVitecNext() {
  return (
    <Box fullWidth fullPadding backgroundColor="white" spacing={smallSpacing}>
      <Box fullWidth direction="row" justify="space-between">
        <MenuLogo />
        <Box left onClick={() => window.open(config.faqUrl, '_blank')} link>
          <MenuItem textKey={Texts.headerFaq} text={`${getFigmaText(Texts.headerFaq)} ↗️`} />
        </Box>
      </Box>
    </Box>
  )
}
