import { AdLengthInfo } from '../../../../pure/libs/AdLengthHelper.js'
import { TextKey } from '../../../../pure/types/Antiloop.js'
import { JaguarRequestForm, JaguarType, User } from '../../../../pure/types/types.js'
import { ProductType } from './ProductInfo.js'

export const MINIMUM_NO_OF_CHARS_TO_COPY = 20

export const MIN_WORD_COUNT = 50
export const MAX_WORD_COUNT = 600
export const DEFAULT_NUMBER_OF_WORDS = AdLengthInfo.medium.value

export const JaguarTypeInfo: {
  [property in JaguarType]: {
    homeFormResponseTextKey?: TextKey
  }
} = {
  [JaguarType.OVERVIEW]: {},
  [JaguarType.INTERIOR]: {},
  [JaguarType.EXTERIOR]: {},
  [JaguarType.AREA]: {},
  [JaguarType.BRF]: {}
}

export function useOverQuota(user: User, product: ProductType) {
  const { noOfFreeAds } = product
  const { usedSessions } = user
  if (isInternalUser(user)) return false

  return !!noOfFreeAds && usedSessions >= noOfFreeAds
}

export const isInternalUser = (u: User) => isGazelleInternalUser(u) || isPrekInternalUser(u)

export const isGazelleInternalUser = (u?: User) => u?.email && u.email.endsWith('@gazelle.work')

export const isPrekInternalUser = ({ email }: User) =>
  email &&
  [
    'bo.martins@prek.se',
    'charlotte.haggnilsson@prek.se',
    'gustav.fasting@prek.se',
    'jesper.aronsson@prek.se',
    'linda.svahn@prek.se',
    'toni.laihinen@prek.se',
    'damijan.penic@higroup.si'
  ].includes(email)

export function shouldSetDefaultPropertyInfo(values: JaguarRequestForm) {
  return values.propertyInfo === '' && !values.googlePlace
}
