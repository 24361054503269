import { useUser } from '../hooks/QueryHooks'
import { useUserId } from '../hooks/useAppState'
import useLoggedInConfig from '../hooks/useLoggedInConfig'
import useQueryParams from '../libs/useQueryParams'
import Box from './Box'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import Layout from './Layout'
import { SettingsAIWriter } from './SettingsAIWriter'
import SettingsMyOrganization from './SettingsMyOrganization'
import SettingsPersonal from './SettingsPersonal'
import Tabs from './Tabs'

export enum SettingsTabs {
  PERSONAL = 0,
  ORG = 1,
  AI_WRITER = 2
}

export default function SettingsPage() {
  const { data: user } = useUser(useUserId())
  const { tabKey } = useQueryParams<any>()
  const config = useLoggedInConfig()

  if (!config.enableOrganizationView)
    return (
      <Layout>
        <Box fullWidth align="center" top>
          <SettingsPersonal />
        </Box>
      </Layout>
    )

  return (
    <Layout>
      <Box fullWidth align="center" top>
        <Tabs
          tabKey={tabKey}
          tabs={[
            {
              tabKey: SettingsTabs.PERSONAL,
              labelElement: <FigmaTextWithStyle type="button1" text="Personal" />,
              labelSelectedElement: <FigmaTextWithStyle type="button1" text="Personal" color="white" />,
              contentElement: <SettingsPersonal />
            },
            {
              tabKey: SettingsTabs.ORG,
              labelElement: <FigmaTextWithStyle type="button1" text="Organization" />,
              labelSelectedElement: <FigmaTextWithStyle type="button1" text="Organization" color="white" />,
              contentElement: <SettingsMyOrganization />,
              enable: !!user?.organizationId
            },
            {
              tabKey: SettingsTabs.AI_WRITER,
              labelElement: <FigmaTextWithStyle type="button1" text="AI Writer" />,
              labelSelectedElement: <FigmaTextWithStyle type="button1" text="AI Writer" color="white" />,
              contentElement: <SettingsAIWriter />,
              enable: !!user?.organizationId
            }
          ]}
        />
      </Box>
    </Layout>
  )
}
