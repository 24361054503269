import styled from '@emotion/styled'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useLoggedInConfig from '../hooks/useLoggedInConfig'
import { isSelectedPage } from '../libs/MenuHelper'
import Colors from '../../../../pure/libs/Colors'
import { CONTACT_US_URL, FAQ_URL } from '../../../../pure/libs/Consts'
import RoutePath from '../../../../pure/libs/RoutePath'
import { Texts } from '../../../../pure/libs/AppTexts'
import { TextKey } from '../../../../pure/types/Antiloop'
import Box from './Box'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import eventEmitter, { Events } from '../../../../pure/libs/EventEmitter'

export function MenuButtonsDesktop() {
  const navigate = useNavigate()
  const config = useLoggedInConfig()

  return (
    <Box direction="row" fullWidth spacing={tinySpacing} top>
      <Box left onClick={() => onClickCreateAd(navigate)} link>
        <MenuItem textKey={Texts.headerCreateAd} routePath={RoutePath.CREATE_AD} />
      </Box>
      {/** 
      <Box left onClick={() => navigate(RoutePath.UPLOAD)} link>
        <MenuItem textKey={Texts.headerSummarize} routePath={RoutePath.UPLOAD} />
      </Box>

      
       * <Box left onClick={() => navigate(RoutePath.DASHBOARD)} link>
        <MenuItem textKey={Texts.headerDashboard} routePath={RoutePath.DASHBOARD} />
      </Box>
       * 
      */}
      <Box left onClick={() => navigate(RoutePath.PRICING)} pointer>
        <MenuItem textKey={Texts.headerPricing} routePath={RoutePath.PRICING} />
      </Box>
    </Box>
  )
}

function MenuItem({ textKey, routePath, text }: { textKey: TextKey; routePath?: RoutePath; text?: string }) {
  return (
    <MenuItemContainer isSelected={isSelectedPage({ window, routePath })} bottom spacing={smallSpacing}>
      <Box left right spacing={tinySpacing}>
        <StyledFigmaTextWithStyle type="body1" textKey={textKey} text={text} />
      </Box>
    </MenuItemContainer>
  )
}

const StyledFigmaTextWithStyle = styled(FigmaTextWithStyle)`
  white-space: nowrap;
`

const MenuItemContainer = styled(Box)<{ isSelected?: boolean }>`
  ${({ isSelected }) => isSelected && `border-bottom: 4px solid ${Colors.secondary100};`}
`

export function onClickFAQ(): void {
  window.open(FAQ_URL, '_blank')
}

export function onClickContactUs() {
  window.open(CONTACT_US_URL, '_blank')
}

export const onClickCreateAd = (navigate: NavigateFunction) => {
  navigate(RoutePath.CREATE_AD)
  eventEmitter.emit(Events.CREATE_AD_CLICKED)
}
