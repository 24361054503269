import { asArray } from '../../../../pure/libs/Common.js'
import Images from '../../../../pure/libs/AppImages.js'
import { Texts } from '../../../../pure/libs/AppTexts.js'
import { TextKeysWithOnlyTexts } from '../../../../pure/libs/TextsDeprecated.js'
import { ImageKey, TextKey, TextKeyWithOnlyText } from '../../../../pure/types/Antiloop.js'
import { JaguarType } from '../../../../pure/types/types.js'
import { ValidationRules } from './ValidationRules.js'

export type SectionInfoItem = {
  sectionNameTextKey: TextKeyWithOnlyText
  describeDescriptionTextKey: TextKeyWithOnlyText
  areaHeaderTextKey?: TextKey
  placeholderTextKey: TextKeyWithOnlyText
  typeOfAdNameTextKey: TextKey
  typeOfAdDescriptionTextKey: TextKey
  propertyInfoNoOfCharsMin: number
  iconImageKey: ImageKey
  iconImageKeyBig: ImageKey
  jaguarSessionType: JaguarType
  checkedAsDefault?: boolean
  enableLocationInAdCreator: boolean
  enableTargetAudienceInAdCreator?: boolean
  enablePropertyTypeInAdCreator: boolean
  enableKeywordsInAdCreator: boolean
  landingPageCardImageKey: ImageKey
  landingPageHeaderTextKey: TextKey
  ordo: number
}

export const SectionInfo: {
  [section: string]: SectionInfoItem
} = {
  [JaguarType.OVERVIEW]: {
    sectionNameTextKey: TextKeysWithOnlyTexts.homeFormTabsOverview,
    typeOfAdNameTextKey: Texts.typeOfAdRowOverview,
    typeOfAdDescriptionTextKey: Texts.typeOfAdRowOverviewDescription,
    describeDescriptionTextKey: TextKeysWithOnlyTexts.homeFormDescribeObjectDescription,
    areaHeaderTextKey: Texts.homeFormAddress,
    placeholderTextKey: TextKeysWithOnlyTexts.homeFormPlaceholderOverview,
    jaguarSessionType: JaguarType.OVERVIEW,
    propertyInfoNoOfCharsMin: ValidationRules.overview.characterLimit,
    iconImageKey: Images.overviewAdIconSmall,
    iconImageKeyBig: Images.overviewAdIcon,
    checkedAsDefault: true,
    enableLocationInAdCreator: true,
    enablePropertyTypeInAdCreator: true,
    enableKeywordsInAdCreator: true,
    enableTargetAudienceInAdCreator: true,
    landingPageCardImageKey: Images.pngLoginPageGeneral,
    landingPageHeaderTextKey: Texts.loginPageGeneralCard,
    ordo: 0
  },
  [JaguarType.INTERIOR]: {
    sectionNameTextKey: TextKeysWithOnlyTexts.homeFormTabsInterior,
    typeOfAdNameTextKey: Texts.typeOfAdRowInterior,
    typeOfAdDescriptionTextKey: Texts.typeOfAdRowInteriorDescription,
    describeDescriptionTextKey: TextKeysWithOnlyTexts.homeFormInteriorDescribeDescription,
    placeholderTextKey: TextKeysWithOnlyTexts.homeFormPlaceholderInterior,
    jaguarSessionType: JaguarType.INTERIOR,
    propertyInfoNoOfCharsMin: ValidationRules.interior.characterLimit,
    iconImageKey: Images.interiorAdIconSmall,
    iconImageKeyBig: Images.interiorIcon,
    enableLocationInAdCreator: false,
    enablePropertyTypeInAdCreator: false,
    enableKeywordsInAdCreator: true,
    landingPageCardImageKey: Images.pngLoginPageInterior,
    landingPageHeaderTextKey: Texts.loginPageInteriorCardHeader,
    ordo: 1
  },
  [JaguarType.EXTERIOR]: {
    sectionNameTextKey: TextKeysWithOnlyTexts.homeFormTabsExterior,
    typeOfAdNameTextKey: Texts.typeOfAdRowExterior,
    typeOfAdDescriptionTextKey: Texts.typeOfAdRowExteriorDescription,
    describeDescriptionTextKey: TextKeysWithOnlyTexts.homeFormExteriorDescribeDescription,
    placeholderTextKey: TextKeysWithOnlyTexts.homeFormPlaceholderExterior,
    jaguarSessionType: JaguarType.EXTERIOR,
    iconImageKey: Images.exteriorAdIconSmall,
    iconImageKeyBig: Images.exteriorIcon,
    propertyInfoNoOfCharsMin: ValidationRules.exterior.characterLimit,
    enableLocationInAdCreator: false,
    enablePropertyTypeInAdCreator: false,
    enableKeywordsInAdCreator: true,
    landingPageCardImageKey: Images.pngLoginPageExterior,
    landingPageHeaderTextKey: Texts.loginPageExteriorCardHeader,
    ordo: 2
  },
  [JaguarType.AREA]: {
    sectionNameTextKey: TextKeysWithOnlyTexts.homeFormTabsArea,
    typeOfAdNameTextKey: Texts.typeOfAdRowArea,
    typeOfAdDescriptionTextKey: Texts.typeOfAdRowAreaDescription,
    describeDescriptionTextKey: TextKeysWithOnlyTexts.homeFormAreaDescribeDescription,
    areaHeaderTextKey: Texts.homeFormAddress,
    placeholderTextKey: TextKeysWithOnlyTexts.homeFormPlaceholderArea,
    jaguarSessionType: JaguarType.AREA,
    iconImageKey: Images.areaAdIconSmall,
    iconImageKeyBig: Images.areaIcon,
    propertyInfoNoOfCharsMin: ValidationRules.area.characterLimit,
    enableLocationInAdCreator: true,
    enablePropertyTypeInAdCreator: false,
    enableKeywordsInAdCreator: false,
    landingPageCardImageKey: Images.pngLoginPageArea,
    landingPageHeaderTextKey: Texts.loginPageAreaCardHeader,
    ordo: 3
  }
}

export const SectionInfoArray: SectionInfoItem[] = asArray(SectionInfo).sort((a, b) => a.ordo - b.ordo)
