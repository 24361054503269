import styled from '@emotion/styled'
import { bigSpacing } from '../enums/Spacings'
import useIsMobile from '../hooks/useIsMobile'
import useLoggedInConfig from '../hooks/useLoggedInConfig'
import { PRODUCT_PAGE_WIDTH } from '../libs/HardCodedSizes'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { Texts } from '../../../../pure/libs/AppTexts'
import Box from './Box'
import Layout from './Layout'
import TypeOfAdPageMobile from './ProductsMobilePage'
import { TypeOfAdSectionInfoRow } from './ProductsSectionInfoRow'
import { SectionInfoArray } from '../libs/SectionInfo'

export default function ProductsPage() {
  const isMobile = useIsMobile()
  const config = useLoggedInConfig()

  if (isMobile) return <TypeOfAdPageMobile />
  return (
    <Layout>
      <Box fullWidth align="center" fullPadding spacing={bigSpacing}>
        <FigmaText textKey={Texts.productsHeading} />
        <Box top>
          <FigmaTextWithLinebreaks styleTextKey={Texts.pricingSubheading} textKey={Texts.productsSubheading} />
        </Box>
        <Container fullWidth>
          <Box top fullWidth>
            {SectionInfoArray.map((sectionInfoItem, key) => (
              <Box key={key} fullWidth top>
                <TypeOfAdSectionInfoRow sectionInfoItem={sectionInfoItem} />
              </Box>
            ))}
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

const Container = styled(Box)`
  max-width: ${PRODUCT_PAGE_WIDTH}px;
`
