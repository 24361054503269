import { GoogleAPI, GoogleApiWrapper, IMapProps, Map as _Map, Marker } from 'google-maps-react'
import React, { CSSProperties, PropsWithChildren, useEffect, useState } from 'react'
import { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete'
import type { LatLng } from 'react-google-places-autocomplete/build/types'
import { HOME_FORM_MAX_WIDTH } from '../libs/HardCodedSizes'
import { GOOGLE_CLOUD_MAPS_API_KEY } from '../../../../pure/libs/Consts'
import { HomeFormGooglePlace, PlaceType } from '../../../../pure/types/GooglePlace'
import { AutoCompleteGooglePlace } from './AutoCompleteGooglePlace'
import Box from './Box'

const Map = _Map as unknown as React.FC<PropsWithChildren<IMapProps>>

const OFFSET = 50 // For some reason google maps add 50
const WIDTH = HOME_FORM_MAX_WIDTH - OFFSET
const HEIGHT = WIDTH / 2

export type GoogleMapsProps = {
  value?: HomeFormGooglePlace
  onChange: (value: { value: PlaceType | null }) => unknown
  enableMap?: boolean
  autoFocus?: boolean
  PopperComponent?: any
}

export const GoogleMap = (
  props: {
    google: GoogleAPI
    containerStyles?: CSSProperties
  } & GoogleMapsProps
) => {
  const { google, containerStyles, onChange, value, enableMap = true, autoFocus } = props
  const placeId = value?.value?.place_id
  const [latLng, setLatLng] = useState<LatLng>()

  useEffect(() => {
    if (!placeId) return
    geocodeByPlaceId(placeId)
      .then((results) => getLatLng(results[0]))
      .then(setLatLng)
      .catch(console.error)
  }, [placeId])

  const { width = `${WIDTH}px`, height = `${HEIGHT}px` } = containerStyles || {}

  return (
    <Box fullWidth bottom>
      <Box fullWidth>
        <AutoCompleteGooglePlace
          value={value?.value as any}
          onChange={(value) => onChange({ value })}
          autoFocus={autoFocus}
          PopperComponent={props.PopperComponent}
        />
      </Box>
      {latLng && enableMap && (
        <Box style={{ ...containerStyles, width, height, position: 'relative' }} top>
          <Map google={google} zoom={14} center={latLng} initialCenter={latLng}>
            <Marker onClick={() => ({})} position={latLng} />
          </Map>
        </Box>
      )}
    </Box>
  )
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_CLOUD_MAPS_API_KEY
})(GoogleMap)
