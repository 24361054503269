import React, { useEffect, useState } from 'react'
import { useAd, useExternalSessions } from '../hooks/QueryHooks'
import useAppState, { State } from '../hooks/useAppState'
import useLogin from '../hooks/useLogin'
import useNotification from '../hooks/useNotification'
import { formatHeadline, getUnpayedSession, useHouseTypeFromExternalSession } from '../libs/ExternalSessionHelper'
import { SignInPageProps } from '../libs/SignInHelper'
import useQueryParams from '../libs/useQueryParams'
import { captureException } from '../libs/SentryHelper'
import { Ad, ExternalSession } from '../../../../pure/types/types'
import AdCreatorPage from './AdCreatorPage'
import { ExternalSessionAdCTAComponent } from './ExternalSessionAdCTAComponent'
import { LoadingPage } from './LoadingPage'
import { ModalExternalSessionUnpayedSession } from './ModalExternalSessionUnpayedSession'
import { useGooglePlaceQueryForExternalSession } from './useGooglePlaceQueryForExternalSession'
import invariant from 'invariant'
import { KeywordsDrawerExternalSession } from './KeywordsDrawerExternalSession'
import { ExternalSessionQueryParams } from '../../../../pure/types/QueryParamTypes'
import { signInWithCustomToken } from '../libs/FirebaseHelper'
import { getExternalSession, updateExternalSession } from '../libs/DBApiHandler'

const ExternalSessionPage: React.FC<SignInPageProps> = () => {
  const queryParams = useQueryParams<ExternalSessionQueryParams>()
  const { externalSessionId, enableLogOutPutToScreen } = queryParams
  const { state } = useAppState()
  const [isLoadingLogin, setIsLoadingLogin] = React.useState(true)
  const [externalSession, setExternalSession] = useState<ExternalSession>()

  const { userId: fasadUserId } = externalSession || {}
  const { data: fasasdSessions = [], isLoading: isLoadingExternalSessions } = useExternalSessions(fasadUserId)

  const unpayedSession = getUnpayedSession(fasasdSessions, externalSession)

  const houseType = useHouseTypeFromExternalSession(externalSession)
  const { data: ad, isLoading: isLoadingAd } = useAd(externalSession?.adId)

  const { loading: isLoadingGooglePlace, data: googlePlace } = useGooglePlaceQueryForExternalSession(externalSession)

  const { error } = useNotification()

  const onLogin = (state: State) => Promise.resolve(state)

  const isLoadingUseLogin = useLogin({
    onLogin,
    onFinally: () => {
      setIsLoadingLogin(false)
    }
  })

  useEffect(() => {
    Promise.resolve().then((): any => {
      if (!externalSessionId) return error()
      return Promise.resolve()
        .then(() =>
          getExternalSession(externalSessionId).then((s) => {
            invariant(s, '!externalSession for if: %s', externalSessionId)
            setExternalSession(s)
            return s
          })
        )
        .then(({ authenticationToken }) => {
          if (!authenticationToken) return
          return signInWithCustomToken(authenticationToken)
        })
        .catch(captureException)
    })
  }, [externalSessionId])

  const _isLoadingAd = !!externalSession?.adId && isLoadingAd

  const logString = JSON.stringify(
    {
      isLoadingUseLogin,
      isLoadingLogin,
      _isLoadingAd,
      isLoadingGooglePlace,
      externalSession: !!externalSession,
      isLoadingExternalSessions
    },
    undefined,
    2
  )

  const loading =
    isLoadingUseLogin ||
    isLoadingLogin ||
    _isLoadingAd ||
    isLoadingGooglePlace ||
    !externalSession ||
    isLoadingExternalSessions

  // if (loading && enableLogOutPutToScreen) return logString
  if (loading) return <LoadingPage />

  if (!state.user._id) return null

  const onAdCompleted = ({ _id: adId }: Ad) => updateExternalSession({ ...externalSession, adId })

  if (unpayedSession)
    return (
      <ModalExternalSessionUnpayedSession
        onClose={() => ({})}
        unpayedSession={unpayedSession}
        externalSession={externalSession}
        setExternalSession={setExternalSession}
      />
    )

  return (
    <AdCreatorPage
      enableUpsell={false}
      AdCreatorAdCTAComponent={ExternalSessionAdCTAComponent}
      ad={ad}
      enableUpsellOnAdCreator={false}
      enableIsOverQuotaCheck={false}
      enableCopyAd={false}
      extra={{ externalSession }}
      googlePlace={googlePlace}
      houseType={houseType}
      locationAsHeadlineText={formatHeadline(externalSession)}
      enableMenu={false}
      enableLatestAds={false}
      onAdCompleted={onAdCompleted}
      enableRightBottomImage={false}
      enableUpdateAd={false}
      KeywordsDrawer={KeywordsDrawerExternalSession}
    />
  )
}

export default ExternalSessionPage
