import styled from '@emotion/styled'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import MaterialTableRow from '@mui/material/TableRow'
import { smallSpacing } from '../enums/Spacings'
import { useOrganization, useUser, useUsersForOrganization } from '../hooks/QueryHooks'
import { useUserId } from '../hooks/useAppState'
import useLoggedInConfig from '../hooks/useLoggedInConfig'
import FigmaText from './FigmaText'
import Colors from '../../../../pure/libs/Colors'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import Box from './Box'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { SettingsMyOrganizationForm } from './SettingsMyOrganizationForm'
import { SettingsMyOrganizationInviteUserAddIcon } from './SettingsMyOrganizationInviteUserAddIcon'
import { SettingsMyOrganizationRow } from './SettingsMyOrganizationRow'

export default function SettingsMyOrganization() {
  const config = useLoggedInConfig()
  const uid = useUserId()
  const { data: user } = useUser(uid)
  const { data: org } = useOrganization(user?.organizationId)

  let { data: users = [] } = useUsersForOrganization(user?.organizationId)
  users = users
    .filter((u) => u.organizationId === user?.organizationId)
    .filter((u) => !config.enableFilterOnDomainInOrganizatoinView || (org && u.email?.endsWith(org.domain)))

  return (
    <Box top fullWidth>
      <Box top bottom>
        <FigmaTextWithStyle type="h3" text="Members" />
      </Box>
      {org && <SettingsMyOrganizationForm org={org} />}
      {config.enableInviteUsers && (
        <Box fullWidth direction="row" justify="flex-end" bottom>
          <Box direction="row" align="center">
            <Box spacing={smallSpacing} right>
              <FigmaText textKey={TextsDeprecated.settingsUsersListInviteButtonText} />
            </Box>
            <SettingsMyOrganizationInviteUserAddIcon />
          </Box>
        </Box>
      )}
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <FigmaText textKey={TextsDeprecated.adminUserListColumnCellUsersText} />
              </TableCell>
              <TableCell>
                <FigmaText textKey={TextsDeprecated.adminUserListColumnCellCreatedText} />
              </TableCell>
              <TableCell>
                <FigmaText textKey={TextsDeprecated.adminUserListColumnCellLastActiveText} />
              </TableCell>
              <TableCell>
                <FigmaText textKey={TextsDeprecated.adminUserListColumnCellPermissionsText} />
              </TableCell>
              <TableCell>
                <FigmaText textKey={TextsDeprecated.adminUserListColumnCellStatusText} />
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, key) => (
              <SettingsMyOrganizationRow key={key} user={user} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const TableRow = styled(MaterialTableRow)`
  background-color: ${Colors.white};
`
