import { VitecNextOrderProduct } from '../components/VitecNextOrderProduct'
import useAppState from '../hooks/useAppState'
import { VitecNextOrderPage } from './VitecNextOrderPage'

export const VitecNextOrderProductPage = () => {
  const { state } = useAppState()
  const { vitecNextOrderProductPageState } = state

  if (!vitecNextOrderProductPageState) return <VitecNextOrderPage />

  return <VitecNextOrderProduct {...vitecNextOrderProductPageState} />
}
