import styled from '@emotion/styled'
import Colors from '../../../../pure/libs/Colors'
import { Texts } from '../../../../pure/libs/AppTexts'
import { BorderRadixes } from '../enums/BorderRadixes'
import Box from './Box'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { HomeFormCheckIcon } from './Icons'
import { useEffect, useState } from 'react'
import FigmaText from './FigmaText'
import { TextKey } from '../../../../pure/types/Antiloop'

export const VitecNextOrderProductSuccessMessage = ({
  textKey = Texts.uploadLoadingInspectionReportSuccess,
  text
}: {
  text?: string
  textKey?: TextKey
}) => {
  return (
    <Container fullPadding direction="row" fullWidth justify="center" align="center">
      <Box>
        <HomeFormCheckIcon color="white" />
      </Box>
      <Box left>
        <FigmaText textKey={textKey} text={text} />
      </Box>
    </Container>
  )
}
export const Container = styled(Box)`
  border-radius: ${BorderRadixes.moderate};
  background-color: ${Colors.success80};
`
