import { TextareaAutosize as MaterialTextAreaAutoSize } from '@mui/base'
import { TextFieldProps, styled } from '@mui/material'
import Colors from '../../../../pure/libs/Colors'
import { TextStyles } from '../../../../pure/libs/TextStyles'

export const VitecLandingImageCaptionsResultsTextField = (props: TextFieldProps) => {
  return <TextareaAutoSize autoFocus {...props} />
}

const style = TextStyles.body3.style.desktop
const TextareaAutoSize = styled(MaterialTextAreaAutoSize)({
  border: 'none',
  outline: 'none',
  background: 'transparent',
  resize: 'none',
  ...style,
  color: Colors.primary100
}) as any
