import React from 'react'
import styled from '@emotion/styled'
import { minSpacing, tinySpacing } from '../enums/Spacings'
import { PROGRESS_STATUS_CIRCLE_SIZE } from '../libs/HardCodedSizes'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import Box from './Box'
import { NoWrapFigmaText } from './StyledComponents'
import { User, OrgUserStatus } from '../../../../pure/types/types'
import Colors from '../../../../pure/libs/Colors'
import useAppState from '../hooks/useAppState'

export function SettingsMyOrganizationUserStatusColumn({ user }: { user: User }) {
  const { state } = useAppState()
  const status = user.orgUserStatus
  const textKey = getTextKey(status)
  return (
    <Box direction="row" align="center">
      <StatusCircle type={status}></StatusCircle>
      <Box left spacing={tinySpacing} direction="row" align="flex-end">
        <NoWrapFigmaText textKey={textKey} />
      </Box>
    </Box>
  )
}

const StatusCircle = styled.span<{ type?: OrgUserStatus }>`
  height: ${PROGRESS_STATUS_CIRCLE_SIZE};
  width: ${PROGRESS_STATUS_CIRCLE_SIZE};
  background-color: ${({ type }) => getColor(type)};
  border-radius: 50%;
  display: inline-block;
  margin-right: ${minSpacing};
`

const getColor = (type?: OrgUserStatus) => {
  if (type === OrgUserStatus.INVITED) return Colors.primary20
  if (type === OrgUserStatus.DISABLED) return Colors.neutral50
  return Colors.success100
}
function getTextKey(type?: OrgUserStatus) {
  if (type === OrgUserStatus.INVITED) return TextsDeprecated.adminUserListInvitedLabel
  if (type === OrgUserStatus.DISABLED) return TextsDeprecated.adminUserListDisabledLabel
  return TextsDeprecated.adminUserListActiveLabel
}
