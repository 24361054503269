import { getArray } from '../../../../pure/libs/Common'
import { NO_OF_LETTERS_IN_SIGN_IN_CODE } from '../../../../pure/libs/Consts'
import { SignInViewProps } from '../../../../pure/types/SignInTypes'

export const LETTER_ARRAY = getArray(NO_OF_LETTERS_IN_SIGN_IN_CODE)
export const onChangeText = (
  code: string,
  setCode: (code: string) => unknown,
  { onPressContinue, signInState }: SignInViewProps
) => {
  setCode(code)

  if (code.length === NO_OF_LETTERS_IN_SIGN_IN_CODE)
    onPressContinue({
      ...signInState,
      data: { ...signInState.data, code }
    })
}
