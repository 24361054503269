import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { Texts } from '../../../../pure/libs/AppTexts'
import { HomeFormGooglePlace } from '../../../../pure/types/GooglePlace'
import { JaguarRequestForm } from '../../../../pure/types/types'
import { AdCreatorKeywordButton } from './AdCreatorKeywordButton'
import Box from './Box'
import { AdCreatorAddKeyWordsIcon, AdCreatorLocationIcon } from './Icons'
import { ModalLocation } from './ModalLocation'
import { getTextWithDots } from './ProductsSectionInfoRow'
import Tooltip from './Tooltip'

const MAX_LENGTH = 20
export function AdCreatorFormSectionLocationButton({ formProps }: { formProps: UseFormReturn<JaguarRequestForm> }) {
  const [showModal, setShowModal] = useState(false)

  const value = formProps.watch('googlePlace') as HomeFormGooglePlace

  const description = value?.value?.description
  const tooltipTitle = (description || '').length > MAX_LENGTH ? description : ''
  return (
    <Box fullWidth>
      {showModal && <ModalLocation onClose={() => setShowModal(false)} formProps={formProps} />}
      <Tooltip title={tooltipTitle}>
        <AdCreatorKeywordButton
          Icon={AdCreatorLocationIcon}
          textKey={Texts.adCreatorFormSectionAddLocation}
          onClick={() => setShowModal(true)}
          text={description ? getTextWithDots(description, MAX_LENGTH) : undefined}
        />
      </Tooltip>
    </Box>
  )
}
