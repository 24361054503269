import styled from '@emotion/styled'
import Colors from '../../../../pure/libs/Colors'
import { Texts } from '../../../../pure/libs/AppTexts'
import { BorderRadixes } from '../enums/BorderRadixes'
import { Shadows } from '../enums/Shadows'
import { regularSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useIsMobile from '../hooks/useIsMobile'
import { onCopyText } from '../libs/onClickCopy'
import { AdCreatorAdSectionResponse } from './AdCreatorAdSectionResponse'
import Box from './Box'
import Button from './Button'
import { ButtonCopyAd } from './ButtonCopyAd'
import FigmaText from './FigmaText'
import FigmaTextWithStyleOverrides from './FigmaTextWithStyleOverrides'
import { AdCreatorRegenerate, InfoIcon } from './Icons'

export type LongShortDescriptionType = 'floating' | 'poinpoints'

export function VitecNextOrderProductLongShortDescriptionItem({
  header,
  description,
  onClickRegenerateAd,
  onClickPinPoints
}: {
  header: string
  description: string
  onClickRegenerateAd?: () => unknown
  onClickPinPoints?: (type: LongShortDescriptionType) => unknown
}) {
  const isMobile = useIsMobile()

  return (
    <Container fullPadding fullWidth position="relative">
      <Box fullWidth>
        <HeaderContainer fullWidth fullPadding spacing={smallSpacing} direction="row" multiline>
          <Box right>
            <InfoIcon />
          </Box>
          <Box>
            <FigmaTextWithStyleOverrides textKeys={{ en: Texts.longShortItemDescription }} />
          </Box>
        </HeaderContainer>
      </Box>
      {header && (
        <Box top fullWidth>
          <FigmaText textKey={Texts.adCreatorAdSectionHeadline} text={header} />
        </Box>
      )}
      {onClickPinPoints && (
        <Box top fullWidth direction="row" align="center">
          <TextTypeTabConatiner link onClick={() => onClickPinPoints('floating')} left right spacing={smallSpacing}>
            <FigmaText textKey={Texts.longShortTabFloatingText} />
          </TextTypeTabConatiner>
          <Box left spacing={tinySpacing}>
            <TextTypeTabConatiner link onClick={() => onClickPinPoints('poinpoints')} left right spacing={smallSpacing}>
              <FigmaText textKey={Texts.longShortTabPinpoints} />
            </TextTypeTabConatiner>
          </Box>
        </Box>
      )}
      <Box top fullWidth>
        <AdCreatorAdSectionResponse text={description} />
      </Box>
      <Box
        top
        fullWidth
        spacing={tinySpacing}
        // there is a weird spacing in AdCreatorAdSectionResponse forcing us to add some padding in the bottom => tech debt
      ></Box>
      <Box fullWidth align={isMobile ? 'center' : 'flex-start'}>
        <Box direction="row" align="center" style={{ flexWrap: 'wrap' }} justify="center">
          <Box top spacing={regularSpacing}>
            <ButtonCopyAd onClick={() => onCopyText(description)} />
          </Box>
          {!!onClickRegenerateAd && (
            <>
              <Box top left right fullWidth={isMobile} alignText="center">
                <FigmaText textKey={Texts.adCreatorAdSectionButtonsOr} />
              </Box>
              <Box top>
                <Button spacing={smallSpacing} onClick={onClickRegenerateAd}>
                  <Box direction="row" align="center">
                    <AdCreatorRegenerate />
                    <Box left spacing={tinySpacing}>
                      <FigmaText textKey={Texts.adCreatorAdSectionRegenerateAdButton} />
                    </Box>
                  </Box>
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Container>
  )
}

export const Container = styled(Box)`
  border-radius: ${BorderRadixes.moderate};
  background: ${Colors.white};
  box-shadow: ${Shadows.tiny};
`

export const HeaderContainer = styled(Box)`
  border-radius: ${BorderRadixes.moderate};
  background: ${Colors.neutral10};
  box-shadow: ${Shadows.medium};
`

export const TextTypeTabConatiner = styled(Box)`
  border-radius: ${BorderRadixes.moderate};
  background: ${Colors.neutral10};
  box-shadow: ${Shadows.medium};
`
