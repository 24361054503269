import invariant from 'invariant'
import KeyWordsDataJson from '../../pure/data/keywords.json' 
import { JaguarType } from '../types/types.js'
import { Texts } from './AppTexts.js'
import { TextKey } from '../types/Antiloop.js'
import { toPascalCase } from './getTextKeyIdForKeywordMapItem.js'

export const KeyWordData = KeyWordsDataJson

export enum KeywordHouseType {
  Villa = 'villa',
  Apartment = 'apartment',
  TownHouse = 'townhouse',
  TerracedHouse = 'terraced-house',
  Plot = 'plot',
  HolidayHome = 'holiday-home',
  TwoFloors = 'two-floors',
  OneFloor = 'one-floor'
}

export enum KeyWordType {
  HouseType = 'house-type',
  Rooms = 'rooms',
  RoomType = 'room-type',
  Interior = 'interior',
  Amenity = 'amenity',
  Other = 'other'
}

export type KeywordItem = {
  id: string
  type: string
  houseType: KeywordHouseType
  localizedName?: string
  section: JaguarType[]
  keywordType: KeyWordType
}

export function getTextKeyForKeywordItem(y: KeywordItem): TextKey {
  const res = findKeyForKeywordItem(y)
  invariant(res, 'Cant find textkey for id: %s', y.id)
  return res
}

export function findKeyForKeywordItem(y: KeywordItem): TextKey {
  return Texts[getTextKeyIdForKeywordItem(y)]
}

function getTextKeyIdForKeywordItem(y: KeywordItem) {
  return `keywordId${toPascalCase(y.id.replaceAll('-', ' '))}`
}
