import invariant from 'invariant'
import { Upload, UploadedFile } from '../../../../pure/types/JaguarTypes'
import { smallSpacing } from '../enums/Spacings'
import { useUploadedFile } from '../hooks/QueryHooks'
import * as DBApiHandler from '../libs/DBApiHandler'
import Box from './Box'
import { Checkbox } from './CheckboxFormik'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import Loader from './Loader'
import { VitecImage } from './ViteImage'
import { Container } from './VitecLandingImageCaptionsResults'
import { VitecLandingImageCaptionsResultsMenu } from './VitecLandingImageCaptionsResultsMenu'

import React, { useEffect } from 'react'

const TIMEOUT = 5000

export function VitecLandingImageCaptionsResult({
  file: _file,
  upload,
  index
}: {
  file: UploadedFile
  upload?: Upload
  index: number
}) {
  const [shouldShow, setShouldShow] = React.useState(false)
  const { data: file, isLoading } = useUploadedFile(_file.id, upload)

  const { metaData } = file || {}
  const PropertyAdditionalInfo = file?.PropertyAdditionalInfo || ''
  const fileChecked = file?.metaData?.checked
  const fileName = file?.id
  const checked = typeof fileChecked === 'boolean' ? fileChecked : true
  const caption = shouldShow ? PropertyAdditionalInfo : ''

  const onClickDone = (text: string) =>
    updateUploadedFile({
      ...file,
      metaData: { ...file?.metaData, userEditedText: text, fileName }
    } as UploadedFile)

  const onClickChecked = (checked: boolean) => {
    return updateUploadedFile({
      ...file,
      metaData: { ...file?.metaData, checked, fileName }
    } as UploadedFile)
  }

  const updateUploadedFile = (file?: UploadedFile) => {
    invariant(upload, 'upload is required')
    invariant(file, 'file is required')
    return DBApiHandler.updateUploadedFile(file, upload)
  }

  useEffect(() => {
    setTimeout(() => setShouldShow(true), Math.random() * TIMEOUT)
  }, [])

  if (isLoading) return null

  return (
    <Box right bottom key={index} spacing={smallSpacing}>
      <Container fullPadding multiline spacing={smallSpacing}>
        <Box fullWidth direction="row" justify="space-between">
          <FigmaTextWithStyle type="h8" text={fileName} />
          <Box>
            <Box>
              {!caption && <Loader size={15} color="primary" />}
              {!!caption && <Checkbox checked={checked} onChange={(e) => onClickChecked(!!e.currentTarget.checked)} />}
            </Box>
          </Box>
        </Box>
        <Box style={{ height: 80 }} fullWidth>
          {caption && <VitecLandingImageCaptionsResultsMenu text={caption} onClickDone={onClickDone} />}
        </Box>
        <VitecImage vitecImage={metaData} />
      </Container>
    </Box>
  )
}
