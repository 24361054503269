import { Controller, UseFormReturn } from 'react-hook-form'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useIsMobile, { useIsDesktop } from '../hooks/useIsMobile'
import { HOME_FORM_MAX_WIDTH } from '../libs/HardCodedSizes'
import { Texts } from '../../../../pure/libs/AppTexts'
import { JaguarRequestForm } from '../../../../pure/types/types'
import Box from './Box'
import Button from './Button'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import GoogleMap from './GoogleMap'
import { ModalCloseIcon } from './Icons'
import Modal, { ModalProps } from './Modal'
import { ModalFullScreen } from './ModalFullScreen'
import { HomeFormGooglePlace } from '../../../../pure/types/GooglePlace'
import FigmaText from './FigmaText'
import { Popper } from '@mui/material'
import ZIndices from '../enums/Zindices'

type Props = ModalProps & { formProps: UseFormReturn<JaguarRequestForm> }
export function ModalLocation(props: Props) {
  const { formProps, ...modalProps } = props
  const isMobile = useIsMobile()
  const ModalComponent = isMobile ? ModalFullScreen : Modal

  const value = formProps.watch('googlePlace') as HomeFormGooglePlace

  return (
    <ModalComponent {...modalProps}>
      <Box fullWidth style={{ maxWidth: HOME_FORM_MAX_WIDTH }} fullPadding spacing={smallSpacing}>
        <Box fullWidth align="flex-end">
          <Box pointer onClick={props.onClose}>
            <ModalCloseIcon />
          </Box>
        </Box>
        <Box fullWidth alignText="center">
          <FigmaTextWithStyle type="h4" textKey={Texts.modalLocationTitle} />
        </Box>
        <Box top spacing={smallSpacing} alignText="center" fullWidth>
          <FigmaTextWithStyle type="body2" textKey={Texts.modalLocationDescription} opacity={0.5} />
        </Box>
        <Box top fullWidth>
          <Controller
            name="googlePlace"
            control={formProps.control}
            render={({ field }) => {
              const onChange = (value): void => {
                field.onChange(value)
              }

              return (
                <Box fullWidth>
                  <FigmaText textKey={Texts.modalLocationSelectFieldLabel} />
                  <Box spacing={tinySpacing} top fullWidth>
                    <GoogleMap
                      value={value}
                      onChange={onChange}
                      enableMap={false}
                      autoFocus
                      PopperComponent={(props) => (
                        <Popper
                          {...props}
                          style={{ zIndex: ZIndices.modal, maxWidth: HOME_FORM_MAX_WIDTH, width: '100%' }}
                        >
                          <Box fullWidth>{props.children}</Box>
                        </Popper>
                      )}
                    />
                  </Box>
                </Box>
              )
            }}
          />
        </Box>
        <Box top fullWidth align="center">
          <Button textKey={Texts.modalLocationButton} onClick={props.onClose} />
        </Box>
      </Box>
    </ModalComponent>
  )
}
