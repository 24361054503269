import styled from '@emotion/styled'
import Tab from '@mui/material/Tab'
import MaterialTabs from '@mui/material/Tabs'
import React, { useState } from 'react'
import Colors from '../../../../pure/libs/Colors'
import Box from './Box'
import useIsMobile from '../hooks/useIsMobile'

type TabType = {
  tabKey?: number
  labelElement: React.ReactElement
  labelSelectedElement: React.ReactElement
  contentElement: React.ReactElement
  enable?: boolean
  default?: boolean
  onChangeTab?: () => unknown
}
type Props = { tabs: TabType[]; tabKey?: string }

export default function Tabs({ tabs: _tabs, tabKey }: Props) {
  const [value, setValue] = useState(tabKey || _tabs[0]?.tabKey?.toString() || '0')
  const _value = parseInt(value)
  const tabs = _tabs.filter((t) => t.enable !== false)
  const isMobile = useIsMobile()

  const handleChange = (event, newValue) => {
    setValue(newValue.toString())
    const onChangeTab = tabs[newValue]?.onChangeTab
    onChangeTab && onChangeTab()
  }

  if (tabs.length === 1) return tabs[0].contentElement

  return (
    <Wrapper fullWidth align="center">
      <Box width={isMobile ? '100%' : '50%'} align="center">
        <MaterialTabs value={_value} onChange={handleChange} aria-label="card-account">
          {tabs.map(({ labelElement, labelSelectedElement }, i) => (
            <Tab label={_value === i ? labelSelectedElement : labelElement} {...a11yProps(i)} key={i} className="tab" />
          ))}
        </MaterialTabs>
        <Container>
          <Box>
            {tabs.map(({ contentElement }, i) => (
              <TabPanel value={_value} index={i} key={i}>
                {contentElement}
              </TabPanel>
            ))}
          </Box>
        </Container>
      </Box>
    </Wrapper>
  )
}

const Wrapper = styled(Box)<{ tabWidth?: string }>`
  .MuiTabs-indicator {
    background-color: ${Colors.neutral50};
  }

  .MuiTab-root {
    background-color: ${Colors.white};
    white-space: nowrap;
    align-items: center;
  }

  .Mui-selected {
    background-color: ${Colors.primary100};
  }
`

const Container = styled(Box)`
  position: relative;
`

function TabPanel(props: any) {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      fullWidth
      hidden={value !== index}
      id={`card-account-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `card-account-tabpanel-${index}`
  }
}
