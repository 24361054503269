import * as Yup from 'yup'
import { URL_REGEXP } from '../../../../pure/libs/Consts'
import { getFigmaText } from '../libs/TextRepository'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'

const YYYY_MM_DD_REGEXP = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/
export const NAME_REGEX = /(\w.+\s).+/i

const getMessage = (message?: string) => {
  if (typeof message === 'string') return message
  return `${getFigmaText(TextsDeprecated.genericInputErrorText)}`
}

export const RequiredTextValidation = (m?: string, numbeOfChars = 2) => {
  const message = getMessage(m)
  return Yup.string().required(message).min(numbeOfChars, message)
}

// TODO remove use RequiredTextValidation
export const RequiredNameValidationDeprecated = (name: string, message?: string) => {
  const _message = getMessage(message)
  return Yup.string().required(_message).min(2, _message)
}

export const TextValidation = () => Yup.string().min(2, getMessage())

// TODO remove use RequiredTextValidation
export const TextValidationDeprecated = (name: string, message?: string) => {
  const _message = getMessage(message)
  return Yup.string().min(2, _message)
}

export const NumberValidation = (message: string) => {
  const _message = getMessage(message)
  return Yup.number().typeError(_message)
}

export const RequiredNumberValidation = (message: string) => {
  const _message = getMessage(message)
  return Yup.number().typeError(_message).required(_message)
}

export const NumberValidationDeprecated = (name: string, message?: string) => {
  const _message = getMessage(message)
  return Yup.number().typeError(_message)
}

export const EmailValidation = (name: string, message?: string) => {
  const _message = getMessage(message)
  return Yup.string().required(_message).typeError(_message).email(_message)
}

export const DateValidation = (message: string) => Yup.string().required(message).matches(YYYY_MM_DD_REGEXP, message)

export const UrlValidation = (message = 'Enter Url') => Yup.string().matches(URL_REGEXP, { message })

export const PersonNameValidation = (message) => Yup.string().matches(NAME_REGEX, { message }).required(message)

export const KeyResultValidation = Yup.object().shape({
  name: RequiredTextValidation(),
  startValue: RequiredNumberValidation(getFigmaText(TextsDeprecated.rightPanelIndicatorStartValueError)),
  goalValue: RequiredNumberValidation(getFigmaText(TextsDeprecated.rightPanelIndicatorEndValueError))
})

export const IsNotMemberOfArrayValidation = (arr: string[], text: string) =>
  Yup.string().test('Unique', text, (text = '') => {
    return !arr.includes(text)
  })
