import OBPFigmaTexts from '../../../../pure/obp/i18n/figma.json' assert { type: 'json' }
import OBPSvtexts from '../../../../pure/obp/i18n/sv.json' assert { type: 'json' }
import GazelleFigmaTexts from '../../../../pure/assets/i18n/figma.json' assert { type: 'json' }
import GazelleSvtexts from '../../../../pure/assets/i18n/sv.json' assert { type: 'json' }
import { Language, TextKeyWithOnlyText } from '../../../../pure/types/Antiloop.js'
import { sv } from '../../../../pure/libs/Consts.js'
import { getLanguageCode } from '../hooks/useDefaultLanguage'
import { assert } from 'console'
const XXX = 'XXX'
export type Printable = string | number | undefined

export type Options = {
  boldParams?: boolean
  boldParamsIndex?: number
  splitCharacter?: string
}

const Svtexts = { ...OBPSvtexts.figma, ...GazelleSvtexts }
const FigmaTexts = { ...OBPFigmaTexts.figma, ...GazelleFigmaTexts }

export const getFigmaText = (textKey: TextKeyWithOnlyText, language: Language = getLanguageCode()): string => {
  switch (language) {
    case sv:
      return Svtexts[textKey.name] || textKey.characters || ''
    default:
      return FigmaTexts[textKey.name] || textKey.characters || ''
  }
}

export const formatText = (
  text: string,
  params: Printable[],
  { boldParams, boldParamsIndex, splitCharacter = XXX }: Options = {}
): string => {
  const _params = params
    .map((p) => p?.toString() || '')
    .map((p, i) => {
      if (boldParams) return makeBold(p)
      if (boldParamsIndex === i) return makeBold(p)
      return p
    })

  return text
    .split(splitCharacter)
    .reduce((a, b, i) => ({ res: a.res + b + (_params[a.i] || '').toString(), i: i + 1 }), { res: '', i: 0 }).res
}

export function makeBold(p: string): string {
  return `<b>${p}</b>`
}
