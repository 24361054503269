import invariant from 'invariant'
import { useNavigate } from 'react-router-dom'
import { Shadows } from '../enums/Shadows'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { SectionInfoItem } from '../libs/SectionInfo'
import FigmaText from './FigmaText'
import Images from '../../../../pure/libs/AppImages'
import RoutePath from '../../../../pure/libs/RoutePath'
import { getFigmaText } from '../libs/TextRepository'
import { JaguarType } from '../../../../pure/types/types'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { NoWrapFigmaText } from './StyledComponents'
import styled from '@emotion/styled'
import Colors from '../../../../pure/libs/Colors'
import { BorderRadixes } from '../enums/BorderRadixes'

const DESCRIPTION_TEXT_LENGTH = 100

export function TypeOfAdSectionInfoRow({
  sectionInfoItem,
  enableShorterDescriptions = false
}: {
  sectionInfoItem: SectionInfoItem
  enableShorterDescriptions?: boolean
}) {
  const { state, setState } = useAppState()
  const navigate = useNavigate()
  const onSelectSection = (jaguarType?: JaguarType) => {
    invariant(jaguarType, 'jaguarType is undefined')
    setState({ ...state, defaultJaguarType: jaguarType })
    navigate(RoutePath.CREATE_AD)
  }

  let descriptionText = getFigmaText(sectionInfoItem.typeOfAdDescriptionTextKey)
  if (enableShorterDescriptions) descriptionText = getTextWithDots(descriptionText)
  return (
    <Container
      fullWidth
      direction="row"
      bottom
      fullPadding
      spacing={smallSpacing}
      pointer
      justify="space-between"
      onClick={() => onSelectSection(sectionInfoItem.jaguarSessionType)}
      boxShadow={Shadows.small}
    >
      <Box direction="row" align="center">
        <Box top left spacing={tinySpacing}>
          <FigmaImageContainer imageKey={sectionInfoItem.iconImageKeyBig} />
        </Box>
        <Box left>
          <NoWrapFigmaText textKey={sectionInfoItem.typeOfAdNameTextKey} />
          <FigmaText textKey={sectionInfoItem.typeOfAdDescriptionTextKey} text={descriptionText} />
        </Box>
      </Box>
      <Box alignSelf="center" left>
        <FigmaImageContainer imageKey={Images.productsArrowRight} />
      </Box>
    </Container>
  )
}

export const getTextWithDots = (text = '', length = DESCRIPTION_TEXT_LENGTH) => {
  if (text.length > length) return `${text.substr(0, length)} ...`
  return text
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.moderate};
`
