import { smallSpacing } from '../enums/Spacings'
import Images from '../../../../pure/libs/AppImages'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { SignInViewProps } from '../../../../pure/types/SignInTypes'
import Box from './Box'
import ContinueWithButton from './ContinueWithButton'
import { shouldShowGoogle, shouldShowMicrosoft } from '../libs/SignInWithAuthProvidersHelper'
import config from '../Config'

// Set to true if you want to create an app for Apple App Store
const enableAppleProvider = false

export function SignInWithAuthProviders(signInViewProps: SignInViewProps) {
  const { onLoginWithGoogle, onLoginWithMicrosoft, onLoginWithApple, signInState } = signInViewProps
  const { preferredLoginMethod } = signInState

  const showGoogle = shouldShowGoogle(preferredLoginMethod)
  const showMicrosoft = shouldShowMicrosoft(preferredLoginMethod) && config.enableOffice365Login

  return (
    <Box fullWidth>
      {showGoogle && (
        <Box fullWidth>
          <ContinueWithButton
            onLogin={onLoginWithGoogle}
            textKey={TextsDeprecated.onboardingAuthProviderGoogleButtonText}
            imageKey={Images.googleIconSmall}
          />
        </Box>
      )}
      {showMicrosoft && (
        <Box top fullWidth spacing={smallSpacing}>
          <ContinueWithButton
            onLogin={onLoginWithMicrosoft}
            textKey={TextsDeprecated.onboardingAuthProviderMicrosoftButtonText}
            imageKey={Images.office365}
          />
        </Box>
      )}
    </Box>
  )
}
