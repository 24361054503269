import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { createUniqueId } from '../../../../pure/libs/Common'
import Images from '../../../../pure/libs/AppImages'
import { Texts } from '../../../../pure/libs/AppTexts'
import config from '../Config'
import { State } from '../hooks/useAppState'
import { createJaguarOrderForVitecNextOrder } from '../libs/CloudFunctionsApiHandler'
import { updateVitecNOOrder } from '../libs/DBApiHandler'
import { VitecNextOrderProductPageState, VitecNextProductInfoItem } from '../types/VitecNextProductTypes'
import { VitecNextOrderProductByggemate } from './VitecNextOrderProductByggemate'
import { VitecNextOrderProductImageCaptions } from './VitecNextOrderProductImageCaptions'
import { VitecNextOrderProductLongShortDescription } from './VitecNextOrderProductLongShortDescription'

const createJaguarOrder = async (s: VitecNextOrderProductPageState, state: State) =>
  _getOrCreateJaguarOrder(s, state, { enableGetExistingJaguarOrder: false })

const getOrCreateJaguarOrder = async (s: VitecNextOrderProductPageState, state: State) =>
  _getOrCreateJaguarOrder(s, state, { enableGetExistingJaguarOrder: true })

const _getOrCreateJaguarOrder = async (
  { vitecNOOrder, vitecNextProductInfoItem }: VitecNextOrderProductPageState,
  state: State,
  { enableGetExistingJaguarOrder = true }
) => {
  const vitecNextProduct = vitecNextProductInfoItem.vitecNextProduct
  let requestId = vitecNOOrder.jaguarResponses?.[vitecNextProduct]?.requestId
  if (enableGetExistingJaguarOrder && requestId) return vitecNOOrder

  requestId = createUniqueId()

  vitecNOOrder = await updateVitecNOOrder({
    ...vitecNOOrder,
    jaguarResponses: {
      ...vitecNOOrder.jaguarResponses,
      [vitecNextProduct]: { requestId }
    }
  })

  await createJaguarOrderForVitecNextOrder(
    {
      requestId: requestId,
      vitecNextOrderId: vitecNOOrder.orderId,
      vitecNextProduct
    },
    state
  )

  return vitecNOOrder
}

export const VitecNextProductInfoItems: {
  [property in VitecNextProduct]: VitecNextProductInfoItem
} = {
  [VitecNextProduct.IMAGE_CAPTIONS]: {
    textKey: Texts.orderViewButtonBildetekst,
    imageKey: Images.orderViewButtonBildetekst,
    slug: 'bildetekst',
    Component: VitecNextOrderProductImageCaptions,
    vitecNextProduct: VitecNextProduct.IMAGE_CAPTIONS,
    onClick: getOrCreateJaguarOrder,
    onClickRunAgain: createJaguarOrder
  },
  [VitecNextProduct.BYGGEMÅTE]: {
    textKey: Texts.orderViewButtonByggemate,
    imageKey: Images.orderViewButtonByggemate,
    slug: 'byggemate',
    Component: VitecNextOrderProductByggemate,
    vitecNextProduct: VitecNextProduct.BYGGEMÅTE,
    onClick: getOrCreateJaguarOrder,
    onClickRunAgain: createJaguarOrder
  },
  [VitecNextProduct.SHORT_LONG_DESCRIPTION]: {
    textKey: Texts.orderViewButtonShortAndLong,
    imageKey: Images.orderViewButtonShortAndLong,
    slug: 'short-and-long',
    Component: VitecNextOrderProductLongShortDescription,
    vitecNextProduct: VitecNextProduct.SHORT_LONG_DESCRIPTION
  },
  [VitecNextProduct.VALIDATE_AD]: {
    textKey: Texts.orderViewButtonAdValidation,
    imageKey: Images.orderViewButtonAdValidation,
    slug: 'ad-validation',
    enabled: config.enableValidateAdProduct,
    vitecNextProduct: VitecNextProduct.VALIDATE_AD,
    onClick: getOrCreateJaguarOrder,
    onClickRunAgain: createJaguarOrder
  }
}
