//@flow
import moment from 'dayjs'
import { createUniqueId, getEmailForFirebaseUser } from '../../../../pure/libs/Common.js'
import { FirebaseUser, Id, User } from '../../../../pure/types/types.js'
import { State } from '../hooks/useAppState.js'

export const toBaseObject = ({ user: { _id: userId } }: State, id: Id = createUniqueId(), now = moment().format()) => ({
  _id: id,
  createdAt: now,
  createdBy: userId
})

export const toUser = (user: FirebaseUser, _user?: Partial<User>): User => ({
  createdAt: moment().format(),
  createdBy: user.uid,
  ..._user,
  _id: user.uid,
  name: _user?.name || user.displayName || '',
  email: getEmailForFirebaseUser(user) || undefined,
  usedSessions: 0,
  shouldShowTutorial: true
})
