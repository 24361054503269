import { Upload, UploadedFile } from '../../../../pure/types/JaguarTypes.js'
import * as QueryHooksJs from './QueryHooks.js'
import { useUpload } from './QueryHooks.js'
import { ReducerState } from './useFirestoreQueryHelper.js'

export const useUploadsForIds = (ids: string[]): ReducerState<Upload[]> => {
  const queries = ids.map((id) => useUpload(id))
  return {
    status: queries.some((query) => query.status === 'error') ? 'error' : 'success',
    data: queries
      .filter((query) => !!query.data)
      .map((query) => query.data as Upload)
      .flat(),
    isLoading: queries.some((query) => query.isLoading)
  }
}

export const useUploadedFilesForUploadIds = (ids: string[]): ReducerState<UploadedFile[]> => {
  const queries = ids.map((id) => useUploadedFiles(id))
  return {
    status: queries.some((query) => query.status === 'error') ? 'error' : 'success',
    data: queries
      .filter((query) => !!query.data)
      .map((query) => query.data as UploadedFile[])
      .flat(),
    isLoading: queries.some((query) => query.isLoading)
  }
}

export const useUploadedFiles = QueryHooksJs._useUploadedFiles
