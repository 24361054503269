import { UseFormReturn } from 'react-hook-form'
import { regularSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { KeywordMapItem } from '../libs/KeywordMapHelper'
import { getFigmaText } from '../libs/TextRepository'
import { TextKey } from '../../../../pure/types/Antiloop'
import { JaguarRequestForm } from '../../../../pure/types/types'
import Box from './Box'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { KeywordsDrawerRowSectionButton } from './KeywordsDrawerRowSectionButton'

export function KeywordsDrawerRow({
  keywordMap,
  formProps,
  headerTextKey
}: {
  keywordMap: KeywordMapItem
  formProps: UseFormReturn<JaguarRequestForm>
  headerTextKey: TextKey
}) {
  const entries = Object.entries(keywordMap).filter(([_]) => _ !== 'needed_renovations') // TODO: write test, https://gazelle-work.slack.com/archives/C052CKTTSKU/p1696352623159089
  return (
    <Box fullWidth>
      <Box spacing={regularSpacing}>
        <FigmaTextWithStyle type="body1" text={getFigmaText(headerTextKey)} />
      </Box>
      <Box top spacing={smallSpacing} fullWidth>
        {entries.map(([keywordId, keywordMapItem], key) => (
          <Box key={key} spacing={tinySpacing} top right fullWidth>
            <KeywordsDrawerRowSectionButton
              keywordId={keywordId}
              keywordMapItem={keywordMapItem}
              formProps={formProps}
            />
          </Box>
        ))}
      </Box>
    </Box>
  )
}
