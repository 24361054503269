import ButtonEmptyState from './ButtonEmptyState'
import ButtonPrimary, { ButtonProps } from './ButtonPrimary'
import ButtonSecondary from './ButtonSecondary'
import ButtonTertiary from './ButtonTertiary'
import ButtonWhite from './ButtonWhite'

export default function Button(props: ButtonProps) {
  const { buttonType, ...buttonProps } = props

  if (buttonType === 'white') return <ButtonWhite {...buttonProps} />
  if (buttonType === 'secondary') return <ButtonSecondary {...buttonProps} />
  if (buttonType === 'tertiary') return <ButtonTertiary {...buttonProps} />
  if (buttonType === 'empty_state') return <ButtonEmptyState {...buttonProps} />
  return <ButtonPrimary {...buttonProps} />
}
