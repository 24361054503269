import React from 'react'
import Box from './Box'

import styled from '@emotion/styled'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import Colors from '../../../../pure/libs/Colors'
import { Texts } from '../../../../pure/libs/AppTexts'
import { UploadFilesRequest } from '../../../../pure/types/JaguarTypes'
import { VitecNOOrder } from '../../../../pure/types/VitecNODerivedTypes.js'
import config from '../Config'
import { BorderRadixes } from '../enums/BorderRadixes'
import { Shadows } from '../enums/Shadows'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import { useUploadedFiles } from '../hooks/useUploadedFiles'
import { UploadViewProps } from '../libs/UploadHelper'
import FigmaTextLoader from './FigmaTextLoader'
import { UploadLoading } from './UploadLoading'
import { VitecNextOrderProductByggemateResult } from './VitecNextOrderProductByggemateResult'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'
import { VitecNextOrderProductSuccessMessage } from './VitecNextOrderProductByggemateResultSuccessMessage'
import { BYGGEMATE_WIDTH } from '../libs/HardCodedSizes'

// test Data: http://localhost:5173/vitec-megler-landing?orderId=0d8e882e-20a2-4e68-a263-dffbac93be87
// Prod: https://app.gazelle.work/vitec-megler-landing?orderId=0d8e882e-20a2-4e68-a263-dffbac93be87

export const VitecNextOrderProductByggemate: React.FC<{ vitecNOOrder: VitecNOOrder }> = ({
  vitecNOOrder: _vitecNOOrder
}) => {
  const { data: vitecNOOrder } = useVitecNOOrder(_vitecNOOrder._id)
  const requestId = vitecNOOrder?.jaguarResponses?.[VitecNextProduct.BYGGEMÅTE]?.requestId
  const { data: uploads = [] } = useUpload(requestId || '')
  const { data: uploadedFiles = [], isLoading } = useUploadedFiles(requestId || '')

  const upload = uploads[0]

  const Component = useComponent({
    upload,
    uploadedFiles
  })

  return (
    <Box fullWidth>
      <Box fullWidth fullPadding direction="row" justify="space-between" align="center">
        <VitecNextOrderProductBackButton />
        <Box width={`${BYGGEMATE_WIDTH}px`}>
          {vitecNOOrder?.jaguarResponses?.BYGGEMÅTE?.hasSentResponse && <VitecNextOrderProductSuccessMessage />}
        </Box>
        <Box />
      </Box>
      {isLoading && <FigmaTextLoader />}
      {!isLoading && (
        <Component
          uploadFileRequest={uploads as unknown as UploadFilesRequest} // we dont have the request here atm, lets see if stuff breakes
          uploadedFiles={uploadedFiles}
          onClickUploadFile={console.log}
          upload={upload}
        />
      )}
    </Box>
  )
}

export const ContainerWithGradient = styled(Box)`
  min-height: 100vh;
  background: var(--LinearVertical, linear-gradient(180deg, ${Colors.primary100} 0%, ${Colors.secondary100} 100%));
`

const useComponent = ({ uploadedFiles = [] }: Partial<UploadViewProps>): React.FC<UploadViewProps> => {
  const headerTextKey = Texts.uploadFilesLoadingInspectionReport
  const UploadLoadingComponent = (props) => <UploadLoading {...props} headerTextKey={headerTextKey} />

  switch (true) {
    case uploadedFiles.some((file) => file.processingStep === 'Done'):
      return VitecNextOrderProductByggemateResult
    default:
      return UploadLoadingComponent
  }
}

export const FileBoxContainer = styled(Box)`
  border-radius: ${BorderRadixes.regular};
  background: ${Colors.white};
  box-shadow: ${Shadows.tiny};
`
