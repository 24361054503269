import Box from './Box'

import styled from '@emotion/styled'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import Colors from '../../../../pure/libs/Colors'
import { VitecNOOrder } from '../../../../pure/types/VitecNODerivedTypes.js'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import { useUploadedFiles } from '../hooks/useUploadedFiles'
import { UploadViewProps } from '../libs/UploadHelper'
import { UploadLoading } from './UploadLoading'
import { VitecLandingImageCaptionsLeftColumn } from './VitecLandingImageCaptionsLeftColumn'
import { VitecLandingImageCaptionsResults } from './VitecLandingImageCaptionsResults'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'
import { VitecNextOrderProductSuccessMessage } from './VitecNextOrderProductByggemateResultSuccessMessage'
import { Texts } from '../../../../pure/libs/AppTexts'
import { BYGGEMATE_WIDTH } from '../libs/HardCodedSizes'

export function VitecNextOrderProductImageCaptions({ vitecNOOrder: _vitecNOOrder }: { vitecNOOrder: VitecNOOrder }) {
  const { data: vitecNOOrder } = useVitecNOOrder(_vitecNOOrder._id)
  const requestId = vitecNOOrder?.jaguarResponses?.[VitecNextProduct.IMAGE_CAPTIONS]?.requestId
  const { data: uploadedFiles = [] } = useUploadedFiles(requestId || '')
  const { data } = useUpload(requestId)

  const Component = useComponent({
    uploadedFiles
  })

  const props = { uploadedFiles: uploadedFiles, onClickUploadFile: console.log, upload: data } as UploadViewProps

  const uploadedFilesWithCaption = uploadedFiles.filter((f) => !!f?.PropertyAdditionalInfo)

  return (
    <Box fullWidth>
      <Box fullWidth fullPadding direction="row" justify="space-between" align="center">
        <VitecNextOrderProductBackButton />
        <Box width={`${BYGGEMATE_WIDTH}px`}>
          {!!vitecNOOrder?.jaguarResponses?.IMAGE_CAPTIONS?.hasSentResponse && (
            <VitecNextOrderProductSuccessMessage
              textKey={Texts.captionLeftWidgetButton}
              text={`${uploadedFiles.length} captions was \nsuccessfully sent to Next!`}
            />
          )}
        </Box>
        <Box />
      </Box>
      <Box direction="row" fullWidth fullPadding>
        {uploadedFilesWithCaption.length > 0 && (
          <Box>
            <VitecLandingImageCaptionsLeftColumn uploadedFiles={uploadedFiles} vitecNOOrder={vitecNOOrder} />
          </Box>
        )}
        <Box fullWidth align="center">
          <Component {...props} />
        </Box>
      </Box>
    </Box>
  )
}

const useComponent = ({ uploadedFiles = [] }: Partial<UploadViewProps>): React.FC<UploadViewProps> => {
  const hasDoneImages = uploadedFiles.some((f) => ['done'].includes(f.status))

  switch (true) {
    case hasDoneImages:
      return VitecLandingImageCaptionsResults
    default:
      return UploadLoading
  }
}

export const ContainerWithGradient = styled(Box)`
  min-height: 100vh;
  background: var(--LinearVertical, linear-gradient(180deg, ${Colors.secondary100} 0%, ${Colors.primary100} 100%));
`
