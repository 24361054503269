import styled from '@emotion/styled'
import dayjs from 'dayjs'
import invariant from 'invariant'
import { useState } from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { getActiveSortedSessions } from '../libs/AdCreatorFormHelper'
import FigmaText from './FigmaText'
import { YYYYMMDD_HHmm } from '../../../../pure/libs/Consts'
import { Texts } from '../../../../pure/libs/AppTexts'
import { Ad } from '../../../../pure/types/types'
import { AdCreatorLatestAdsItemSession } from './AdCreatorLatestAdsItemSession'
import Box from './Box'
import { DEFAULT_ICON_SIZE, LatestAdsRemoveIcon } from './Icons'
import { SectionInfo } from '../libs/SectionInfo'
import { useCurrentProduct } from '../hooks/useCurrentProduct'
import useLoggedInConfig from '../hooks/useLoggedInConfig'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { removeAd } from '../libs/DBApiHandler'

export const LIST_ITEM_MAX_TEXT_LENGTH = 360
export function AdCreatorLatestAdsItem({ ad, onClickAd }: { ad: Ad; onClickAd?: (ad: Ad) => unknown }) {
  const headline = Object.values(ad.activeSessions || {}).find((session) => !!session?.headline)?.headline || ''
  const sessions = getActiveSortedSessions(ad, SectionInfo)
  const [isHovering, setIsHovering] = useState(false)
  const { data: product } = useCurrentProduct()
  const config = useLoggedInConfig()

  // TODO WRITE TEST, should be able to remove ads if product or config says so
  const enableRemoveAds = product?.enableRemoveAds || config.enableRemoveAds

  const onMouseEnter = () => enableRemoveAds && setIsHovering(true)
  const onMouseLeave = () => setIsHovering(false)
  const onClickRemoveAd = () => enableRemoveAds && removeAd(ad)

  return (
    <Container
      fullPadding
      spacing={smallSpacing}
      fullWidth
      position="relative"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Box
        position="absolute"
        style={{ top: -DEFAULT_ICON_SIZE, right: -DEFAULT_ICON_SIZE / 2, display: isHovering ? 'block' : 'none' }}
        pointer
        onClick={onClickRemoveAd}
      >
        <LatestAdsRemoveIcon />
      </Box>
      <FigmaText textKey={Texts.latestAdsDate} text={dayjs(ad.createdAt).format(YYYYMMDD_HHmm)} />
      <Box top spacing={tinySpacing} onClick={() => onClickAd?.(ad)} link={!!onClickAd}>
        <FigmaTextWithStyle type="body3" text={headline} />
      </Box>
      <Box top spacing={tinySpacing} fullWidth>
        {sessions.map((s, key) => {
          invariant(s, 'session is not null')
          return (
            <Box key={key} top={key > 0} spacing={tinySpacing} fullWidth>
              <Box top spacing={tinySpacing} fullWidth>
                <AdCreatorLatestAdsItemSession session={s} maxTextLength={LIST_ITEM_MAX_TEXT_LENGTH} />
              </Box>
            </Box>
          )
        })}
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
`
