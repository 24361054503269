import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'
import { smallSpacing } from '../enums/Spacings'
import { useUser } from '../hooks/QueryHooks'
import useAppState from '../hooks/useAppState'
import { StatusInfo, StatusInfoItem, checkProperyInfoDebounced } from '../libs/AdCreatorFormSectionStatusHelper'
import { SectionInfo } from '../libs/SectionInfo'
import { captureException } from '../libs/SentryHelper'
import { getText } from '../libs/getText'
import FigmaText from './FigmaText'
import { NOT_ENOUGH_INFO_ERROR } from '../../../../pure/libs/Consts'
import { Texts } from '../../../../pure/libs/AppTexts'
import { JaguarRequestForm } from '../../../../pure/types/types'
import Box from './Box'
import Loader from './Loader'

export type OnNewJaguarRequest = (req: JaguarRequestForm | undefined) => unknown

export function AdCreatorFormSectionStatus({
  formProps,
  validByServerContext
}: {
  formProps: UseFormReturn<JaguarRequestForm>
  validByServerContext: [boolean | undefined, (val: boolean | undefined) => void]
}) {
  const { control } = formProps
  const [_, setValidByServer] = validByServerContext
  const propertyInfo = useWatch({ name: 'propertyInfo', control }) || ''
  const keywordMapItems = useWatch({ name: 'keywordMapItems', control }) || []

  const type = useWatch({ name: 'type', control })
  const section = SectionInfo[type]
  const { state } = useAppState()
  const { data: user = state.user } = useUser(state.user._id)
  const [statusInfo, setStatusInfo] = useState<StatusInfoItem>(StatusInfo.default)
  const [loading, setLoading] = useState(false)

  const text = getText({ statusInfo, propertyInfo, section, keywordMapItems })

  useEffect(() => {
    if (!formProps.formState.isDirty) return
    Promise.resolve(setLoading(true))
      .then(() =>
        checkProperyInfoDebounced({ formProps, user })
          .then((res) => {
            setStatusInfo(StatusInfo.success)
            setValidByServer(true)
          })
          .catch((err) => {
            if (!!err.message?.includes('Failed to fetch')) return // surpress network errors https://gazelle-work.slack.com/archives/C052E16DWMQ/p1696406364665379?thread_ts=1696404665.095539&cid=C052E16DWMQ
            if (!err.message?.includes(NOT_ENOUGH_INFO_ERROR)) return captureException(err)
            setStatusInfo(StatusInfo.warning)
            setValidByServer(false)
          })
      )
      .finally(() => setLoading(false))
  }, [propertyInfo, keywordMapItems])

  return <AdCreatorFormSectionStatusView loading={loading} statusInfo={statusInfo} text={text} />
}

const Container = styled(Box)<{ color: string; filter?: string }>`
  border-radius: 50%;
  width: ${smallSpacing};
  height: ${smallSpacing};
  background-color: ${({ color }) => color};
  ${({ filter }) => (filter ? `filter: ${filter}` : '')};
`

export function AdCreatorFormSectionStatusView({
  loading,
  statusInfo,
  text
}: {
  loading?: boolean
  statusInfo: StatusInfoItem
  text?: string
}) {
  const { color, filter, textKey } = statusInfo

  return (
    <Box direction="row" align="center">
      {loading && <Loader size={smallSpacing} />}
      {!loading && <Container color={color} filter={filter} />}
      <Box left spacing={smallSpacing}>
        {textKey && <FigmaText styleTextKey={Texts.adCreatorFormSectionHelperText} textKey={textKey} text={text} />}
      </Box>
    </Box>
  )
}
