import OrganizationTemplatesJSON from '../../pure/data/OrganizationTemplates.json' 
import { User } from '../types/types.js'
import { AdLength, AdLengthInfo, AdLengthInfoType } from './AdLengthHelper.js'

export type OrganizationTemplate = {
  organizationId: string
  adLengthInfoType: AdLengthInfoType
  adLength: string
  headerAdLength?: number
  languageCode: string
}

export const OrganizationTemplates = OrganizationTemplatesJSON

export const getOrganizationTemplate = (u?: User): OrganizationTemplate | undefined => {
  if (!u?.organizationId) return undefined
  const template = Object.values(OrganizationTemplates).find((t) => t.organizationId === u.organizationId)
  if (!template) return undefined
  return {
    ...template,
    adLengthInfoType: AdLengthInfo[template.adLength as AdLength] || AdLengthInfo.medium
  }
}
