import invariant from 'invariant'
import { Controller, UseFormReturn } from 'react-hook-form'
import * as Yup from 'yup'
import { defaultSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { useForm } from '../hooks/useForm'
import useLoggedInConfig from '../hooks/useLoggedInConfig'
import useNotification from '../hooks/useNotification'
import { RequiredTextValidation } from '../libs/ValidationHelper'
import FigmaText from './FigmaText'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { Organization } from '../../../../pure/types/types'
import Box from './Box'
import Button from './Button'
import { TextFieldOutlined } from './TextFieldOutlined'
import { TextFieldWithLabelForm } from './TextFieldWithLabelForm'
import { updateOrganization } from '../libs/DBApiHandler'

type InitialValues = {
  name: string
  domain: string
}

export const SettingsMyOrganizationForm = ({ org }: { org: Organization }) => {
  const notificaion = useNotification()
  const config = useLoggedInConfig()

  const domain = org?.domain || ''

  const defaultValues = {
    name: org?.name || '',
    domain: domain
  }

  const formProps = useForm<InitialValues>(ValidationSchema, {
    defaultValues
  }) as UseFormReturn<InitialValues>

  const onSubmit = ({ name, domain }: InitialValues) => {
    invariant(org, '!org')

    return updateOrganization({ ...org, name, domain }).then(() => notificaion.success())
  }

  if (!org) return null

  return (
    <Box fullWidth>
      <Box fullWidth align="center" bottom>
        <Box fullWidth direction="row" align="center">
          <FigmaText textKey={TextsDeprecated.settingsMyOrgOrgNameLabel} text="Id:" />
          <Box left spacing={tinySpacing}>
            <FigmaText textKey={TextsDeprecated.settingsMyOrgOrgNameLabel} text={org?._id} />
          </Box>
        </Box>
        <Box bottom spacing={smallSpacing} />

        <Controller
          control={formProps.control}
          name="name"
          render={({ field, fieldState }) => (
            <TextFieldWithLabelForm
              {...field}
              fieldState={fieldState}
              labelTextKey={TextsDeprecated.settingsMyOrgOrgNameLabel}
              name="name"
              component={TextFieldOutlined}
              fullWidth
            />
          )}
        />

        <Box bottom spacing={smallSpacing} />
        <Box left right spacing={tinySpacing}></Box>
        {config.enableEditDomains && (
          <Controller
            control={formProps.control}
            name="domain"
            render={({ field, fieldState }) => (
              <TextFieldWithLabelForm
                {...field}
                fieldState={fieldState}
                labelTextKey={TextsDeprecated.settingsMyOrgOrgNameDomainLabel}
                name="domain"
                component={TextFieldOutlined}
                fullWidth
              />
            )}
          />
        )}
      </Box>
      <Button
        textKey={TextsDeprecated.saveButtonText}
        onClick={formProps.handleSubmit(onSubmit)}
        loading={formProps.formState.isSubmitting}
        spacing={defaultSpacing}
      >
        <FigmaText styleTextKey={TextsDeprecated.cssPrimaryButtonTextSmall} textKey={TextsDeprecated.saveButtonText} />
      </Button>
    </Box>
  )
}
const ValidationSchema = Yup.object().shape({
  name: RequiredTextValidation(),
  domain: RequiredTextValidation()
})
