import invariant from 'invariant'
import { useState } from 'react'
import { bigSpacing, defaultSpacing } from '../enums/Spacings'
import { useUser } from '../hooks/QueryHooks'
import useAppState, { useUserId } from '../hooks/useAppState'
import useIsMobile from '../hooks/useIsMobile'
import useUpdateUser from '../hooks/useUpdateUser'
import { AdCreatorFormMaxWidth, MENU_HEIGHT } from '../libs/HardCodedSizes'
import Images from '../../../../pure/libs/AppImages'
import { Ad, JaguarSession } from '../../../../pure/types/types'
import { AdCreatorAd } from './AdCreatorAd'
import { AdCreatorForm } from './AdCreatorForm'
import AdCreatorPageMobile, { AdCreatorPageProps, ContainerWithGradient } from './AdCreatorPageMobile'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import Layout from './Layout'
import { ModalTutorial } from './ModalTutorial'
import { updateAd } from '../libs/DBApiHandler'

const OFFSET = 6 // if this is not here you get a white line at the bottom of the page
const AdCreatorPage: React.FC<AdCreatorPageProps> = (props) => {
  const { state } = useAppState()
  const { enableRightBottomImage = true, enableTutorial = true } = props
  const [selectedAd, setSelectedAd] = useState<Ad | undefined>(props.ad)

  const height = window.innerHeight - MENU_HEIGHT + OFFSET

  const isMobile = useIsMobile()
  const { data: user = state.user } = useUser(useUserId())
  const updateUser = useUpdateUser()
  const [shouldShowTutorialModal, setShouldShowTutorialModal] = useState(enableTutorial && user.shouldShowTutorial)

  if (isMobile) return <AdCreatorPageMobile {...props} />

  const onCloseTutorial = () => {
    setShouldShowTutorialModal(false)
    updateUser({ ...user, shouldShowTutorial: false })
  }

  function onClickCreateNewAd() {
    setSelectedAd(undefined)
  }

  function onUpdateSession(session: JaguarSession) {
    invariant(selectedAd, '!selectedAd')
    updateAd({ ...selectedAd, activeSessions: { ...selectedAd?.activeSessions, [session.request.type]: session } })
  }

  return (
    <Layout enableMenu={props.enableMenu} MenuComponent={props.MenuComponent} fullHeight enableFooter={false}>
      {shouldShowTutorialModal && <ModalTutorial onClose={onCloseTutorial} />}
      <ContainerWithGradient fullWidth position="relative" style={{ minHeight: height }}>
        {enableRightBottomImage && (
          <Box position="fixed" style={{ bottom: `-${defaultSpacing}`, right: 0 }}>
            <FigmaImageContainer imageKey={Images.pngCity} />
          </Box>
        )}
        <Box fullWidth direction="row" fullHeight>
          <Box fullWidth bottom align="center">
            <Box width={`${AdCreatorFormMaxWidth}px`} spacing={bigSpacing} top>
              {!selectedAd && <AdCreatorForm {...props} />}
              {selectedAd && (
                <AdCreatorAd
                  ad={selectedAd}
                  onUpdateSession={onUpdateSession}
                  onClickCreateNewAd={onClickCreateNewAd}
                  sessions={{}}
                  adCreatorPageProps={props}
                />
              )}
            </Box>
          </Box>
        </Box>
      </ContainerWithGradient>
    </Layout>
  )
}

export default AdCreatorPage
