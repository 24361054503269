import styled from '@emotion/styled'
import { ProductInfo, ProductType } from '../libs/ProductInfo'
import Box from './Box'
import { BorderRadixes } from '../enums/BorderRadixes'
import Colors from '../../../../pure/libs/Colors'
import { regularSpacing, tinySpacing } from '../enums/Spacings'
import FigmaText from './FigmaText'
import { Texts } from '../../../../pure/libs/AppTexts'
import invariant from 'invariant'

const PRODCUT_INFOS = [ProductInfo.ANNUAL_MEMBERSHIP, ProductInfo.MONTHLY_MEMBERSHIP]
export function PricingProductToggle({
  productType,
  setProductType
}: {
  productType: ProductType
  setProductType: (productType: ProductType) => void
}) {
  return (
    <Container direction="row" fullPadding spacing={tinySpacing}>
      {PRODCUT_INFOS.map((p, key) => {
        const otherProductInfo = PRODCUT_INFOS.find((pI) => pI.stripeProductId !== productType.stripeProductId)
        invariant(otherProductInfo, 'cant find other product info %s', productType.stripeProductId)
        const Component = p.stripeProductId === productType.stripeProductId ? ButtonContainer : Box
        return (
          <Component
            fullPadding
            key={key}
            onClick={() => setProductType(otherProductInfo)}
            spacing={regularSpacing}
            pointer
          >
            <FigmaText textKey={p.pricingPageToggleTextKey} />
          </Component>
        )
      })}
    </Container>
  )
}

const Container = styled(Box)`
  border-radius: ${BorderRadixes.moderate};
  background: ${Colors.neutral20};
`
const ButtonContainer = styled(Box)`
  border-radius: ${BorderRadixes.moderate};
  background: ${Colors.white};
`
