import { GoogleAuthProvider, MicrosoftAuthProvider } from './SignInProviders'
import { getFigmaText } from '../libs/TextRepository'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { SignInMethod } from 'firebase/auth'

export const LABELS = {
  [GoogleAuthProvider.providerId]: {
    radioLabel: getFigmaText(TextsDeprecated.onboardingConnectAccountGoogleText),
    buttonLabel: getFigmaText(TextsDeprecated.onboardingVerifyGoogleButtonText)
  },
  [MicrosoftAuthProvider.providerId]: {
    radioLabel: getFigmaText(TextsDeprecated.onboardingConnectAccountMicrosoftText),
    buttonLabel: getFigmaText(TextsDeprecated.onboardingVerifyMicrosoftButtonText)
  },
  default: {
    radioLabel: getFigmaText(TextsDeprecated.onboardingConnectAccountEmailText),
    buttonLabel: getFigmaText(TextsDeprecated.onboardingVerifyEmailButtonText)
  }
}

export const LABELS_MOBILE: typeof LABELS = {
  [GoogleAuthProvider.providerId]: {
    radioLabel: getFigmaText(TextsDeprecated.onboardingMobileConnectAccountGoogleText),
    buttonLabel: getFigmaText(TextsDeprecated.onboardingVerifyEmailButtonText)
  },
  [MicrosoftAuthProvider.providerId]: {
    radioLabel: getFigmaText(TextsDeprecated.onboardingMobileConnectAccountMicrosoftText),
    buttonLabel: getFigmaText(TextsDeprecated.onboardingVerifyEmailButtonText)
  },
  default: {
    radioLabel: getFigmaText(TextsDeprecated.onboardingMobileConnectAccountEmailText),
    buttonLabel: getFigmaText(TextsDeprecated.onboardingVerifyEmailButtonText)
  }
}

export function getDefaultProviderId(signInMethods: string[]) {
  return signInMethods[0] || SignInMethod.EMAIL_LINK
}
