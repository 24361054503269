import { SignInMethod } from 'firebase/auth'
import invariant from 'invariant'
import { State } from '../hooks/useAppState'

import { SignInState, SignInSteps } from '../../../../pure/types/SignInTypes'
import { getProviderForProviderId } from './SignInHelper'
import { ServicesType } from './SignInMachineHelper'
import config from '../Config'
import { toBaseObject } from '../../../../pure/libs/FirebaseStorageClientBaseHelper'
import { ServerFirebaseUser } from '../../../../pure/types/ServerTypes'

export const onPressContinue = (
  signInState: SignInState,
  { isMobile }: { isMobile: boolean },
  state: State,
  s: ServicesType
): Promise<SignInState> =>
  Promise.resolve().then(() => {
    switch (signInState.step) {
      case SignInSteps.SIGN_UP:
      case SignInSteps.LANDING:
        return startSignInWithCode(signInState.data.email, signInState, state, s)
      case SignInSteps.DISABLED:
        return { ...signInState, step: SignInSteps.LANDING }
      case SignInSteps.ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIALS: {
        const { pendingProviderId, pendingUser } = signInState.data
        const email = pendingUser?.email
        invariant(email, '!email')
        invariant(pendingProviderId, '!pendingProviderId')

        if (pendingProviderId === SignInMethod.EMAIL_LINK) return startSignInWithCode(email, signInState, state, s)

        const provider = getProviderForProviderId(pendingProviderId)
        invariant(provider, '!provider, pendingProviderId: %s', pendingProviderId)
        return s.signInWithPopup(provider).then(() => signInState)
      }

      case SignInSteps.ENTER_CODE: {
        const { loginWithCodeToken, code } = signInState.data
        invariant(loginWithCodeToken, 'signInState.data.loginWithCodeToken')
        invariant(code, '!code')
        return s
          .createAuthenticationTokenForLoginToken({ ...loginWithCodeToken, code }, state)
          .then(({ token }) => s.signInWithCustomToken(token))
          .then(() => signInState)
          .catch((e) => ({ ...signInState, data: { ...signInState.data, loginWithCodeTokenError: e.message } }))
      }
      default:
        throw new Error(`Unknown step: ${signInState.step}`)
    }
  })

export const onAccountExistsWithDifferentCredentialsError = (
  err: any,
  signInState: SignInState,
  state: State,
  s: ServicesType
): Promise<SignInState> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { customData } = err
  const { _tokenResponse: pendingUser } = customData
  const pendingUserCredential = s.credentialFromError(err)
  invariant(pendingUserCredential, '!pendingUserCredential')
  const _email = customData.email || signInState.data.email
  invariant(_email, '!_email')
  return s.fetchSignInMethodsForEmail(_email).then((signInMethods) => {
    const partialData = {
      email: _email,
      pendingUserCredential: pendingUserCredential.toJSON(),
      pendingUser,
      signInMethods
    }

    if (!signInMethods[0]) return startSignInWithCode(_email, signInState, state, s, partialData)
    return {
      ...signInState,
      data: {
        ...signInState.data,
        ...partialData
      },
      step: SignInSteps.ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIALS
    }
  })
}

const startSignInWithCode = (
  email: string,
  signInState: SignInState,
  state: State,
  s: ServicesType,
  partialData?: Partial<SignInState['data']>
): Promise<SignInState> => {
  return s
    .createLoginWithCodeToken({ ...toBaseObject({uid: state.firebaseUser?.uid as string} as ServerFirebaseUser), email, enableSendgrid: config.enableSendgrid }, state)
    .then((loginWithCodeToken) => ({
      ...signInState,
      data: {
        ...signInState.data,
        loginWithCodeToken: { ...loginWithCodeToken, code: '' },
        ...partialData
      },
      step: SignInSteps.ENTER_CODE
    }))
}
