import { AuthProvider, linkWithCredential, OAuthCredential, UserCredential } from 'firebase/auth'
import { PRIVACY_POLICY_URL, TERMS_AND_CONDITION_URL } from '../../../../pure/libs/Consts'
import RoutePath from '../../../../pure/libs/RoutePath'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { PreferredLoginMethod, SignInState, SignInSteps } from '../../../../pure/types/SignInTypes'
import { FirebaseUser } from '../../../../pure/types/types'
import { State } from '../hooks/useAppState'
import { addQueryParamsToUrl } from '../hooks/useQueryparamHelper'
import { ACTION_CODE_SETTINGS } from './ActionCodeSettings'
import * as FirebaseHelper from './FirebaseHelper'
import onUnhandledPromiseRejection from './onUnhandledPromiseRejection'
import { GoogleAuthProvider, MicrosoftAuthProvider } from './SignInProviders'

export type SignInPageProps = {
  onLogin?: (state: State) => unknown
  signInState?: Partial<SignInState>
}

export const getProviderForProviderId = (providerId: string): AuthProvider | undefined => {
  if (providerId === GoogleAuthProvider.providerId) return GoogleAuthProvider
  if (providerId === MicrosoftAuthProvider.providerId) return MicrosoftAuthProvider
}

export function getUrlToResendPage(email: any): string {
  return addQueryParamsToUrl(RoutePath.RESEND_SECURE_LINK, { email })
}

export function signInWithEmailLink(signInState: SignInState): Promise<UserCredential> {
  const { email, invitationToken } = signInState.data
  return FirebaseHelper.signInWithEmailLink(email, window.location.href).then((userCredential) => {
    return onSignInWithEmailLink(userCredential, invitationToken)
  })
}

export const SIGN_IN_PROPS_SETS = [
  { textKey: TextsDeprecated.onboardingMobileTerms1 },
  { textKey: TextsDeprecated.onboardingMobileTermsLinkPrivacy, anchorHref: PRIVACY_POLICY_URL },
  { textKey: TextsDeprecated.onboardingMobileTerms2 },

  { textKey: TextsDeprecated.onboardingMobileTermsLinkService, anchorHref: TERMS_AND_CONDITION_URL }
]

export function onSignInWithEmailLink(
  userCredential: UserCredential,
  invitationToken?: string,
  _window: Window = window
) {
  let url = ACTION_CODE_SETTINGS.url
  if (invitationToken) url = addQueryParamsToUrl(url, { invitationToken: invitationToken as string })
  _window.location.href = url

  return userCredential
}

const Functions = { linkWithCredential }
export const onAuthStateChanged = (user: FirebaseUser | null, signInState: SignInState, functions = Functions) => {
  const { linkWithCredential } = functions
  const { pendingUserCredential } = signInState.data

  if (!user) return
  if (!pendingUserCredential) return

  const _pendingUserCredential = OAuthCredential.fromJSON(pendingUserCredential)

  if (!_pendingUserCredential) return
  const isLinkedWithProvider = user.providerData.some(
    (userInfo) => userInfo && userInfo.providerId === _pendingUserCredential.providerId
  )
  if (!isLinkedWithProvider) linkWithCredential(user, _pendingUserCredential).catch(onUnhandledPromiseRejection)
}

export const DEFAULT_SIGN_IN_STATE: SignInState = {
  data: {
    email: '',
    signInMethods: []
  },
  preferredLoginMethod: PreferredLoginMethod.MAIL,
  step: SignInSteps.LANDING
}

const InAppBrowsers = ['Instagram', 'FBAN', 'FBAV', 'Twitter', 'Pinterest', 'LinkedInApp', 'Slack']

export const isInAppBrowser = () => {
  const ua = navigator.userAgent || navigator.vendor
  return InAppBrowsers.some((app) => ua.includes(app))
}
