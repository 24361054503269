import styled from '@emotion/styled'
import { FormControl, FormControlLabel, RadioGroup } from '@mui/material'
import { useEffect, useState } from 'react'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import eventEmitter, { Events } from '../../../../pure/libs/EventEmitter'
import { getDefaultProviderId, LABELS_MOBILE as LABELS } from '../libs/SignInExistsWithDifferentCredentialsHelper'
import FigmaText from './FigmaText'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { SignInViewProps } from '../../../../pure/types/SignInTypes'
import BackButtonMobileOnboarding from './BackButtonMobileOnboarding'
import Box from './Box'
import Button from './Button'
import OnboardingMobileWrapper from './OnboardingMobileWrapper'
import { Radio } from './Radio'

export default function SignInExistsWithDifferentCredentialsMobile({
  onPressContinue,
  signInState,
  onTakeMeBack,
  isLoading
}: SignInViewProps) {
  const {
    data: { email, pendingUserCredential, pendingUser, signInMethods }
  } = signInState

  const defaultProviderId = getDefaultProviderId(signInMethods)
  const [providerId, setProviderId] = useState(defaultProviderId || '')
  useEffect(() => {
    if (!pendingUserCredential) eventEmitter.emit(Events.NEW_SERVER_ERROR)
  }, [])

  if (!pendingUserCredential) return null

  const _onPressContinue = () =>
    onPressContinue({ ...signInState, data: { ...signInState.data, pendingProviderId: providerId } })

  return (
    <OnboardingMobileWrapper
      content={() => (
        <Box align="center" fullWidth fullPadding spacing={tinySpacing}>
          <Box fullWidth align="flex-start" justify="center" left spacing={smallSpacing}>
            <BackButtonMobileOnboarding
              textKey={TextsDeprecated.onboardingMobileBackButtonText}
              onClickBack={() => onTakeMeBack()}
            />
          </Box>
          <Box align="center" fullPadding spacing={bigSpacing}>
            <Box align="center" top spacing={bigSpacing}>
              <Box fullWidth bottom spacing={smallSpacing}>
                <FigmaText
                  textKey={TextsDeprecated.onboardingCreateAccountHeader}
                  text={formatText(getFigmaText(TextsDeprecated.onboardingCreateAccountHeader), [
                    pendingUser?.displayName || email
                  ])}
                />
                <Box top>
                  <FigmaText textKey={TextsDeprecated.onboardingMobileExistingAccountInfoText} />
                </Box>
                <Box top>
                  <FigmaText textKey={TextsDeprecated.onboardingMobileConnectedAccountListHeader} />
                </Box>
              </Box>
            </Box>
            <Box fullWidth bottom spacing={smallSpacing}>
              <FormControl component="fieldset">
                <RadioGroup
                  defaultValue={defaultProviderId}
                  onChange={({ target: { value: providerId } }) => setProviderId(providerId)}
                >
                  {signInMethods.map((providerId, key) => {
                    const labels = LABELS[providerId] || LABELS.default
                    const { radioLabel } = labels
                    return (
                      <StyledFormControlLabel key={key} value={providerId} control={<Radio />} label={radioLabel} />
                    )
                  })}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box align="center" fullWidth top spacing={bigSpacing}>
              <Button buttonType="white" fullWidth onClick={_onPressContinue} loading={isLoading}>
                <FigmaText
                  textKey={TextsDeprecated.onboardingMobileVerifyEmailButtonText}
                  text={LABELS[providerId]?.buttonLabel || LABELS.default.buttonLabel}
                />
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    />
  )
}

const StyledFormControlLabel = styled(FormControlLabel)`
  color: white;
`
