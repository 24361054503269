import { useState } from 'react'
import { RatingThankYou } from './RatingThankYou'
import { Container, RatingSelectRate } from './RatingSelectRate'
import { JaguarSessions } from '../libs/AdCreatorFormHelper'

export function Rating({ jaguarSessions }: { jaguarSessions: JaguarSessions }) {
  const [showThankYou, setShowThankYou] = useState(false)
  const [showRating, setShowRating] = useState(true)
  const onClick = () => setShowThankYou(true)

  if (!showRating) return <Container visibility="hidden" />
  if (showThankYou) return <RatingThankYou onClick={() => setShowRating(false)} />
  return <RatingSelectRate onClick={onClick} jaguarSessions={jaguarSessions} />
}
