import styled from '@emotion/styled'
import invariant from 'invariant'
import { useForm } from 'react-hook-form'
import { BorderRadixes } from '../enums/BorderRadixes'
import { Shadows } from '../enums/Shadows'
import { SMALL_SPACING_PX, regularSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useIsMobile from '../hooks/useIsMobile'
import { useDefaultSectionFormValues } from '../libs/AdCreatorFormHelper'
import { SectionInfo } from '../libs/SectionInfo'
import { getParagraphs } from '../libs/getParagraphs'
import { onClickCopySessions } from '../libs/onClickCopy'
import Colors from '../../../../pure/libs/Colors'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../../pure/libs/AppTexts'
import { Ad, JaguarRequestForm, JaguarSession } from '../../../../pure/types/types'
import { AdCreatorAdSectionResponseWithSelectionMenu } from './AdCreatorAdSectionResponseWithSelectionMenu'
import { AdCreatorPageProps } from './AdCreatorPageMobile'
import AdCreatorThumb from './AdCreatorThumb'
import Box from './Box'
import Button from './Button'
import { AdCreatorRegenerate } from './Icons'
import { GreyLine } from './StyledComponents'
import { ButtonCopyAd } from './ButtonCopyAd'
import FigmaText from './FigmaText'

export type OnClickRegenerateAd = (req: JaguarRequestForm) => unknown
export type OnUpdateSession = (session: JaguarSession) => unknown

export function AdCreatorAdSection({
  session,
  index,
  onClickRegenerateAd,
  adCreatorPageProps = {},
  onUpdateSession
}: {
  session: JaguarSession
  index: number
  onUpdateSession: OnUpdateSession
  onClickRegenerateAd?: OnClickRegenerateAd
  adCreatorPageProps?: AdCreatorPageProps
  ad: Ad
}) {
  const { enableCopyAd = true, locationAsHeadlineText } = adCreatorPageProps || {}
  const isMobile = useIsMobile()
  const section = SectionInfo[session.request?.type as string]
  const isActive = session.status === 'active'
  const { state } = useAppState()
  invariant(section, 'sectionInfo not found for %s', session.request?.type)

  const _defaultValues = useDefaultSectionFormValues({ sectionInfo: SectionInfo[session.request.type], state })
  const defaultValues: JaguarRequestForm = {
    ..._defaultValues,
    ...session.request

    // googlePlace: undefined, uncommenting this will make the google place disappear when regenerating ad, tech debt
  }

  const formProps = useForm({
    defaultValues
  })

  const enableRegenerateAd = formProps.formState.isDirty

  return (
    <Container key={index} fullPadding fullWidth position="relative">
      <Box position="absolute" style={{ bottom: -SMALL_SPACING_PX, right: -SMALL_SPACING_PX }}>
        <AdCreatorThumb session={session} />
      </Box>
      <FigmaText
        textKey={Texts.adCreatorFormSectionHeader}
        text={`${index}. ${getFigmaText(section.sectionNameTextKey)}`}
        textTransform="uppercase"
      />
      <Box top spacing={smallSpacing} fullWidth>
        <GreyLine />
      </Box>
      {session.headline && (
        <Box top spacing={smallSpacing} fullWidth>
          <FigmaText textKey={Texts.adCreatorAdSectionHeadline} text={locationAsHeadlineText || session.headline} />
        </Box>
      )}
      <Box top fullWidth>
        {getParagraphs(session.response).map((text, key) => (
          <AdCreatorAdSectionResponseWithSelectionMenu
            key={key}
            text={text}
            session={session}
            onUpdateSession={onUpdateSession}
          />
        ))}
      </Box>
      <Box
        top
        fullWidth
        spacing={tinySpacing}
        // there is a weird spacing in AdCreatorAdSectionResponse forcing us to add some padding in the bottom => tech debt
      ></Box>
      {!isActive && (
        <Box fullWidth align={isMobile ? 'center' : 'flex-start'}>
          <Box direction="row" align="center" style={{ flexWrap: 'wrap' }} justify="center">
            {enableCopyAd && (
              <Box top spacing={regularSpacing}>
                <ButtonCopyAd onClick={() => onClickCopySessions([session])} />
              </Box>
            )}
            {!!onClickRegenerateAd && (
              <>
                <Box top left right fullWidth={isMobile} alignText="center">
                  <FigmaText textKey={Texts.adCreatorAdSectionButtonsOr} />
                </Box>
                <Box top>
                  <Button
                    spacing={smallSpacing}
                    disabled={!enableRegenerateAd}
                    onClick={() => onClickRegenerateAd?.(formProps.getValues())}
                  >
                    <Box direction="row" align="center">
                      <AdCreatorRegenerate />
                      <Box left spacing={tinySpacing} style={{ opacity: enableRegenerateAd ? 1 : 0.4 }}>
                        <FigmaText
                          textKey={
                            enableRegenerateAd
                              ? Texts.adCreatorAdSectionRegenerateAdButton
                              : Texts.adCreatorAdSectionRegenerateAdButtonDisabled
                          }
                        />
                      </Box>
                    </Box>
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}
    </Container>
  )
}

export const Container = styled(Box)`
  border-radius: ${BorderRadixes.moderate};
  background: ${Colors.white};
  box-shadow: ${Shadows.tiny};
`
