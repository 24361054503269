import { FAQ_URL, PRIVACY_POLICY_URL, TERMS_AND_CONDITION_URL } from '../../../../pure/libs/Consts'
import Box from './Box'
import Iframe from './GoogleDocsIframe'

export const TermsAndConditionsIframe = () => {
  return <Iframe name="Temrms and Conditions" url={TERMS_AND_CONDITION_URL} />
}

export const PrivacyPolicyIframe = () => {
  return <Iframe name="Privacy Policy" url={PRIVACY_POLICY_URL} />
}

export const FAQIframe = () => {
  return <Iframe name="FAQ" url={FAQ_URL} />
}
