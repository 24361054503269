import { isPrekInternalUser } from '../libs/CreateAdHelper'
import { ProductInfo, ProductType } from '../libs/ProductInfo'
import { useStripeProducts, useUser } from './QueryHooks'
import { getCurrentProduct } from './getCurrentProduct'
import useAppState from './useAppState'
import { useActiveStripeSubscription } from './useStripeSubscription'

export const useCurrentProduct = (): { isLoading: boolean; data: ProductType } => {
  const sub = useActiveStripeSubscription()
  const { state } = useAppState()
  const { data: user, isLoading: isLoadingUser } = useUser(state.user._id)
  const { data: prods = [], isLoading: isLoadingProducts } = useStripeProducts()

  const isLoading = isLoadingUser || isLoadingProducts

  const query = { isLoading, data: getCurrentProduct({ subscription: sub, user }) }

  return query
}
