import CloudFunctionError, { CloudFunctionErrorType } from './CloudFunctionError.js'
import { httpsCallable } from 'firebase/functions'
import { State } from '../hooks/useAppState.js'
import { functions } from './Firebase.js'
import CloudFunction from '../../../../pure/leopard/CloudFunctions.js'
import { TEN_MINUTES } from '../../../../pure/libs/Consts.js'

export const callFunction = (cloudfunction: CloudFunction, state: State, req?: any) =>
  httpsCallable(functions, cloudfunction, { timeout: TEN_MINUTES })(req)
    .then(({ data }) => Promise.resolve(console.log('Success', cloudfunction)).then(() => data))
    .catch(onErrorFactory(cloudfunction, state)) as Promise<any>

const onErrorFactory = (functionName: CloudFunction, state: State) => (err: CloudFunctionErrorType) => {
  err.message = `Error in ${functionName}: ${err.message}`
  console.log('Error', err.message)
  return Promise.reject(new CloudFunctionError(err, functionName, state))
}
