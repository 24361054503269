import { UploadedFile } from '../../../../pure/types/JaguarTypes'
import { JaguarSession } from '../../../../pure/types/types'

export function getTextToCopy(sessions: JaguarSession[]) {
  return sessions
    .map((session) => {
      const headline = session.headline ? `${session.headline}\n\n` : ''
      return `${headline}${session.response}`
    })
    .join('\n\n')
}

export function getTextToCopyForUploadedFile({ Headline, PropertyAdditionalInfo }: UploadedFile) {
  return `${Headline}\n\n${PropertyAdditionalInfo}`
}
