import styled from '@emotion/styled'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { Texts } from '../../../../pure/libs/AppTexts'
import Box from './Box'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'

const TextKey = Texts.adCreatorAdSectionParagraph

export function AdCreatorAdSectionResponse({ text, isSelected }: { text: string; isSelected?: boolean }) {
  return (
    <Box fullWidth>
      <FigmaTextWithLinebreaks
        textKey={Texts.typeOfAdRowExteriorDescription}
        text={text}
        // color={isSelected ? 'white' : 'primary100'}
      />
    </Box>
  )
}
