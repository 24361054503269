import { JaguarSession, Organization } from '../../../../pure/types/types'
import invariant from 'invariant'

export function isLikedSession({ org, session }: { org: Organization | undefined; session: JaguarSession }) {
  const likedSessions = org?.likedSessions || []
  const likedSessionIds = likedSessions.map((s) => s._id)
  const isLiked = !!likedSessionIds?.includes(session._id)
  return isLiked
}

export const getNewLikedSession = (session, org) => {
  const isLiked = isLikedSession({ org, session })
  const likedSessions = org?.likedSessions || []

  invariant(org, '!org')
  const _likedSessions = isLiked ? likedSessions.filter((s) => s._id !== session._id) : [...likedSessions, session]

  return _likedSessions
}
