import styled from '@emotion/styled'
import { smallSpacing } from '../enums/Spacings'
import FigmaText from './FigmaText'
import Colors from '../../../../pure/libs/Colors'
import { CONTACT_US_URL, PRIVACY_POLICY_URL, TERMS_AND_CONDITION_URL } from '../../../../pure/libs/Consts'
import Images from '../../../../pure/libs/AppImages'
import { Texts } from '../../../../pure/libs/AppTexts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaTextWithStyleOverrides from './FigmaTextWithStyleOverrides'

export default function FooterLoggedIn() {
  return (
    <Container
      fullWidth
      direction="row"
      align="center"
      justify="space-between"
      left
      right
      style={{ paddingTop: smallSpacing, paddingBottom: smallSpacing }}
    >
      <Box>
        <FigmaImageContainer imageKey={Images.companyMixedLogo} />
      </Box>
      <Box direction="row" align="center">
        <Box fullPadding spacing={smallSpacing}>
          <FigmaText textKey={Texts.footerLoggedInCopyright} />
        </Box>
        <Box fullPadding spacing={smallSpacing} onClick={() => window.open(TERMS_AND_CONDITION_URL, '_blank')} link>
          <FigmaText textKey={Texts.footerLoggedInTermsAndConditions} />
        </Box>
        <Box fullPadding spacing={smallSpacing} onClick={() => window.open(PRIVACY_POLICY_URL, '_blank')} link>
          <FigmaText textKey={Texts.footerLoggedInPrivacyPolicy} />
        </Box>
      </Box>
      <Box onClick={() => window.open(CONTACT_US_URL, '_blank')} pointer>
        <FigmaTextWithStyleOverrides
          textKeys={{ en: Texts.footerLoggedInContactUs, sv: Texts.footerLoggedInContactUsSv }}
        />
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.secondary20};
  border-top: 1px solid ${Colors.primary40};
`
