/* eslint-disable prefer-const */
import { useState } from 'react'

import OtpInput from 'react-otp-input'
import { noBorderRadius } from '../enums/BorderRadixesDeprecated'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { onChangeText } from '../libs/SignInCodeHelper'
import FigmaText from './FigmaText'
import Colors from '../../../../pure/libs/Colors'
import { toPx } from '../../../../pure/libs/Common'
import { NO_OF_LETTERS_IN_SIGN_IN_CODE } from '../../../../pure/libs/Consts'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { TextsDeprecated, TextKeysWithOnlyTexts } from '../../../../pure/libs/TextsDeprecated'
import { SignInViewProps } from '../../../../pure/types/SignInTypes'
import Box from './Box'
import Button from './Button'
import Loader from './Loader'
import { Helmet } from './Helmet'

const LETTER_WIDTH = toPx(TextsDeprecated.onboardingEmailCodeInputText.style.fontSize) * 2
const LETTER_HEIGHT = LETTER_WIDTH * 1.3

export default function SignInCode(props: SignInViewProps) {
  const { signInState, onTakeMeBack, isLoading } = props
  const { data } = signInState
  const { email, loginWithCodeTokenError, code: _code = '' } = data
  let [code, setCode] = useState(_code)
  const headerTextKey = TextsDeprecated.headerCheckEmailForCode
  const description1TextKey = TextsDeprecated.signInCodeLoginText1
  const description2TextKey = TextsDeprecated.signInCodeLoginText2

  return (
    <Box fullWidth align="center" top spacing={bigSpacing}>
      <Box style={{ position: 'absolute', bottom: 0 }}></Box>
      <Helmet title="Enter Code" />

      <Box top spacing={bigSpacing} fullWidth alignText="center" align="center">
        <FigmaText textKey={headerTextKey} />
        <Box top spacing={smallSpacing} fullWidth>
          <FigmaText
            textKey={TextsDeprecated.onboardingInvitedWithEmailInfo}
            text={formatText(getFigmaText(description1TextKey), [email])}
          />
        </Box>
      </Box>
      <Box top direction="row" align="center">
        {isLoading && <Loader />}
        {!isLoading && (
          <OtpInput
            value={code}
            onChange={(code) => onChangeText(code, setCode, props)}
            numInputs={NO_OF_LETTERS_IN_SIGN_IN_CODE}
            inputStyle={{
              width: LETTER_WIDTH,
              height: LETTER_HEIGHT,
              border: `1px solid ${Colors.secondary40}`,
              borderRadius: noBorderRadius,
              marginLeft: tinySpacing,
              marginRight: tinySpacing,
              ...TextsDeprecated.onboardingEmailCodeInputText.style,
              color: 'black'
            }}
          />
        )}
      </Box>
      {loginWithCodeTokenError && (
        <Box top>
          <FigmaText textKey={TextsDeprecated.onboardingEmailCodeErrorTextNotValid} />
        </Box>
      )}
      <Box top>
        <FigmaText styleTextKey={TextsDeprecated.onboardingEmailCodeInfoText} textKey={description2TextKey} />
      </Box>
      <Box top fullWidth align="center">
        <Button
          textKey={TextsDeprecated.secondaryReturnToLoginButtonText}
          buttonType="secondary"
          onClick={onTakeMeBack}
        />
      </Box>
    </Box>
  )
}
