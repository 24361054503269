import React from 'react'
import Images from '../../../../pure/libs/AppImages'
import { getFigmaText } from '../libs/TextRepository'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { ModalInviteUser } from './ModalInviteUser'
import Tooltip from './Tooltip'

export function SettingsMyOrganizationInviteUserAddIcon() {
  const [showModal, setShowModal] = React.useState(false)

  return (
    <>
      <Box onClick={() => setShowModal(true)} link>
        <Tooltip title={getFigmaText(TextsDeprecated.inviteDialogHeader)} placement="top">
          <FigmaImageContainer imageKey={Images.iconAddPerson} />
        </Tooltip>
      </Box>
      {showModal && <ModalInviteUser onClose={() => setShowModal(false)} />}
    </>
  )
}
