import styled from '@emotion/styled'
import invariant from 'invariant'
import { BorderRadixes } from '../enums/BorderRadixes'
import { tinySpacing } from '../enums/Spacings'
import { useOrganization } from '../hooks/QueryHooks'
import useAppState from '../hooks/useAppState'
import Colors from '../../../../pure/libs/Colors'
import { JaguarSession } from '../../../../pure/types/types'
import { AdCreatorAdThumbViewEmptyState } from './AdCreatorAdThumbViewEmptyState'
import { getNewLikedSession, isLikedSession } from './AdCreatorThumbsHelper'
import Box from './Box'
import { ThumbUp } from './Icons'
import useLoggedInConfig from '../hooks/useLoggedInConfig'
import { updateOrganization } from '../libs/DBApiHandler'

export default function AdCreatorThumb({ session }: { session: JaguarSession }) {
  const { state } = useAppState()

  const { data: org, isLoading } = useOrganization(state.user.organizationId)
  const isLiked = isLikedSession({ org, session })
  const config = useLoggedInConfig()

  if (isLoading) return null
  if (session.status !== 'completed') return null
  if (!config.enableLikeAd) return null

  const onToggleThumb = () => {
    invariant(org, '!org')
    updateOrganization({ ...org, likedSessions: getNewLikedSession(session, org) })
  }

  return (
    <Box onClick={onToggleThumb} backgroundColor={Colors.white}>
      {!isLiked && <AdCreatorAdThumbViewEmptyState />}
      {isLiked && <AdCreatorThumbView />}
    </Box>
  )
}

export function AdCreatorThumbView() {
  return (
    <Box>
      <Container direction="row" fullPadding spacing={tinySpacing} align="center">
        <Box pointer>
          <ThumbUp color={Colors.white} />
        </Box>
      </Container>
    </Box>
  )
}
const Container = styled(Box)`
  border-radius: ${BorderRadixes.regular};
  background-color: ${Colors.secondary100};
`
