import invariant from 'invariant'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { defaultSpacing, smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useAsyncFunction } from '../hooks/useAsyncFunction'
import { isVitecNextProductEnabled } from '../libs/CloudFunctionsApiHandler'
import useQueryParams from '../libs/useQueryParams'
import RoutePath from '../../../../pure/libs/RoutePath'
import { Texts } from '../../../../pure/libs/AppTexts'
import { OnClickVitecNextProductInfoItem, VitecNextProductInfoItem } from '../types/VitecNextProductTypes'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaTextLoader from './FigmaTextLoader'
import { VitecNextOrderRefreshIcon } from './Icons'
import Tooltip from './Tooltip'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'

import styled from '@emotion/styled'
import { BorderRadixes } from '../enums/BorderRadixes'
import { Shadows } from '../enums/Shadows'
import Colors from '../../../../pure/libs/Colors'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { getFigmaText } from '../libs/TextRepository'
import { addQueryParamsToUrl } from '../hooks/useQueryparamHelper'
import { VitecNOOrder, VitecNOOrderQueryParams } from '../../../../pure/types/VitecNODerivedTypes.js'
import { ONE_SECOND, TEN_SECONDS } from '../../../../pure/libs/Consts'

export const VitecNextOrderProductButton = ({
  vitecNextProduct,
  vitecNOOrder
}: {
  vitecNextProduct: VitecNextProduct
  vitecNOOrder: VitecNOOrder
}) => {
  const [isLoading, setIsLoading] = useState<boolean>()
  const vitecNextProductInfoItem: VitecNextProductInfoItem = VitecNextProductInfoItems[vitecNextProduct]
  const { textKey, imageKey, enabled = true } = vitecNextProductInfoItem

  const enableRefresh = !!vitecNOOrder.jaguarResponses?.[vitecNextProduct]?.requestId
  const hasSomeOrder = !!Object.entries(vitecNOOrder.jaguarResponses || {}).filter(([k, v]) => !!v.requestId).length
  const { state, setState } = useAppState()
  const qp = useQueryParams<VitecNOOrderQueryParams>()
  const productPageUrl = addQueryParamsToUrl(RoutePath.VITEC_NEXT_ORDER_PRODUCT, qp)
  const navigate = useNavigate()

  if (!enabled) return null

  const { data: isProductEnabled } = useAsyncFunction(() =>
    isVitecNextProductEnabled({ vitecNextOrderId: vitecNOOrder._id, vitecNextProduct }, state)
  )

  const onClick = async (
    vitecNextProductInfoItem: VitecNextProductInfoItem,
    onClick?: OnClickVitecNextProductInfoItem
  ) => {
    invariant(vitecNOOrder, 'vitecNOOrder should be defined')

    setIsLoading(true)

    if (onClick)
      Promise.resolve()
        .then(() => onClick({ vitecNOOrder, vitecNextProductInfoItem }, state))
        .finally(() => setIsLoading(false))

    setTimeout(() => {
      setState({ ...state, vitecNextOrderProductPageState: { vitecNOOrder, vitecNextProductInfoItem } })
      navigate(productPageUrl)
    }, ONE_SECOND)
  }

  return (
    <Container
      key={vitecNextProductInfoItem.slug}
      spacing={defaultSpacing}
      fullPadding
      align="center"
      pointer
      style={{ opacity: isProductEnabled?.isEnabled ? 1 : 0.8 }}
    >
      <Tooltip title={isProductEnabled?.isEnabled ? '' : isProductEnabled?.message || ''} enableNoLineHeight={false}>
        <Box
          pointer
          onClick={() => onClick(vitecNextProductInfoItem, vitecNextProductInfoItem.onClick)}
          fullWidth
          align="center"
        >
          <Box fullWidth align="center" fullHeight>
            <Box fullWidth align="center">
              <FigmaImageContainer imageKey={imageKey} />
              <FigmaTextLoader textKey={textKey} loading={isLoading} />
            </Box>
          </Box>
          <Box
            top
            spacing={smallSpacing}
            onClick={() =>
              onClick(
                vitecNextProductInfoItem,
                vitecNextProductInfoItem.onClickRunAgain || vitecNextProductInfoItem.onClick
              )
            }
            visibility={hasSomeOrder && !isLoading ? 'visible' : 'hidden'}
          >
            <Box visibility={enableRefresh ? 'visible' : 'hidden'}>
              <Tooltip title={getFigmaText(Texts.orderViewRerun)}>
                <VitecNextOrderRefreshIcon />
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Tooltip>
    </Container>
  )
}

const Container = styled(Box)`
  margin: ${defaultSpacing};
  border-radius: ${BorderRadixes.regular};
  background-color: ${Colors.neutral30};
  box-shadow: ${Shadows.large};
  width: 250px;
`
