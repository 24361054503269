import Menu from '@mui/material/Menu'
import useAppState from '../hooks/useAppState'
import useLoggedInConfig, { isLimitedUser } from '../hooks/useLoggedInConfig'
import { deleteAccount } from '../libs/CloudFunctionsApiHandler'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { OrgUserRole, OrgUserStatus, User } from '../../../../pure/types/types'
import { MenuItem } from './MenuItem'
import useNotification from '../hooks/useNotification'
import { updateUser } from '../libs/DBApiHandler'

type Props = {
  anchorEl?: HTMLElement
  onClose: () => void
  user: User
}

export default function SettingsMyOrganizationRowMenu({ anchorEl, onClose, user }: Props) {
  const { state } = useAppState()
  const config = useLoggedInConfig()
  const notification = useNotification()

  const onClickDisable = () => setStatus(OrgUserStatus.DISABLED)

  const onClickEnable = () => setStatus(OrgUserStatus.ACTIVE)

  const setStatus = (orgUserStatus: OrgUserStatus) => {
    updateUser({
      ...user,
      orgUserStatus
    })
    onClose()
  }

  const onChangePermissions = (role: OrgUserRole) => {
    updateUser({
      ...user,
      role
    })
    onClose()
  }

  const status = user.orgUserStatus
  const isEnabled = !status || status === OrgUserStatus.ACTIVE
  const isDisabled = [OrgUserStatus.DISABLED, OrgUserStatus.DELETE].includes(status as OrgUserStatus)
  const _isLimitedUser = isLimitedUser(state)
  const onClickDeleteAccount = () =>
    deleteAccount(user._id, state)
      .then(() => notification.success())
      .then(() => onClose())
  return (
    <div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose} disableAutoFocusItem>
        {isDisabled && <MenuItem onClick={onClickEnable} textKey={TextsDeprecated.adminUserListEnableAccountMenuText}></MenuItem>}
        {isEnabled && (
          <MenuItem onClick={onClickDisable} textKey={TextsDeprecated.adminUserListDisableAccountMenuText}></MenuItem>
        )}
        {!_isLimitedUser && (
          <MenuItem
            onClick={() => onChangePermissions(OrgUserRole.LIMITED)}
            textKey={TextsDeprecated.adminUserListReadOnlyUserMenuText}
          ></MenuItem>
        )}
        {_isLimitedUser && (
          <MenuItem
            onClick={() => onChangePermissions(OrgUserRole.FULL)}
            textKey={TextsDeprecated.adminUserListFullUserMenuText}
          />
        )}
        {config.enableDeleteAccount && (
          <MenuItem textKey={TextsDeprecated.adminUserListDeleteAccountMenuText} onClick={onClickDeleteAccount} />
        )}
      </Menu>
    </div>
  )
}
