import styled from '@emotion/styled'
import { ONBOARDING_MAX_WIDTH } from '../libs/HardCodedSizes'
import { isInAppBrowser } from '../libs/SignInHelper'
import { SignInViewProps } from '../../../../pure/types/SignInTypes'
import Box from './Box'
import Layout from './Layout'
import SignInLandingMobileInAppBrowser from './SignInLandingMobileInAppBrowser'
import { SignInLandingSignInForm } from './SignInLandingSignInForm'
import Colors from '../../../../pure/libs/Colors'

export default function SignInLandingMobile(props: SignInViewProps) {
  if (isInAppBrowser()) return <SignInLandingMobileInAppBrowser {...props} />

  return (
    <Layout enableFooter={false} Container={LayoutContinaner}>
      <Box fullWidth fullPadding>
        <SignInLandingSignInForm {...props} />
      </Box>
    </Layout>
  )
}

export const LayoutContinaner = styled(Box)`
  background-color: ${Colors.primary100};
  overflow-x: hidden;
  overflow-y: hidden;
`
