import { useState } from 'react'
import { smallSpacing } from '../enums/Spacings'
import FigmaText from './FigmaText'
import { Texts } from '../../../../pure/libs/AppTexts'
import { JaguarSession, CustomText } from '../../../../pure/types/types'
import Box from './Box'
import {
  AdCreatorLatestAdsFileCopy,
  AdCreatorLatestadsSectionCollapseIcon,
  AdCreatorLatestadsSectionExpandIcon
} from './Icons'
import { getTextWithDots } from './ProductsSectionInfoRow'
import { onClickCopySessions } from '../libs/onClickCopy'
import { LIST_ITEM_MAX_TEXT_LENGTH } from './AdCreatorLatestAdsItem'

const SOMETHING_HIGH = 10000000000
export function SettingsAIWriterCustomTextView({ uploadedText }: { uploadedText: CustomText }) {
  const [expanded, setExpanded] = useState(false)
  return (
    <Box fullWidth pointer onClick={() => setExpanded(!expanded)}>
      <Box top spacing={smallSpacing}>
        <FigmaText
          textKey={Texts.adCreatorLatestAdsSectionDescription}
          text={getTextWithDots(uploadedText.text, expanded ? SOMETHING_HIGH : LIST_ITEM_MAX_TEXT_LENGTH)}
        />
      </Box>
    </Box>
  )
}
