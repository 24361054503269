import { useEffect } from 'react'
import eventEmitter, { Events } from '../../../../pure/libs/EventEmitter'
import config from '../Config'
import useNotification from './useNotification'

export const OnMountApp = () => {
  useOnMountApp()
  return null
}

export const useOnMountApp = () => {
  const notification = useNotification()

  useEffect(() => {
    eventEmitter.on(Events.NEW_SERVER_ERROR, (message) => {
      notification.error(undefined, config.enableErrorMessagesInNotifications ? message : undefined)
    })
    eventEmitter.on(Events.NEW_ANALYTICS_EVENT, (data) => console.log('New GA Event: ', data))

    return () => {
      eventEmitter.removeAllListeners()
    }
  }, [])
}
