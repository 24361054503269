import { smallSpacing, tinySpacing } from '../enums/Spacings'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { Texts } from '../../../../pure/libs/AppTexts'
import { UploadedFile } from '../../../../pure/types/JaguarTypes'
import { JaguarRequestForm, JaguarSession } from '../../../../pure/types/types'
import Box from './Box'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import Loader from './Loader'

export type OnClickRegenerateAd = (req: JaguarRequestForm) => unknown
export type OnUpdateSession = (session: JaguarSession) => unknown

export type VitecSummarizeDocResultItemProps = {
  file: UploadedFile
  header: string
  description: string
}

export function VitecSummarizeDocResultItem({ file, description, header }: VitecSummarizeDocResultItemProps) {
  return (
    <Box fullWidth>
      <Box top spacing={smallSpacing} fullWidth>
        <FigmaText textKey={Texts.adCreatorAdSectionHeadline} text={header} />
      </Box>
      <Box top bottom fullWidth>
        <Box fullWidth>
          {!description && (
            <Box fullWidth align="center">
              <Loader />
            </Box>
          )}
          {!!description && (
            <FigmaTextWithStyle
              Component={FigmaTextWithLinebreaks as any}
              separator={<span />}
              separatorWhenEmptyContent={<br />}
              type="body1"
              text={description}
            />
          )}
        </Box>
      </Box>
      <Box
        top
        fullWidth
        // there is a weird spacing in AdCreatorAdSectionResponse forcing us to add some padding in the bottom => tech debt
        spacing={tinySpacing}
      ></Box>
    </Box>
  )
}
