import { useEffect } from 'react'
import config from '../Config'
import { hideChatBubble, showChatBubble } from '../libs/TidioHelper'
import useScript from './useScript'

export function useTidio() {
  useScript('//code.tidio.co/edeij4z50aasodzjrytc5vkavwqe7ufl.js', config.enableTidio)

  useEffect(() => {
    config.enableTidio && showChatBubble()
    return () => hideChatBubble()
  }, [])
}
