enum AnalyticsEvent {
  LOGIN = 'login',
  SIGN_UP = 'sign_up',
  CLICK_JAGUAR_TYPE = 'click_jaguar_type',
  BLUR_PROPERTY_INFO = 'blur_property_info',
  CHANGE_LANGUAGE_CODE = 'change_language_code',
  CHANGE_STYLE = 'change_style',
  CHANGE_AD_LENGTH = 'change_ad_length',
  CHANGE_NUMBER_OF_WORDS = 'change_number_of_words',
  CHANGE_NUMBER_OF_PARAGRAPHS = 'change_number_of_paragraphs',
  CLICK_CREATE_AD = 'click_create_ad',
  CLICK_GOTO_CREATE_AD = 'click_goto_create_ad',
  FINISHED_CREATING_AD = 'finished_creating_ad',
  COPY_TO_CLIPBOARD = 'copy_to_clipboard'
}

export default AnalyticsEvent
