import styled from '@emotion/styled'
import invariant from 'invariant'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BorderRadixes } from '../enums/BorderRadixes'
import { Shadows } from '../enums/Shadows'
import { SMALL_SPACING_PX, bigSpacing, defaultSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import ZIndices from '../enums/Zindices'
import useAppState from '../hooks/useAppState'
import { useOnClickCreateAndRedirectToStripeCheckoutSession } from '../hooks/useOnClickCreateAndRedirectToStripeCheckoutSession'
import { useUpsellPriceInfo } from '../hooks/useUpsellPriceInfo'
import { PricingPageProductContainerWidth } from '../libs/HardCodedSizes'
import { PricingProductInfoItem } from '../libs/PricingPageHelper'
import { ProductType } from '../libs/ProductInfo'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import Colors from '../../../../pure/libs/Colors'
import RoutePath from '../../../../pure/libs/RoutePath'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../../pure/libs/AppTexts'
import Box from './Box'
import Button from './Button'
import FigmaTextLoader from './FigmaTextLoader'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { PricingRowCheckIcon } from './Icons'
import { onClickContactUs } from './MenuButtonsDesktop'
import { NoWrapFigmaText } from './StyledComponents'

const TOP_PADDING = SMALL_SPACING_PX / 2
export function PricingProduct({
  productInfo: pI,
  onClick,
  productType
}: {
  productInfo: PricingProductInfoItem
  onClick?: () => unknown
  productType: ProductType
}) {
  const { state } = useAppState()
  const { price, ordinaryPrice } = useUpsellPriceInfo({ productType })

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const goToStripe = useOnClickCreateAndRedirectToStripeCheckoutSession({ setIsLoading, productType })
  const onRedirectToStripe = () => {
    if (!state.user._id) return navigate(RoutePath.ROOT)
    goToStripe(setIsLoading)
  }

  const priceTextKey = pI.enablePriceText ? { ...pI.priceTextKey, characters: price } : pI.priceTextKey
  return (
    <Box top spacing={`${TOP_PADDING}px`}>
      <OuterContainer fullHeight position="relative">
        <BackgroundContainer
          zIndex={ZIndices.regular}
          style={{ height: bigSpacing, top: -TOP_PADDING }}
          position="absolute"
          enableGradient={pI.enableGradient}
          fullWidth
        />
        <Container
          zIndex={ZIndices.high}
          fullPadding
          spacing={defaultSpacing}
          fullHeight
          position="relative"
          justify="space-between"
          fullWidth
        >
          <Box fullWidth fullHeight>
            <Box top fullWidth>
              <FigmaTextWithStyle type="h8" textKey={pI.titleTextKey} />
            </Box>
            <Box direction="row" fullWidth align="center">
              <FigmaTextWithStyle
                type="h2"
                textKey={priceTextKey}
                text={pI.enablePriceText ? price : undefined}
                Component={NoWrapFigmaText}
              />
              {!!pI.priceTextKeySuffix && (
                <Box top spacing={defaultSpacing}>
                  <Box left spacing={tinySpacing}>
                    <FigmaTextWithLinebreaks textKey={pI.priceTextKeySuffix} />
                  </Box>
                </Box>
              )}
            </Box>
            {pI.descriptionPrefixTextKey && (
              <Box fullWidth>
                <FigmaTextWithStyle
                  type="body1"
                  text={formatText(getFigmaText(pI.descriptionPrefixTextKey), [ordinaryPrice])}
                  opacity={0.3}
                />
              </Box>
            )}
            <Box fullWidth top={!!pI.descriptionPrefixTextKey} spacing={smallSpacing}>
              <FigmaTextWithLinebreaks
                styleTextKey={Texts.pricingItemDescriptionFree}
                textKey={pI.descriptionTextKey}
              />
            </Box>

            {pI.buttonTextKey && (
              <Box fullWidth align="center" top spacing={smallSpacing} fullHeight>
                <Button
                  color={pI.primaryButtonColor as any}
                  onClick={onClick || onRedirectToStripe}
                  loading={isLoading}
                  spacing={smallSpacing}
                  fullWidth
                >
                  <FigmaTextLoader textKey={pI.buttonTextKey} loading={isLoading} />
                </Button>
              </Box>
            )}
            {pI.buttonSecondaryTextKey && (
              <Box fullWidth align="center" top spacing={smallSpacing} fullHeight>
                <Button
                  variant="outlined"
                  textKey={pI.buttonSecondaryTextKey}
                  onClick={onClickContactUs}
                  loading={isLoading}
                  spacing={smallSpacing}
                  fullWidth
                >
                  <FigmaTextLoader textKey={pI.buttonSecondaryTextKey} />
                </Button>
              </Box>
            )}
          </Box>
        </Container>
        <Box top left>
          {pI.rows
            .filter((r) => !!r)
            .map((row, key) => {
              invariant(row, 'row')
              const { header, description } = row
              return (
                <Box key={key} direction="row" align="center" bottom spacing={defaultSpacing}>
                  <CheckContainer backgroundColor={pI.rowIconBackgroundColor} fullPadding spacing={tinySpacing}>
                    <PricingRowCheckIcon color={pI.rowIconCheckColor} />
                  </CheckContainer>
                  <Box left spacing={smallSpacing}>
                    <FigmaText textKey={header} />
                    <FigmaText textKey={description} />
                  </Box>
                </Box>
              )
            })}
        </Box>
      </OuterContainer>
    </Box>
  )
}

const OuterContainer = styled(Box)`
  border-radius: 0px 0px 16px 16px;
  background: var(--neutral-10, #f9fafa);
  box-shadow: ${Shadows.blurred};
`

const Container = styled(Box)`
  border-radius: ${BorderRadixes.moderate};
  background: ${Colors.white};
  box-shadow: ${Shadows.blurred};
  max-width: ${PricingPageProductContainerWidth}px;
`

const BackgroundContainer = styled(Container)<{ enableGradient?: boolean }>`
  background: ${({ enableGradient }) =>
    enableGradient ? `linear-gradient(to right, #000b61ff, #2069faff)` : Colors.neutral90};
`

const CheckContainer = styled(Box)`
  border-radius: 50%;
`
