import React from 'react'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import FigmaTextArray from './FigmaTextArray'
import RoutePath from '../../../../pure/libs/RoutePath'
import { Texts } from '../../../../pure/libs/AppTexts'

export function SignInLandingFingerprint() {
  return (
    <FigmaTextArray
      figmaTextPropSets={[
        { textKey: Texts.signInLandingFineprint },
        {
          textKey: Texts.signInLandingFineprint,
          anchorHref: RoutePath.TERMS_AND_CPNDITIONS,
          text: 'Terms and Conditions',
          link: true
        },
        { textKey: Texts.signInLandingFineprint, text: '&' },

        {
          textKey: Texts.signInLandingFineprint,
          anchorHref: RoutePath.PRIVACY_POLICY,
          text: 'Privacy Policy',
          link: true
        }
      ]}
    />
  )
}
