import styled from '@emotion/styled'
import { ClickAwayListener } from '@mui/base'
import { useState } from 'react'
import { BorderRadixes } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import ZIndices from '../enums/Zindices'
import { useUser } from '../hooks/QueryHooks'
import useAppState, { useUserId } from '../hooks/useAppState'
import { editSection } from '../libs/ApiHandler'
import Colors from '../../../../pure/libs/Colors'
import { captureException } from '../libs/SentryHelper'
import { EditSectionRequest } from '../../../../pure/types/JaguarTypes'
import { JaguarSession } from '../../../../pure/types/types'
import { OnUpdateSession } from './AdCreatorAdSection'
import { AdCreatorAdSectionResponse } from './AdCreatorAdSectionResponse'
import { AdCreatorAdSectionResponseTextField } from './AdCreatorAdSectionResponseTextField'
import { AdCreatorAdSectionResponseWithSelectionMenuView } from './AdCreatorAdSectionResponseWithSelectionMenuView'
import Box from './Box'

export const AdCreatorAdSectionResponseWithSelectionMenu: React.FC<{
  text: string
  session: JaguarSession
  onUpdateSession: OnUpdateSession
}> = ({ session, onUpdateSession, text: sectionText }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState('')

  const { state } = useAppState()
  const { data: user = state.user } = useUser(useUserId())
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const onClickSection = () => shouldShowMenu && setOpen(true)

  const onClickMenuItem = (textTransformType: EditSectionRequest['textTransformType']) =>
    Promise.resolve(setLoading(true))
      .then(() =>
        editSection({
          req: {
            requestId: session.request.requestId,
            organizationId: session.request.organizationId,
            languageCode: session.request.languageCode,
            keyWords: [],
            keywordMapItems: session.request.keywordMapItems,
            propertyInfo: session.request.propertyInfo,
            textTransformType,
            generatedAd: session.response,
            sectionText
          }
        })
      )
      .then((res) => onClickEdit(res.response))
      .catch((err) => {
        captureException(err)
        onClose()
      })
      .finally(() => setLoading(false))

  const onClickRephrase = () => onClickMenuItem('rephrase')
  const onClickExpand = () => onClickMenuItem('expand')
  const onClickShorten = () => onClickMenuItem('shorten')

  const onClickEdit = (value: string) => {
    setIsEditing(true)
    setValue(value)
  }

  const shouldShowMenu = session.status !== 'active'

  const onClickDone = () => {
    onClose()
    onUpdateSession({
      ...session,
      response: getNewSessionResponse({ newText: value, sectionText, session })
    })
  }

  const onClose = () => {
    setIsEditing(false)
    setOpen(false)
  }

  if (isEditing)
    return (
      <AdCreatorAdSectionResponseTextField
        onClickCancel={onClose}
        onClickDone={onClickDone}
        value={value}
        onChange={({ target: { value } }) => setValue(value)}
      />
    )

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)} mouseEvent="onMouseDown">
      <Container fullWidth position="relative" pointer open={open}>
        <Box fullWidth align="center" zIndex={ZIndices.high} position="relative">
          {open && (
            <Box position="absolute" style={{ top: -50 }}>
              <AdCreatorAdSectionResponseWithSelectionMenuView
                onClickRephrase={onClickRephrase}
                onClickExpand={onClickExpand}
                onClickShorten={onClickShorten}
                onClickEdit={() => onClickEdit(sectionText)}
                loading={loading}
              />
            </Box>
          )}
        </Box>
        <Box zIndex={ZIndices.regular} position="relative" onClick={onClickSection}>
          <AdCreatorAdSectionResponse text={sectionText} isSelected={open} />
        </Box>
      </Container>
    </ClickAwayListener>
  )
}

const Container = styled(Box)<{ open?: boolean }>`
  background-color: ${({ open }) => (open ? Colors.primary100 : Colors.white)};
  border-radius: ${BorderRadixes.moderate};

  padding: ${({ open }) => (open ? `${smallSpacing}` : `${smallSpacing} 0`)};
  color: ${({ open }) => (open ? Colors.white : Colors.neutral100)};
`

function getNewSessionResponse({
  newText,
  sectionText,
  session
}: {
  newText: string
  sectionText: string
  session: JaguarSession
}) {
  const oldText = session.response
  const newResponse = oldText.replace(sectionText, newText)
  return newResponse
}
