import styled from '@emotion/styled'
import invariant from 'invariant'
import { Controller } from 'react-hook-form'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState, { useOrgId } from '../hooks/useAppState'
import { useForm } from '../hooks/useForm'
import useNotification from '../hooks/useNotification'
import { EDIT_VISION_STATEMENT_MODAL_MIN_WIDTH } from '../libs/HardCodedSizes'
import FigmaText from './FigmaText'
import { inviteUser } from '../libs/CloudFunctionsApiHandler'
import { getFigmaText } from '../libs/TextRepository'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import Box from './Box'
import Button from './Button'
import FigmaTextLoader from './FigmaTextLoader'
import { TextFieldOutlined } from './TextFieldOutlined'
import { TextFieldWithLabelForm } from './TextFieldWithLabelForm'
import { useOrganization } from '../hooks/QueryHooks'
import { ValidationSchema } from '../libs/ModalInviteUserHelper'
import { Texts } from '../../../../pure/libs/AppTexts'
import { useLanguage } from '../hooks/useDefaultLanguage'

type InitialValues = {
  name: string
  email: string
}

export default function ModalInviteUserForm({ onClose }: { onClose: () => void }) {
  const { state } = useAppState()
  const notificaiton = useNotification()
  const orgId = useOrgId()
  const language = useLanguage()

  const onSubmit = ({ email, name }) => {
    return inviteUser(
      {
        orgId,
        name,
        email,
        language,
        createdBy: state.user._id
      },
      state
    ).then(() => {
      onClose()
      notificaiton.success()
    })
  }

  const defaultValues = {
    name: '',
    email: ''
  }

  const formProps = useForm<InitialValues>(ValidationSchema, { defaultValues })

  return (
    <Container fullWidth align="center">
      <Box fullWidth align="center" spacing={bigSpacing}>
        <Box bottom>
          <FigmaText textKey={TextsDeprecated.inviteDialogHeader} />
        </Box>
        <Box fullWidth bottom spacing={smallSpacing}>
          <Controller
            name="name"
            control={formProps.control}
            render={({ field, fieldState }) => (
              <TextFieldWithLabelForm
                {...field}
                fieldState={fieldState}
                labelTextKey={TextsDeprecated.inviteDialogEmailLabel}
                labelText={getFigmaText(TextsDeprecated.onboardingMobileUserNameLabel)}
                name="name"
                component={TextFieldOutlined}
                fullWidth
              />
            )}
          />
        </Box>
        <Box fullWidth bottom spacing={smallSpacing}>
          <Controller
            name="email"
            control={formProps.control}
            render={({ field, fieldState }) => (
              <TextFieldWithLabelForm
                {...field}
                fieldState={fieldState}
                labelTextKey={TextsDeprecated.inviteDialogEmailLabel}
                name="email"
                component={TextFieldOutlined}
                fullWidth
              />
            )}
          />
        </Box>
        <Box direction="row" align="center" justify="space-between" fullWidth top>
          <Box right spacing={tinySpacing}>
            <Button
              onClick={onClose}
              textKey={TextsDeprecated.cancelButtonText}
              buttonType="secondary"
              spacing={tinySpacing}
            />
          </Box>
          <Button onClick={formProps.handleSubmit(onSubmit)} spacing={smallSpacing}>
            <FigmaTextLoader textKey={TextsDeprecated.inviteButtonText} loading={formProps.formState.isSubmitting} />
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  min-width: ${EDIT_VISION_STATEMENT_MODAL_MIN_WIDTH};
`
