import styled from '@emotion/styled'
import { minSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import FigmaText from './FigmaText'
import { TextKey } from '../../../../pure/types/Antiloop'
import Box from './Box'
import Colors from '../../../../pure/libs/Colors'
import { BorderRadixes } from '../enums/BorderRadixes'

export function Badge({ textKey, text }: { textKey: TextKey; text?: string }) {
  return (
    <Container fullPadding spacing={minSpacing}>
      <Box left right spacing={tinySpacing}>
        <FigmaText textKey={textKey} text={text} />
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  border-radius: ${BorderRadixes.circle};
  background: ${Colors.warning80};
`
