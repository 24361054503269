import React, { PropsWithChildren } from 'react'
import { Button, Dialog, DialogTitle, DialogContent } from '@mui/material'
import Box from './Box'
import { ModalCloseIcon } from './Icons'

export const ModalFullScreen: React.FC<PropsWithChildren<{ onClose }>> = ({ onClose, children }) => {
  return (
    <Dialog
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
      }}
      fullScreen
      open
      onClose={onClose}
    >
      <DialogContent sx={{ width: '100vw' }}>{children}</DialogContent>
    </Dialog>
  )
}
