import { UseStripePriceOptions, useStripePrice, useStripePriceNumber } from './useStripePrice'
import { ProductType, StripeProduct } from '../libs/ProductInfo'
import { useUpsellProductForOrgUser } from '../libs/OrgInfo'
import { useUser } from './QueryHooks'
import { useUserId } from './useAppState'

const BaseProduct = StripeProduct.MONTHLY_MEMBERSHIP

export function useUpsellPriceInfo({ productType, opts }: { productType: ProductType; opts?: UseStripePriceOptions }) {
  const { data: user } = useUser(useUserId())
  const orgUserUpsellProduct = useUpsellProductForOrgUser(user)
  productType = orgUserUpsellProduct || productType
  const price = useStripePrice(productType.stripeProductId, opts)
  const ordinaryPrice = useStripePrice(BaseProduct, opts)
  return { price, ordinaryPrice }
}
