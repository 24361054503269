import React from 'react'
import useIsMobile from '../hooks/useIsMobile.js'
import FigmaText, { FigmaTextProps } from './FigmaText.js'
import { TextStyles } from '../../../../pure/libs/TextStyles.js'
import { TextKey } from '../../../../pure/types/Antiloop.js'
import Colors from '../../../../pure/libs/Colors.js'
import { Variant } from '../../../../pure/enums/Variants.js'

type Props = FigmaTextProps & {
  type: keyof typeof TextStyles
  color?: keyof typeof Colors
  opacity?: number
  Component?: React.FC<FigmaTextProps>
  fontFamily?: string
  loading?: boolean
}

export const FigmaTextWithStyle: React.FC<Props> = (props) => {
  const { color, type, opacity = 1, Component = FigmaText } = props
  const isMobile = useIsMobile()
  const textStyle = TextStyles[type].style[isMobile ? Variant.Mobile : Variant.Desktop]
  const styleTextKey: TextKey = {
    ...TextStyles[type],
    style: {
      ...textStyle,
      fontFamily: props.fontFamily || textStyle.fontFamily,
      color: Colors[color || 'primary100'],
      opacity
    }
  }

  return <Component {...props} styleTextKey={styleTextKey} />
}
