import styled from '@emotion/styled'
import TableCell from '@mui/material/TableCell'
import MaterialTableRow from '@mui/material/TableRow'
import moment from 'moment'
import React from 'react'
import useAppState from '../hooks/useAppState'
import FigmaText from './FigmaText'
import { isLimitedUser } from '../hooks/useLoggedInConfig'
import Colors from '../../../../pure/libs/Colors'
import { YYYY_MM_DD } from '../../../../pure/libs/Consts'
import { getFigmaText } from '../libs/TextRepository'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { User } from '../../../../pure/types/types'
import Box from './Box'
import { MoreActionVert } from './MoreAction'
import { MoreActionWithMenu } from './MoreActionWithMenu'
import SettingsMyOrganizationRowMenu from './SettingsMyOrganizationRowMenu'
import { SettingsMyOrganizationUserStatusColumn } from './SettingsMyOrganizationUserStatusColumn'
import { UserWidgetView } from './UserWidget'
import { useOrganization, useUser } from '../hooks/QueryHooks'

type Props = { user: User }
export function SettingsMyOrganizationRow({ user }: Props) {
  const [isHoovering, setIsHoovering] = React.useState(false)
  const { state } = useAppState()
  const { data: org } = useOrganization(user.organizationId)
  const roleTextKey = isLimitedUser(state)
    ? TextsDeprecated.adminUserListUserRowPermissionOptionReadonly
    : TextsDeprecated.adminUserListUserRowPermissionOptionFull

  const onMouseEnter = () => setIsHoovering(true)
  const onMouseLeave = () => setIsHoovering(false)

  const onCloseMenu = ({ onClose }) => {
    onClose()
    setIsHoovering(false)
  }

  return (
    <TableRow onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} isHoovering={isHoovering}>
      <Cell>
        <Box direction="row">
          <Box right>
            <UserWidgetView userId={user._id} />
          </Box>
          <Box>
            <FigmaText textKey={TextsDeprecated.cssAdminUserListUserRowNameText} text={user.name || user.email || '-'} />
            <FigmaText textKey={TextsDeprecated.cssAdminUserListUserRowEmailText} text={user.email || '-'} />
          </Box>
        </Box>
      </Cell>
      <Cell>
        <FigmaText textKey={TextsDeprecated.cssAdminUserListUserRowCellText} text={moment(user.createdAt).format(YYYY_MM_DD)} />
      </Cell>
      <Cell>
        {user.lastActiveAt && (
          <FigmaText
            textKey={TextsDeprecated.cssAdminUserListUserRowCellText}
            text={moment(user.lastActiveAt).format(YYYY_MM_DD)}
          />
        )}
      </Cell>
      <Cell>
        <FigmaText textKey={TextsDeprecated.cssAdminUserListUserRowCellText} text={getFigmaText(roleTextKey)} />
      </Cell>
      <Cell>
        <SettingsMyOrganizationUserStatusColumn user={user} />
      </Cell>
      <Cell>
        <MoreActionWithMenu
          visible
          Menu={(props) => <SettingsMyOrganizationRowMenu {...props} onClose={() => onCloseMenu(props)} user={user} />}
          MoreAction={MoreActionVert}
          isAlwaysVisible
        />
      </Cell>
    </TableRow>
  )
}

const TableRow = styled(MaterialTableRow)<{ isHoovering: boolean }>`
  background-color: ${({ isHoovering }) => (isHoovering ? Colors.white : Colors.white)};
`

const Cell = styled(TableCell)`
  border: 0px;
`
