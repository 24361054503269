import styled from '@emotion/styled'
import { littleSpacing, tinySpacing } from '../enums/Spacings'
import Colors from '../../../../pure/libs/Colors'
import { toPx } from '../../../../pure/libs/Common'
import { getFigmaText } from '../libs/TextRepository'
import { TextStyles } from '../../../../pure/libs/TextStyles'
import { TextKey, TextKeyWithOnlyText } from '../../../../pure/types/Antiloop'
import Box from './Box'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { AdCreatorFormSectionKeyWordDeleteIcon } from './Icons'

export type AdCreatorFormSectionButtonViewProps = {
  onClick?: () => unknown
  onClickRemove?: () => unknown
  textKey?: TextKeyWithOnlyText
  text?: string
  isSelected?: boolean
}

export function AdCreatorFormSectionButtonView({
  onClick,
  textKey,
  isSelected,
  text: _text,
  onClickRemove
}: AdCreatorFormSectionButtonViewProps) {
  const text = _text || (textKey ? getFigmaText(textKey) : '')
  return (
    <Container onClick={onClick} isSelected={isSelected} pointer direction="row" align="center">
      <FigmaTextWithStyle type="body3" text={text} textTransform="uppercase" />
      {onClickRemove && (
        <Box left spacing={tinySpacing} onClick={onClickRemove}>
          <AdCreatorFormSectionKeyWordDeleteIcon size={toPx(TextStyles.body3.style.desktop.fontSize)} />
        </Box>
      )}
    </Container>
  )
}

const Container = styled(Box)<{ isSelected?: boolean }>`
  border-radius: 3.8125rem;
  background-color: ${({ isSelected }) => (isSelected ? Colors.primary20 : Colors.neutral10)};
  padding: ${tinySpacing} ${littleSpacing};
`

export const AdCreatorFormSectionButtonViewContainer = Container
