import invariant from 'invariant'
import { useEffect, useState } from 'react'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { useUser } from '../hooks/QueryHooks'
import useAppState, { useUserId } from '../hooks/useAppState'
import { useCurrentProduct } from '../hooks/useCurrentProduct'
import useIsMobile from '../hooks/useIsMobile'
import useLoggedInConfig from '../hooks/useLoggedInConfig'
import {
  JaguarSessions,
  Requests,
  getDefaultActiveSessions,
  useDefaultSectionFormValues as getDefaultSectionFormValues,
  onNewSession,
  getIsDisabled as useIsDisabled
} from '../libs/AdCreatorFormHelper'
import { useOverQuota } from '../libs/CreateAdHelper'
import JaguarSessionController from '../libs/JaguarSessionController'
import { SectionInfo, SectionInfoArray } from '../libs/SectionInfo'
import { captureException } from '../libs/SentryHelper'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import Colors from '../../../../pure/libs/Colors'
import { NOT_ENOUGH_INFO_ERROR } from '../../../../pure/libs/Consts'
import { getAd, updateAd, updateJaguarSession } from '../libs/DBApiHandler'
import eventEmitter, { Events } from '../../../../pure/libs/EventEmitter'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../../pure/libs/AppTexts'
import { Ad, JaguarRequestForm, JaguarSession } from '../../../../pure/types/types'
import { AdCreatorAd } from './AdCreatorAd'
import { AdCreatorAdUpsellInfo } from './AdCreatorAdUpsellInfo'
import { AdCreatorFormRadioButton } from './AdCreatorFormRadioButton'
import { AdCreatorFormSection } from './AdCreatorFormSection'
import { AdCreatorPageProps } from './AdCreatorPageMobile'
import Box from './Box'
import Button from './Button'
import FigmaTextLoader from './FigmaTextLoader'
import { Line } from './StyledComponents'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import { toBaseObject } from '../libs/Mapper'

export function AdCreatorForm(props: AdCreatorPageProps) {
  const {
    extra = {},
    ad,
    enableIsOverQuotaCheck = true,
    enableUpsellOnAdCreator: enableUpsellOnAdCreatorProps = true,
    enableUpdateAd = true,
    enableAdCreatorForm = true,
    enableAutoStart
  } = props

  const { data: product } = useCurrentProduct()
  const enableUpsellOnAdCreator = product.enableUpsellOnAdCreator && enableUpsellOnAdCreatorProps
  const { state } = useAppState()
  const { defaultJaguarType } = state
  const isMobile = useIsMobile()
  const [loading, setLoading] = useState(false)
  const config = useLoggedInConfig()
  const sections = SectionInfoArray
  const [activeAd, setActiveAd] = useState<Ad | undefined>(ad)

  const userId = useUserId()
  const { data: user = state.user } = useUser(useUserId())
  const { name } = user

  // eslint-disable-next-line prettier/prettier
  const DefaultRequests = sections.reduce((acc, s) => {
    switch (true) {
      case s.jaguarSessionType === defaultJaguarType:
      case s.checkedAsDefault && !defaultJaguarType:
        return {
          ...acc,
          [s.jaguarSessionType as string]: getDefaultSectionFormValues({
            sectionInfo: s,
            user,
            adCreatorPageProps: props,
            state
          })
        }

      default:
        return acc
    }
  }, {})

  const [requests, setRequests] = useState<Requests>(DefaultRequests)

  const _isDisabled = useIsDisabled(requests)

  const _isOverQuota = useOverQuota(user, product) && enableIsOverQuotaCheck
  let isDisabled = _isDisabled || _isOverQuota
  if (config.enableSampleData) isDisabled = false

  const defaultActiveSessions = getDefaultActiveSessions(ad)

  const [sessions, setSessions] = useState<JaguarSessions>(defaultActiveSessions)
  const hasResponse = Object.values(sessions).some((s) => !!s.response)

  const onClick = (requests: Requests) => {
    const _requests = Object.values(requests)
    // TODO WRITE TEST, should close keywords dialog when clicking create ad
    eventEmitter.emit(Events.ON_CREATE_AD_CTA_CLICKED)
    return Promise.resolve(setLoading(true))
      .then(() => updateAd({ ...toBaseObject(state), ...activeAd, userId, extra }))
      .then((ad) => {
        setActiveAd(ad)
        return Promise.all(
          _requests.map((request) => {
            if (!request) return Promise.resolve(undefined)

            invariant(request.isValidByServer !== false, 'request.isValidByServer === false')

            return new JaguarSessionController().startSession({
              request,
              adId: ad._id,

              onNewSession: (session) =>
                onNewSession({
                  session,
                  ad,
                  state,
                  setSessions,
                  updateJaguarSession,
                  updateAd,
                  getAd,
                  onNewAd: props.onAdCompleted
                }),
              state
            })
          })
        )
      })
      .catch((error) => {
        if (error.name === 'AbortError') return
        if (error.message.includes(NOT_ENOUGH_INFO_ERROR)) return
        if (error.message.includes('Failed to fetch')) return
        if (error.message.includes('fetch failed')) return
        if (error.message.includes('Load failed')) return

        // captureAndNotifyError(error)
        captureException(error)
      })
      .finally(() => setLoading(false))
  }

  function onClickCreateNewAd() {
    setActiveAd(undefined)
    setRequests(DefaultRequests)
  }

  function onClickRegenerateAd(req: JaguarRequestForm) {
    const _requests = { ...requests, [req.type]: { ...requests[req.type], ...req } }
    setRequests(_requests)
    onClick(_requests)
  }

  function onUpdateSession(session: JaguarSession) {
    invariant(activeAd, '!activeAd')
    setSessions({ ...sessions, [session.request.type]: session })
    updateAd({ ...activeAd, activeSessions: { ...sessions, [session.request.type]: session } })
  }

  useEffect(() => {
    eventEmitter.addListener(Events.CREATE_AD_CLICKED, () => {
      onClickCreateNewAd()
    })
    return () => {
      eventEmitter.removeAllListeners(Events.CREATE_AD_CLICKED)
    }
  }, [])

  // Autostart ad generation, feauture is used in vitecNext and input validation is done when order is created in vitecNext.
  useEffect(() => {
    enableAutoStart && onClick(requests)
  }, [])

  const shouldShowActiveAd = hasResponse && activeAd

  return (
    <Box fullWidth>
      {enableAdCreatorForm && (
        <Box fullWidth>
          {!name && <FigmaText textKey={Texts.adCreatorWelcomeEmptyState} />}
          {name && (
            <FigmaText
              textKey={Texts.adCreatorWelcome}
              text={formatText(getFigmaText(Texts.adCreatorWelcome), [name])}
            />
          )}

          <FigmaTextWithStyle type="h3" textKey={Texts.adCreatorTitle} color="white" />
          <FigmaTextWithLinebreaks textKey={Texts.adCreatorSubtitleLong} />
          <Box direction="row" align="center" style={{ flexWrap: 'wrap' }} top>
            {Object.entries(SectionInfo).map(([jaguarType, section], key) => (
              <Box top={isMobile} spacing={tinySpacing} key={key}>
                <Box right spacing={tinySpacing}>
                  <AdCreatorFormRadioButton
                    textKey={section.sectionNameTextKey}
                    checked={!!requests[jaguarType]}
                    onChange={() =>
                      setRequests({
                        [jaguarType]: getDefaultSectionFormValues({ sectionInfo: SectionInfo[jaguarType], state })
                      })
                    }
                  />
                </Box>
              </Box>
            ))}
          </Box>
          <Box top fullWidth>
            {Object.entries(requests)
              .filter(([_, value]) => !!value)
              .map(([type, _], index, arr) => {
                const section = SectionInfo[type as string]
                invariant(section, '!section &s', type)
                return (
                  <Box key={type} fullWidth bottom={index < arr.length - 1}>
                    <AdCreatorFormSection
                      sectionInfo={section}
                      index={index + 1}
                      onNewJaguarRequest={(req?: JaguarRequestForm) => setRequests({ ...requests, [type]: req })}
                      adCreatorPageProps={props}
                    />
                  </Box>
                )
              })}
          </Box>
        </Box>
      )}
      {(!hasResponse || enableUpdateAd) && (
        <Box top fullWidth align="center" spacing={bigSpacing}>
          <Box width={isMobile ? '100%' : '50%'}>
            <Button disabled={isDisabled} onClick={() => onClick(requests)} fullWidth>
              <Box direction="row" align="center">
                <Box left spacing={tinySpacing}>
                  <FigmaTextLoader
                    textKey={shouldShowActiveAd ? Texts.adCreatorUpdateAdCtaButton : Texts.adCreatorCtaButton}
                    loading={loading}
                  />
                </Box>
              </Box>
            </Button>
          </Box>
        </Box>
      )}
      {enableUpsellOnAdCreator && (
        <Box fullWidth align="center" top spacing={smallSpacing}>
          <AdCreatorAdUpsellInfo {...props} />
        </Box>
      )}
      {shouldShowActiveAd && (
        <Box fullWidth top spacing={bigSpacing} bottom>
          <Box fullWidth>
            <Line color={Colors.white} opacity={0.2} />
          </Box>
          <Box fullWidth>
            <AdCreatorAd
              ad={activeAd}
              sessions={sessions}
              onClickCreateNewAd={onClickCreateNewAd}
              onClickRegenerateAd={onClickRegenerateAd}
              adCreatorPageProps={props}
              onUpdateSession={onUpdateSession}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}
