import config from '../Config'
import { AdFeedbackRequest } from '../../../../pure/types/types'
import { AdditionalInfo, CreatePromptRequest, EditSectionRequest, UploadFilesRequest } from '../../../../pure/types/JaguarTypes'
import { get, post } from './ApiHandlerHelper'

export const getKeyWords = () => get(`${config.jaguarUrl}/keywords`, { params: {} })

export const createFeedback = (req: AdFeedbackRequest) => post(`${config.jaguarUrl}/createFeedback`, { body: req })

export const createPrompt = ({ req, signal }: { req: CreatePromptRequest; signal: AbortSignal }) =>
  post(`${config.jaguarUrl}/createPrompt`, { body: req, signal })

export const createHeadline = (req: CreatePromptRequest): Promise<{ requestId: string; response: string }> =>
  post(`${config.jaguarUrl}/createHeadline`, { body: req }).then((res) => res.json())

export const checkPropertyInfo = ({ req }: { req: CreatePromptRequest }) =>
  post(`${config.jaguarUrl}/checkPropertyInfo`, { body: req })

export const throwError = ({ req }: { req: { status: number } }) =>
  post(`${config.jaguarUrl}/throwError`, { body: req })

export const uploadFiles = ({ req }: { req: UploadFilesRequest }): Promise<AdditionalInfo[]> =>
  post(`${config.jaguarUrl}/uploadFiles`, { body: req }).then((res) => res.json())

export const editSection = ({ req }: { req: EditSectionRequest }): Promise<{ requestId: ''; response: string }> =>
  post(`${config.jaguarUrl}/editSection`, { body: req }).then((res) => res.json())
