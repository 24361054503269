import styled from '@emotion/styled'
import { Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { BorderRadixes } from '../enums/BorderRadixes'
import { Rating } from '../enums/Rating'
import { Shadows } from '../enums/Shadows'
import { bigSpacing, regularSpacing, smallSpacing } from '../enums/Spacings'
import { useForm } from '../hooks/useForm'
import { JaguarSessions } from '../libs/AdCreatorFormHelper'
import * as ApiHandler from '../libs/ApiHandler'
import { captureException } from '../libs/SentryHelper'
import { RequiredTextValidation } from '../libs/ValidationHelper'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../../pure/libs/AppTexts'
import { AdFeedbackRequest } from '../../../../pure/types/types'
import Box from './Box'
import Button from './Button'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { RatingButton } from './RatingButton'
import TextFieldForm from './TextFieldForm'

const ValidationSchema = Yup.object().shape({
  comment: RequiredTextValidation('Enter value')
})

export function RatingSelectRate({
  onClick: propsOnClick,
  jaguarSessions
}: {
  onClick: () => unknown
  jaguarSessions: JaguarSessions
}) {
  const onSubmit = (req: Partial<AdFeedbackRequest>) => {
    propsOnClick?.()
    Promise.all(
      Object.values(jaguarSessions).map(({ _id: requestId }) => ApiHandler.createFeedback({ ...req, requestId }))
    )
      //
      .catch(captureException)
  }

  const defaultValues: Partial<AdFeedbackRequest> = { comment: '' }
  const formProps = useForm(ValidationSchema, { defaultValues, reValidateMode: 'onChange' })

  return (
    <Container top bottom spacing={bigSpacing}>
      <Box fullWidth right left alignText="center" align="center">
        <FigmaTextWithLinebreaks textKey={Texts.ratingHeader} />
        <Box top spacing={regularSpacing} fullWidth>
          <RatingButton textKey={Texts.ratingDontLike} rating={Rating.ONE} onClick={onSubmit} />
        </Box>
        <Box top spacing={smallSpacing} fullWidth>
          <RatingButton textKey={Texts.ratingNotGood} rating={Rating.TWO} onClick={onSubmit} />
        </Box>
        <Box top spacing={smallSpacing} fullWidth>
          <RatingButton textKey={Texts.ratingItsOk} rating={Rating.THREE} onClick={onSubmit} />
        </Box>
        <Box top spacing={smallSpacing} fullWidth>
          <RatingButton textKey={Texts.ratingItsGreat} rating={Rating.FOUR} onClick={onSubmit} />
        </Box>
        <Box top spacing={smallSpacing} fullWidth>
          <RatingButton textKey={Texts.ratingSuperb} rating={Rating.FIVE} onClick={onSubmit} />
        </Box>
        <Box top spacing={smallSpacing}>
          <FigmaTextWithStyle type="body2" text={getFigmaText(Texts.adCreatorAdSectionButtonsOr)} />
        </Box>
        <Box top spacing={smallSpacing} fullWidth>
          <Controller
            name="comment"
            control={formProps.control}
            render={({ field, fieldState }) => (
              <TextFieldForm
                {...field}
                name="comment"
                fieldState={fieldState}
                placeholderTextKey={Texts.ratingSuggestionPLaceholder}
                multiline
                rows={4}
                fullWidth
              />
            )}
          />
        </Box>
        <Box top spacing={smallSpacing}>
          <Button
            textKey={Texts.ratingButtonWriteSuggestions}
            variant="outlined"
            buttonType="secondary"
            spacing={smallSpacing}
            onClick={formProps.handleSubmit(onSubmit)}
          />
        </Box>
      </Box>
    </Container>
  )
}

export const Container = styled(Box)`
  border-radius: ${BorderRadixes.moderate};
  background: var(--White, #fff);
  box-shadow: ${Shadows.tiny};
  border: 1px solid lightgrey;
  min-width: 320px;
  min-height: 650px;
`
