import styled from '@emotion/styled'
import { bigSpacing, defaultSpacing, smallSpacing } from '../enums/Spacings'
import useIsMobile from '../hooks/useIsMobile'
import { useScrollToTop } from '../hooks/useScrollToTop'
import FigmaText from './FigmaText'
import Colors from '../../../../pure/libs/Colors'
import Images from '../../../../pure/libs/AppImages'
import { Texts } from '../../../../pure/libs/AppTexts'
import { TextKey } from '../../../../pure/types/Antiloop'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import Footer from './Footer'
import Layout from './Layout'
import { PricingItemInfoRow } from './PricingItemInfoRow'
import { PricingPageMobile } from './PricingPageMobile'
import { PricingProductToggle } from './PricingProductToggle'
import { useState } from 'react'
import { ProductInfo, ProductType } from '../libs/ProductInfo'

export default function PricingPage(props: { headerTextKey?: TextKey; enableUpsellEnterprice?: boolean }) {
  const { headerTextKey = Texts.pricingSubheading } = props
  const [productType, setProductType] = useState<ProductType>(ProductInfo.ANNUAL_MEMBERSHIP)

  useScrollToTop()
  const isMobile = useIsMobile()

  if (isMobile) return <PricingPageMobile headerTextKey={headerTextKey} productType={productType} />

  return (
    <Layout enableFooter={false}>
      <Container fullWidth top spacing={defaultSpacing} align="center" style={{ minHeight: '100vh' }}>
        <FigmaText textKey={Texts.pricingHeading} />
        <Box top spacing={smallSpacing}>
          <FigmaTextWithStyle type="body5" textKey={Texts.pricingSubheading} />
        </Box>
        <Box top>
          <PricingProductToggle productType={productType} setProductType={setProductType} />
        </Box>
        <Box top bottom spacing={bigSpacing} position="relative">
          <Box position="absolute" style={{ top: -600, right: -200, pointerEvents: 'none' }}>
            <FigmaImageContainer imageKey={Images.pricingBackgroundGazelleLogo} />
          </Box>
          <PricingItemInfoRow productType={productType} />
        </Box>
        <Footer />
      </Container>
    </Layout>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
`
