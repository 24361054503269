import Box from './Box'
import React, { PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import PublicAssets from '../../../../pure/enums/PublicAssets'
import { smallSpacing } from '../enums/Spacings'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import BackButtonMobileOnboarding from './BackButtonMobileOnboarding'

type Props = { content?: React.FC; onClickBack?: () => unknown }
const OnboardingMobileWrapper: React.FC<PropsWithChildren<Props>> = ({
  content: Content,
  onClickBack,
  children: _children
}) => {
  const children = Content ? React.createElement(Content) : _children
  return (
    <Container fullWidth>
      {!!onClickBack && (
        <Box fullWidth align="flex-start" justify="center" left spacing={smallSpacing} top>
          <BackButtonMobileOnboarding
            textKey={TextsDeprecated.onboardingMobileBackButtonText}
            onClickBack={onClickBack}
          />
        </Box>
      )}
      {children}
    </Container>
  )
}

const Container = styled(Box)`
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
`
export default OnboardingMobileWrapper
