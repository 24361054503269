import { ProductInfo, ProductType, Products } from './ProductInfo'
import Orgs from '../../../../pure/assets/orgs.json' 
import { User } from '../../../../pure/types/types'
import { OrgIds } from '../../../../pure/enums/OrgIds'

const DefaultOrgInfo = {
  productType: ProductInfo.MONTHLY_MEMBERSHIP
}

type OrgInfoType = {
  productType?: ProductType
  upsellProductType?: ProductType
}

export const OrgInfo: { [orgId: string]: OrgInfoType } = {
  [OrgIds.GAZELLE]: DefaultOrgInfo,
  // [OrgIds.LANSFAST]: DefaultOrgInfo,
  [OrgIds.BJURFORS]: {
    upsellProductType: ProductInfo.BJURFORS_POST_MVP_CAMPAIGN
  }
}

export const getOrgIdForEmail = (email: string, orgs = Orgs): string | undefined => {
  return orgs.find((org) => org.domain === email.split('@')[1])?._id
}

export const getProductForOrgUser = (user?: User): ProductType | undefined =>
  OrgInfo[user?.organizationId || '']?.productType

export const getUpsellProductForOrgUser = (user?: User): ProductType | undefined =>
  OrgInfo[user?.organizationId || '']?.upsellProductType

export const useUpsellProductForOrgUser = getUpsellProductForOrgUser
