import { UseFormReturn, useWatch } from 'react-hook-form'
import { tinySpacing } from '../enums/Spacings'
import { JaguarRequestForm } from '../../../../pure/types/types'
import { AdCreatorFormSectionButtonView } from './AdCreatorFormSectionButtonView'
import Box from './Box'

export function AdCreatorFormSectionKeywordsMap({ formProps }: { formProps: UseFormReturn<JaguarRequestForm> }) {
  const keywordMapItems = useWatch({ name: 'keywordMapItems', control: formProps.control })
  const hasKeyWords = keywordMapItems.length > 0
  if (!hasKeyWords) return null
  return (
    <Box direction="row" style={{ flexWrap: 'wrap' }}>
      {keywordMapItems.map((k, key) => (
        <Box key={key} right spacing={tinySpacing} top>
          <AdCreatorFormSectionButtonView
            text={k.localizedName}
            onClickRemove={() =>
              formProps.setValue(
                'keywordMapItems',
                keywordMapItems.filter(({ id }) => id !== k.id)
              )
            }
          />
        </Box>
      ))}
    </Box>
  )
}
