import { bigSpacing, regularSpacing, smallSpacing } from '../enums/Spacings'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import Images from '../../../../pure/libs/AppImages'
import { Texts } from '../../../../pure/libs/AppTexts'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import { Container } from './RatingSelectRate'

export function RatingThankYou({ onClick }: { onClick: () => unknown }) {
  return (
    <Container top bottom spacing={bigSpacing}>
      <Box fullWidth right left alignText="center" align="center">
        <FigmaImageContainer imageKey={Images.gazelleIconBlueRound} />
        <Box top spacing={regularSpacing}>
          <FigmaTextWithLinebreaks textKey={Texts.ratingThankYouHeader} />
        </Box>
        <Box top spacing={smallSpacing}>
          <Button
            textKey={Texts.modalFasadMachineSendToFasadSuccessButton}
            variant="outlined"
            buttonType="secondary"
            spacing={smallSpacing}
            onClick={onClick}
          />
        </Box>
      </Box>
    </Container>
  )
}
