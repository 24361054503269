import { getFigmaText } from '../libs/TextRepository'
import { CreatePromptRequest } from '../../../../pure/types/JaguarTypes'
import { JaguarRequestForm, JaguarSessionRequest, User } from '../../../../pure/types/types'

import toast from 'react-hot-toast'
import { getTypedAdLength } from '../../../../pure/libs/AdLengthHelper'
import { FIVE_SECONDS, ONE_SECOND } from '../../../../pure/libs/Consts'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { ExternalSessionQueryParams } from '../../../../pure/types/QueryParamTypes'
import { getQueryParams } from './useQueryParams'
import { captureException } from './SentryHelper'

export const mapToJaguarRequest = (req: JaguarRequestForm, user: User): CreatePromptRequest => {
  const { googlePlace, propertyInfo = '', languageCode = 'en', ...rest } = req

  //TODO WRITE TEST, should set correct adLength for eindomsmegler1 ("long" instead of number)
  const adLength = getTypedAdLength(req.adLength)
  return {
    ...rest,
    requestId: '',
    languageCode,
    propertyInfo,
    organizationId: user.organizationId,
    placesId: googlePlace?.value?.place_id,
    logAdInJaguar: req.isValidByServer,
    adLength
  }
}

export function getExtra(search: string): JaguarSessionRequest['extra'] | undefined {
  const { externalSessionId } = getQueryParams<ExternalSessionQueryParams>(search)
  if (!externalSessionId) return undefined
  return { externalSessionId }
}

export const HIGH_LOAD_ERROR_MESSAGE_TIMEOUT = ONE_SECOND * 15

export const onHighLoadTimeout = () => {
  toast(getFigmaText(TextsDeprecated.homeFormHighLoadToastMessage), {
    icon: '⏰',
    duration: FIVE_SECONDS
  })
  captureException(
    new Error(`JaguarSessionController: No response after ${HIGH_LOAD_ERROR_MESSAGE_TIMEOUT / 1000} seconds`)
  )
}
