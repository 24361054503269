import { useUser } from '../hooks/QueryHooks'
import useAppState, { useUserId } from '../hooks/useAppState'
import { onUpdateUser } from '../libs/SettingsPersonalHelper'
import FigmaText from './FigmaText'
import { LANGUAGES_OBJECT_MENU } from '../../../../pure/libs/Consts'
import { Texts } from '../../../../pure/libs/AppTexts'
import { Language } from '../../../../pure/types/Antiloop'
import SelectFieldWithLabel, { SelectFieldWithLabelProps } from './SelectFieldWithLabel'
import { useLanguage } from '../hooks/useDefaultLanguage'
import { updateUser } from '../libs/DBApiHandler'

export function MenuLoggedInLanguagePicker() {
  const uid = useUserId()
  const { data: user } = useUser(uid)

  const onChange = (language: Language) => {
    user && updateUser({ ...user, language }).then((newUser) => newUser && onUpdateUser(newUser, user))
  }

  return <MenuLoggedInLanguagePickerView onChange={(event) => onChange(event.target.value as Language)} />
}

export function MenuLoggedInLanguagePickerView(props: Omit<SelectFieldWithLabelProps, 'options'>) {
  const { state } = useAppState()
  const language = useLanguage()
  const options = Object.entries(LANGUAGES_OBJECT_MENU).map(([key, value]) => ({ key: value, value: key }))
  return (
    <SelectFieldWithLabel
      size="small"
      defaultValue={state.user?.language || language || 'en'}
      renderValue={(value) => (
        <FigmaText textKey={Texts.menuLanguageSelectText} text={LANGUAGES_OBJECT_MENU[value as Language]} />
      )}
      {...props}
      options={options}
    />
  )
}
