import { ClickAwayListener } from '@mui/base'
import { useState } from 'react'
import { bigSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { captureException } from '../libs/SentryHelper'
import { processExternalSession } from '../libs/CloudFunctionsApiHandler'
import { AdCreatorAdProps } from './AdCreatorAd'
import Box from './Box'
import { ModalCloseIcon } from './Icons'
import Modal, { ModalProps } from './Modal'
import {
  ModalFasadMachineSteps,
  getExternalSessionWithActiveSessions,
  useComponent
} from './ModalExternalSessionMachineHelper'
import { ModalFullScreen } from './ModalFullScreen'

type Props = ModalProps & AdCreatorAdProps

export function ModalExternalSessionMachine(props: Props) {
  const [step, setStep] = useState(ModalFasadMachineSteps.SEND_TO_FASAD)

  const { state } = useAppState()
  const [loading, setLoading] = useState(false)

  const onClickContinue = () => {
    Promise.resolve(setLoading(true))
      .then(() => {
        switch (step) {
          case ModalFasadMachineSteps.SEND_TO_FASAD: {
            const externalSession = getExternalSessionWithActiveSessions(props)
            return processExternalSession(externalSession, state)
              .then(() => setStep(ModalFasadMachineSteps.SEND_TO_FASAD_SUCCESS))
              .then(() =>
                Promise.resolve()
                  .then(() => (window.top || window)?.postMessage('success', '*')) // used as a signal to fasad to close the iframe
                  .finally(() => ({}))
              )
              .catch(() => setStep(ModalFasadMachineSteps.SEND_TO_FASAD_FAILURE))
          }
          case ModalFasadMachineSteps.SEND_TO_FASAD_SUCCESS:
            return window.location.reload() // we reload page to set the new ExternalSession state in ExternalSessionPage component
          default:
            return props.onClose()
        }
      })
      .catch((err) => captureException(err))
      .finally(() => setLoading(false))
  }

  const { Component, enableClose = true } = useComponent(step)

  return (
    <ModalFullScreen {...props}>
      <ClickAwayListener onClickAway={console.log}>
        <Box fullWidth>
          {enableClose && (
            <Box onClick={props.onClose} fullWidth align="flex-end" pointer>
              <ModalCloseIcon />
            </Box>
          )}
          <Box fullWidth spacing={bigSpacing} align="center" bottom left right>
            <Component {...props} onClickContinue={onClickContinue} loading={loading} />
          </Box>
        </Box>
      </ClickAwayListener>
    </ModalFullScreen>
  )
}
