import * as auth from 'firebase/auth'
import React, { useContext } from 'react'
import { StorageKey } from './useLocalStorageItemHelper'
import { useSessionState } from './useSessionState'
import { useStorageItem } from './useStorageItem'
import { VitecNextOrderProductPageState } from '../types/VitecNextProductTypes'
import { JaguarRequestForm, JaguarType, User } from '../../../../pure/types/types'

export type State = {
  user: User
  firebaseUser?: auth.User
  shouldShowExpandedMenu?: boolean
  settingsTabIndex?: number
  jaguarRequestForm?: JaguarRequestForm
  enableUpsell?: boolean
  defaultJaguarType?: JaguarType
  vitecNextOrderProductPageState?: VitecNextOrderProductPageState
}

export const DEFAULT_STATE: State = {
  user: { _id: '', email: undefined } as User,
  shouldShowExpandedMenu: false,
  enableUpsell: true
}

export type AppContext = {
  state: State
  setState: (state: State) => unknown
}
export const Context = React.createContext<AppContext>({
  state: DEFAULT_STATE,
  setState: () => ({})
})

const IGNORED_KEYS: Array<keyof State> = []

export const useAppStateContext = () => {
  const { storageItem: state, setStorageItem: setState } = useStorageItem(StorageKey.STATE, DEFAULT_STATE, {
    ignoredKeys: IGNORED_KEYS
  })

  const session = useSessionState(state)

  return { state: { ...state, ...session }, setState }
}

export default function useAppState() {
  return useContext(Context)
}

export const useUserId = () => useAppState().state.user._id
export const useOrgId = () => useAppState().state.user.organizationId
