import invariant from 'invariant'
import { auth } from './Firebase'
import { addQueryParamsToUrl } from '../hooks/useQueryparamHelper';

export const get = (url: string, { params }: { params?: object }): Promise<Response> =>
  getIdToken().then((token) =>
    fetch(addQueryParamsToUrl(url, params || {}), {
      method: 'GET',
      headers: getHeaders(token)
    })
      .then((res) => handleResponse(res, url))
      .then((res) => res.json())
  )
export const post = (url: string, { body, signal }: { body: object; signal?: AbortSignal }): Promise<Response> =>
  getIdToken().then((token) =>
    fetch(url, {
      method: 'POST',
      headers: getHeaders(token),
      body: JSON.stringify(body),
      signal
    }).then((res) => handleResponse(res, url))
  )
function getIdToken() {
  invariant(auth.currentUser, 'auth.currentUser')
  return auth.currentUser.getIdToken()
}
function getHeaders(token: string): HeadersInit | undefined {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }
}
function handleResponse(response, url) {
  if ([400, 422].includes(response.status))
    return response.json().then((error) => Promise.reject(new Error(JSON.stringify(error))))

  if (!response.ok)
    return Promise.reject(
      new Error(`${response.status} - ${response.statusText} (${response.request?.method || ''} ${url})`) // method can be null https://gazelle-work.sentry.io/issues/4336758536/?project=4505027568140288&referrer=slack
    )

  return response
}

