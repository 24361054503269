import { TextKeyWithOnlyText } from '../types/Antiloop.js'
import { JaguarStyle } from '../types/types.js'
import { Texts } from './AppTexts.js'
import { TextKeysWithOnlyTexts, TextsDeprecated } from './TextsDeprecated.js'

interface ValidationError {
  loc: string[]
  msg: string
  type: string
  ctx: any
}

interface ValidationResponse {
  detail: ValidationError[]
}

interface ClientErrorResponse {
  detail: string
}

export function formatClientErrors(errorResponse: ValidationResponse | ClientErrorResponse): string {
  try {
    const errors = errorResponse.detail
    // TODO WRITE TEST, should handle both ValidationResponse and ClientErrorResponse
    if (!Array.isArray(errors)) return (errorResponse as ClientErrorResponse).detail
    const summary = errors.map((error) => `${error.loc.join('.')} - ${error.msg}`).join('\n')
    return summary
  } catch (e) {
    console.error(`Error parsing JSON: ${e}`)
    return 'Invalid JSON input'
  }
}

export const JaguarStyleInfo: {
  [property in JaguarStyle]: {
    textKey: TextKeyWithOnlyText
    descriptionTextKey?: TextKeyWithOnlyText
  }
} = {
  [JaguarStyle.DETAILED]: {
    textKey: Texts.jaguarStyleDetailed,
    descriptionTextKey: TextKeysWithOnlyTexts.jaguarStyleDetailedDescription
  },
  [JaguarStyle.SALESY]: {
    textKey: Texts.jaguarStyleSelling,
    descriptionTextKey: TextKeysWithOnlyTexts.jaguarStyleSalesyDescription
  },
  [JaguarStyle.CONCISE]: {
    textKey: Texts.jaguarStyleConcise,
    descriptionTextKey: TextKeysWithOnlyTexts.jaguarStyleConsiceDescription
  },
  [JaguarStyle.EXCLUSIVE]: {
    textKey: Texts.jaguarStyleExclusive,
    descriptionTextKey: TextsDeprecated.homeFormExclusiveDescription
  }
}
