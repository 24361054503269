import { ClickAwayListener } from '@mui/base'
import invariant from 'invariant'
import { useState } from 'react'
import { regularSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { TutorialItems, TutorialItemsType, TutorialSteps } from '../libs/ModalTutorialHelper'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../../pure/libs/AppTexts'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import { ModalCloseIcon } from './Icons'
import Modal, { ModalProps } from './Modal'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'

type Props = ModalProps & { steps?: TutorialItemsType }
export function ModalTutorial(props: Props) {
  const { steps = TutorialItems } = props
  const tutorialSteps = Object.keys(steps) as TutorialSteps[]
  const firstStep = 0
  const lastStep = tutorialSteps.length - 1
  const [step, setStep] = useState(firstStep)

  const item = steps[TutorialSteps[tutorialSteps[step]]]
  invariant(item, `step ${step} not found`)

  const { headerTextKey, descriptionTextKey, imageKey } = item

  const onClickBack = () => setStep(step - 1)
  const onClickForward = () => {
    if (step === lastStep) return props.onClose()
    setStep(step + 1)
  }

  const nextButtonTextKey = step !== lastStep ? Texts.tutorialNextButton : Texts.tutorialStartButton

  const isFirstStep = step === firstStep
  return (
    <Modal {...props} transform="10%">
      <ClickAwayListener onClickAway={props.onClose}>
        <Box align="center" width={`${imageKey.width}px`}>
          <Box>
            <Box direction="row" align="center" justify="space-between" fullWidth>
              <Box>
                <FigmaText
                  textKey={Texts.tutorialHeaderPrefix}
                  text={formatText(getFigmaText(Texts.tutorialHeaderPrefix), [step + 1, tutorialSteps.length])}
                />
              </Box>
              <Box onClick={props.onClose} pointer>
                <ModalCloseIcon />
              </Box>
            </Box>
            <Box top spacing={regularSpacing}>
              <FigmaImageContainer imageKey={imageKey} />
            </Box>
            <Box top spacing={regularSpacing}>
              <FigmaTextWithLinebreaks textKey={headerTextKey} />
            </Box>
            <Box top multiline spacing={regularSpacing}>
              <FigmaTextWithLinebreaks textKey={descriptionTextKey} />
            </Box>
          </Box>

          <Box top direction="row" align="center" fullWidth spacing={regularSpacing}>
            <Box right spacing={tinySpacing} fullWidth>
              <Button
                variant="outlined"
                disabled={isFirstStep}
                fullWidth
                onClick={onClickBack}
                verticalSpacing={smallSpacing}
              >
                <FigmaTextWithStyle type="button2" textKey={Texts.tutorialBackButton} opacity={isFirstStep ? 0.5 : 1} />
              </Button>
            </Box>
            <Box left spacing={tinySpacing} fullWidth>
              <Button variant="contained" fullWidth onClick={onClickForward}>
                <FigmaText textKey={nextButtonTextKey} />
              </Button>
            </Box>
          </Box>
          {step < lastStep && (
            <Box top fullWidth spacing={regularSpacing}>
              <Button variant="outlined" onClick={props.onClose} fullWidth>
                <FigmaText textKey={Texts.tutorialSkipButton} />
              </Button>
            </Box>
          )}
        </Box>
      </ClickAwayListener>
    </Modal>
  )
}
