import React from 'react'
import Box from './Box'
import { Layout } from './LayoutExperimental'

import { VitecNextOrderPage } from '../pages/VitecNextOrderPage'
import { VitecNextOrderProductPageState } from '../types/VitecNextProductTypes'
import MenuVitecNext from './MenuVitecNext'

// test Data: http://localhost:5173/vitec-megler-landing?orderId=0d8e882e-20a2-4e68-a263-dffbac93be87
// Prod: https://app.gazelle.work/vitec-megler-landing?orderId=0d8e882e-20a2-4e68-a263-dffbac93be87

export const VitecNextOrderProduct: React.FC<VitecNextOrderProductPageState> = (p) => {
  const { vitecNextProductInfoItem } = p
  const Component = vitecNextProductInfoItem?.Component

  if (!Component) return <VitecNextOrderPage />

  return (
    <Layout enableFooter={false} MenuComponent={MenuVitecNext} enableRightBottomImage={false}>
      <Box fullWidth>{Component && <Component {...p} />}</Box>
    </Layout>
  )
}
