import styled from '@emotion/styled'
import config from '../Config'
import ZIndices from '../enums/Zindices'
import Colors from '../../../../pure/libs/Colors'
import Box, { BoxProps } from './Box'
import ErrorBoundaryView from './ErrorBoundaryView'
import FooterLoggedIn from './FooterLoggedIn'
import Menu from './Menu'

const Layout: React.FC<{
  enableFooter?: boolean
  enableMenu?: boolean
  fullHeight?: boolean
  menuZIndex?: ZIndices
  Container?: React.FC<BoxProps>
  children: React.ReactNode
  MenuComponent?: React.FC
}> = ({
  children,
  enableFooter = true,
  enableMenu = true,
  menuZIndex = ZIndices.regular,
  Container = LayoutContinaner,
  MenuComponent = Menu
}) => {
  if (config.enableServiceUnavilablePage) return <ErrorBoundaryView />

  return (
    <Container fullWidth align="center" justify="space-between">
      <Box fullWidth>
        {enableMenu && (
          <Box fullWidth position="relative" zIndex={menuZIndex}>
            <MenuComponent />
          </Box>
        )}
        <Box fullWidth position="relative">
          {children}
        </Box>
      </Box>
      <Box fullWidth>{enableFooter && <FooterLoggedIn />}</Box>
    </Container>
  )
}

export const LayoutContinaner = styled(Box)`
  min-height: 100vh;
  background-color: ${Colors.secondary20};
  overflow-x: hidden;
`

export default Layout
