import { AuthErrorCodes } from 'firebase/auth'

enum RoutePath {
  ROOT = '/',
  SANDBOX = '/sandbox',
  MY_AI = '/my-ai',
  MY_ORG = '/my-agency',
  CREATE_AD = '/create-ad',
  UPLOAD = '/summarize',
  INFO_MAP = '/info-map',
  PRICING = '/pricing',
  PRICING_WEB_IFRAME = '/pricing-web-iframe',
  PRODUCTS = '/products',
  SIGN_UP = '/signup',
  LANDING_FASAD = '/landing-fasad',
  LINKED_IN = '/linked-in',
  TERMS_AND_CPNDITIONS = '/terms-and-conditions',
  PRIVACY_POLICY = '/privacy-policy',
  RESEND_SECURE_LINK = '/resend-secure-link',
  SETTINGS = '/settings',
  EXTERNAL_SESSION = '/external-session',
  VITEC_LANDING = '/vitec-landing',
  LOGIN_WITH_CUSTOMER = '/login-with-customer',
  VITEC_NEXT_ORDER = '/vitec-next-order',
  VITEC_NEXT_ORDER_PRODUCT = '/vitec-next-order-product',
  VITEC_NEXT_FAQ = '/vitec-next-faq'
}

export default RoutePath
