import { SelectChangeEvent } from '@mui/material'
import useAppState from '../hooks/useAppState'
import { Language } from '../../../../pure/types/Antiloop'
import { MenuLoggedInLanguagePickerView } from './MenuLoggedInLanguagePicker'

export function MenuLoggedOutLanguagePicker() {
  const { state, setState } = useAppState()

  const onChange = ({ target: { value } }: SelectChangeEvent<unknown>) => {
    setState({ ...state, user: { ...state.user, language: value as Language } })
    window.location.reload()
  }

  return <MenuLoggedInLanguagePickerView onChange={onChange} />
}
