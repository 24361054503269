import styled from '@emotion/styled'
import React from 'react'
import { tinyBorderRadius } from '../enums/BorderRadixesDeprecated'
import { bigSpacing, tinySpacing } from '../enums/Spacings'
import Colors from '../../../../pure/libs/Colors'
import { getFigmaText } from '../libs/TextRepository'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import Box from './Box'
import FigmaTextLoader from './FigmaTextLoader'
import { ButtonProps } from './ButtonPrimary'

const ButtonWhite: React.FC<ButtonProps> = (props) => {
  const { children, className, textKey, loading, verticalSpacing, onClick } = props
  return (
    <StyledButton className={className} spacing={verticalSpacing} pointer onClick={onClick as any}>
      <Box align="center" fullWidth>
        {children || (
          <FigmaTextLoader
            textKey={TextsDeprecated.onboardingMobileContinueButtonText}
            text={textKey ? getFigmaText(textKey) : undefined}
            loading={loading}
          />
        )}
      </Box>
    </StyledButton>
  )
}

const StyledButton = styled(Box)<{ spacing?: string }>`
  padding-left: ${({ spacing }) => spacing || bigSpacing};
  padding-right: ${({ spacing }) => spacing || bigSpacing};
  padding-top: ${tinySpacing};
  padding-bottom: ${tinySpacing};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  background-color: ${Colors.white};
  border: 1px solid ${Colors.black};
  border-radius: ${tinyBorderRadius};
`

export default ButtonWhite
