import invariant from 'invariant'
import { State } from '../hooks/useAppState'
import AnalyticsEvent from '../../../../pure/enums/AnalyticsEvent'
import { CreatePromptRequest } from '../../../../pure/types/JaguarTypes'
import { JaguarRequestForm, JaguarSession } from '../../../../pure/types/types'
import { logEvent } from './AnalyticsHelper'
import { createHeadline, createPrompt } from './ApiHandler'
import {
  mapToJaguarRequest,
  getExtra,
  HIGH_LOAD_ERROR_MESSAGE_TIMEOUT,
  onHighLoadTimeout
} from './JaguarSessionControllerHelper'
import { toBaseObject } from '../../../../pure/libs/FirebaseStorageClientBaseHelper'
import { ServerFirebaseUser } from '../../../../pure/types/ServerTypes'
import { parseQueryParam } from '../hooks/useQueryparamHelper'

export default class JaguarSessionController {
  private static instance: JaguarSessionController
  session: JaguarSession | undefined
  reader: ReadableStreamReader<Uint8Array> | undefined
  decoder: TextDecoder | undefined
  aborter: AbortController | undefined
  request: JaguarRequestForm | undefined
  onNewSession: (session: JaguarSession) => unknown | undefined = () => ({})

  startSession = ({
    request,
    state,
    onNewSession,
    adId
  }: {
    request: JaguarRequestForm
    state: State
    onNewSession: (session: JaguarSession) => unknown
    adId: string
  }) => {
    this.onNewSession = onNewSession
    this.request = request
    const { user } = state
    invariant(this.request, '!this.request')
    invariant(user, '!user')
    const { organizationId } = parseQueryParam<{ organizationId: string }>(window.location.search)
    if (organizationId) user.organizationId = organizationId
    const req: CreatePromptRequest = mapToJaguarRequest(this.request, user)
    invariant(req, '!request')

    const session: JaguarSession = {
      ...toBaseObject({uid: state.firebaseUser?.uid} as ServerFirebaseUser),
      userId: user._id,
      request: req,
      response: '',
      status: 'active',
      extra: getExtra(window.location.search)
    }

    invariant(session, '!session')
    this.session = session
    this._setNewSession(session)

    return Promise.all([
      this.createPrompt(req, session),
      createHeadline({ ...req, adLength: req.headerAdLength || req.adLength }).then(({ response: headline }) =>
        this._setNewSession({ headline })
      )
    ]).catch((error: Error) => {
      // TODO WRITE TEST, should not be called if user aborts
      this._setNewSession({ status: 'failed' })
      return Promise.reject(error)
    })
  }

  createPrompt = (req: CreatePromptRequest, session: JaguarSession) => {
    this.aborter = new AbortController()
    const { signal } = this.aborter
    const _req = { ...req, requestId: session._id }

    setTimeout(() => {
      if (this.isLoading() && !this.hasResponse()) onHighLoadTimeout()
    }, HIGH_LOAD_ERROR_MESSAGE_TIMEOUT)

    return createPrompt({ req: _req, signal })
      .then((response) => ({
        response,
        session
      }))
      .then(async ({ response, session }) => {
        invariant(session, '!session')

        // This data is a ReadableStream
        const data = response.body
        if (!data) {
          return
        }

        invariant(this.session, '!this.session')
        this.reader = data.getReader()
        this.decoder = new TextDecoder()
        let done = false

        this._setNewSession({ status: 'active' })

        while (!done && this.session?.status === 'active') {
          const { value, done: doneReading } = await this.reader.read()
          done = doneReading
          const chunkValue = this.decoder.decode(value)
          invariant(this.session, '!this.session')
          this._setNewSession({ response: this.session?.response + chunkValue })
        }

        if (!done) return

        this._setNewSession({ status: 'completed' })
      })
      .then(() => logEvent(AnalyticsEvent.FINISHED_CREATING_AD, this.request))
  }

  stopSession = () => {
    this.aborter?.abort()
    if (!this.session) return
    this._setNewSession({ status: 'stopped' })
  }

  isLoading = () => this.session?.status === 'active'

  isNotStartedOrCompleted = () => !this.session || this.session?.status === 'completed'

  hasResponse = () => (this.session?.response?.length || 0) > 0

  _setNewSession = (newSession: Partial<JaguarSession>) => {
    if (!this.session) return
    Object.keys(newSession).forEach((key) => this.session && (this.session[key] = newSession[key]))
    this.onNewSession(this.session)
  }
}
