import MAterialCheckbox, { CheckboxProps } from '@mui/material/Checkbox'

import styled from '@emotion/styled'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Colors from '../../../../pure/libs/Colors'

type Props<T> = CheckboxProps & { name: string }
export default function CheckboxFormik<T>(props: Props<T>) {
  const { name, ..._props } = props
  const value = true
  return <StyledCheckbox checkedIcon={<StyledCheckboxIcon />} {..._props} name={name} checked={value} />
}

export function Checkbox(props: CheckboxProps) {
  return <StyledCheckbox checkedIcon={<StyledCheckboxIcon />} {...props} />
}

const StyledCheckbox = styled(MAterialCheckbox)`
  padding: 0px;
`

export const StyledCheckboxIcon = styled(CheckBoxIcon)`
  color: ${Colors.black};
`
