import styled from '@emotion/styled'
import { BorderRadixes } from '../enums/BorderRadixes'
import { Shadows } from '../enums/Shadows'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import FigmaText from './FigmaText'
import Colors from '../../../../pure/libs/Colors'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../../pure/libs/AppTexts'
import { TextKeyWithOnlyText } from '../../../../pure/types/Antiloop'
import Box, { BoxProps } from './Box'
import { Radio } from './Radio'
import { AD_CREATOR_FORM_RADIO_BUTTON_CONTAINER_MIN_WIDTH } from '../libs/HardCodedSizes'

export function AdCreatorFormRadioButton({
  textKey,
  checked,
  onChange,
  Container = CheckboxContainer
}: {
  textKey: TextKeyWithOnlyText
  checked: boolean
  onChange?: (value: boolean) => unknown
  Container?: React.FC<BoxProps>
}) {
  return (
    <Container direction="row" align="center">
      <Radio checked={checked} onChange={(e) => onChange?.(!!e.currentTarget.checked)} />
      <Box>
        <FigmaText textKey={Texts.adSelectionTitle} text={getFigmaText(textKey)} />
      </Box>
    </Container>
  )
}

const CheckboxContainer = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.moderate};
  padding: ${tinySpacing} ${smallSpacing};
  box-shadow: ${Shadows.small};
  min-width: ${AD_CREATOR_FORM_RADIO_BUTTON_CONTAINER_MIN_WIDTH}px;
`
