import styled from '@emotion/styled'
import Slider from '@mui/material/Slider/Slider'
import { Controller, UseFormReturn } from 'react-hook-form'
import { BorderRadixes } from '../enums/BorderRadixes'
import { logEvent } from '../libs/AnalyticsHelper'
import { MAX_WORD_COUNT, MIN_WORD_COUNT } from '../libs/CreateAdHelper'
import FigmaText from './FigmaText'
import Colors from '../../../../pure/libs/Colors'
import { AdLengthInfo } from '../../../../pure/libs/AdLengthHelper'
import { Texts } from '../../../../pure/libs/AppTexts'
import { JaguarRequestForm } from '../../../../pure/types/types'
import { AdCreatorFormSectionButtonViewContainer } from './AdCreatorFormSectionButtonView'
import Box from './Box'
import AnalyticsEvent from '../../../../pure/enums/AnalyticsEvent'

const STEP_COUNT = 25
export function AdCreatorFormSectionMoreOptionsAdLengthSlider({
  formProps,
  setValue,
  option
}: {
  formProps: UseFormReturn<JaguarRequestForm>
  setValue
  option
}) {
  return (
    <Container fullWidth isSelected={option.isSelected}>
      <FigmaText textKey={Texts.adLengthCustom} />
      <Box fullWidth>
        <Controller
          control={formProps.control}
          name="adLength"
          render={({ field }) => (
            <Slider
              {...field}
              defaultValue={AdLengthInfo.medium.value}
              step={STEP_COUNT}
              min={MIN_WORD_COUNT}
              max={MAX_WORD_COUNT}
              valueLabelDisplay="auto"
              size="small"
              onChange={(event, value) => {
                field.onChange(event)
                setValue(value)
              }}
              onChangeCommitted={(event, value) => {
                logEvent(AnalyticsEvent.CHANGE_NUMBER_OF_WORDS, { numberOfCharacters: value as number })
              }}
              sx={{
                color: Colors.black
              }}
            />
          )}
        />
      </Box>
    </Container>
  )
}

const Container = styled(AdCreatorFormSectionButtonViewContainer)`
  border-radius: ${BorderRadixes.moderate};
`
