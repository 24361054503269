import { VitecNOOrderQueryParams } from '../../../../pure/types/VitecNODerivedTypes.js'
import { VitecNextOrder } from '../components/VitecNextOrder'
import useQueryParams from '../libs/useQueryParams'

// EstateType: https://gazelle-work.slack.com/archives/C06DUK010G2/p1706649574837279
// Test data: http://localhost:5173/vitec-megler-doc?orderId=66FDAA52-9E8F-4564-9434-19454DF6990D

export const VitecNextOrderPage = () => {
  const { orderId } = useQueryParams<VitecNOOrderQueryParams>()

  return <VitecNextOrder vitecNextOrderId={orderId} />
}
