import dayjs from 'dayjs'
import invariant from 'invariant'
import React from 'react'
import { bigSpacing, tinySpacing } from '../enums/Spacings'
import useIsMobile from '../hooks/useIsMobile'
import FigmaText from './FigmaText'
import { YYYYMMDD_HHmm } from '../../../../pure/libs/Consts'
import { Texts } from '../../../../pure/libs/AppTexts'
import type { AdCreatorAdProps } from './AdCreatorAd'
import Box from './Box'
import Button from './Button'
import FigmaTextWithStyleOverrides from './FigmaTextWithStyleOverrides'
import { AdCreatorKeyboardArrowRightIcon } from './Icons'
import { ModalExternalSessionMachine } from './ModalExternalSessionMachine'

export function ExternalSessionAdCTAComponent(props: AdCreatorAdProps) {
  const [showModal, setShowModal] = React.useState(false)
  const isMobile = useIsMobile()
  const externalSession = props.adCreatorPageProps?.extra?.externalSession
  invariant(externalSession, 'externalSession must be defined')
  const { status, billedAt } = externalSession

  if (status === 'completed')
    return (
      <FigmaText
        textKey={Texts.fasadSessionAdCtaFineprint}
        text={`You were billed through FasaAd ${dayjs(billedAt).format(YYYYMMDD_HHmm)}`}
      />
    )
  return (
    <Box fullWidth>
      {showModal && <ModalExternalSessionMachine {...props} onClose={() => setShowModal(false)} />}
      <Box direction="row" align="center" style={{ flexWrap: 'wrap' }} justify="center" fullWidth>
        <Box top>
          <Button spacing={bigSpacing} onClick={() => setShowModal(true)}>
            <Box direction="row" align="center">
              <Box right spacing={tinySpacing}>
                <FigmaText textKey={Texts.adCreatorAdFasadCta} />
              </Box>
              <AdCreatorKeyboardArrowRightIcon />
            </Box>
          </Button>
        </Box>
      </Box>
      <Box top fullWidth alignText="center">
        <FigmaTextWithStyleOverrides
          textKeys={{ en: Texts.fasadSessionAdCtaFineprint, sv: Texts.fasadSessionAdCtaFineprintSv }}
        />
      </Box>
    </Box>
  )
}
