import styled from '@emotion/styled'
import { littleSpacing, tinySpacing } from '../enums/Spacings'
import Colors from '../../../../pure/libs/Colors'
import { KeywordButtonProps, getIsSelectedKeyword, onClickKeyword } from '../libs/KeywordMapHelper'
import { getFigmaText } from '../libs/TextRepository'
import { getTextKeyIdForKeywordMapItem } from '../../../../pure/libs/getTextKeyIdForKeywordMapItem'
import { TextKey } from '../../../../pure/types/Antiloop'
import Box from './Box'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'

export function KeywordsDrawerRowSectionButtonItemsItem(props: KeywordButtonProps) {
  const { keywordId } = props
  const isSelected = getIsSelectedKeyword(props)
  const text = getTextForKeywordId(keywordId)

  return (
    <Container isSelected={isSelected} pointer direction="row" align="center" onClick={() => onClickKeyword(props)}>
      <Box spacing={tinySpacing}>
        <FigmaTextWithStyle type="body3" text={isSelected ? '-' : '+'} textTransform="uppercase" />
      </Box>
      <Box spacing={tinySpacing} left>
        <FigmaTextWithStyle type="body3" text={text} textTransform="uppercase" />
      </Box>
    </Container>
  )
}

const Container = styled(Box)<{ isSelected?: boolean }>`
  border-radius: 61px;
  background-color: ${({ isSelected }) => (isSelected ? Colors.primary20 : Colors.neutral10)};
  padding: ${tinySpacing} ${littleSpacing};
`

export function getTextForKeywordId(keywordId: string) {
  return getFigmaText({ name: getTextKeyIdForKeywordMapItem(keywordId) } as TextKey)
}
