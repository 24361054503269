import styled from '@emotion/styled'
import Box from './Box'
import { KeywordsDrawerRowSectionButtonItemsItem } from './KeywordsDrawerRowSectionButtonItemsItem'
import { Shadows } from '../enums/Shadows'
import Colors from '../../../../pure/libs/Colors'
import { smallSpacing } from '../enums/Spacings'
import { KeywordButtonProps, getEntries } from '../libs/KeywordMapHelper'

export function KeywordsDrawerRowSectionButtonItems(props: KeywordButtonProps) {
  const { formProps, keywordMapItem } = props
  const entries = getEntries(keywordMapItem)
  return (
    <Container
      left
      right
      bottom
      spacing={smallSpacing}
      fullWidth
      style={{ flexWrap: 'wrap' }}
      direction="row"
      align="center"
    >
      {entries.map(([keywordId], index) => (
        <Box key={index} top spacing={smallSpacing}>
          <KeywordsDrawerRowSectionButtonItemsItem keywordId={keywordId} formProps={formProps} keywordMapItem={{}} />
        </Box>
      ))}
    </Container>
  )
}

const Container = styled(Box)`
  border-radius: 0px 15px 15px 15px;
  background: ${Colors.white};
  box-shadow: ${Shadows.small};
`
