import React from 'react'
import { tinySpacing } from '../enums/Spacings'
import FigmaText from './FigmaText'
import Box from './Box'
import TextFieldForm, { TextFieldFormProps } from './TextFieldForm'

const TextFieldWithLabelFormComponent = (props: TextFieldFormProps, ref) => {
  const { labelTextKey, fullWidth, labelText, ...textFieldProps } = props
  return (
    <Box fullWidth={fullWidth}>
      {labelTextKey && <FigmaText textKey={labelTextKey} text={labelText} />}
      <Box top spacing={tinySpacing} fullWidth>
        <TextFieldForm {...textFieldProps} ref={ref} fullWidth />
      </Box>
    </Box>
  )
}

export const TextFieldWithLabelForm = React.forwardRef(TextFieldWithLabelFormComponent)
