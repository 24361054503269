import Modal, { ModalProps } from './Modal'
import ModalInviteUserForm from './ModalInviteUserForm'

type Props = ModalProps
export function ModalInviteUser(props: Props) {
  return (
    <Modal {...props}>
      <ModalInviteUserForm onClose={props.onClose} />
    </Modal>
  )
}
