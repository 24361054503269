import { MenuProps } from '@mui/material'
import NativeSelect, { NativeSelectProps } from '@mui/material/NativeSelect'
import { SelectProps } from '@mui/material/Select'
import React, { ReactElement, useRef } from 'react'
import { defaultSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useIsMobile from '../hooks/useIsMobile'
import FigmaText from './FigmaText'
import { TextKey } from '../../../../pure/types/Antiloop'
import Box from './Box'
import { InfoIcon } from './Icons'
import Loader from './Loader'
import { MenuItem } from './MenuItem'
import { MaterialSelectStyled } from './SelectFieldWithLabelMulti'
import Tooltip from './Tooltip'

export type SelectFieldWithLabelProps = SelectProps & {
  labelTextKey?: TextKey
  fullWidth?: boolean
  options: { key: string; value: any; element?: ReactElement }[]
  menuProps?: Partial<MenuProps>
  isLoading?: boolean
  tooltipElement?: React.ReactElement
}

function SelectFieldWithLabel(props: SelectFieldWithLabelProps, ref) {
  const { labelTextKey, menuProps, variant = 'outlined', isLoading, tooltipElement, fullWidth, ...selectProps } = props
  const menuAnchorElement = useRef(null)
  const isMobile = useIsMobile()
  const MaterialComponent = isMobile ? SelectFieldNative : MaterialSelectStyled

  return (
    <Box fullWidth={fullWidth}>
      {labelTextKey && (
        <Box
          bottom
          spacing={tinySpacing}
          direction="row"
          align="center"
          style={{ lineHeight: labelTextKey.style.fontSize }}
        >
          <Box right spacing={smallSpacing}>
            <FigmaText textKey={labelTextKey} />
          </Box>
          {tooltipElement && (
            <Tooltip title="titleElement is defined below" titleElement={tooltipElement}>
              <Box pointer>
                <InfoIcon />
              </Box>
            </Tooltip>
          )}
        </Box>
      )}
      <MaterialComponent
        renderValue={() => selectProps.value as string}
        {...selectProps}
        variant={variant}
        size="small"
        ref={ref}
        MenuProps={{
          ...menuProps,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
        // endAdornment={<InputAdornment position="end">{isLoading ? <IsSearchingLoader /> : undefined}</InputAdornment>} Removed support see trello: https://trello.com/c/z9aE95Lp/57-drop-downs-clicking-on-the-arrow-doesnt-expand-the-drop-down-only-the-text-does
      >
        {props.options.map(({ key, value, element }) => (
          <MenuItem key={key} value={value} text={key} element={element} />
        ))}
      </MaterialComponent>
      <div ref={menuAnchorElement} />
    </Box>
  )
}

export function IsSearchingLoader() {
  return (
    <Box spacing={tinySpacing} right>
      <Loader size={defaultSpacing} />
    </Box>
  )
}

export const SelectFieldNative = (props: SelectFieldWithLabelProps) => {
  const { labelTextKey, menuProps, variant = 'outlined', isLoading, tooltipElement, fullWidth, ...selectProps } = props
  return (
    <NativeSelect {...(selectProps as NativeSelectProps)}>
      {props.options.map(({ key, value }) => (
        <option key={key} value={value}>
          {key}
        </option>
      ))}
    </NativeSelect>
  )
}

export default React.forwardRef(SelectFieldWithLabel)
