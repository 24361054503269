import { Loader, LoaderOptions, google } from 'google-maps'
import { GOOGLE_CLOUD_MAPS_API_KEY } from '../../../../pure/libs/Consts'
import { captureException } from './SentryHelper'
import { ExternalSession } from '../../../../pure/types/types'

export type AutocompletePrediction = google.maps.places.AutocompletePrediction

const options: LoaderOptions = {
  libraries: ['places']
}

const loader = new Loader(GOOGLE_CLOUD_MAPS_API_KEY, options)

let _google: google

const initializeGoogleMaps = (): Promise<google> => {
  if (_google) return Promise.resolve(_google)
  return loader
    .load()
    .then((g) => {
      _google = g
      return g
    })
    .catch((err) => {
      captureException(err)
      return Promise.reject(err)
    })
}

export const getPlacePredictionsForExternalSession = ({
  objectMetaData: o
}: ExternalSession): Promise<AutocompletePrediction | undefined> => {
  if (!o.address) return Promise.resolve(undefined)
  return getPlacePredictions(o.address, o.zipArea).then((predictions = []) => {
    // TODO WRITE TEST should return first match on street address
    // const _predictions = predictions.filter((p) => p.types.includes('street_address'))
    // if (_predictions?.length !== 1) return undefined
    // return _predictions[0]
    return predictions?.[0] || undefined
  })
}

export const getPlacePredictions = (streetAddress: string, area: string): Promise<AutocompletePrediction[]> =>
  initializeGoogleMaps().then(
    (g) =>
      new Promise((resolve) =>
        new g.maps.places.AutocompleteService().getPlacePredictions({ input: `${streetAddress}, ${area}` }, resolve)
      )
  )
