import { UseFormReturn, useForm } from 'react-hook-form'
import useAppState from '../hooks/useAppState'
import { useDefaultSectionFormValues } from '../libs/AdCreatorFormHelper'
import { SectionInfo, SectionInfoArray } from '../libs/SectionInfo'
import { JaguarRequestForm } from '../../../../pure/types/types'
import Box from './Box'
import { KeywordsDrawerRows } from './KeywordsDrawerRows'
import SignInLanding from './SignInLandingExperimental'
import { SignInState } from '../../../../pure/types/SignInTypes'
import { DEFAULT_SIGN_IN_STATE } from '../libs/SignInHelper'
import SignInLandingCard from './SignInLandingCard'
import { getArray } from '../../../../pure/libs/Common'
import { ContainerWithGradient } from './AdCreatorPageMobile'
import styled from '@emotion/styled'

const NUMBER_OF_COLUMNS = 5
const SectionInfoArrayDouble = getArray(NUMBER_OF_COLUMNS).reduce(
  (arr, index) => arr.concat(SectionInfoArray),
  [] as any
)

const DoubleSectionInfoArray = SectionInfoArray.concat(SectionInfoArray)

export function SignInLandingCards() {
  return (
    <Container fullWidth fullHeight direction="row">
      {getArray(NUMBER_OF_COLUMNS).map((_, index) => (
        <InnerContainer key={index} right left>
          {DoubleSectionInfoArray.map((sectionInfo, key) => (
            <Box key={key} fullPadding>
              <SignInLandingCard sectionInfo={SectionInfoArrayDouble[index + key]} />
            </Box>
          ))}
        </InnerContainer>
      ))}
    </Container>
  )
}

const Container = styled(Box)`
  transform: translate(-400px, -800px);
`
const InnerContainer = styled(Box)`
  transform: rotate(30deg);
`
