import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import config from '../Config'
import { StripeProduct } from '../libs/ProductInfo'
import { captureException } from '../libs/SentryHelper'
import { payments } from '../libs/StripeHelper'
import { Subscription, onCurrentUserSubscriptionUpdate } from '../stripe'
import { useStripeSubscriptions } from './useStripeSubscriptions'
import { SubscriptionStatus } from '../stripe/subscription'
import { getStripeProductIdForSubscription } from './getCurrentProduct'

const ACTIVE_SUBSCRIPTION_STATUSES: Subscription['status'][] = ['active', 'trialing']

export function useStripeSubscription({ stripeStatuses }: { stripeStatuses?: Subscription['status'][] } = {}) {
  const StripeSubscriptionIds = Object.values(StripeProduct)
  const [subscription, setSubscription] = useState<Subscription>()
  const experimentalSubscriptions = useStripeSubscriptions()
  useEffect(() => {
    try {
      onCurrentUserSubscriptionUpdate(payments, (snapshot) => {
        const subscription = getSubscription({
          subscriptions: snapshot.subscriptions,
          stripeStatuses,
          StripeSubscriptionIds
        })
        setSubscription(subscription)
      })
    } catch (e) {
      const error = e as Error
      if (error.message === 'Failed to determine currently signed in user. User not signed in.') return // see https://trello.com/c/pV5AoV0n/103-when-logging-out-user-get-white-screen
      captureException(error)
    }
  }, [])

  if (config.enablePremium) return { ...subscription, status: 'active' } as Subscription

  return (
    subscription ||
    getSubscription({
      subscriptions: experimentalSubscriptions as unknown as Subscription[],
      stripeStatuses,
      StripeSubscriptionIds
    })
  )
}

export const useActiveStripeSubscription = () => useStripeSubscription({ stripeStatuses: ACTIVE_SUBSCRIPTION_STATUSES })

function getSubscription({
  subscriptions,
  stripeStatuses,
  StripeSubscriptionIds
}: {
  subscriptions: Subscription[]
  stripeStatuses?: SubscriptionStatus[]
  StripeSubscriptionIds: StripeProduct[]
}): Subscription | undefined {
  return subscriptions
    .filter((s) => !!s)
    .filter((s) => !stripeStatuses || stripeStatuses.includes(s.status))
    .sort((a, b) => dayjs(b.created).valueOf() - dayjs(a.created).valueOf()) // sort by created date, newest first, customer can have multiple subscriptions,  https://gazelle-work.slack.com/archives/C052E16DWMQ/p1696960070102249?thread_ts=1696957002.079239&cid=C052E16DWMQ WRITE TESTS
    .find((s) => {
      const product = getStripeProductIdForSubscription(s)
      return StripeSubscriptionIds.includes(product as StripeProduct)
    })
}
