import styled from '@emotion/styled'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { BUTTON_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import RoutePath from '../../../../pure/libs/RoutePath'
import Box from './Box'
import { MenuLoggedOutLanguagePicker } from './MenuLoggedOutLanguagePicker'
import { MenuLogo } from './MenuLogo'

export default function MenuLoggedOut({ routePath }: { routePath?: RoutePath }) {
  return (
    <Box
      fullWidth
      direction="row"
      align="center"
      justify="space-between"
      fullPadding
      backgroundColor="white"
      spacing={smallSpacing}
    >
      <Container direction="row" align="center">
        <MenuLogo />
        <Box left spacing={smallSpacing} pointer>
          <Box top spacing={tinySpacing}>
            <MenuLoggedOutLanguagePicker />
          </Box>
        </Box>
      </Container>
      <Container></Container>
    </Box>
  )
}

const Container = styled(Box)`
  width: ${BUTTON_CONTAINER_WIDTH}px;
`
