import { useState } from 'react'

import OtpInput from 'react-otp-input'
import { noBorderRadius } from '../enums/BorderRadixesDeprecated'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { onChangeText } from '../libs/SignInCodeHelper'
import FigmaText from './FigmaText'
import Colors from '../../../../pure/libs/Colors'
import { toPx } from '../../../../pure/libs/Common'
import { NO_OF_LETTERS_IN_SIGN_IN_CODE } from '../../../../pure/libs/Consts'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { TextsDeprecated, TextKeysWithOnlyTexts } from '../../../../pure/libs/TextsDeprecated'
import { TextKey } from '../../../../pure/types/Antiloop'
import { SignInViewProps } from '../../../../pure/types/SignInTypes'
import Box from './Box'
import Loader from './Loader'
import OnboardingMobileWrapper from './OnboardingMobileWrapper'
import { Helmet } from './Helmet'

const LETTER_WIDTH = toPx(TextsDeprecated.mobileOnboardingEmailCodeInputText.style.fontSize)
const LETTER_HEIGHT = LETTER_WIDTH * 1.2

export default function SignInCodeMobile(props: SignInViewProps) {
  const { signInState, onTakeMeBack, isLoading } = props
  const { data } = signInState
  const { email, loginWithCodeTokenError, code: _code = '' } = data
  const [code, setCode] = useState(_code)

  const headerTextKey = TextsDeprecated.headerCheckEmailForCode
  const description1TextKey = TextsDeprecated.signInCodeLoginText1
  const description2TextKey = TextsDeprecated.signInCodeLoginText2

  return (
    <OnboardingMobileWrapper onClickBack={onTakeMeBack}>
      <Box fullWidth align="center" left right spacing={smallSpacing}>
        <Helmet title="Enter Code" />

        <Box top spacing={bigSpacing} alignText="center">
          <FigmaText styleTextKey={TextsDeprecated.mobileHeaderCheckEmailForCode} textKey={headerTextKey} />
          <Box top spacing={smallSpacing}>
            <FigmaText
              textKey={TextsDeprecated.mobileOnboardingEmailCodeSubHeader}
              text={formatText(getFigmaText(description1TextKey), [email])}
            />
          </Box>
        </Box>
        <Box top direction="row" align="center">
          {isLoading && <Loader />}
          {!isLoading && (
            <OtpInput
              value={code}
              onChange={(code) => onChangeText(code, setCode, props)}
              numInputs={NO_OF_LETTERS_IN_SIGN_IN_CODE}
              shouldAutoFocus
              isInputNum
              inputStyle={{
                width: LETTER_WIDTH,
                height: LETTER_HEIGHT,
                border: `1px solid ${Colors.secondary60}`,
                borderRadius: noBorderRadius,
                marginLeft: tinySpacing,
                marginRight: tinySpacing,
                ...TextsDeprecated.onboardingEmailCodeInputText.style,
                padding: 0
              }}
            />
          )}
        </Box>
        {loginWithCodeTokenError && (
          <Box top>
            <FigmaText textKey={TextsDeprecated.mobileOnboardingEmailCodeErrorTextNotValid} />
          </Box>
        )}
        <Box top fullWidth alignText="center">
          <FigmaText styleTextKey={TextsDeprecated.mobileOnboardingEmailCodeInfoText} textKey={description2TextKey} />
        </Box>
      </Box>
    </OnboardingMobileWrapper>
  )
}
