import styled from '@emotion/styled'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import React from 'react'
import Colors from '../../../../pure/libs/Colors'
import { Texts } from '../../../../pure/libs/AppTexts'
import { UploadedFile } from '../../../../pure/types/JaguarTypes'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import useIsMobile from '../hooks/useIsMobile'
import { updateUploadedFile } from '../libs/DBApiHandler'
import { UploadViewProps } from '../libs/UploadHelper'
import Box from './Box'
import FigmaText from './FigmaText'
import { CheckBoxOutlineBlank } from './Icons'
import { VitecLandingImageCaptionsResult } from './VitecLandingImageCaptionsResult'

export const VitecLandingImageCaptionsResults = ({ uploadedFiles = [], upload }: UploadViewProps) => {
  const isMobile = useIsMobile()
  const [checked, setChecked] = React.useState(true)

  const onClickSelectAll = () => {
    if (!upload) return
    const newValue = !checked
    setChecked(newValue)
    uploadedFiles.forEach((file) => {
      updateUploadedFile(
        {
          ...file,
          metaData: { ...file?.metaData, checked: newValue }
        } as UploadedFile,
        upload
      )
    })
  }

  return (
    <Box fullWidth>
      <Box fullWidth bottom>
        <Box fullWidth align="flex-end" right spacing={bigSpacing}>
          <ButtonContainer fullPadding spacing={smallSpacing} pointer onClick={() => onClickSelectAll()}>
            <Box direction="row" align="center">
              <FigmaText textKey={Texts.cationsSelectAllButton} />
              <Box left>{checked ? <StyledCheckboxIcon /> : <CheckBoxOutlineBlank />}</Box>
            </Box>
          </ButtonContainer>
        </Box>
      </Box>
      <Box
        fullWidth
        left
        spacing={isMobile ? smallSpacing : bigSpacing}
        direction="row"
        style={{ flexWrap: 'wrap' }}
        justify="center"
      >
        {uploadedFiles?.map((file, key) => {
          return (
            <Box key={key}>
              <VitecLandingImageCaptionsResult file={file} upload={upload} index={key} />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export const Container = styled(Box)`
  border-radius: ${BorderRadixes.regular};
  background-color: ${Colors.neutral30};
  width: 220px;
`

export const ButtonContainer = styled(Box)`
  border: 1px solid ${Colors.white};
  border-radius: ${BorderRadixes.regular};
`

export const StyledCheckboxIcon = styled(CheckBoxIcon)`
  color: ${Colors.white};
`
