import { Subscription } from '../stripe'
import Colors from '../../../../pure/libs/Colors'
import { Texts } from '../../../../pure/libs/AppTexts'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated.js'
import { TextKey } from '../../../../pure/types/Antiloop'
import { JaguarSession, PieChartData, User } from '../../../../pure/types/types'
import { PricingItemInfo, PricingProductInfoItem } from './PricingPageHelper'
import { getSessionsLeft } from './getSessionsLeft'

export const SOMETHING_VERY_HIGH = 3000000000

export type ProductType = {
  stripeProductId: StripeProduct
  name: string
  noOfFreeAds?: number
  noOfFreeAdsText: string
  noOfFreeUploadedDocs?: number
  useDashboardPieChartData: (sessions: JaguarSession[], user: User) => PieChartData
  upsellProduct?: boolean
  showWelcomeModal?: boolean
  upsellPricingItemInfo: PricingProductInfoItem
  enableUpsellOnAdCreator: boolean
  enableRemoveAds: boolean
  pricingPageToggleTextKey: TextKey
}

export enum Products {
  FREE = 'FREE',
  MONTHLY_MEMBERSHIP = 'MONTHLY_MEMBERSHIP',
  ANNUAL_MEMBERSHIP = 'ANNUAL_MEMBERSHIP',
  SUMMER_CAMPAIGN = 'SUMMER_CAMPAIGN',
  FASAD_SUMMER_CAMPAIGN = 'FASAD_SUMMER_CAMPAIGN',
  MONTHLY_BETA_MEMBERSHIP = 'MONTHLY_BETA_MEMBERSHIP',
  SOTHEBYS = 'SOTHEBYS',
  A_PRODUCT = 'A_PRODUCT',
  BJURFORS_POST_MVP_CAMPAIGN = 'BJURFORS_POST_MVP_CAMPAIGN'
}

export enum StripeProduct {
  MONTHLY_MEMBERSHIP = 'prod_NzRiHXSOgQDZPh',
  ANNUAL_MEMBERSHIP = 'prod_ONNshrH7ywpRNY',
  SUMMER_CAMPAIGN = 'prod_NzRh6dXJzdSp0X', // 499 SEK https://dashboard.stripe.com/products/prod_NzRh6dXJzdSp0X
  FASAD_SUMMER_CAMPAIGN = 'prod_NzRgqW9p60pfjt',
  MONTHLY_BETA_MEMBERSHIP = 'prod_NmHneoGYFwHMit',
  SOTHEBYS = 'prod_OLUikEVBjtnyws',
  A_PRODUCT = 'prod_OdVnaQ6mpk3Iij', // https://trello.com/c/sjFTl8hE/775-add-stripe-product-prododvnaq6mpk3iij-as-membership
  BJURFORS_POST_MVP_CAMPAIGN = 'prod_P9nlehMTPLCGja'
}

const NUMBER_OF_FREE_ADS = 2

export const DefaultProduct: ProductType = {
  stripeProductId: '' as StripeProduct,
  name: '',
  noOfFreeAds: NUMBER_OF_FREE_ADS,
  noOfFreeAdsText: `${NUMBER_OF_FREE_ADS}`,
  noOfFreeUploadedDocs: 2,
  useDashboardPieChartData: (sessions: JaguarSession[]) => [],
  upsellPricingItemInfo: PricingItemInfo.premium,
  enableUpsellOnAdCreator: false,
  enableRemoveAds: true,
  pricingPageToggleTextKey: Texts.pricingProductToggleMonthly
}

const defaultPremiumProduct: ProductType = {
  ...DefaultProduct,
  name: '',
  stripeProductId: StripeProduct.MONTHLY_MEMBERSHIP,
  noOfFreeAds: undefined,
  noOfFreeAdsText: 'Unlimited',
  noOfFreeUploadedDocs: undefined,
  useDashboardPieChartData: () => [],
  showWelcomeModal: false,
  upsellPricingItemInfo: PricingItemInfo.enterprice,
  enableUpsellOnAdCreator: false
}

export const ProductInfo: { [property in Products]: ProductType } = {
  FREE: {
    ...DefaultProduct,
    name: 'FREE',
    noOfFreeAds: NUMBER_OF_FREE_ADS,
    noOfFreeAdsText: NUMBER_OF_FREE_ADS.toString(),
    useDashboardPieChartData: (sessions: JaguarSession[], user: User): PieChartData => [
      {
        textKey: Texts.dashboardHeaderPieAdsLeft,
        value: getSessionsLeft({ noOfFreeAds: NUMBER_OF_FREE_ADS } as ProductType, user),
        backgroundColor: Colors.success20
      },
      {
        textKey: Texts.dashboardHeaderPieAdsUsed,
        value: user.usedSessions,
        backgroundColor: Colors.warning20
      }
    ],
    showWelcomeModal: true,
    enableUpsellOnAdCreator: true,
    enableRemoveAds: false
  },
  MONTHLY_MEMBERSHIP: {
    ...defaultPremiumProduct,
    name: 'MONTHLY_MEMBERSHIP',
    stripeProductId: StripeProduct.MONTHLY_MEMBERSHIP
  },
  ANNUAL_MEMBERSHIP: {
    ...defaultPremiumProduct,
    name: 'ANNUAL_MEMBERSHIP',
    stripeProductId: StripeProduct.ANNUAL_MEMBERSHIP,
    pricingPageToggleTextKey: Texts.pricingProductToggleAnnual
  },
  SUMMER_CAMPAIGN: {
    ...defaultPremiumProduct,
    name: 'SUMMER_CAMPAIGN',
    stripeProductId: StripeProduct.SUMMER_CAMPAIGN
  },
  FASAD_SUMMER_CAMPAIGN: {
    ...defaultPremiumProduct,
    name: 'FASAD_SUMMER_CAMPAIGN',
    stripeProductId: StripeProduct.FASAD_SUMMER_CAMPAIGN
  },
  MONTHLY_BETA_MEMBERSHIP: {
    ...defaultPremiumProduct,
    name: 'MONTHLY_BETA_MEMBERSHIP',
    stripeProductId: StripeProduct.MONTHLY_BETA_MEMBERSHIP
  },
  SOTHEBYS: {
    ...defaultPremiumProduct,
    name: 'SOTHEBYS',
    stripeProductId: StripeProduct.SOTHEBYS
  },
  BJURFORS_POST_MVP_CAMPAIGN: {
    ...defaultPremiumProduct,
    name: 'BJURFORS_POST_MVP_CAMPAIGN',
    stripeProductId: StripeProduct.BJURFORS_POST_MVP_CAMPAIGN
  },
  A_PRODUCT: {
    ...defaultPremiumProduct,
    name: 'A_PRODUCT',
    stripeProductId: StripeProduct.A_PRODUCT
  }
}

export const getTextKeysForSubscription = (subscription?: Subscription) => {
  if (!subscription || !subscription.status)
    return {
      settingsPersonalProductDescription: TextsDeprecated.settingsFreeProdcutDescription,
      settingsPersonalUpsellButtonTextKey: TextsDeprecated.settingsBecomeAMemberButton
    }

  switch (subscription.status) {
    case 'active':
    case 'trialing':
      return {
        settingsPersonalProductDescription: TextsDeprecated.settingsBetaProductDescription,
        settingsPersonalUpsellButtonTextKey: TextsDeprecated.settingsManageMemberShipButton
      }
    case 'canceled':
      return {
        settingsPersonalProductDescription: TextsDeprecated.settingsFreeProdcutDescription,
        settingsPersonalUpsellButtonTextKey: TextsDeprecated.settingsBecomeAMemberButton
      }
    case 'incomplete':
    case 'incomplete_expired':
    case 'past_due':
    case 'unpaid':
      return {
        settingsPersonalProductDescription: TextsDeprecated.settingsBetaProductDescriptionIncomplete,
        settingsPersonalUpsellButtonTextKey: TextsDeprecated.settingsManageMemberShipButton
      }

    default:
      throw new Error(`Unexpected subscription status: ${subscription.status}`)
  }
}
