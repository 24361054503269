import styled from '@emotion/styled'
import React from 'react'
import { tinyBorderRadius } from '../enums/BorderRadixesDeprecated'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../../pure/libs/AppTexts'
import Box from './Box'
import ButtonBase from './ButtonBase'
import { ButtonProps } from './ButtonPrimary'
import FigmaTextLoader from './FigmaTextLoader'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'

const ButtonSecondary: React.FC<ButtonProps> = (props) => {
  const {
    children,
    className,
    loading,
    textKey = TextsDeprecated.cancelButtonText,
    color = 'secondary',
    ...buttonProps
  } = props
  const styleTextKey = props.variant === 'outlined' ? Texts.buttonTextBlack : TextsDeprecated.inviteButtonText

  return (
    <StyledButton className={className} variant="contained" {...buttonProps} color={color}>
      <Box align="center" fullWidth>
        {children || (
          <FigmaTextLoader textKey={styleTextKey} text={props.text || getFigmaText(textKey)} loading={loading} />
        )}
      </Box>
    </StyledButton>
  )
}

const StyledButton = styled(ButtonBase)<{ spacing?: string }>`
  border-radius: ${tinyBorderRadius};
`

export default ButtonSecondary
