import { useState } from 'react'
import { smallSpacing } from '../enums/Spacings'
import FigmaText from './FigmaText'
import { Texts } from '../../../../pure/libs/AppTexts'
import { JaguarSession } from '../../../../pure/types/types'
import Box from './Box'
import {
  AdCreatorLatestAdsFileCopy,
  AdCreatorLatestadsSectionCollapseIcon,
  AdCreatorLatestadsSectionExpandIcon
} from './Icons'
import { getTextWithDots } from './ProductsSectionInfoRow'
import { onClickCopySessions } from '../libs/onClickCopy'

export function AdCreatorLatestAdsItemSession({
  session: s,
  maxTextLength
}: {
  session: JaguarSession
  maxTextLength?: number
}) {
  const [expanded, setExpanded] = useState(false)
  const Icon = expanded ? AdCreatorLatestadsSectionExpandIcon : AdCreatorLatestadsSectionCollapseIcon
  const textKey = expanded ? Texts.adCreatorLatestAdsSectionHeaderBold : Texts.latestAdsSectionHeader
  return (
    <Box fullWidth>
      <Box fullWidth direction="row" align="center" justify="space-between">
        <Box direction="row" align="center" onClick={() => setExpanded(!expanded)} pointer>
          <Icon />
          <Box left spacing={smallSpacing}>
            <FigmaText textKey={textKey} text={s.request.type} textTransform="uppercase" />
          </Box>
        </Box>
        <Box>
          {expanded && (
            <Box pointer onClick={() => onClickCopySessions([s])}>
              <AdCreatorLatestAdsFileCopy />
            </Box>
          )}
        </Box>
      </Box>
      {expanded && (
        <Box top spacing={smallSpacing}>
          <FigmaText
            textKey={Texts.adCreatorLatestAdsSectionDescription}
            text={maxTextLength ? getTextWithDots(s.response, maxTextLength) : s.response}
          />
        </Box>
      )}
    </Box>
  )
}
