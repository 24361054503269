import LocationOnIcon from '@mui/icons-material/LocationOn'
import Autocomplete from '@mui/material/Autocomplete'
import MaterialBox from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { debounce } from '@mui/material/utils'
import parse from 'autosuggest-highlight/parse'
import * as React from 'react'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../../pure/libs/AppTexts'
import { PlaceType } from '../../../../pure/types/GooglePlace'
import { Popper } from '@mui/material'
import ZIndices from '../enums/Zindices'
import Box from './Box'

const autocompleteService = { current: null }

export function AutoCompleteGooglePlace({
  value,
  onChange,
  autoFocus,
  PopperComponent
}: {
  value: PlaceType | null
  onChange: (value: PlaceType | null) => void
  autoFocus?: boolean
  PopperComponent?: any
}) {
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<readonly PlaceType[]>([])

  const fetch = React.useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        // eslint-disable-next-line prettier/prettier
        (autocompleteService.current as any).getPlacePredictions(request, callback) 
      }, 400),
    []
  )

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = []

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      PopperComponent={PopperComponent}
      fullWidth
      filterSelectedOptions
      value={value || undefined}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options)
        onChange(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      onFocus={() => onChange(null)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          fullWidth
          placeholder={getFigmaText(Texts.homeFormAddressPlaceholderOptional)}
          autoFocus={autoFocus}
        />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || []

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length])
        )

        return (
          <MaterialBox>
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  {parts.map((part, index) => (
                    <MaterialBox key={index} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                      {part.text}
                    </MaterialBox>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          </MaterialBox>
        )
      }}
    />
  )
}
