
export function parseQueryParam<T>(search: string): T {
  // eslint-disable-next-line prettier/prettier
  const queryParams = (parse(search) as unknown) as T
  return queryParams as T
}

const parse = (url: string) => {
  const hashes = url.slice(url.indexOf(`?`) + 1).split(`&`)
  if (hashes[0].includes('=') === false) return {}
  return hashes.reduce((acc, hash) => {
    const [key, val] = hash.split(`=`)
    return {
      ...acc,
      [key]: decodeURIComponent(val)
    }
  }, {})
}

export function addQueryParamsToUrl<T extends object>(url: string, queryParams: T) {
  const keys = Object.keys(queryParams).filter((key) => !!queryParams[key])
  if (keys.length === 0) return url

  return keys.reduce((a, key, i, arr) => a + `${key}=${queryParams[key]}${i === arr.length - 1 ? '' : '&'}`, `${url}?`)
}

export const parseEmailQueryParam = (url: string) => {
  const suffix = url.split('email=')[1]
  if (!suffix) return ''
  return suffix.split('&')[0]
}
