import styled from '@emotion/styled'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { ONBOARDING_MAX_WIDTH } from '../libs/HardCodedSizes'
import { SignInViewProps } from '../../../../pure/types/SignInTypes'
import Box from './Box'
import Layout from './Layout'
import LoginWithEmailForm from './LoginWithEmailForm'
import { SignInLandingFingerprint } from './SignInLandingFingerprint'
import { SignInLandingUpsell } from './SignInLandingUpsell'
import { Helmet } from './Helmet'

export default function SignInLandingMobileInAppBrowser(props: SignInViewProps) {
  return (
    <Layout>
      <Box fullWidth align="center" top spacing={bigSpacing} bottom>
        <Helmet title="Login" />
        <Box fullWidth align="center" left right>
          <SignInLandingUpsell />
          <Container fullWidth align="center" top>
            <Box top fullWidth spacing={smallSpacing}>
              <LoginWithEmailForm {...props} />
            </Box>
            <Box top fullWidth spacing={smallSpacing} alignText="center">
              <SignInLandingFingerprint />
            </Box>
          </Container>
        </Box>
      </Box>
    </Layout>
  )
}

const Container = styled(Box)`
  max-width: ${ONBOARDING_MAX_WIDTH};
  margin: auto;
`
