import Images from '../../../../pure/libs/AppImages'
import { Texts } from '../../../../pure/libs/AppTexts'
import { ImageKey, TextKey } from '../../../../pure/types/Antiloop'

export enum TutorialSteps {
  WELCOME = 'WELCOME',
  TYPES = 'TYPES',
  ADDRESS = 'ADDRESS',
  KEYWORD = 'KEYWORD',
  OPTION = 'OPTION',
  EDIT_SECTION = 'EDIT_SECTION',
  SEND_TO_FASAD = 'SEND_TO_FASAD',
  FASAD_END = 'FASAD_END'
}

export type TutorialItem = {
  headerTextKey: TextKey
  descriptionTextKey: TextKey
  imageKey: ImageKey
}

export type TutorialItemsType = { [key in TutorialSteps]: TutorialItem }

export const TutorialItems: TutorialItemsType = {
  [TutorialSteps.WELCOME]: {
    headerTextKey: Texts.tutorialStepWelcomeHeader,
    descriptionTextKey: Texts.tutorialStepWelcomeDescription,
    imageKey: Images.pngTutorialStepWelcome
  },
  [TutorialSteps.TYPES]: {
    headerTextKey: Texts.tutorialStepTypesHeader,
    descriptionTextKey: Texts.tutorialStepTypesDescription,
    imageKey: Images.pngTutorialStepTypesHeader
  },
  [TutorialSteps.ADDRESS]: {
    headerTextKey: Texts.tutorialStepAddressHeader,
    descriptionTextKey: Texts.tutorialStepAddressDescription,
    imageKey: Images.pngTutorialStepAddressHeader
  },
  [TutorialSteps.KEYWORD]: {
    headerTextKey: Texts.tutorialStepKeywordHeader,
    descriptionTextKey: Texts.tutorialStepKeywordDescription,
    imageKey: Images.pngTutorialStepKeywordHeader
  },
  [TutorialSteps.OPTION]: {
    headerTextKey: Texts.tutorialStepOptionHeader,
    descriptionTextKey: Texts.tutorialStepOptionDescription,
    imageKey: Images.pngTutorialStepOption
  },
  [TutorialSteps.EDIT_SECTION]: {
    headerTextKey: Texts.tutorialStepEditSectionHeader,
    descriptionTextKey: Texts.tutorialStepEditSectionDescription,
    imageKey: Images.pngTutorialStepEditSection
  },
  [TutorialSteps.SEND_TO_FASAD]: {
    headerTextKey: Texts.tutorialStepSendToFasadHeader,
    descriptionTextKey: Texts.tutorialStepSendToFasadDescription,
    imageKey: Images.pngTutorialStepSendToFasad
  },
  [TutorialSteps.FASAD_END]: {
    headerTextKey: Texts.tutorialStepFasadEndHeader,
    descriptionTextKey: Texts.tutorialStepFasadEndDescription,
    imageKey: Images.pngTutorialStepFasad
  }
}
