import { styled } from '@mui/material'
import { ClickAwayListener, TextareaAutosize as MaterialTextAreaAutoSize } from '@mui/base'
import { TextFieldProps } from '@mui/material'
import { useState } from 'react'
import { minSpacing } from '../enums/Spacings'
import { SectionInfoItem } from '../libs/SectionInfo'
import FigmaText from './FigmaText'
import { toPx } from '../../../../pure/libs/Common'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../../pure/libs/AppTexts'
import { TextKey } from '../../../../pure/types/Antiloop'
import Box from './Box'
import React from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'
import { JaguarRequestForm } from '../../../../pure/types/types'

const PLACE_HOLDER_TEXT_KEY = Texts.adCreatorFormPlaceholderLine1

const MIN_ROWS = 5

type Props = {
  sectionInfo: SectionInfoItem
  formProps: UseFormReturn<JaguarRequestForm>
} & TextFieldProps

const AdCreatorFormTextFieldComponent = (props: Props, ref) => {
  const { sectionInfo, formProps, ...textFieldProps } = props
  const keywordMapItems = useWatch({ control: formProps.control, name: 'keywordMapItems' }) || []
  const shouldShowPlaceHolder = keywordMapItems.length === 0
  const { value } = textFieldProps
  const hasInitialValue = !!value
  const [showTextArea, setClicked] = useState(!!hasInitialValue)
  const onClick = () => {
    setClicked(true)
  }

  const onClickAway = () => !value && showTextArea && setClicked(false)

  if (showTextArea)
    return (
      <ClickAwayListener onClickAway={onClickAway}>
        <TextareaAutoSize
          placeholder={getFigmaText(PLACE_HOLDER_TEXT_KEY)}
          autoFocus={!hasInitialValue}
          minRows={MIN_ROWS}
          ref={ref}
          {...textFieldProps}
        />
      </ClickAwayListener>
    )

  return (
    <Box
      multiline
      onClick={onClick}
      fullPadding
      spacing={minSpacing}
      fullWidth
      style={{
        minHeight: (MIN_ROWS + 1.8) * toPx(PLACE_HOLDER_TEXT_KEY.style.fontSize),
        cursor: 'text'
      }}
    >
      <Box fullWidth>
        <FigmaText textKey={PLACE_HOLDER_TEXT_KEY} />
        {shouldShowPlaceHolder && (
          <FigmaText
            styleTextKey={Texts.adCreatorFormPlaceholderLine2}
            textKey={sectionInfo.placeholderTextKey as TextKey}
          />
        )}
      </Box>
    </Box>
  )
}

const TextareaAutoSize = styled(MaterialTextAreaAutoSize)({
  '::placeholder': PLACE_HOLDER_TEXT_KEY.style,
  border: 'none',
  outline: 'none',
  resize: 'none',
  ...PLACE_HOLDER_TEXT_KEY.style
}) as any

export const AdCreatorFormTextField = React.forwardRef(AdCreatorFormTextFieldComponent)
