import { onAuthStateChanged } from 'firebase/auth'
import dayjs from 'dayjs'
import { User } from 'firebase/auth'
import invariant from 'invariant'
import React from 'react'
import { auth } from '../libs/Firebase'
import { SignInPageProps } from '../libs/SignInHelper'
import { getEmailForFirebaseUser } from '../../../../pure/libs/Common'
import { captureException, setSentryUser } from '../libs/SentryHelper'
import { OrgUserStatus } from '../../../../pure/types/types'
import useAppState, { State } from './useAppState'
import { useLogout } from './useLogout'
import { getOrgIdForEmail } from '../libs/OrgInfo'
import { getUser, updateUser } from '../libs/DBApiHandler'
import { toUser } from '../libs/Mapper'

type Props = SignInPageProps & {
  onFinally: () => unknown
}

export default function useLogin(props: Props) {
  const { onFinally } = props
  const { state, setState } = useAppState()
  const [loading, setLoading] = React.useState(true)
  const logout = useLogout()

  const onLogin = (firebaseUser: User) =>
    getUser(firebaseUser.uid).then((user) => {
      const email = getEmailForFirebaseUser(firebaseUser)
      setSentryUser({ email, id: firebaseUser.uid })
      setState({ ...state, firebaseUser })

      return Promise.resolve(user)
        .then((user) => {
          if (!!user?._id) return { user, isNewUser: false }

          return updateUser({
            ...toUser(firebaseUser),
            ...user,
            organizationId: getOrgIdForEmail(email || '')
          }).then((user) => ({ user, isNewUser: true }))
        })
        .then(({ user, isNewUser }) => {
          invariant(user, '!user')
          invariant(user._id, '!user._id')

          // TODO refactor out enableUpsell
          const _state: State = { ...state, user, enableUpsell: !isNewUser }
          setState(_state)

          const currentUser = auth.currentUser
          invariant(currentUser, 'currentUser')

          if (user.orgUserStatus === OrgUserStatus.INVITED)
            currentUser
              .getIdToken(true)
              .then(() => currentUser.getIdTokenResult(true))
              .then((idtoken) => {
                invariant(
                  idtoken.claims.organizationId === user.organizationId,
                  `corrupted idToken for user: ${user.email}`
                )
              })
              .catch(captureException)

          // TODO WRITE TEST, Should set custom claims on in leopard
          // TODO WRITE TEST, Should set active on invited users on first login
          updateUser({ ...user, lastActiveAt: dayjs().format(), orgUserStatus: OrgUserStatus.ACTIVE })

          return props.onLogin?.(_state)
        })
        .catch((e) => {
          logout()
          return Promise.reject(e)
        })
    })

  React.useEffect(() => {
    onAuthStateChanged(
      auth,
      (user) =>
        Promise.resolve()
          .then(() => {
            if (!user && !!state.user._id) return logout()
            if (!user) return
            return Promise.resolve(setLoading(true))
              .then(() => onLogin(user))
              .finally(() => onFinally())
          })
          .catch(captureException)
          .finally(() => setLoading(false)),
      captureException
    )
  }, [])

  return loading
}
