import { captureAndNotifyError } from '../libs/ErrorHelper'
import { ProductType } from '../libs/ProductInfo'
import { createCheckoutSession } from '../libs/StripeHelper'
import { createStripePortalLink } from '../libs/CloudFunctionsApiHandler'
import { useUser } from './QueryHooks'
import useAppState from './useAppState'

export function useOnClickCreateAndRedirectToStripeCheckoutSession({
  setIsLoading,
  productType
}: {
  setIsLoading: (loading: boolean) => unknown
  productType: ProductType
}) {
  return async (event) => {
    setIsLoading(true)

    try {
      const stripeProductId = productType.stripeProductId
      const session = await createCheckoutSession(stripeProductId)
      window.location.href = session.url
    } catch (err: unknown) {
      captureAndNotifyError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }
}

export function useOnClickCreateAndRedirectToStripeBillingPortalSession(setIsLoading: (loading: boolean) => unknown) {
  const { state } = useAppState()
  return async (event) => {
    setIsLoading(true)

    try {
      const session = await createStripePortalLink({ returnUrl: window.location.href }, state)
      window.location.href = session.url
    } catch (err: unknown) {
      captureAndNotifyError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }
}
