import { useState } from 'react'
import MenuMobileMenu from '../../MenuLoggedInMobileMenu'
import { smallSpacing } from '../enums/Spacings'
import { MENU_LOGO_WIDTH, USER_WIDGET_SIZE } from '../libs/HardCodedSizes'
import Images from '../../../../pure/libs/AppImages'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { MenuLoggedInMobileIcon } from './Icons'
import UserWidget from './UserWidget'

export default function MenuLoggedInMobile() {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>()

  const onClose = () => setAnchorEl(undefined)

  return (
    <Box fullWidth fullPadding backgroundColor="white">
      <MenuMobileMenu anchorEl={anchorEl} onClose={onClose} />
      <Box fullWidth direction="row" align="center">
        <Box fullWidth onClick={(e) => setAnchorEl(e.currentTarget)}>
          <MenuLoggedInMobileIcon size={USER_WIDGET_SIZE} />
        </Box>
        <FigmaImageContainer imageKey={Images.companyMixedLogo} width={MENU_LOGO_WIDTH} />
        <Box fullWidth align="flex-end">
          <UserWidget />
        </Box>
      </Box>
    </Box>
  )
}
