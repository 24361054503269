export const getInitials = (name: string): string => {
  const rgx = new RegExp(/(\p{L})\p{L}+/u, 'gu')

  //@ts-ignore
  const initials = [...name.matchAll(rgx)] || []

  return initials
    .reduce((acc, match, index) => {
      // Only add the first and last initials to the result string.
      if (index === 0 || index === initials.length - 1) {
        return acc + (match[1] || '')
      }
      return acc
    }, '')
    .toUpperCase()
}
