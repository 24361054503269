import config from '../Config'
import { isPrekInternalUser } from '../libs/CreateAdHelper'
import { ProductInfo, ProductType, StripeProduct } from '../libs/ProductInfo'
import { Subscription } from '../stripe'
import { getProductForOrgUser } from '../libs/OrgInfo'
import { User } from '../../../../pure/types/types'

type Options = {
  subscription?: Subscription
  user?: User
}

export const getCurrentProduct = ({ subscription, user }: Options = {}): ProductType => {
  const orgProduct = getProductForOrgUser(user)

  if (orgProduct) return orgProduct
  if (user && isPrekInternalUser(user)) return ProductInfo.FASAD_SUMMER_CAMPAIGN
  if (user && ['marie.kullberg@bjurfors.se'].includes(user.email || '')) return ProductInfo.BJURFORS_POST_MVP_CAMPAIGN

  if (config.enablePremium) return ProductInfo.MONTHLY_MEMBERSHIP

  const product = getStripeProductIdForSubscription(subscription)
  const productType: ProductType | undefined = Object.values(ProductInfo).find(
    (p) => !!p.stripeProductId && p.stripeProductId === product
  )

  return { ...ProductInfo.FREE, ...productType }
}

export function getStripeProductIdForSubscription(s?: Subscription) {
  return s?.product || (s?.plan.product as StripeProduct)
}
