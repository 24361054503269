import { updateUser } from '../libs/DBApiHandler'
import { User } from '../../../../pure/types/types'
import useAppState from './useAppState'

export default function useUpdateUser() {
  const { state, setState } = useAppState()
  return (user: User) => {
    setState({ ...state, user })
    return updateUser(user)
  }
}
