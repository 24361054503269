import { useState } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'
import { Texts } from '../../../../pure/libs/AppTexts'
import { JaguarRequestForm } from '../../../../pure/types/types'
import { AdCreatorKeywordButton } from './AdCreatorKeywordButton'
import Box from './Box'
import { AdCreatorArrowDownIcon, AdCreatorArrowUpIcon } from './Icons'

import { ClickAwayListener } from '@mui/base'
import { regularSpacing, tinySpacing } from '../enums/Spacings'
import {
  KeyWordData,
  KeyWordType,
  KeywordHouseType,
  KeywordItem,
  getTextKeyForKeywordItem
} from '../../../../pure/libs/KeyWordsHelper'
import { AdCreatorFormSectionButtonView } from './AdCreatorFormSectionButtonView'
import MaterialPopper from './Popper'

export function AdCreatorFormSectionPopperAndButtonHouseType({
  formProps
}: {
  formProps: UseFormReturn<JaguarRequestForm>
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const houseType = useWatch({ name: 'houseType', control: formProps.control })
  const textKey = houseType
    ? getTextKeyForKeywordItem({ id: houseType } as KeywordItem)
    : Texts.adCreatorFormSectionChooseTypeButton

  const Icon = anchorEl ? AdCreatorArrowUpIcon : AdCreatorArrowDownIcon

  return (
    <>
      <Popper anchorEl={anchorEl} onClose={() => setAnchorEl(undefined)} formProps={formProps} />
      <AdCreatorKeywordButton Icon={Icon} textKey={textKey} onClick={(e) => setAnchorEl(e.currentTarget)} />
    </>
  )
}

export function Popper({
  anchorEl,
  onClose,
  formProps
}: {
  onClose: () => unknown
  anchorEl: HTMLElement | undefined
  formProps: UseFormReturn<JaguarRequestForm>
}) {
  const keyWordData = KeyWordData.filter((keyWordItem) => keyWordItem.type === KeyWordType.HouseType) as KeywordItem[]
  const houseType = useWatch({ name: 'houseType', control: formProps.control })
  function onClickKeyWord(item: KeywordItem) {
    formProps.setValue('houseType', item.id as KeywordHouseType)
    onClose()
  }

  return (
    <MaterialPopper anchorEl={anchorEl} placement="bottom-start">
      <ClickAwayListener onClickAway={onClose}>
        <Box fullWidth fullPadding spacing={regularSpacing}>
          <Box fullWidth style={{ flexWrap: 'wrap' }}>
            {keyWordData.map((keyWordItem, key) => (
              <Box key={key} spacing={tinySpacing} top right>
                <AdCreatorFormSectionButtonView
                  textKey={getTextKeyForKeywordItem(keyWordItem)}
                  onClick={() => onClickKeyWord(keyWordItem)}
                  isSelected={houseType === keyWordItem.id}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </ClickAwayListener>
    </MaterialPopper>
  )
}
