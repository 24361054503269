import styled from '@emotion/styled'
import { tinyBorderRadius } from '../enums/BorderRadixesDeprecated'
import { useStorageUrlExperimental } from '../hooks/useStorageUrlExperimental'
import { PERSONAL_PHOTO_CONTAINER_SIZE } from '../libs/HardCodedSizes'
import Colors from '../../../../pure/libs/Colors'
import { User } from '../../../../pure/types/types'
import Box from './Box'
import { PersonIcon } from './Icons'
import { getRoundImageStyles, RoundImage } from './StyledComponents'

type Props = {
  size?: number
  user: User
  overrideUrl?: string
}

const DEFAULT_SIZE = PERSONAL_PHOTO_CONTAINER_SIZE

export default function PersonalPhotoView({ user, size = DEFAULT_SIZE, overrideUrl }: Props) {
  const showEmptyState = !overrideUrl && !user.profileImage
  return (
    <PersonalPhotoContainer align="center" justify="center" size={size}>
      <PersonalPhotoInnerContainer align="center" justify="center" size={size}>
        {!showEmptyState && <PersonalPhotoViewUrl user={user} size={size} overrideUrl={overrideUrl} />}
        {showEmptyState && (
          <PersonIcon
            size={size / 2.5}
            color={Colors.white}
            backgroundColor={Colors.primary20}
            borderRadius={tinyBorderRadius}
          />
        )}
      </PersonalPhotoInnerContainer>
    </PersonalPhotoContainer>
  )
}

const PersonalPhotoViewUrl = ({ overrideUrl, size = DEFAULT_SIZE, user: { profileImage } }: Props) => {
  const { url: _url } = useStorageUrlExperimental(profileImage)
  const url = overrideUrl || _url
  if (!url) return null
  return <RoundImage src={url} alt="Profile" size={size} />
}

const RATIO = 1.2
const PersonalPhotoContainer = styled(Box)<{ size: number }>`
  ${({ size }) => getRoundImageStyles(size * RATIO)}
  background-color: ${Colors.primary100};
`

const PersonalPhotoInnerContainer = styled(Box)<{ size: number }>`
  ${({ size }) => getRoundImageStyles(size)}
  border: 1px dashed ${Colors.neutral50};
`
