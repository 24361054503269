import { bigSpacing } from '../enums/Spacings'
import FigmaText from './FigmaText'
import Images from '../../../../pure/libs/AppImages'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'

export default function ErrorBoundaryView() {
  return (
    <Box fullWidth fullPadding align="center" spacing={bigSpacing} fullHeight>
      <Box>
        <FigmaImageContainer imageKey={Images.menuLogo} />
      </Box>
      <Box top>
        <FigmaText textKey={TextsDeprecated.errorMaintenanceTitle} />
      </Box>
      <Box top>
        <FigmaText textKey={TextsDeprecated.errorMaintenanceDescription} />
      </Box>
    </Box>
  )
}
