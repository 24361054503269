import styled from '@emotion/styled'
import dayjs from 'dayjs'
import invariant from 'invariant'
import { useState } from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { useOrganization } from '../hooks/QueryHooks'
import useAppState from '../hooks/useAppState'
import FigmaText from './FigmaText'
import { YYYYMMDD_HHmm } from '../../../../pure/libs/Consts'
import { Texts } from '../../../../pure/libs/AppTexts'
import { CustomText } from '../../../../pure/types/types'
import Box from './Box'
import { DEFAULT_ICON_SIZE, LatestAdsRemoveIcon } from './Icons'
import { SettingsAIWriterCustomTextView } from './SettingsAIWriterCustomTextView'
import { updateOrganization } from '../libs/DBApiHandler'

export function SettingsAIWriterCustomText({ customText }: { customText: CustomText }) {
  const [isHovering, setIsHovering] = useState(false)
  const { state } = useAppState()
  const { data: org } = useOrganization(state.user.organizationId)

  const onMouseEnter = () => setIsHovering(true)
  const onMouseLeave = () => setIsHovering(false)
  const onClickRemoveAd = () => {
    invariant(org, '!org')
    updateOrganization({ ...org, customTexts: org.customTexts.filter((t) => t._id !== customText._id) })
  }

  return (
    <Container
      fullPadding
      spacing={smallSpacing}
      fullWidth
      position="relative"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Box
        position="absolute"
        style={{ top: -DEFAULT_ICON_SIZE, right: -DEFAULT_ICON_SIZE / 2, display: isHovering ? 'block' : 'none' }}
        pointer
        onClick={onClickRemoveAd}
      >
        <LatestAdsRemoveIcon />
      </Box>
      <FigmaText textKey={Texts.latestAdsDate} text={dayjs(customText.createdAt).format(YYYYMMDD_HHmm)} />
      <Box top spacing={tinySpacing} fullWidth>
        <Box spacing={tinySpacing} fullWidth>
          <Box top spacing={tinySpacing} fullWidth>
            <SettingsAIWriterCustomTextView uploadedText={customText} />
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
`
