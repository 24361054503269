import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { bigSpacing, defaultSpacing } from '../enums/Spacings'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { TextKey } from '../../../../pure/types/Antiloop'
import Box from './Box'
import Layout from './Layout'
import { Texts } from '../../../../pure/libs/AppTexts'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { PricingItemInfoRowMobile } from './PricingItemInfoRowMobile'
import { ProductType } from '../libs/ProductInfo'

export function PricingPageMobile({
  headerTextKey = TextsDeprecated.pricingHeaderMobile,
  productType
}: {
  headerTextKey?: TextKey
  productType: ProductType
}) {
  return (
    <Layout>
      <Box fullWidth top spacing={defaultSpacing} align="center">
        <FigmaTextWithStyle type="h2" textKey={Texts.pricingHeading} />
        <Box>
          <FigmaTextWithStyle type="h5" color="neutral60" textKey={headerTextKey} />
        </Box>
        <Box top spacing={bigSpacing} fullWidth>
          <PricingItemInfoRowMobile productType={productType} />
        </Box>
      </Box>
    </Layout>
  )
}
