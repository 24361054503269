import React from 'react'
import styled from '@emotion/styled'
import { minBorderRadius } from '../enums/BorderRadixesDeprecated'
import Colors from '../../../../pure/libs/Colors'
import { HEADER_ACTION_CONTAINERSIZE } from '../libs/HardCodedSizes'
import Box from './Box'
import { MoreHoriz, MoreVert, MoreVertWhite } from './Icons'
import { IconProps } from './Icon'

export type MoreActionProps = {
  Component?: React.FC<Partial<IconProps>>
  visible?: boolean
  onClick?: (e: any) => void
  backgroundColor?: string
  color?: string
}

export function MoreAction({ backgroundColor, onClick, visible, color }: MoreActionProps) {
  return (
    <Container
      direction="row"
      align="center"
      justify="center"
      onClick={onClick}
      link
      backgroundColor={backgroundColor}
      visible={visible}
    >
      <MoreHoriz backgroundColor={backgroundColor} color={color || Colors.white} />
    </Container>
  )
}

export function MoreActionVert({ onClick, visible, Component = MoreVert }: MoreActionProps) {
  return (
    <BaseContainer direction="row" align="center" justify="center" onClick={onClick} link visible={visible}>
      <Component />
    </BaseContainer>
  )
}

export const MoreActionVerWhite = (props: MoreActionProps) => <MoreActionVert {...props} Component={MoreVertWhite} />

export const BaseContainer = styled(Box)<{
  backgroundColor?: string
  visible?: boolean
}>`
  width: ${HEADER_ACTION_CONTAINERSIZE};
  height: ${HEADER_ACTION_CONTAINERSIZE};
  ${({ visible }) => (!visible ? 'visibility: hidden;' : '')}
`

export const Container = styled(BaseContainer)<{
  backgroundColor?: string
  visible?: boolean
}>`
  background-color: ${({ backgroundColor }) => backgroundColor || Colors.primary100};
  border-radius: ${minBorderRadius};
`
