import debounce from 'debounce-promise'
import * as ApiHandler from './ApiHandler'
import { mapToJaguarRequest } from './JaguarSessionControllerHelper'
import Colors from '../../../../pure/libs/Colors'
import { TextKey } from '../../../../pure/types/Antiloop'
import { Texts } from '../../../../pure/libs/AppTexts'
import { getFigmaText } from './TextRepository'

export type StatusInfoItem = {
  textKey?: TextKey
  color: string
  filter?: string
  text?: string
}
export const StatusInfo: { [key: string]: StatusInfoItem } = {
  default: {
    textKey: undefined,
    color: Colors.neutral40
  },
  warning: {
    textKey: Texts.adCreatorFormSectionHelperText,
    color: Colors.warning80,
    text: `(XXX / XXX) ${getFigmaText(Texts.adCreatorFormSectionHelperText)}`
  },
  success: {
    textKey: Texts.adCreatorFormSectionHelperTextSuccess,
    color: Colors.success60,
    filter: `drop-shadow(0px 0px 4px #00FF0C)`
  }
}
const DEBOUNCE_TIMEOUT = 1000
const checkPropertyInfo = ({ formProps, user }: { formProps: any; user: any }): Promise<any> =>
  ApiHandler.checkPropertyInfo({ req: mapToJaguarRequest(formProps.getValues(), user) })

export const checkProperyInfoDebounced = debounce(checkPropertyInfo, DEBOUNCE_TIMEOUT)
