import useAppState from './useAppState'
import { getEstateForVitecNOOrder } from '../libs/CloudFunctionsApiHandler'
import { ExternalSession } from '../../../../pure/types/types'
import { useAsyncFunction } from './useAsyncFunction'
import { KeywordHouseType } from '../../../../pure/libs/KeyWordsHelper'
import { VitecNextEstate, VitecNOOrder } from '../../../../pure/types/VitecNODerivedTypes.js'

export function useExternalSessionForVitecNOOrder(vitecNextOrder: VitecNOOrder) {
  const { state } = useAppState()
  return useAsyncFunction(
    () =>
      getEstateForVitecNOOrder(vitecNextOrder, state).then(
        (vitecNoEstate) => map(vitecNoEstate) as ExternalSession
      ),
    {
      enabled: !!vitecNextOrder.orderId,
      key: vitecNextOrder.orderId
    }
  )
}

function map(vitecNoEstate?: VitecNextEstate): ExternalSession {
  return {
    objectMetaData: {
      address: vitecNoEstate?.address?.streetAdress,
      zipCode: vitecNoEstate?.address?.zipCode,
      zipArea: vitecNoEstate?.address?.city
    },
    houseType: getObjectTypeForVitecNOEstate(vitecNoEstate)
  } as ExternalSession
}

function getObjectTypeForVitecNOEstate(vitecNoEstate?: VitecNextEstate): KeywordHouseType {
  return KeywordHouseType.Apartment
}
