import config from '../Config'
import { onClickContactUs } from '../components/MenuButtonsDesktop'
import Colors from '../../../../pure/libs/Colors'
import Images from '../../../../pure/libs/AppImages'
import { Texts } from '../../../../pure/libs/AppTexts'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { ImageKey, TextKey } from '../../../../pure/types/Antiloop'

export type PricingProductInfoItem = {
  enableGradient?: boolean
  titleTextKey: TextKey
  descriptionPrefixTextKey?: TextKey
  descriptionTextKey: TextKey
  priceTextKey: TextKey
  priceTextKeySuffix?: TextKey
  enablePriceText?: boolean
  priceTextKeyDescriptions?: TextKey
  priceTextKeyDescriptionsHidden?: boolean
  buttonTextKey?: TextKey
  buttonSecondaryTextKey?: TextKey
  rows: { header: TextKey; description?: TextKey }[]
  disabled?: boolean
  onClick?: () => void
  enableShowOrdinaryPrice?: boolean
  enableDashedBorder?: boolean
  iconImageKey: ImageKey
  primaryButtonColor: string
  secondaryButtonVariant?: string
  rowIconBackgroundColor: string
  rowIconCheckColor?: string
}

export enum PricingItemInfoKey {
  free = 'free',
  premium = 'premium',
  enterprice = 'enterprice'
}

export const PricingItemInfo: { [property in PricingItemInfoKey]: PricingProductInfoItem } = {
  free: {
    titleTextKey: Texts.pricingItemHeaderFree,
    descriptionTextKey: Texts.pricingItemDescriptionFree,
    priceTextKey: Texts.pricingItemPriceFree,
    priceTextKeyDescriptionsHidden: true,
    buttonTextKey: Texts.pricingItemPrimaryButtonFree,
    buttonSecondaryTextKey: Texts.pricingItemSecondaryButtonFree,
    onClick: onClickContactUs,
    disabled: true,
    rows: [
      { header: Texts.pricingItemFeatures1HeaderFree, description: Texts.pricingItemFeatures1DescriptionFree },
      { header: Texts.pricingItemFeatures2HeaderFree, description: Texts.pricingItemFeatures2DescriptionFree }
    ],
    enableDashedBorder: true,
    iconImageKey: Images.person,
    primaryButtonColor: 'secondary',
    secondaryButtonVariant: 'outlined',
    enableGradient: true,
    rowIconBackgroundColor: Colors.neutral30
  },
  premium: {
    titleTextKey: Texts.pricingItemHeaderPremium,
    descriptionPrefixTextKey: Texts.pricingItemDescriptionPrefixPremium,
    descriptionTextKey: Texts.pricingItemDescriptionPremium,
    priceTextKey: Texts.pricingItemPricePremium,
    priceTextKeySuffix: Texts.pricingItempriceSuffixPremium,
    enablePriceText: true,
    priceTextKeyDescriptions: Texts.originalPrice,
    enableShowOrdinaryPrice: true,
    buttonTextKey: Texts.premiumPricingButtonText,
    buttonSecondaryTextKey: Texts.pricingItemSecondaryButtonPremium,
    onClick: () => ({}),
    rows: [
      { header: Texts.pricingItemFeatures1HeaderPremium },
      { header: Texts.pricingItemFeatures2HeaderPremium },
      { header: Texts.pricingItemFeatures3HeaderPremium },
      { header: Texts.pricingItemFeatures4HeaderPremium },
      { header: Texts.pricingItemFeatures5HeaderPremium }
    ],
    rowIconBackgroundColor: Colors.secondary60,
    iconImageKey: Images.star,
    primaryButtonColor: 'primary',
    enableGradient: true
  },
  enterprice: {
    titleTextKey: Texts.pricingItemHeaderCustom,
    descriptionTextKey: Texts.pricingItemDescriptionCustom,
    priceTextKey: Texts.pricingItemPriceCustom,
    priceTextKeyDescriptions: TextsDeprecated.pricingFixedMonthlyPriceForTheEntireCompanyagency,
    buttonSecondaryTextKey: Texts.pricingItemSecondaryButtonCustom,
    onClick: onClickContactUs,
    rows: [{ header: Texts.pricingItemFeatures1HeaderCustom }, { header: Texts.pricingItemFeatures2HeaderCustom }],
    iconImageKey: Images.enterprise,
    primaryButtonColor: 'secondary',
    rowIconBackgroundColor: Colors.black,
    rowIconCheckColor: Colors.white
  }
}
