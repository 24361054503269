import { ClickAwayListener } from '@mui/base'
import { bigSpacing } from '../enums/Spacings'
import { formatHeadline } from '../libs/ExternalSessionHelper'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import Images from '../../../../pure/libs/AppImages'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../../pure/libs/AppTexts'
import { ExternalSession } from '../../../../pure/types/types'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import { ModalProps } from './Modal'
import { ModalFullScreen } from './ModalFullScreen'

type Props = ModalProps & {
  unpayedSession: ExternalSession
  setExternalSession: (newSession: ExternalSession) => unknown
  externalSession: ExternalSession
}
export function ModalExternalSessionUnpayedSession(props: Props) {
  const { unpayedSession, setExternalSession, externalSession } = props

  const onClick = () =>
    setExternalSession({ ...unpayedSession, authenticationToken: externalSession.authenticationToken })

  return (
    <ModalFullScreen {...props}>
      <ClickAwayListener onClickAway={console.log}>
        <Box fullWidth>
          <Box fullWidth spacing={bigSpacing} align="center" bottom left right>
            <Box fullWidth align="center">
              <Box top>
                <FigmaImageContainer imageKey={Images.pngGazelleArrowFasad} />
              </Box>
              <Box top alignText="center">
                <FigmaTextWithLinebreaks textKey={Texts.modalBillingFasadHeader} />
              </Box>
              <Box top alignText="center">
                <FigmaTextWithLinebreaks
                  textKey={Texts.modalBillingFasadDescription}
                  text={formatText(
                    getFigmaText(Texts.modalBillingFasadDescription, 'sv'),
                    [formatHeadline(unpayedSession)],
                    {
                      boldParams: true
                    }
                  )}
                />
                <Box fullWidth top>
                  <Button textKey={Texts.modalBillingFasadButton} fullWidth onClick={onClick} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </ClickAwayListener>
    </ModalFullScreen>
  )
}
