import useAppState from '../hooks/useAppState'
import useIsMobile from '../hooks/useIsMobile'
import MenuLoggedIn from './MenuLoggedIn'
import MenuLoggedInMobile from './MenuLoggedInMobile'
import MenuLoggedOut from './MenuLoggedOut'

export default function Menu() {
  const { state } = useAppState()
  const { user } = state

  const isMobile = useIsMobile()
  if (!user._id) return <MenuLoggedOut />

  if (isMobile) return <MenuLoggedInMobile />

  return <MenuLoggedIn />
}
