import styled from '@emotion/styled'
import MaterialButton from '@mui/material/Button'
import { bigSpacing, tinySpacing } from '../enums/Spacings'

export default styled(MaterialButton, { shouldForwardProp: (prop) => prop !== 'verticalSpacing' })<{
  spacing?: string
  verticalSpacing?: string
}>`
  padding-left: ${({ spacing }) => spacing || bigSpacing};
  padding-right: ${({ spacing }) => spacing || bigSpacing};
  padding-top: ${({ verticalSpacing }) => verticalSpacing || tinySpacing};
  padding-bottom: ${({ verticalSpacing }) => verticalSpacing || tinySpacing};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  min-width: auto;
  text-transform: none;
`
