import React from 'react'
import FigmaText, { FigmaTextProps } from './FigmaText.js'

const FigmaTextArray: React.FC<{
  figmaTextPropSets: FigmaTextProps[]
}> = ({ figmaTextPropSets }) => {
  return (
    <div>
      {figmaTextPropSets.map((props, key) => (
        <span key={key} style={{ paddingRight: 2 }}>
          <FigmaText {...props} />
        </span>
      ))}
    </div>
  )
}

export default FigmaTextArray
