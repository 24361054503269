import invariant from 'invariant'
import { Price, getPrices } from '../stripe'
import { StripeProduct } from '../libs/ProductInfo'
import { useAsyncFunction } from './useAsyncFunction'
import { payments } from '../libs/StripeHelper'

export type UseStripePriceOptions = { enableCurrency?: boolean }

export function useStripePriceNumber(stripeProductId: StripeProduct): number {
  const price = useStripePriceObject(stripeProductId)
  return getPriceAmount(price)
}

export function useStripePrice(
  stripeProductId: StripeProduct,
  { enableCurrency = true }: UseStripePriceOptions = {}
): string {
  const price = useStripePriceObject(stripeProductId)

  if (!price) return ''
  const { currency } = price
  const amount = getPriceAmount(price)

  const priceText = amount.toString()
  if (!enableCurrency) return priceText
  return `${priceText} ${currency.toUpperCase()}`
}

function useStripePriceObject(stripeProductId: StripeProduct): Price {
  const { data: prices = [] } = useAsyncFunction(() => getPrices(payments, stripeProductId), { key: stripeProductId })
  const activePrice = getActivePrice(prices)
  const price = prices[0]
  return activePrice || price
}

export function getActivePrice(prices: Price[]) {
  return prices.filter((p) => !!p.active)[0]
}

export function getPriceAmount(price?: Price): number {
  if (!price) return 0
  const { unit_amount, interval } = price
  const intervalCount = interval === 'year' ? 12 : 1
  invariant(unit_amount, 'unit_amount is undefined')

  return Math.round(unit_amount / 100 / intervalCount)
}
