import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useIsMobile from '../hooks/useIsMobile'
import FigmaText from './FigmaText'
import Colors from '../../../../pure/libs/Colors'
import RoutePath from '../../../../pure/libs/RoutePath'
import { TextsDeprecated, DeprecatedTextKeys } from '../../../../pure/libs/TextsDeprecated'
import Box from './Box'
import FooterMobile from './FooterMobile'
import { CONTACT_US_URL, FAQ_URL, PRICING_URL_DEPRECATED } from '../../../../pure/libs/Consts'
import { FooterSocialMediaIcons } from './FooterSocialMediaIcons'
import { Texts } from '../../../../pure/libs/AppTexts'
import FigmaImageContainer from './FigmaImageContainer'
import Images from '../../../../pure/libs/AppImages'

export default function Footer() {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  if (isMobile) return <FooterMobile />

  return (
    <Container fullWidth position="relative">
      <Box fullWidth direction="row" fullHeight>
        <Box fullHeight fullWidth top left spacing={bigSpacing}>
          <Box>
            <FigmaImageContainer imageKey={Images.companyMixedLogo} />
            <Box top spacing={smallSpacing} width="400px">
              <FigmaText textKey={Texts.footerComapnyInfoText} />
            </Box>
            <Box top spacing={smallSpacing}>
              <Box left spacing={tinySpacing}>
                <FooterSocialMediaIcons />
              </Box>
            </Box>
          </Box>
          <Box top spacing={bigSpacing}>
            <FigmaText textKey={TextsDeprecated.footerCopyright} />
          </Box>
        </Box>

        <Box top bottom>
          <WhiteVerticalLine />
        </Box>
        <Box fullWidth spacing={bigSpacing} fullPadding fullHeight justify="space-between">
          <Box fullWidth direction="row">
            <Box left spacing={bigSpacing}>
              <Box bottom link>
                <FigmaText styleTextKey={Texts.footerCompany} textKey={TextsDeprecated.footerCompany} />
              </Box>
              <Box bottom link spacing={tinySpacing} onClick={() => navigate(RoutePath.ROOT)}>
                <FigmaText styleTextKey={Texts.footerHomeHowItWorks} textKey={TextsDeprecated.footerHome} />
              </Box>
              <Box bottom link spacing={tinySpacing} onClick={() => window.open(CONTACT_US_URL, '_blank')}>
                <FigmaText styleTextKey={Texts.footerHomeHowItWorks} textKey={Texts.footerResourcesContactUs} />
              </Box>
              <Box bottom link spacing={tinySpacing} onClick={() => window.open(FAQ_URL, '_blank')}>
                <FigmaText styleTextKey={Texts.footerHomeHowItWorks} textKey={TextsDeprecated.footerFaq} />
              </Box>
              <Box bottom link spacing={tinySpacing} onClick={() => window.open(PRICING_URL_DEPRECATED, '_blank')}>
                <FigmaText styleTextKey={Texts.footerHomeHowItWorks} textKey={Texts.pricingHeading} />
              </Box>
              <Box direction="row" align="center" spacing={bigSpacing} top>
                <Box right spacing={smallSpacing} link onClick={() => navigate(RoutePath.TERMS_AND_CPNDITIONS)}>
                  <FigmaText styleTextKey={Texts.footerCopyright} textKey={DeprecatedTextKeys.footerTerms} />
                </Box>
                <Box right spacing={smallSpacing}>
                  <FigmaText styleTextKey={Texts.footerCopyright} textKey={DeprecatedTextKeys.footerTerms} text="-" />
                </Box>
                <Box right spacing={smallSpacing} onClick={() => navigate(RoutePath.PRIVACY_POLICY)} link>
                  <FigmaText styleTextKey={Texts.footerCopyright} textKey={DeprecatedTextKeys.footerPrivacy} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.neutral10};
`

export const WhiteVerticalLine = styled(Box)`
  width: 1px;
  height: 100%;
  border-left: 1px solid ${Colors.white};
`
