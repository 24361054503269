import styled from '@emotion/styled'
import { useEffect } from 'react'
import Colors from '../../../../pure/libs/Colors'
import { Texts } from '../../../../pure/libs/AppTexts'
import { VitecNOByggemateOrderViewQueryParams } from '../../../../pure/types/VitecNODerivedTypes.js'
import { BorderRadixes } from '../enums/BorderRadixes'
import { Shadows } from '../enums/Shadows'
import { smallSpacing } from '../enums/Spacings'
import useIsMobile from '../hooks/useIsMobile'
import { BYGGEMATE_WIDTH } from '../libs/HardCodedSizes'
import { captureException } from '../libs/SentryHelper'
import { UploadViewProps } from '../libs/UploadHelper'
import { onClickCopy } from '../libs/VitecSummarizeDocResultHelper'
import useQueryParams from '../libs/useQueryParams'
import Box from './Box'
import { ButtonCopyAd } from './ButtonCopyAd'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { GreyLine } from './StyledComponents'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'
import {
  VitecSummarizeDocResultItem,
  VitecSummarizeDocResultItemProps
} from './VitecNextOrderProductByggemateResultItem'

export const VitecNextOrderProductByggemateResult = ({ uploadedFiles = [] }: UploadViewProps) => {
  const items: VitecSummarizeDocResultItemProps[] = uploadedFiles
    .reduce(
      (a, file) =>
        a.concat(
          Object.entries(file.PropertyAdditionalInfo || {})
            // .filter(([k, v]) => k === 'Byggemåte')
            .map(([k, v]) => ({
              header: k,
              description: v as string,
              file
            }))
        ),
      [] as VitecSummarizeDocResultItemProps[]
    )
    .sort((a, b) => a.header.localeCompare(b.header))

  const file = uploadedFiles[0]

  const qp = useQueryParams<VitecNOByggemateOrderViewQueryParams>()

  useEffect(() => {
    if (qp.copy !== 'true') return
    Promise.resolve()
      .then(() => onClickCopy(items))
      .catch((e) => captureException(e))
  }, [])

  return (
    <Box fullWidth>
      <Box fullWidth fullPadding justify="space-between" direction="row">
        <Box visibility="hidden">
          <VitecNextOrderProductBackButton />
        </Box>
        <Box>
          <Container fullWidth fullPadding>
            <FigmaTextWithStyle
              type="body2"
              color="neutral50"
              textKey={Texts.adCreatorFormSectionHeader}
              text={`📄 ${file.id}`}
            />
            <Box top spacing={smallSpacing} fullWidth>
              <GreyLine color={Colors.neutral50} />
            </Box>
            {items?.map(({ file, header, description }, key) => (
              <VitecSummarizeDocResultItem file={file} description={description} header={header} />
            ))}
            <Box top spacing={smallSpacing} fullWidth>
              <GreyLine color={Colors.neutral50} />
            </Box>
            <Box fullWidth top align="center">
              <ButtonCopyAd onClick={() => onClickCopy(items)} />
            </Box>
          </Container>
        </Box>
        <Box />
      </Box>
    </Box>
  )
}

export const Container = styled(Box)`
  border-radius: ${BorderRadixes.moderate};
  background: ${Colors.white};
  box-shadow: ${Shadows.tiny};
  max-width: ${BYGGEMATE_WIDTH}px;
`
