import React, { useEffect } from 'react'
import { AutocompletePrediction, getPlacePredictionsForExternalSession } from '../libs/GoogleMaps'
import { ExternalSession } from '../../../../pure/types/types'
import { captureException } from '../libs/SentryHelper'

export function useGooglePlaceQueryForExternalSession(externalSession: ExternalSession | undefined) {
  const [googlePlaceQuery, setGooglePlaceQuery] = React.useState<{ loading?: boolean; data?: AutocompletePrediction }>({
    loading: false
  })

  useEffect(() => {
    if (!!googlePlaceQuery.data) return
    if (!externalSession) return
    setGooglePlaceQuery({ loading: true })
    getPlacePredictionsForExternalSession(externalSession)
      .then((data) => setGooglePlaceQuery({ data }))
      .catch((e) => {
        captureException(e)
        setGooglePlaceQuery({ loading: false })
      })
  }, [externalSession])

  return googlePlaceQuery
}
