import { Carousel } from 'react-responsive-carousel'
import { PricingItemInfo } from '../libs/PricingPageHelper'
import Box from './Box'
import { PricingProduct } from './PricingProduct'
import { tinySpacing } from '../enums/Spacings'
import { ProductType } from '../libs/ProductInfo'

export function PricingItemInfoRowMobile({
  onClick,
  productType
}: {
  productType: ProductType
  onClick?: () => unknown
}) {
  return (
    <Box fullWidth>
      <Carousel selectedItem={1}>
        {Object.keys(PricingItemInfo)
          .map((key) => PricingItemInfo[key])
          .map((productInfo, key) => (
            <Box key={key} alignText="left" fullPadding spacing={tinySpacing}>
              <PricingProduct productInfo={productInfo} onClick={onClick} productType={productType} />
            </Box>
          ))}
      </Carousel>
    </Box>
  )
}
