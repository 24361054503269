import styled from '@emotion/styled'
import React from 'react'
import FigmaText from './FigmaText'
import { getFigmaText } from '../libs/TextRepository'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import Box from './Box'
import ButtonBase from './ButtonBase'
import { ButtonProps } from './ButtonPrimary'

const ButtonTertiary: React.FC<ButtonProps> = (props) => {
  const { children, className, textKey, ...buttonProps } = props

  return (
    <StyledButton className={className} {...buttonProps}>
      <Box align="center" fullWidth link>
        {children || (
          <FigmaText
            textKey={TextsDeprecated.cssGenericDialogButtonTertiaryButtonText}
            text={textKey ? getFigmaText(textKey) : undefined}
            textTransform="uppercase"
          />
        )}
      </Box>
    </StyledButton>
  )
}

const StyledButton = styled(ButtonBase)<{ spacing?: string }>``

export default ButtonTertiary
