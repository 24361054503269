import { UseFormReturn, useWatch } from 'react-hook-form'
import { smallSpacing } from '../enums/Spacings'
import { useIsDesktop } from '../hooks/useIsMobile'
import FigmaText from './FigmaText'
import { AdLength, AdLengthInfo } from '../../../../pure/libs/AdLengthHelper'
import { LANGUAGES_OBJECT_CREATE_AD } from '../../../../pure/libs/Consts'
import { JaguarStyleInfo } from '../../../../pure/libs/JaguarHelper'
import { TargetAudienceInfo } from '../../../../pure/libs/TargeAudienceHelper'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../../pure/libs/AppTexts'
import { JaguarRequestForm } from '../../../../pure/types/types'
import { AdCreatorFormSectionMoreOptionsAdLengthSlider } from './AdCreatorFormSectionMoreOptionsAdLengthSlider'
import { AdCreatorFormSectionPopperAndButton } from './AdCreatorFormSectionPopperAndButton'
import Box from './Box'

const AD_LENGTH_VALUES = Object.values(AdLengthInfo).map((v) => v.value)

export function AdCreatorFormSectionMoreOptions({
  formProps,
  enableTargetAudience = false,
  enableShowValueOnMount
}: {
  enableTargetAudience?: boolean
  enableShowValueOnMount?: boolean
  formProps: UseFormReturn<JaguarRequestForm>
}) {
  const adLength = useWatch({ name: 'adLength', control: formProps.control })
  const isCustomAdLength = !AD_LENGTH_VALUES.includes(adLength)
  const customText = isCustomAdLength
    ? `${getFigmaText(Texts.adLengthCustom)} (${adLength} ${getFigmaText(Texts.adLengthCustomWords)})`
    : undefined

  const isDektop = useIsDesktop()
  return (
    <Box fullWidth direction="row" align="center">
      {isDektop && <FigmaText textKey={Texts.adCreatorMoreOptions} textTransform="uppercase" />}
      {enableTargetAudience && (
        <Box left={isDektop} spacing={smallSpacing}>
          <AdCreatorFormSectionPopperAndButton
            options={Object.entries(TargetAudienceInfo).map(([key, textKey]) => ({ value: key, textKey }))}
            defaultTextKey={Texts.targetAudience}
            name="targetAudience"
            formProps={formProps}
          />
        </Box>
      )}
      <Box left={enableTargetAudience} spacing={smallSpacing}>
        <AdCreatorFormSectionPopperAndButton
          options={Object.entries(JaguarStyleInfo).map(([key, value]) => ({ value: key, textKey: value.textKey }))}
          defaultTextKey={Texts.adCreatorStyleButton}
          name="style"
          formProps={formProps}
        />
      </Box>
      <Box left spacing={smallSpacing}>
        <AdCreatorFormSectionPopperAndButton
          options={Object.entries({
            ...AdLengthInfo,
            [AdLength.CUSTOM]: {
              ...AdLengthInfo.CUSTOM,
              Component: (props) => <AdCreatorFormSectionMoreOptionsAdLengthSlider formProps={formProps} {...props} />,
              text: customText,
              isSelected: isCustomAdLength
            }
          }).map(([key, value]) => ({
            value: value.value,
            textKey: value.textKey,
            Component: value.Component,
            text: value.text,
            isSelected: value.isSelected
          }))}
          defaultTextKey={Texts.adCreatorLengthButton}
          buttonText={customText}
          name="adLength"
          formProps={formProps}
        />
      </Box>
      <Box left spacing={smallSpacing}>
        <AdCreatorFormSectionPopperAndButton
          options={Object.entries(LANGUAGES_OBJECT_CREATE_AD).map(([key, value]) => ({
            value: key,
            text: value
          }))}
          defaultTextKey={Texts.adCreatorLanguageButton}
          name="languageCode"
          formProps={formProps}
        />
      </Box>
    </Box>
  )
}
