import styled from '@emotion/styled'
import React from 'react'
import { isPropValid } from '../libs/EmotionHelper'
import { LEAD_USER_CONATINER_SIZE, USER_WIDGET_SIZE } from '../libs/HardCodedSizes'
import FigmaText from './FigmaText'
import Colors from '../../../../pure/libs/Colors'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { getInitials } from '../../../../pure/libs/UserWidgetHelper'
import { TextKey } from '../../../../pure/types/Antiloop'
import Box from './Box'
import { PersonIcon } from './Icons'
import PersonalPhotoView from './PersonalPhotoView'
import UserWidgetMenu from './UserWidgetMenu'
import useAppState from '../hooks/useAppState'
import { useUser } from '../hooks/QueryHooks'
import { USER_WIDGET_MENU_ID } from '../../../../pure/libs/Consts'

type Options = { enablePhoto?: boolean }

export default function UserWidget({
  opts,
  disabled,
  size,
  ref
}: {
  opts?: Options
  disabled?: boolean
  size?: number
  ref?
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>()
  const { state } = useAppState()
  const { user } = state

  const onClick = (e) => !disabled && setAnchorEl(e.currentTarget)

  if (!user) return null
  return (
    <>
      <Box
        onClick={onClick}
        id={USER_WIDGET_MENU_ID /** used by keywords drawer, dont have two nodes in dom withthis id */}
      >
        <UserWidgetView userId={user._id} opts={opts} disabled={disabled} size={size} />
      </Box>
      <UserWidgetMenu anchorEl={anchorEl} onClose={() => setAnchorEl(undefined)} />
    </>
  )
}

export const UserWidgetViewLead = ({
  userId,
  size = LEAD_USER_CONATINER_SIZE,
  opts
}: {
  userId: string
  size?: number
  opts?: Options
}) => <UserWidgetView userId={userId} size={size} textKey={TextsDeprecated.allTooltipText} link={false} opts={opts} />

export const UserWidgetViewCelebrationItem = UserWidgetViewLead

export const UserWidgetView = ({
  userId,
  size = USER_WIDGET_SIZE,
  textKey = TextsDeprecated.userMenuNamePrefixCss,
  link = true,
  opts,
  disabled
}: {
  userId: string
  size?: number
  textKey?: TextKey
  link?: boolean
  opts?: Options
  disabled?: boolean
}) => {
  return (
    <Container align="center" justify="center" pointer={link} size={size} disabled={disabled}>
      <UserWidgetContent userId={userId} size={size} textKey={textKey} opts={opts} />
    </Container>
  )
}

export const UserWidgetContent = ({
  userId,
  size,
  textKey,
  opts
}: {
  userId: string
  size: number
  textKey: TextKey
  opts?: Options
}) => {
  const { data: user } = useUser(userId)

  const initials = getInitials(user?.name || '')

  if (!!user?.profileImage && opts?.enablePhoto !== false) return <PersonalPhotoView user={user} size={size} />
  if (!!initials) return <FigmaText textKey={textKey} text={initials} />

  return <PersonIcon size={size} />
}

const Container = styled(Box, { shouldForwardProp: isPropValid })<{
  size: number
  $backgroundColor?: string
  disabled?: boolean
}>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => size / 2}px;
  opacity: ${({ disabled }) => (!!disabled ? 0.5 : 1)};
  background-color: ${Colors.neutral40};
`
