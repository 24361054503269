/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-restricted-globals */
import Menu from '@mui/material/Menu'
import { useNavigate } from 'react-router-dom'
import RoutePath from '../../pure/libs/RoutePath'
import { MenuItem } from './src/components/MenuItem'
import { Texts } from '../../pure/libs/AppTexts'
import { TextsDeprecated } from '../../pure/libs/TextsDeprecated'
import { getFigmaText } from './src/libs/TextRepository'

type Props = {
  anchorEl?: HTMLElement
  onClose: (e?) => void
}

export default function MenuLoggedInMobileMenu(props: Props) {
  const { anchorEl, onClose } = props
  const navigate = useNavigate()

  return (
    <div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
        {/**  DUPLICATED Also see MenuLoggedInMobileMenu.tsx */}
        <MenuItem onClick={() => navigate(RoutePath.ROOT)} text={getFigmaText(TextsDeprecated.menuHome)} />
        <MenuItem onClick={() => navigate(RoutePath.CREATE_AD)} text={getFigmaText(Texts.headerCreateAd)} />
        <MenuItem onClick={() => navigate(RoutePath.PRICING)} text={getFigmaText(TextsDeprecated.menuPricing)} />
      </Menu>
    </div>
  )
}
