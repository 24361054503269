import React from 'react'
import Box from './Box'
import { Layout } from './LayoutExperimental'

import { reallyBigSpacing } from '../enums/Spacings'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import { useVitecNextEstateForOrder } from '../hooks/useVitecNextEstateForOrder'
import { capitalize } from '../../../../pure/libs/Common'
import { Texts } from '../../../../pure/libs/AppTexts'
import FigmaTextLoader from './FigmaTextLoader'
import MenuVitecNext from './MenuVitecNext'
import { VitecNextOrderProductButton } from './VitecNextOrderProductButton'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'

// test Data: http://localhost:5173/vitec-megler-landing?orderId=0d8e882e-20a2-4e68-a263-dffbac93be87
// Prod: https://app.gazelle.work/vitec-megler-landing?orderId=0d8e882e-20a2-4e68-a263-dffbac93be87

export const VitecNextOrder: React.FC<{ vitecNextOrderId: string }> = ({ vitecNextOrderId }) => {
  const { data: vitecNOOrder } = useVitecNOOrder(vitecNextOrderId)

  const { data: estate, isLoading } = useVitecNextEstateForOrder({ orderId: vitecNextOrderId, useCache: true })
  const address = [estate?.address?.streetAdress, estate?.address?.apartmentNumber, estate?.address?.city]
    .filter((s) => s)
    .map((s) => capitalize(s?.toLocaleLowerCase()))
    .join(', ')

  return (
    <Layout enableFooter={false} MenuComponent={MenuVitecNext} enableRightBottomImage={false}>
      <Box fullWidth align="center">
        <Box fullWidth align="center" top spacing={reallyBigSpacing}>
          <Box>
            <FigmaTextLoader textKey={Texts.orderViewHeader} text={address} loading={isLoading} />
          </Box>
        </Box>
        <Box direction="row" fullHeight top spacing={reallyBigSpacing} align="center">
          {vitecNOOrder &&
            Object.keys(VitecNextProductInfoItems).map((v) => (
              <VitecNextOrderProductButton vitecNOOrder={vitecNOOrder} vitecNextProduct={v as VitecNextProduct} />
            ))}
        </Box>
      </Box>
    </Layout>
  )
}
