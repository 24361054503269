/* eslint-disable prettier/prettier */
// @flow
import { collection, QueryConstraint, query } from 'firebase/firestore'
import { ServerFirebaseUser } from '../../../../pure/types/ServerTypes.js'
import { BaseObject, DB } from '../../../../pure/types/types.js'
import Collections from '../../../../pure/libs/Collections.js'
import * as FirebaseStorageClientBase from './FirebaseStorageClientBase.js'
import * as mapSnapshot from "../../../../pure/libs/FirebaseStorageClientBaseHelper.js"
import { State } from '../hooks/useAppState.js'
import { db } from './Firebase.js'

const toServerUser = ({user}: State) => ({uid: user._id}) as ServerFirebaseUser

export const createObject = <T>(object: T, collection: string, state: State): Promise<T> => FirebaseStorageClientBase.createObject({ ...mapSnapshot.toBaseObject(toServerUser(state)), ...object }, toServerUser(state), collection, db)
export const addObject = <T>(object: T, collection: string): Promise<T> => FirebaseStorageClientBase.addObject(object, collection, db)
export const updateObject = <T extends BaseObject>(object: T, collection: string): Promise<T> => FirebaseStorageClientBase.updateObject(object, collection, db)
export const removeObject = <T extends BaseObject>(object: T, collection: string): Promise<void> => FirebaseStorageClientBase.removeObject(object._id, collection, db)
  
export const getObject = (_id: string, collection: string) => FirebaseStorageClientBase.getObject(_id, collection, db)

export const getObjectQuery = (_id: string, collection: Collections) => FirebaseStorageClientBase.getObjectQuery(db, collection, _id)

export const getObjectsQuery = (_collection: string) => collection(db, _collection)

export const getAllUsersForUserQuery = (orgId: string) => FirebaseStorageClientBase.getAllUsersForUserQuery(db, orgId)

export const getAllObjectsWithUserIdQuery = (uid: string, collection: Collections, queryContraits: QueryConstraint[] = []) => FirebaseStorageClientBase.getAllObjectsWithUserIdQuery(uid, collection, db, queryContraits)

export const getQuery = (path: Collections, queryContraits: QueryConstraint[] = []) => FirebaseStorageClientBase.getQuery(path, db, queryContraits)