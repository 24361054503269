import styled from '@emotion/styled'
import { useCurrentProduct } from '../hooks/useCurrentProduct'
import Colors from '../../../../pure/libs/Colors'
import { KeywordHouseType } from '../../../../pure/libs/KeyWordsHelper'
import { PlaceType } from '../../../../pure/types/GooglePlace'
import { Ad } from '../../../../pure/types/types'
import { AdCreatorAdProps } from './AdCreatorAd'
import { AdCreatorForm } from './AdCreatorForm'
import Box from './Box'
import { KeywordsDrawerProps } from './KeywordsDrawer'
import Layout from './Layout'
import { JaguarKeywordMapItem } from '../../../../pure/types/JaguarTypes'

export interface AdCreatorPageProps {
  enableUpsell?: boolean
  AdCreatorAdCTAComponent?: React.FC<AdCreatorAdProps>
  enableUpsellOnAdCreator?: boolean
  ad?: Ad
  extra?: Ad['extra']
  enableCopyAd?: boolean
  enableIsOverQuotaCheck?: boolean
  locationAsHeadlineText?: string
  googlePlace?: PlaceType
  enableMenu?: boolean
  MenuComponent?: React.FC
  enableLatestAds?: boolean
  enableTutorial?: boolean
  houseType?: KeywordHouseType
  onAdCompleted?: (ad: Ad) => unknown
  enableRightBottomImage?: boolean
  enableUpdateAd?: boolean
  KeywordsDrawer?: React.FC<KeywordsDrawerProps>
  keywordMapItems?: JaguarKeywordMapItem[]
  propertyInfo?: string
  enableAutoStart?: boolean
  showKeywords?: boolean
  logAdInJaguar?: boolean
  enableAdCreatorForm?: boolean
}

export const AdCreatorPageMobile = (props: AdCreatorPageProps) => {
  const { data: p } = useCurrentProduct()

  return (
    <Layout enableFooter={false}>
      <ContainerWithGradient fullWidth fullHeight>
        <Box fullPadding fullWidth>
          <AdCreatorForm {...props} />
        </Box>
      </ContainerWithGradient>
    </Layout>
  )
}

export const ContainerWithGradient = styled(Box)`
  min-height: 100vh;
  background: var(--LinearVertical, linear-gradient(180deg, ${Colors.primary100} 0%, ${Colors.secondary100} 100%));
`

export default AdCreatorPageMobile
