import Box from './Box'
import Layout from './Layout'
import SettingsMyOrganization from './SettingsMyOrganization'

export default function MyOrganizationPage() {
  return (
    <Layout>
      <Box fullWidth align="center" bottom>
        <SettingsMyOrganization />
      </Box>
    </Layout>
  )
}
