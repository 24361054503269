import styled from '@emotion/styled'
import { bigSpacing, minSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useIsMobile from '../hooks/useIsMobile'
import { StatusRowStatus, getStatusRows, useProcessingStep } from '../libs/UploadLoadingHelper'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import Colors from '../../../../pure/libs/Colors'
import { toPx } from '../../../../pure/libs/Common'
import Images from '../../../../pure/libs/AppImages'
import { TextStyles } from '../../../../pure/libs/TextStyles'
import { Texts } from '../../../../pure/libs/AppTexts'
import Box from './Box'
import FigmaImage from './FigmaImage'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { UploadLoadingCheckIcon } from './Icons'
import Loader from './Loader'
import { UploadViewProps } from '../libs/UploadHelper'
import Button from './Button'
import { getFigmaText } from '../libs/TextRepository'
import { getTextWithDots } from './ProductsSectionInfoRow'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'

export const UploadLoading = ({
  uploadedFiles = [],
  onClickUploadFile: onClickStartNewUpload,
  headerTextKey = Texts.overviewLoadingHeader
}: UploadViewProps) => {
  const isMobile = useIsMobile()
  const processingStep = useProcessingStep()
  const error = uploadedFiles[0]?.error
  const fileNames = getTextWithDots(uploadedFiles.map((file) => file.id).join(', '), 50)

  return (
    <Box fullWidth align="center" alignText="center">
      <Box align="center">
        <FigmaImage imageKey={Images.blueGazelleWhiteBackground} />
        <Box top>
          <FigmaTextWithLinebreaks styleTextKey={Texts.uploadV2Header} textKey={headerTextKey} />
        </Box>
        <Box fullWidth fullPadding>
          {getStatusRows(processingStep).map(({ textKey, status, step }, key, arr) => (
            <Box key={key} justify="space-between" direction="row" fullWidth top spacing={tinySpacing} align="center">
              <FigmaTextWithStyle
                type="body2"
                color="white"
                textKey={textKey}
                text={key === 0 && textKey ? `${getFigmaText(textKey)} (${fileNames})` : undefined}
                opacity={status === 'not_started' ? 0.3 : 1}
              />
              <UploadLoadingStatusRow key={key} status={status} error={error} />
            </Box>
          ))}
        </Box>
        {!!error && (
          <Box fullWidth>
            <FigmaText textKey={Texts.uploadLoadingGenericError} />
            <Box top fullWidth align="center">
              <Button variant="outlined" onClick={() => onClickStartNewUpload()}>
                <FigmaText textKey={Texts.uploadLoadingErrorButton} />
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const UploadLoadingStatusRow = ({ status, error }: { status: StatusRowStatus; error?: string }) => {
  let Component = StatusContainer
  if (status === 'done') Component = DoneContainer
  if (status === 'processing') Component = InProgressContainer
  if (status === 'processing' && error) Component = FailedContainer

  return (
    <Component direction="row" align="center">
      <Box>
        {status === 'processing' && !error && <Loader size={TextStyles.body2.style.desktop.fontSize} />}
        {status === 'done' && <UploadLoadingCheckIcon size={toPx(TextStyles.body2.style.desktop.fontSize)} />}
      </Box>

      <Box left right spacing={tinySpacing}>
        {status === 'processing' && !error && <FigmaText textKey={Texts.uploadLoadingStateInProgress} />}
        {status === 'processing' && !!error && <FigmaText textKey={Texts.uploadLoadingStateFailed} />}
        {status === 'done' && <FigmaText textKey={Texts.uploadLoadingStateDone} />}
      </Box>
    </Component>
  )
}

const StatusContainer = styled(Box)`
  border-radius: 1.5rem;
  padding: ${minSpacing} ${tinySpacing};
`

const DoneContainer = styled(StatusContainer)`
  background: ${Colors.success40};
`
const InProgressContainer = styled(StatusContainer)`
  background: ${Colors.primary20};
`

const FailedContainer = styled(StatusContainer)`
  background: ${Colors.error100};
`
