import styled from '@emotion/styled'
import invariant from 'invariant'
import { useState } from 'react'
import { UploadedFileInfoMetadata } from '../../../../pure/leopard/UploadedFileInfoForServer.js'
import { Texts } from '../../../../pure/libs/AppTexts.js'
import { UploadedFile } from '../../../../pure/types/JaguarTypes'
import { Shadows } from '../enums/Shadows'
import { mediumSpacing, smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useNotification from '../hooks/useNotification'
import { deliverViteNOCaptions } from '../libs/CloudFunctionsApiHandler'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import FigmaTextLoader from './FigmaTextLoader'
import { InfoMapLocationButtonView } from './InfoMapLocationButton'
import { VitecNOOrder } from '../../../../pure/types/VitecNODerivedTypes.js'

export const VitecLandingImageCaptionsLeftColumn = ({
  uploadedFiles: _uploadedFiles,
  vitecNOOrder
}: {
  vitecNOOrder?: VitecNOOrder
  uploadedFiles: UploadedFile[]
}) => {
  const { state } = useAppState()
  const { installationId, estateId } = vitecNOOrder || {}

  const uploadedFiles = _uploadedFiles
    .filter(({ metaData }) => metaData)
    .filter((v) => v.metaData?.checked !== false)
    .filter((v) => getImageDescription(v).length > 0)

  const length = uploadedFiles.length

  const { success } = useNotification()
  const [loading, setLoading] = useState(false)

  const onClick = () => {
    setLoading(true)
    invariant(vitecNOOrder, 'vitecNOOrder is required')
    invariant(installationId, 'installationId is required')
    invariant(estateId, 'estateId is required')
    const rows = uploadedFiles.map((b) => {
      const _metaData = b.metaData as UploadedFileInfoMetadata
      const imageDescription = getImageDescription(b)
      invariant(imageDescription, 'imageDescription is required')

      return {
        installationId,
        estateId,
        imageId: _metaData?.externalId as string,
        imageDescription,
        orderId: vitecNOOrder.orderId
      }
    })

    return deliverViteNOCaptions({ rows, orderId: vitecNOOrder.orderId }, state)
      .then(() => success({ text: `${length} bildetekst oppdatert` }))
      .catch(captureAndNotifyError)
      .finally(() => setLoading(false))
  }
  return (
    <Container fullPadding>
      <FigmaText textKey={Texts.captionLeftWidgetHeader} />
      <Box fullWidth top>
        <InnerContainer fullPadding spacing={mediumSpacing} fullWidth>
          <FigmaText textKey={Texts.captionLeftWidgetDescription} />
          <Box top fullWidth align="center">
            <Box>{false && <InfoMapLocationButtonView text="Sjöstigen 4, Lidingö, Sweden" />}</Box>
          </Box>
          <Box top fullWidth>
            <Button verticalSpacing={smallSpacing} fullWidth loading={loading} onClick={onClick}>
              <FigmaTextLoader
                textKey={Texts.captionLeftWidgetButton}
                text={`Send ${length} bildetekster till Next`}
                loading={loading}
              />
            </Button>
          </Box>
        </InnerContainer>
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  border-radius: 10px;
  background: var(--White, #fff);
  min-width: 475px;
`

const InnerContainer = styled(Box)`
  border-radius: 16px;
  background: var(--Neutral10, #f9fafa);

  /* Big */
  box-shadow: ${Shadows.big};
`

function getImageDescription(b: UploadedFile): string {
  const { metaData, PropertyAdditionalInfo } = b
  const imageDescription = metaData?.userEditedText || PropertyAdditionalInfo || ''
  return imageDescription
}
