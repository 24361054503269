import path from 'path'
import RoutePath from '../../../../pure/libs/RoutePath'

export const isSelectedPage = ({
  window,
  routePath,
  isDefault
}: {
  window: Window
  routePath?: RoutePath
  isDefault?: boolean
}) => {
  const pathname = window.location.pathname
  if (pathname === '/' && isDefault) return true
  return window.location.pathname === routePath
}
