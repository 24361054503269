import { useEffect, useRef } from 'react'
import { smallSpacing } from '../enums/Spacings'
import { asArray } from '../../../../pure/libs/Common'
import Images from '../../../../pure/libs/AppImages'
import { Texts } from '../../../../pure/libs/AppTexts'
import { Ad, JaguarSession } from '../../../../pure/types/types'
import { AdCreatorAdSection, OnClickRegenerateAd, OnUpdateSession } from './AdCreatorAdSection'
import { AdCreatorPageProps } from './AdCreatorPageMobile'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { Rating } from './Rating'

const SCROLL_TIMEOUT = 1000

export type AdCreatorAdProps = {
  ad: Ad
  sessions: {
    [jaguarType: string]: JaguarSession
  }
  onClickCreateNewAd: () => unknown
  onClickRegenerateAd?: OnClickRegenerateAd
  onUpdateSession: OnUpdateSession
  adCreatorPageProps?: AdCreatorPageProps
}

export function AdCreatorAd(props: AdCreatorAdProps) {
  const { ad, sessions, adCreatorPageProps = {}, onUpdateSession } = props
  const { AdCreatorAdCTAComponent } = adCreatorPageProps
  const hasCompletedAd = Object.values(sessions).some((s) => s.status === 'completed')
  const shouldShowRating = hasCompletedAd

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }, SCROLL_TIMEOUT)
  }, [])

  return (
    <Box fullWidth>
      <Box fullWidth direction="row" align="center" top>
        <FigmaImageContainer imageKey={Images.adCreatorAdGazelle} />
        <Box left spacing={smallSpacing}>
          <FigmaTextWithStyle type="body4" textKey={Texts.adCreatorAdFreshlyNewAd} color="white" />
        </Box>
      </Box>
      <Box top fullWidth>
        {asArray({ ...ad.activeSessions, ...sessions }).map((session, index) => {
          return (
            <Box key={index} top={index > 0} fullWidth>
              <AdCreatorAdSection
                key={index}
                index={index + 1}
                session={session as JaguarSession}
                // onClickRegenerateAd={onClickRegenerateAd}
                adCreatorPageProps={adCreatorPageProps}
                onUpdateSession={onUpdateSession}
                ad={ad}
              />
            </Box>
          )
        })}
      </Box>
      <Box ref={ref} />
      {shouldShowRating && (
        <Box top fullWidth align="center">
          <Rating jaguarSessions={sessions} />
        </Box>
      )}
      {AdCreatorAdCTAComponent && <AdCreatorAdCTAComponent {...props} />}
    </Box>
  )
}
