import styled from '@emotion/styled'
import dayjs from 'dayjs'
import invariant from 'invariant'
import { useState } from 'react'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { useOrganization } from '../hooks/QueryHooks'
import useAppState from '../hooks/useAppState'
import FigmaText from './FigmaText'
import { YYYYMMDD_HHmm } from '../../../../pure/libs/Consts'
import { Texts } from '../../../../pure/libs/AppTexts'
import { JaguarSession } from '../../../../pure/types/types'
import { AdCreatorLatestAdsItemSession } from './AdCreatorLatestAdsItemSession'
import { getNewLikedSession } from './AdCreatorThumbsHelper'
import Box from './Box'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { DEFAULT_ICON_SIZE, LatestAdsRemoveIcon } from './Icons'
import { updateOrganization } from '../libs/DBApiHandler'

export function SettingsAIWriterLikedSession({ session }: { session: JaguarSession }) {
  const headline = session.headline || ''
  const [isHovering, setIsHovering] = useState(false)
  const { state } = useAppState()
  const { data: org } = useOrganization(state.user.organizationId)

  const onMouseEnter = () => setIsHovering(true)
  const onMouseLeave = () => setIsHovering(false)
  const onClickRemoveAd = () => {
    invariant(org, '!org')
    updateOrganization({ ...org, likedSessions: getNewLikedSession(session, org) })
  }

  return (
    <Container
      fullPadding
      spacing={smallSpacing}
      fullWidth
      position="relative"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Box
        position="absolute"
        style={{ top: -DEFAULT_ICON_SIZE, right: -DEFAULT_ICON_SIZE / 2, display: isHovering ? 'block' : 'none' }}
        pointer
        onClick={onClickRemoveAd}
      >
        <LatestAdsRemoveIcon />
      </Box>
      <FigmaText textKey={Texts.latestAdsDate} text={dayjs(session.createdAt).format(YYYYMMDD_HHmm)} />
      <Box top spacing={tinySpacing}>
        <FigmaTextWithStyle type="body3" text={headline} />
      </Box>
      <Box top spacing={tinySpacing} fullWidth>
        <Box spacing={tinySpacing} fullWidth>
          <Box top spacing={tinySpacing} fullWidth>
            <AdCreatorLatestAdsItemSession session={session} />
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
`
