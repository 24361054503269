
import {ReactComponent as iconGoogleImg} from '../assets/images/iconGoogle.svg'
export const iconGoogle = iconGoogleImg 
import {ReactComponent as iconLinkedinImg} from '../assets/images/iconLinkedin.svg'
export const iconLinkedin = iconLinkedinImg 
import {ReactComponent as menuLogoImg} from '../assets/images/menuLogo.svg'
export const menuLogo = menuLogoImg 
import {ReactComponent as office365Img} from '../assets/images/office365.svg'
export const office365 = office365Img 
import {ReactComponent as gbFlagRoundedImg} from '../assets/images/gbFlagRounded.svg'
export const gbFlagRounded = gbFlagRoundedImg 
import {ReactComponent as sweFlagRoundedImg} from '../assets/images/sweFlagRounded.svg'
export const sweFlagRounded = sweFlagRoundedImg 
import pngFasadLogoImg from '../assets/images/pngFasadLogo.png'
export const pngFasadLogo = pngFasadLogoImg 
import {ReactComponent as iconLinkedinSmallImg} from '../assets/images/iconLinkedinSmall.svg'
export const iconLinkedinSmall = iconLinkedinSmallImg 
import {ReactComponent as iconInstagramSmallImg} from '../assets/images/iconInstagramSmall.svg'
export const iconInstagramSmall = iconInstagramSmallImg 
import {ReactComponent as iconYouTubeSmallImg} from '../assets/images/iconYouTubeSmall.svg'
export const iconYouTubeSmall = iconYouTubeSmallImg 
import {ReactComponent as googleIconSmallImg} from '../assets/images/googleIconSmall.svg'
export const googleIconSmall = googleIconSmallImg 
import {ReactComponent as pricingMostPopularLegacyImg} from '../assets/images/pricingMostPopularLegacy.svg'
export const pricingMostPopularLegacy = pricingMostPopularLegacyImg 
import {ReactComponent as iconTwitterSmallImg} from '../assets/images/iconTwitterSmall.svg'
export const iconTwitterSmall = iconTwitterSmallImg 
import {ReactComponent as companyMixedLogoImg} from '../assets/images/companyMixedLogo.svg'
export const companyMixedLogo = companyMixedLogoImg 
import {ReactComponent as iconAddPersonImg} from '../assets/images/iconAddPerson.svg'
export const iconAddPerson = iconAddPersonImg 
import {ReactComponent as closeIconImg} from '../assets/images/closeIcon.svg'
export const closeIcon = closeIconImg 
import {ReactComponent as adCreatorAdGazelleImg} from '../assets/images/adCreatorAdGazelle.svg'
export const adCreatorAdGazelle = adCreatorAdGazelleImg 
import {ReactComponent as overviewAdIconSmallImg} from '../assets/images/overviewAdIconSmall.svg'
export const overviewAdIconSmall = overviewAdIconSmallImg 
import {ReactComponent as interiorAdIconSmallImg} from '../assets/images/interiorAdIconSmall.svg'
export const interiorAdIconSmall = interiorAdIconSmallImg 
import {ReactComponent as exteriorAdIconSmallImg} from '../assets/images/exteriorAdIconSmall.svg'
export const exteriorAdIconSmall = exteriorAdIconSmallImg 
import {ReactComponent as areaAdIconSmallImg} from '../assets/images/areaAdIconSmall.svg'
export const areaAdIconSmall = areaAdIconSmallImg 
import {ReactComponent as associationIconSmallImg} from '../assets/images/associationIconSmall.svg'
export const associationIconSmall = associationIconSmallImg 
import {ReactComponent as trainModelImg} from '../assets/images/trainModel.svg'
export const trainModel = trainModelImg 
import {ReactComponent as myAgencyImg} from '../assets/images/myAgency.svg'
export const myAgency = myAgencyImg 
import {ReactComponent as pricingMostPopularBgImg} from '../assets/images/pricingMostPopularBg.svg'
export const pricingMostPopularBg = pricingMostPopularBgImg 
import {ReactComponent as starImg} from '../assets/images/star.svg'
export const star = starImg 
import {ReactComponent as checkIconImg} from '../assets/images/checkIcon.svg'
export const checkIcon = checkIconImg 
import {ReactComponent as enterpriseImg} from '../assets/images/enterprise.svg'
export const enterprise = enterpriseImg 
import {ReactComponent as personImg} from '../assets/images/person.svg'
export const person = personImg 
import {ReactComponent as adCreatorSpecialOfferImg} from '../assets/images/adCreatorSpecialOffer.svg'
export const adCreatorSpecialOffer = adCreatorSpecialOfferImg 
import {ReactComponent as selectionMenuArrowImg} from '../assets/images/selectionMenuArrow.svg'
export const selectionMenuArrow = selectionMenuArrowImg 
import {ReactComponent as overviewAdIconImg} from '../assets/images/overviewAdIcon.svg'
export const overviewAdIcon = overviewAdIconImg 
import {ReactComponent as productsArrowRightImg} from '../assets/images/productsArrowRight.svg'
export const productsArrowRight = productsArrowRightImg 
import {ReactComponent as interiorIconImg} from '../assets/images/interiorIcon.svg'
export const interiorIcon = interiorIconImg 
import {ReactComponent as exteriorIconImg} from '../assets/images/exteriorIcon.svg'
export const exteriorIcon = exteriorIconImg 
import {ReactComponent as areaIconImg} from '../assets/images/areaIcon.svg'
export const areaIcon = areaIconImg 
import {ReactComponent as associationIconImg} from '../assets/images/associationIcon.svg'
export const associationIcon = associationIconImg 
import {ReactComponent as uploadGazelleIconBigImg} from '../assets/images/uploadGazelleIconBig.svg'
export const uploadGazelleIconBig = uploadGazelleIconBigImg 
import {ReactComponent as pricingBackgroundGazelleLogoImg} from '../assets/images/pricingBackgroundGazelleLogo.svg'
export const pricingBackgroundGazelleLogo = pricingBackgroundGazelleLogoImg 
import pngGazelleArrowFasadImg from '../assets/images/pngGazelleArrowFasad.png'
export const pngGazelleArrowFasad = pngGazelleArrowFasadImg 
import pngTutorialStepWelcomeImg from '../assets/images/pngTutorialStepWelcome.png'
export const pngTutorialStepWelcome = pngTutorialStepWelcomeImg 
import pngTutorialStepTypesHeaderImg from '../assets/images/pngTutorialStepTypesHeader.png'
export const pngTutorialStepTypesHeader = pngTutorialStepTypesHeaderImg 
import pngTutorialStepAddressHeaderImg from '../assets/images/pngTutorialStepAddressHeader.png'
export const pngTutorialStepAddressHeader = pngTutorialStepAddressHeaderImg 
import pngTutorialStepKeywordHeaderImg from '../assets/images/pngTutorialStepKeywordHeader.png'
export const pngTutorialStepKeywordHeader = pngTutorialStepKeywordHeaderImg 
import pngTutorialStepOptionImg from '../assets/images/pngTutorialStepOption.png'
export const pngTutorialStepOption = pngTutorialStepOptionImg 
import pngTutorialStepEditSectionImg from '../assets/images/pngTutorialStepEditSection.png'
export const pngTutorialStepEditSection = pngTutorialStepEditSectionImg 
import pngTutorialStepSendToFasadImg from '../assets/images/pngTutorialStepSendToFasad.png'
export const pngTutorialStepSendToFasad = pngTutorialStepSendToFasadImg 
import pngTutorialStepFasadImg from '../assets/images/pngTutorialStepFasad.png'
export const pngTutorialStepFasad = pngTutorialStepFasadImg 
import pngTutorialEditSectionImg from '../assets/images/pngTutorialEditSection.png'
export const pngTutorialEditSection = pngTutorialEditSectionImg 
import {ReactComponent as uploadGazelleIconImg} from '../assets/images/uploadGazelleIcon.svg'
export const uploadGazelleIcon = uploadGazelleIconImg 
import pngLoginPageInteriorImg from '../assets/images/pngLoginPageInterior.png'
export const pngLoginPageInterior = pngLoginPageInteriorImg 
import pngLoginPageGeneralImg from '../assets/images/pngLoginPageGeneral.png'
export const pngLoginPageGeneral = pngLoginPageGeneralImg 
import pngLoginPageExteriorImg from '../assets/images/pngLoginPageExterior.png'
export const pngLoginPageExterior = pngLoginPageExteriorImg 
import pngLoginPageAreaImg from '../assets/images/pngLoginPageArea.png'
export const pngLoginPageArea = pngLoginPageAreaImg 
import {ReactComponent as gazelleIconBlueRoundImg} from '../assets/images/gazelleIconBlueRound.svg'
export const gazelleIconBlueRound = gazelleIconBlueRoundImg 
import pngCityImg from '../assets/images/pngCity.png'
export const pngCity = pngCityImg 
import {ReactComponent as companyMixedLogoWhiteImg} from '../assets/images/companyMixedLogoWhite.svg'
export const companyMixedLogoWhite = companyMixedLogoWhiteImg 
import {ReactComponent as locationOnImg} from '../assets/images/locationOn.svg'
export const locationOn = locationOnImg 
import {ReactComponent as infomapIconsImg} from '../assets/images/infomapIcons.svg'
export const infomapIcons = infomapIconsImg 
import {ReactComponent as blueGazelleWhiteBackgroundImg} from '../assets/images/blueGazelleWhiteBackground.svg'
export const blueGazelleWhiteBackground = blueGazelleWhiteBackgroundImg 
import {ReactComponent as orderViewButtonBildetekstImg} from '../assets/images/orderViewButtonBildetekst.svg'
export const orderViewButtonBildetekst = orderViewButtonBildetekstImg 
import {ReactComponent as orderViewButtonByggemateImg} from '../assets/images/orderViewButtonByggemate.svg'
export const orderViewButtonByggemate = orderViewButtonByggemateImg 
import {ReactComponent as orderViewButtonShortAndLongImg} from '../assets/images/orderViewButtonShortAndLong.svg'
export const orderViewButtonShortAndLong = orderViewButtonShortAndLongImg 
import {ReactComponent as orderViewButtonAdValidationImg} from '../assets/images/orderViewButtonAdValidation.svg'
export const orderViewButtonAdValidation = orderViewButtonAdValidationImg 