import { useNavigate } from 'react-router-dom'
import { smallSpacing } from '../enums/Spacings'
import { useUser } from '../hooks/QueryHooks'
import useAppState, { useUserId } from '../hooks/useAppState'
import { useCurrentProduct } from '../hooks/useCurrentProduct'
import { useOverQuota } from '../libs/CreateAdHelper'
import { ProductInfo } from '../libs/ProductInfo'
import FigmaText from './FigmaText'
import Colors from '../../../../pure/libs/Colors'
import RoutePath from '../../../../pure/libs/RoutePath'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../../pure/libs/AppTexts'
import { AdCreatorPageProps } from './AdCreatorPageMobile'
import { Badge } from './Badge'
import Box from './Box'
import FigmaTextWithStyleOverrides from './FigmaTextWithStyleOverrides'

export function AdCreatorAdUpsellInfo(props: AdCreatorPageProps) {
  const { enableIsOverQuotaCheck = true } = props
  const { state } = useAppState()
  const { data: product } = useCurrentProduct()
  const { data: user = state.user } = useUser(useUserId())
  const isOverQuota = useOverQuota(user, product) && enableIsOverQuotaCheck

  if (!!isOverQuota) return <AdCreatorAdUpsellInfoFineprint />

  return (
    <Box direction="row" align="center">
      <FigmaText textKey={Texts.adCreatorAdTopUpsell} />
      <Box left spacing={smallSpacing}>
        <Badge
          textKey={Texts.adCreatorAdTopUpsellBadgeLegacy}
          text={formatText(getFigmaText(Texts.adCreatorAdTopUpsellBadgeLegacy), [ProductInfo.FREE.noOfFreeAdsText])}
        />
      </Box>
    </Box>
  )
}

export function AdCreatorAdUpsellInfoFineprint() {
  const navigate = useNavigate()
  return (
    <Box onClick={() => navigate(RoutePath.PRICING)} multiline alignText="center">
      <FigmaTextWithStyleOverrides
        color={Colors.white}
        textKeys={{
          en: Texts.adCreatorButtonUpsellFineprint,
          sv: Texts.adCreatorButtonUpsellFineprintSv
        }}
      />
    </Box>
  )
}
