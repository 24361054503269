import { addQueryParamsToUrl } from '../hooks/useQueryparamHelper'
import { getActivePrice } from '../hooks/useStripePrice'
import * as Stripe from '../stripe'
import { app } from './Firebase'
import { StripeProduct } from './ProductInfo'

const DefaultStripePriceId = 'price_1NDSoiHlb1dmBncHri205VmN' // 998 SEK https://dashboard.stripe.com/products/prod_NzRiHXSOgQDZPh

export const payments = Stripe.getStripePayments(app, {
  productsCollection: 'products',
  customersCollection: 'users'
})

const DEFAULT_SESSION_CREATE_PARAMS = {
  automatic_tax: true,
  trial_from_plan: false,
  success_url: addQueryParamsToUrl(window.location.href, { stripeSuccess: true }),
  cancel_url: addQueryParamsToUrl(window.location.href, { stripeSuccess: false })
} as Stripe.SessionCreateParams

export const createCheckoutSession = (stripeProductId: StripeProduct, params = DEFAULT_SESSION_CREATE_PARAMS) =>
  Stripe.getProduct(payments, stripeProductId)
    .then((product) => Stripe.getPrices(payments, product.id))
    .then((prices) =>
      Stripe.createCheckoutSession(payments, { ...params, price: getActivePrice(prices)?.id || DefaultStripePriceId })
    )
