import { TextFieldProps } from '@mui/material'
import ZIndices from '../enums/Zindices'
import { ESC_KEYCODE } from '../../../../pure/libs/Consts'
import { AdCreatorAdSectionTextFieldMenu } from './AdCreatorAdSectionResponseTextFieldMenu'
import { AdCreatorAdSectionTextField } from './AdCreatorAdSectionTextField'
import Box from './Box'

export function AdCreatorAdSectionResponseTextField(
  props: {
    onClickCancel?: () => unknown
    onClickDone: () => unknown
  } & TextFieldProps
) {
  const { onClickCancel, onClickDone, ...textFieldProps } = props
  return (
    <Box fullWidth position="relative" pointer>
      <Box zIndex={ZIndices.regular} position="relative" fullWidth>
        <AdCreatorAdSectionTextField
          {...textFieldProps}
          onKeyDown={(e) => e.keyCode === ESC_KEYCODE && onClickDone()}
        />
      </Box>
      <Box fullWidth align="center" zIndex={ZIndices.high}>
        <AdCreatorAdSectionTextFieldMenu onClickCancel={onClickCancel} onClickDone={onClickDone} />
      </Box>
    </Box>
  )
}
