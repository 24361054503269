import styled from '@emotion/styled'
import { Popper } from '@mui/material'
import MaterialTooltip, { TooltipProps } from '@mui/material/Tooltip'
import React from 'react'
import FigmaText from './FigmaText'
import Colors from '../../../../pure/libs/Colors'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'

type Props = TooltipProps & { title: string; titleElement?: React.ReactElement; enableNoLineHeight?: boolean }
const Tooltip: React.FC<Props> = (props) => {
  const { titleElement, enableNoLineHeight = true } = props
  if (props.title === '') return props.children
  return (
    <MaterialTooltip
      {...props}
      title={titleElement || <FigmaText textKey={TextsDeprecated.allTooltipText} text={props.title} />}
      PopperComponent={StyledPopper}
    >
      <div
        style={{
          width: '100%',
          lineHeight: enableNoLineHeight
            ? 0
            : undefined /* without line height 0, you get misplaced info icon when info icon is used in with tooltip*/
        }}
      >
        {props.children}
      </div>
    </MaterialTooltip>
  )
}

const StyledPopper = styled(Popper)`
  .MuiTooltip-tooltip {
    background-color: ${Colors.primary100};
  }
`

export default Tooltip
