import React from 'react'
import Box from './Box'
import FigmaText from './FigmaText'
import { OnboardingArrowLeftIcon } from './Icons'
import Button from './Button'
import { TextKey } from '../../../../pure/types/Antiloop'

type Props = {
  textKey?: TextKey
  onClickBack: () => unknown
}

export default function BackButtonMobileOnboarding({ textKey, onClickBack }: Props) {
  return (
    <Box pointer>
      <Button onClick={onClickBack} spacing="0" variant="text" startIcon={<OnboardingArrowLeftIcon />}>
        {!!textKey && <FigmaText textKey={textKey} />}
      </Button>
    </Box>
  )
}
