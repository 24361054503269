import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCurrentProduct } from '../hooks/useCurrentProduct'
import { useOnClickCreateAndRedirectToStripeBillingPortalSession } from '../hooks/useOnClickCreateAndRedirectToStripeCheckoutSession'
import RoutePath from '../../../../pure/libs/RoutePath'
import Button from './Button'
import { useStripeSubscription } from '../hooks/useStripeSubscription'
import { getTextKeysForSubscription } from '../libs/ProductInfo'

export function SettingsPersonalSubscriptionButton() {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const onClickGoToStripeBillingPortalSession = useOnClickCreateAndRedirectToStripeBillingPortalSession(setIsLoading)

  const subscription = useStripeSubscription()
  const { settingsPersonalUpsellButtonTextKey } = getTextKeysForSubscription(subscription)

  const { stripeProductId } = useCurrentProduct().data

  const onClick = async (e) => {
    if (!stripeProductId) return navigate(RoutePath.PRICING)
    else return onClickGoToStripeBillingPortalSession(e)
  }

  return (
    <Button
      textKey={settingsPersonalUpsellButtonTextKey}
      buttonType="secondary"
      onClick={onClick}
      loading={isLoading}
    />
  )
}
