import invariant from 'invariant'
import { AdCreatorPageProps } from '../components/AdCreatorPageMobile'
import { State } from '../hooks/useAppState'
import { AdLengthInfo, AdLengthInfoType } from '../../../../pure/libs/AdLengthHelper'
import { asArray } from '../../../../pure/libs/Common'
import { OrganizationTemplate, getOrganizationTemplate } from '../../../../pure/libs/OrganizationTemplateHelper'
import { TargetAudience } from '../../../../pure/types/JaguarTypes'
import { Ad, JaguarRequestForm, JaguarSession, JaguarStyle, JaguarType, User } from '../../../../pure/types/types'
import { SectionInfo, SectionInfoItem } from './SectionInfo'
import { getLanguageCode } from '../hooks/useDefaultLanguage'

export type JaguarSessions = {
  [type: string]: JaguarSession
}

export type Requests = {
  [type: string]: JaguarRequestForm | undefined
}

export function getIsDisabled(requests: Requests) {
  const _requests = Object.values(requests).filter((r) => !!r)
  if (_requests.length === 0) return true
  return (
    requests &&
    _requests.some((r) => {
      if (r?.type === JaguarType.AREA && !r?.googlePlace && !r.propertyInfo) return true
      if (r?.type === JaguarType.AREA && !!r?.googlePlace) return false

      return !!r && !r.isValidByServer
    })
  )
}

// TODO WRITE TEST
export function getDefaultActiveSessions(ad?: Ad) {
  return Object.entries(ad?.activeSessions || {}).reduce((acc, [type, session]) => {
    if (!session) return acc
    return { ...acc, [type]: session }
  }, {})
}

export function getActiveSortedSessions(ad: Ad, SectionInfo) {
  return asArray(ad.activeSessions || {})
    .filter((s) => !!s)
    .sort((a, b) => SectionInfo[a?.request.type as string]?.ordo - SectionInfo[b?.request.type as string]?.ordo || 0)
}

export const onNewSession = async ({
  session,
  ad: _ad,
  state,
  setSessions,
  getAd,
  updateAd,
  updateJaguarSession,
  onNewAd
}: {
  session: JaguarSession
  ad: Ad
  onNewAd?: (ad: Ad) => unknown
  state: State
  setSessions
  getAd
  updateAd
  updateJaguarSession
}) => {
  setSessions((sessions) => ({ ...sessions, [session.request.type]: session }))

  // Above will be called millioins of times i.e should only update ad in the end
  if (session.status !== 'active') {
    const ad = await getAd(_ad._id)
    invariant(ad, 'ad must exist')
    const newAd = { ...ad, activeSessions: { ...ad.activeSessions, [session.request.type]: session } }
    updateAd(newAd)
    onNewAd?.(newAd)
  }

  if (session.status === 'completed') await updateJaguarSession(session, state)
}

export function useDefaultSectionFormValues({
  sectionInfo,
  user,
  adCreatorPageProps,
  state
}: {
  sectionInfo: SectionInfoItem
  user?: User
  adCreatorPageProps?: AdCreatorPageProps
  state: State
}): JaguarRequestForm {
  const googlePlace =
    sectionInfo.enableLocationInAdCreator && adCreatorPageProps?.googlePlace
      ? { value: adCreatorPageProps.googlePlace }
      : undefined

  const houseType = adCreatorPageProps?.houseType

  const organizationTemplate = getOrganizationTemplate(user || state.user)

  // TODO WRITE TEST, should take adLengthInfoType from organizationTemplate
  const adLengthInfo: AdLengthInfoType = organizationTemplate?.adLengthInfoType || AdLengthInfo.medium

  // TODO WRITE TEST, should take adLengthInfoType from organizationTemplate
  const headerAdLength = organizationTemplate?.headerAdLength

  // TODO WRITE TEST, should take languageCode from organizationTemplate
  const languageCode = organizationTemplate?.languageCode || user?.language || getLanguageCode()

  // TODO WRITE TEST, should take propertyInfo from adCreatorPageProps or ''
  const propertyInfo = adCreatorPageProps?.propertyInfo || ''

  // TODO WRITE TEST, should take keywordMapItems from adCreatorPageProps or []
  const keywordMapItems = adCreatorPageProps?.keywordMapItems || []

  // TODO WRITE TEST, should take logAdInJaguar from adCreatorPageProps or false
  const logAdInJaguar = adCreatorPageProps?.logAdInJaguar || false

  return {
    adLength: adLengthInfo.value,
    headerAdLength: headerAdLength,
    style: JaguarStyle.DETAILED,
    additionalInfo: [],
    logAdInJaguar,
    targetAudience: TargetAudience.general,
    languageCode,
    ...state?.jaguarRequestForm,
    houseType, // TODO WRITE TEST
    propertyInfo,
    keywordMapItems,
    googlePlace, // TODO WRITE TEST
    type: sectionInfo.jaguarSessionType // TODO WRITE TEST
  }
}

export function shouldValidateRequest(request: JaguarRequestForm | undefined) {
  return !!request && !!request.propertyInfo && !request?.isValidByServer
}

export function shouldUseDefaultProperyInfo(request: JaguarRequestForm, section = SectionInfo) {
  return !request.propertyInfo && section[request.type].propertyInfoNoOfCharsMin > 0
}
