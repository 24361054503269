import toast from 'react-hot-toast'
import { ONE_MINUTE } from '../../../../pure/libs/Consts'
import { VitecSummarizeDocResultItemProps } from '../types/VitecSummarizeDocResultItemProps'

export const onClickCopy = (items: VitecSummarizeDocResultItemProps[]) => {
  const text = items.reduce((a, { header, description }) => a + `${header}\n${description}\n\n`, '')

  if (!navigator.clipboard) return

  toast.success('Copied to clipboard', {
    duration: ONE_MINUTE,
    style: {
      width: '100%',
      background: '#61d345',
      color: 'white'
    }
  })

  navigator.clipboard.writeText(text)
}
