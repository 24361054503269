const Shadows = {
  tiny: '0px 1px 2px 0px rgba(5, 7, 12, 0.05)',
  small: '0px 1px 2px -2px rgba(5, 7, 12, 0.10), 0px 1px 6px 0px rgba(5, 7, 12, 0.10)',
  medium: '0px 2px 4px -2px rgba(5, 7, 12, 0.1), 0px 4px 6px -1px rgba(5, 7, 12, 0.1)',
  blurred: '0px 25px 50px -12px rgba(5, 7, 12, 0.10)',
  large: '0px 4px 6px -4px rgba(5, 7, 12, 0.10), 0px 10px 15px -3px rgba(5, 7, 12, 0.10)',
  big: `0px 8px 10px -6px rgba(5, 7, 12, 0.10), 0px 20px 25px -5px rgba(5, 7, 12, 0.10)`
}

export { Shadows }
