import { FormControl, FormControlLabel, RadioGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FigmaText from './FigmaText'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { SignInViewProps } from '../../../../pure/types/SignInTypes'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import eventEmitter, { Events } from '../../../../pure/libs/EventEmitter'
import Box from './Box'
import Button from './Button'
import { Radio } from './Radio'
import { NoWrapFigmaText } from './StyledComponents'
import { getDefaultProviderId, LABELS } from '../libs/SignInExistsWithDifferentCredentialsHelper'

export default function SignInExistsWithDifferentCredentials({
  onPressContinue,
  signInState,
  onTakeMeBack,
  isLoading
}: SignInViewProps) {
  const {
    data: { email, pendingUserCredential, pendingUser, signInMethods }
  } = signInState

  const defaultProviderId = getDefaultProviderId(signInMethods)
  const [providerId, setProviderId] = useState(defaultProviderId || '')
  useEffect(() => {
    if (!pendingUserCredential) eventEmitter.emit(Events.NEW_SERVER_ERROR)
  }, [])

  if (!pendingUserCredential) return null

  const _onPressContinue = () =>
    onPressContinue({ ...signInState, data: { ...signInState.data, pendingProviderId: providerId } })

  return (
    <Box fullWidth align="center">
      <Box top align="center" spacing={bigSpacing}>
        <Box align="center">
          <Box fullWidth bottom spacing={smallSpacing}>
            <FigmaText
              textKey={TextsDeprecated.onboardingWelcomeText}
              text={formatText(getFigmaText(TextsDeprecated.onboardingWelcomeText), [
                pendingUser?.displayName || email
              ])} // TODO, change to name
            />
            <Box top>
              <FigmaText textKey={TextsDeprecated.onboardingExistingAccountInfoText} />
            </Box>
            <Box top>
              <FigmaText textKey={TextsDeprecated.onboardingConnectedAccountListHeader} />
            </Box>
          </Box>

          <Box fullWidth bottom spacing={smallSpacing}>
            <FormControl component="fieldset">
              <RadioGroup
                defaultValue={defaultProviderId}
                onChange={({ target: { value: providerId } }) => setProviderId(providerId)}
              >
                {signInMethods.map((providerId, key) => {
                  const labels = LABELS[providerId] || LABELS.default
                  const { radioLabel } = labels
                  return <FormControlLabel key={key} value={providerId} control={<Radio />} label={radioLabel} />
                })}
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>

        <Box fullWidth direction="row" justify="space-between">
          <Button buttonType="secondary" onClick={onTakeMeBack}>
            <NoWrapFigmaText textKey={TextsDeprecated.secondaryReturnToLoginButtonText} />
          </Button>
          <Button fullWidth onClick={_onPressContinue} loading={isLoading}>
            <FigmaText
              textKey={TextsDeprecated.onboardingVerifyEmailButtonText}
              text={LABELS[providerId]?.buttonLabel || LABELS.default.buttonLabel}
            />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
