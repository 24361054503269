import Box from './Box'

import { useNavigate } from 'react-router-dom'
import RoutePath from '../../../../pure/libs/RoutePath'
import useAppState from '../hooks/useAppState'
import { addQueryParamsToUrl } from '../hooks/useQueryparamHelper'
import useQueryParams from '../libs/useQueryParams'
import { VitecNextOrderArrowBackIcon } from './Icons'

export function VitecNextOrderProductBackButton() {
  const { state, setState } = useAppState()
  const navigate = useNavigate()
  const qp = useQueryParams()

  return (
    <Box
      pointer
      onClick={() => {
        setState({ ...state, vitecNextOrderProductPageState: undefined })
        navigate(addQueryParamsToUrl(RoutePath.VITEC_NEXT_ORDER, qp))
      }}
    >
      <VitecNextOrderArrowBackIcon />
    </Box>
  )
}
