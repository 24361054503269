import { JaguarSession } from '../../../../pure/types/types'
import toast from 'react-hot-toast'
import AnalyticsEvent from '../../../../pure/enums/AnalyticsEvent'
import { logEvent } from './AnalyticsHelper'
import { getTextToCopy } from './getTextToCopy'

export const onClickCopySessions = (sessions: JaguarSession[]) => {
  const text = getTextToCopy(sessions)

  onCopyText(text)
}

export const onCopyText = (text: string) => {
  if (!navigator.clipboard) return

  toast('Copied to clipboard', {
    icon: '✂️'
  })

  navigator.clipboard.writeText(text)
  logEvent(AnalyticsEvent.COPY_TO_CLIPBOARD)
}
