import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { JaguarRequestForm } from '../../../../pure/types/types'
import { AdCreatorKeywordButton } from './AdCreatorKeywordButton'
import Box from './Box'
import { AdCreatorArrowDownIcon, AdCreatorArrowUpIcon } from './Icons'

import { ClickAwayListener } from '@mui/base'
import { regularSpacing, tinySpacing } from '../enums/Spacings'
import { getFigmaText } from '../libs/TextRepository'
import { TextKey, TextKeyWithOnlyText } from '../../../../pure/types/Antiloop'
import { AdCreatorFormSectionButtonView } from './AdCreatorFormSectionButtonView'
import MaterialPopper from './Popper'

type Props = {
  name: keyof JaguarRequestForm
  defaultTextKey: TextKey
  buttonText?: string
  formProps: UseFormReturn<JaguarRequestForm>
  options: {
    textKey?: TextKeyWithOnlyText
    text?: string
    value: string | number
    Component?: React.FC<{ setValue; option }>
    isSelected?: boolean
  }[]
}

export function AdCreatorFormSectionPopperAndButton(props: Props) {
  const { options } = props
  const [value, setValue] = useState(props.formProps.getValues(props.name))
  const o = options.find((o) => o.value === value)
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()

  const text = useText({ value, o, props })
  const Icon = anchorEl ? AdCreatorArrowUpIcon : AdCreatorArrowDownIcon

  return (
    <>
      <Popper anchorEl={anchorEl} onClose={() => setAnchorEl(undefined)} {...props} value={value} setValue={setValue} />
      <AdCreatorKeywordButton Icon={Icon} text={text || ''} onClick={(e) => setAnchorEl(e.currentTarget)} />
    </>
  )
}

function useText({ value, o, props }: { value; o; props: Props }): string | undefined {
  if (!value) return getFigmaText(props.defaultTextKey)
  if (!!value && !!props.buttonText) return props.buttonText

  if (!o) return '' // https://gazelle-work.sentry.io/issues/4547096990/?alert_rule_id=14111773&alert_type=issue&notification_uuid=db744665-331a-4395-b99c-dc3a8698c337&project=4505027568140288&referrer=slack

  if (!!value && o.text) return o.text
  if (!!value && o.textKey) return getFigmaText(o.textKey)

  return ''
}

function Popper({
  anchorEl,
  onClose,
  formProps,
  options,
  name,
  value,
  setValue
}: { value: any; onClose: () => unknown; anchorEl: HTMLElement | undefined; setValue: (item) => unknown } & Props) {
  function onClick(item) {
    formProps.setValue(name, item, { shouldDirty: true })
    setValue(item)
    onClose()
  }

  return (
    <MaterialPopper anchorEl={anchorEl} placement="bottom-start">
      <ClickAwayListener onClickAway={onClose}>
        <Box fullWidth fullPadding spacing={regularSpacing}>
          <Box fullWidth style={{ flexWrap: 'wrap' }}>
            {options.map((o, key) => {
              const element = o.Component ? (
                <o.Component setValue={setValue} option={o} />
              ) : (
                <AdCreatorFormSectionButtonView
                  textKey={o.textKey}
                  text={o.text}
                  onClick={() => onClick(o.value)}
                  isSelected={o.isSelected || o.value === value}
                />
              )

              return (
                <Box key={key} spacing={tinySpacing} top right fullWidth>
                  {element}
                </Box>
              )
            })}
          </Box>
        </Box>
      </ClickAwayListener>
    </MaterialPopper>
  )
}
