/* eslint-disable react/jsx-pascal-case */
import MaterialMenuItem, { MenuItemProps as MaterialMenuItemProps } from '@mui/material/MenuItem'
import { ReactElement } from 'react'
import { smallSpacing } from '../enums/Spacings'
import { Texts } from '../../../../pure/libs/AppTexts'
import { TextKey } from '../../../../pure/types/Antiloop'
import Box from './Box'
import FigmaTextLoader from './FigmaTextLoader'

export type MenuItemProps = MaterialMenuItemProps & {
  button?: boolean
  textKey?: TextKey
  text?: string
  leftElement?: ReactElement
  element?: ReactElement
  rightElement?: ReactElement
  topElement?: ReactElement
  className?: string
  loading?: boolean
}

export const MenuItem = (props: MenuItemProps) => {
  const {
    textKey = Texts.adCreatorSelectionMenuRephrase,
    leftElement,
    text,
    button,
    topElement,
    rightElement,
    className,
    loading,
    element,
    ...menuProps
  } = props
  return (
    <MaterialMenuItem {...menuProps}>
      <Box>
        <Box align="center" direction="row">
          <Box right spacing={!!leftElement ? smallSpacing : '0'}>
            {leftElement}
          </Box>
          <Box className={className}>
            {topElement}
            {element || (
              <FigmaTextLoader
                styleTextKey={Texts.adCreatorSelectionMenuRephrase}
                textKey={textKey}
                text={text}
                loading={loading}
              />
            )}
          </Box>
          <Box>{rightElement}</Box>
        </Box>
      </Box>
    </MaterialMenuItem>
  )
}
