import MaterialRadio, { RadioProps } from '@mui/material/Radio'
import Colors from '../../../../pure/libs/Colors'
import Box from './Box'

type Props = RadioProps & { radioColor?: string }

export function RadioContainer(props: Props) {
  return (
    <Box align="center" justify="center">
      <Radio {...props} />
    </Box>
  )
}

export function Radio(props: Props) {
  const { radioColor, ...rest } = props
  const color = radioColor || Colors.black
  return (
    <MaterialRadio
      sx={{
        color,
        '&.Mui-checked': {
          color
        }
      }}
      disableRipple
      disableFocusRipple
      size="small"
      {...rest}
    />
  )
}
