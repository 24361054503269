import { smallSpacing } from '../enums/Spacings'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import Images from '../../../../pure/libs/AppImages'
import { Texts } from '../../../../pure/libs/AppTexts'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaTextWithStyleOverrides from './FigmaTextWithStyleOverrides'
import { onClickContactUs } from './MenuButtonsDesktop'
import { ModalFasadMachineViewProps } from './ModalExternalSessionMachineHelper'

export function ModalExternalSessionMachineSendToFasad(props: ModalFasadMachineViewProps) {
  return (
    <Box fullWidth align="center">
      <Box top>
        <FigmaImageContainer imageKey={Images.pngGazelleArrowFasad} />
      </Box>
      <Box top alignText="center">
        <FigmaTextWithLinebreaks textKey={Texts.modalSendToFasadTitle} />
      </Box>
      <Box top alignText="center">
        <FigmaTextWithStyleOverrides
          textKeys={{ en: Texts.modalSendToFasadDescription, sv: Texts.modalSendToFasadDescriptionSv }}
        />
      </Box>
      <Box top fullWidth spacing={smallSpacing}>
        <Button textKey={Texts.modalSendToFasadCta} onClick={props.onClickContinue} fullWidth loading={props.loading} />
      </Box>
      <Box top spacing={smallSpacing} onClick={onClickContactUs} pointer>
        <FigmaTextWithStyleOverrides
          textKeys={{ en: Texts.modalSendToFasadFineprint, sv: Texts.modalSendToFasadFineprintSv }}
        />
      </Box>
    </Box>
  )
}
