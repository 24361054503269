import config from '../Config'
import AnalyticsEvent from '../../../../pure/enums/AnalyticsEvent'
import eventEmitter, { Events } from '../../../../pure/libs/EventEmitter'

export const logEvent = (eventName: AnalyticsEvent, eventParams?: { [key: string]: any }) => {
  // if (config.enableAnalytics) Analytics.logEvent(analytics, eventName as string, eventParams)

  if (!config.enableAnalyticsEmitter) return

  return eventEmitter.emit(Events.NEW_ANALYTICS_EVENT, { eventName, eventParams })
}
