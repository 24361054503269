/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-restricted-globals */
import styled from '@emotion/styled'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import { useNavigate } from 'react-router-dom'
import config from '../Config'
import { littleSpacing, mediumSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import useIsMobile from '../hooks/useIsMobile'
import { useLogout } from '../hooks/useLogout'
import { deleteAccount } from '../libs/CloudFunctionsApiHandler'
import { withStopProgagation } from '../libs/HtmlHelper'
import FigmaText from './FigmaText'
import Colors from '../../../../pure/libs/Colors'
import RoutePath from '../../../../pure/libs/RoutePath'
import { captureException } from '../libs/SentryHelper'
import { OldTextKeys, TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { TextKey } from '../../../../pure/types/Antiloop'
import { MenuItem } from './MenuItem'

type Props = {
  anchorEl?: HTMLElement
  onClose: (e?) => void
}

export default function UserWidgetMenu(props: Props) {
  const { anchorEl } = props
  const { state } = useAppState()
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const { user } = state

  const enableDevMenu = config.enableDevMenu

  const onDeleteUser = () =>
    deleteAccount(user._id, state)
      .then(() => logout())
      .catch(captureException)

  const onLogout = () => {
    props.onClose()
    navigate(RoutePath.ROOT)
  }

  const logout = useLogout(onLogout)

  const onClose = withStopProgagation(props.onClose)

  return (
    <div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
        <MenuItemTitle textKey={TextsDeprecated.usermenuUserNameButtonText} text={user.email?.toString()} />

        <MenuItem onClick={() => navigate(RoutePath.SETTINGS)} textKey={TextsDeprecated.userMenuSettingsButtonText} />

        {enableDevMenu && (
          <MenuItem onClick={onDeleteUser} textKey={OldTextKeys.userMenuDeleteUser as TextKey}></MenuItem>
        )}
        <Divider />

        <MenuItem onClick={logout} textKey={TextsDeprecated.userMenuLogoutButtonText} />
      </Menu>
    </div>
  )
}

const MenuItemTitle = styled(FigmaText)`
  padding: ${littleSpacing} ${mediumSpacing};
  background-color: ${Colors.primary100};
  display: block;
`
