import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import * as Yup from 'yup'
import config from '../Config'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { useUser } from '../hooks/QueryHooks'
import useAppState from '../hooks/useAppState'
import { useCurrentProduct } from '../hooks/useCurrentProduct'
import useFirebaseUser from '../hooks/useFirebaseUser'
import { useForm } from '../hooks/useForm'
import useNotification from '../hooks/useNotification'
import { useStripeSubscription } from '../hooks/useStripeSubscription'
import useUpdateUser from '../hooks/useUpdateUser'
import * as ApiHandler from '../libs/ApiHandler'
import { isInternalUser } from '../libs/CreateAdHelper'
import { onUpdateUser } from '../libs/SettingsPersonalHelper'
import { PersonNameValidation } from '../libs/ValidationHelper'
import FigmaText from './FigmaText'
import useQueryParams from '../libs/useQueryParams'
import { LANGUAGES, LANGUAGES_OBJECT, YYYY_MM_DD, en } from '../../../../pure/libs/Consts'
import { getFigmaText } from '../libs/TextRepository'
import { TextsDeprecated, OldTextKeys, TextKeysWithOnlyTexts } from '../../../../pure/libs/TextsDeprecated'
import { Language } from '../../../../pure/types/Antiloop'
import Box from './Box'
import Button from './Button'
import SelectFieldWithLabel from './SelectFieldWithLabel'
import { SettingsPersonalSubscriptionButton } from './SettingsPersonalSubscriptionButton'
import { TextFieldOutlined } from './TextFieldOutlined'
import { TextFieldWithLabelForm } from './TextFieldWithLabelForm'
import { getTextKeysForSubscription } from '../libs/ProductInfo'
import { StripeCheckoutCallbackQueryParams } from '../../../../pure/types/QueryParamTypes'

const ValidationSchema = Yup.object().shape({
  name: PersonNameValidation('Enter first and last name')
})

type InitialValues = { name: string; language: Language }

export default function SettingsPersonal() {
  const { state, setState } = useAppState()
  const { data: user = state.user } = useUser(state.user._id)
  const updateUser = useUpdateUser()
  const notification = useNotification()
  const firebaseUser = useFirebaseUser()

  const defaultValues = { name: user.name || '', language: (user.language || en) as Language }

  const { handleSubmit, control, setValue, register } = useForm(ValidationSchema, {
    defaultValues
  })

  const onSubmit = ({ name, language }: InitialValues) => {
    const newUser = { ...user, name, language }
    return updateUser(newUser)
      .then(() => setState({ ...state }))
      .then(() => onUpdateUser(newUser, user))
      .then(() => notification.success())
  }

  // Stripe
  const { stripeSuccess } = useQueryParams<StripeCheckoutCallbackQueryParams>()
  useEffect(() => {
    if (stripeSuccess === 'true') notification.success()
    if (stripeSuccess === 'false') notification.error()
  }, [])
  const subscription = useStripeSubscription()
  const product = useCurrentProduct().data
  const { current_period_end } = subscription || {}

  const { settingsPersonalProductDescription } = getTextKeysForSubscription(subscription)

  return (
    <Box fullWidth top bottom fullHeight align="center">
      <Container>
        <Box fullWidth>
          <Box fullWidth>
            <FigmaText textKey={TextsDeprecated.settingsDescriptionsCreated} text="Email" />
            <FigmaText textKey={TextsDeprecated.settingsFreeProdcutDescription} text={`${firebaseUser?.email || ''}`} />
          </Box>
          <Box top fullWidth>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <TextFieldWithLabelForm
                  {...field}
                  labelTextKey={TextsDeprecated.settingsPersonalInformationName}
                  component={TextFieldOutlined}
                  fieldState={fieldState}
                />
              )}
            />
          </Box>
          <Box top fullWidth>
            <SelectFieldWithLabel
              {...register('language')}
              defaultValue={defaultValues.language}
              labelTextKey={TextsDeprecated.settingsApplicationLanguage}
              renderValue={(value) => LANGUAGES_OBJECT[value as string]}
              options={LANGUAGES.map((value) => ({ key: LANGUAGES_OBJECT[value], value }))}
              onChange={({ target: { value: language } }) =>
                setValue('language', language as Language, { shouldValidate: true, shouldDirty: true })
              }
            />
          </Box>
          <Box top fullWidth>
            <FigmaText textKey={TextsDeprecated.settingsMembershipPlan} />
            <FigmaText textKey={TextsDeprecated.settingsFreeProdcutName} text={product.name} />
            <Box top spacing={tinySpacing}>
              <FigmaText
                textKey={TextsDeprecated.settingsFreeProdcutDescription}
                text={getFigmaText(settingsPersonalProductDescription)}
              />
            </Box>
          </Box>
          <Box top fullWidth>
            <FigmaText textKey={TextsDeprecated.settingsDescriptionsCreated} />
            <Box spacing={smallSpacing}>
              <FigmaText
                textKey={TextsDeprecated.settingsFreeProdcutDescription}
                text={`${user.usedSessions || 0} / ${product.noOfFreeAdsText}`}
              />
              {isInternalUser(user) && (
                <Box top spacing={tinySpacing}>
                  <FigmaText
                    textKey={TextsDeprecated.homeFormFineprint}
                    text={`Interna användare har möjligheten att överskrida gränsen på ${product.noOfFreeAdsText} annonser.`}
                  />
                </Box>
              )}
            </Box>
          </Box>
          {current_period_end && (
            <Box top fullWidth>
              <FigmaText
                textKey={TextsDeprecated.adminSelectApplicationLanguageLabel}
                text={getFigmaText(TextKeysWithOnlyTexts.settingsPersonalExpiresAtLabel)}
              />
              <Box spacing={smallSpacing}>
                <FigmaText textKey={OldTextKeys.cssSettingsValue} text={dayjs(current_period_end).format(YYYY_MM_DD)} />
              </Box>
            </Box>
          )}
          <Box top fullWidth>
            <SettingsPersonalSubscriptionButton />
          </Box>
          {config.enableTestButtons && (
            <>
              <Box top fullWidth>
                <Button
                  buttonType="secondary"
                  text="Throw Jaguar 400"
                  onClick={() => firebaseUser && ApiHandler.throwError({ req: { status: 400 } })}
                />
              </Box>
              <Box top fullWidth>
                <Button
                  buttonType="secondary"
                  text="Throw Jaguar 500"
                  onClick={() => firebaseUser && ApiHandler.throwError({ req: { status: 500 } })}
                />
              </Box>
            </>
          )}
        </Box>
      </Container>
      <ButtonContainer top>
        <Button textKey={TextsDeprecated.saveButtonText} onClick={handleSubmit(onSubmit)} fullWidth />
      </ButtonContainer>
    </Box>
  )
}

const CONTAINER_MAX_WIDTH = 320

const Container = styled(Box)`
  width: ${CONTAINER_MAX_WIDTH}px;
`

const ButtonContainer = styled(Box)`
  width: ${CONTAINER_MAX_WIDTH}px;
`
