import { PricingItemInfo } from '../libs/PricingPageHelper'
import { ProductType } from '../libs/ProductInfo'
import Box from './Box'
import { PricingProduct } from './PricingProduct'

export function PricingItemInfoRow({ onClick, productType }: { onClick?: () => unknown; productType: ProductType }) {
  return (
    <Box direction="row">
      {Object.entries(PricingItemInfo).map(([key, productInfo], index, arr) => {
        const isFirst = index === 0
        const isLast = index === arr.length - 1
        return (
          <Box key={key} fullHeight left={!isFirst} right={!isLast}>
            <PricingProduct productInfo={productInfo} onClick={onClick} productType={productType} />
          </Box>
        )
      })}
    </Box>
  )
}
