import { UseFormReturn } from 'react-hook-form'
import { KeyWordsMap, getKeyWordSections } from '../libs/KeywordMapHelper'
import { getTextKeyIdForKeywordMapItem } from '../../../../pure/libs/getTextKeyIdForKeywordMapItem'
import { TextKey } from '../../../../pure/types/Antiloop'
import { JaguarRequestForm } from '../../../../pure/types/types'
import Box from './Box'
import { KeywordsDrawerRow } from './KeywordsDrawerRow'

export function KeywordsDrawerRows({ formProps }: { formProps: UseFormReturn<JaguarRequestForm> }) {
  const keywordSections = getKeyWordSections(KeyWordsMap, formProps)

  return (
    <>
      {keywordSections.map(([key, keywordMap], index) => {
        const keywords = Object.keys(keywordMap)
        const textKey = { name: getTextKeyIdForKeywordMapItem(key) } as TextKey
        if (keywords.length === 0) return null
        return (
          <Box top key={index} fullWidth>
            <KeywordsDrawerRow keywordMap={keywordMap} headerTextKey={textKey} formProps={formProps} />
          </Box>
        )
      })}
    </>
  )
}
