import { useEffect } from 'react'
import { useTidio } from './useTidio'

export default function useOnLoggedIn() {
  useTidio()

  useEffect(() => {
    //@ts-ignore
    window.api = {}
  }, [])

  return null
}
