import React, { useEffect } from 'react'
import { getDownloadURL, ref, getStorage } from 'firebase/storage'

export const Storage = getStorage()

export function useStorageUrlExperimental(path?: string) {
  const [url, setUrl] = React.useState((!!path && window.localStorage.getItem(path)) || '')
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  useEffect(() => {
    if (!path) return setUrl('')
    if (!url) setIsLoading(true)
    getDownloadURL(ref(Storage, path))
      .then((url) => {
        setUrl(url)
        window.localStorage.setItem(path, url)
      })
      .finally(() => setIsLoading(false))
  }, [path])

  return { url, setUrl, isLoading }
}
