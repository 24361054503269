import styled from '@emotion/styled'
import React, { MouseEvent } from 'react'
import { tinySpacing } from '../enums/Spacings'
import Colors from '../../../../pure/libs/Colors'
import { TextKey, TextKeyWithOnlyText } from '../../../../pure/types/Antiloop'
import Box from './Box'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { IconProps } from './Icon'

type Props = {
  textKey?: TextKeyWithOnlyText
  text?: string
  Icon?: React.FC<Partial<IconProps>>
  onClick: (e: MouseEvent<HTMLDivElement>) => unknown
}

export function AdCreatorKeywordButton({ Icon, textKey, text, onClick }: Props) {
  const [isHovered, setIsHovered] = React.useState(false)
  return (
    <Container
      fullPadding
      spacing={tinySpacing}
      direction="row"
      align="center"
      pointer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isHovered={isHovered}
      onClick={onClick}
    >
      {Icon ? <Icon /> : null}
      <Box left spacing={tinySpacing} right>
        <FigmaTextWithStyle type="body2" textKey={textKey as TextKey} text={text} />
      </Box>
    </Container>
  )
}

const Container = styled(Box)<{ isHovered?: boolean }>`
  border-radius: 60px;
  background: ${({ isHovered }) => (isHovered ? Colors.neutral30 : Colors.neutral20)};
`
