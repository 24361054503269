import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { smallSpacing } from '../enums/Spacings'
import { useOrganization, useUser, useUsersForOrganization } from '../hooks/QueryHooks'
import { useUserId } from '../hooks/useAppState'
import { MyOrganizationAIAdWidth } from '../libs/HardCodedSizes'
import RoutePath from '../../../../pure/libs/RoutePath'
import Box from './Box'
import Button from './Button'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { SettingsAIWriterCustomText } from './SettingsAIWriterCustomText'
import { SettingsAIWriterLikedSession } from './SettingsAIWriterLikedSession'

export function SettingsAIWriter() {
  const uid = useUserId()
  const { data: user } = useUser(uid)
  const { data: org } = useOrganization(user?.organizationId)

  let { data: users = [] } = useUsersForOrganization(user?.organizationId)
  users = users.filter((u) => u.organizationId === user?.organizationId)
  const hasLikedAds = (org?.likedSessions || []).length > 0
  const navigate = useNavigate()

  return (
    <Box top fullWidth>
      <FigmaTextWithStyle type="h3" text="AI Writer" />
      <Box top>
        <FigmaTextWithStyle type="h4" text="Liked Ads" />
        <Box top spacing={smallSpacing}>
          <FigmaTextWithStyle
            type="body2"
            text="Liked Ads will be used when fine tuning your AI writer"
            color="neutral80"
          />
        </Box>
        <Box direction="row" style={{ flexWrap: 'wrap' }}>
          {!hasLikedAds && (
            <Box top>
              <Button
                text="No ads here yet :(. Create an add an like it to use it when fine tuning your AI writer"
                buttonType="empty_state"
                onClick={() => navigate(RoutePath.CREATE_AD)}
              />
            </Box>
          )}
          {hasLikedAds &&
            org?.likedSessions?.map((session, key) => (
              <LikedAdsContainer key={key} top left>
                <SettingsAIWriterLikedSession session={session} />
              </LikedAdsContainer>
            ))}
        </Box>
      </Box>
      <Box top>
        <FigmaTextWithStyle type="h4" text="Uploaded Ads" />
        <Box top spacing={smallSpacing}>
          <FigmaTextWithStyle
            type="body2"
            text="Upload an existing ad to use when fine tuning your AI writer"
            color="neutral80"
          />
        </Box>
        <Box direction="row" style={{ flexWrap: 'wrap' }}>
          {org?.customTexts?.map((customText, key) => (
            <LikedAdsContainer key={key} top left>
              <SettingsAIWriterCustomText customText={customText} />
            </LikedAdsContainer>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export const LikedAdsContainer = styled(Box)`
  max-width: ${MyOrganizationAIAdWidth}px;
  width: 100%;
`
