import { Upload, UploadedFile } from '../types/JaguarTypes.js'
import Collections from './Collections.js'
import { createUniqueId } from './Common.js'

export function getPathToUploadedFiles(uploadId: string): string {
  return `${Collections.UPLOADS}/${uploadId || createUniqueId()}/files`
}

export function getPathToUploadedFile(file: UploadedFile, upload: Upload): string {
  return `${Collections.UPLOADS}/${upload.id}/files/${file.id}`
}
// TODO WRITE TEST, should return only completed files

export function getCompletedUploadedFiles(uploadedFiles: UploadedFile[]) {
  return uploadedFiles.filter((item) => !!item.PropertyAdditionalInfo)
}
