import invariant from 'invariant'
import React, { CSSProperties } from 'react'
import FigmaText from './FigmaText.js'
import { TextKey } from '../../../../pure/types/Antiloop.js'
import { useLanguage } from '../hooks/useDefaultLanguage.js'

export type FigmaTextWithStyleOverridesProps<T> = {
  textKeys: { [language: string]: Required<TextKey> }
  Component: React.FC<{ style: T | undefined; onClick?: () => unknown; children?: React.ReactNode }>
  onClick?: () => unknown
  mapStyle?: <T>(style: CSSProperties) => T
  children?: React.ReactNode
  color?: string
}

function FigmaTextWithStyleOverrides<T>({
  textKeys,
  onClick,
  Component,
  mapStyle,
  color
}: FigmaTextWithStyleOverridesProps<T>) {
  const language = useLanguage()
  const textKey = textKeys[language] || textKeys.default || textKeys.en
  invariant(textKey, '!textKey')
  const { style, styleOverrideTable, characterStyleOverrides } = textKey
  if (!!color) style.color = color

  if (!characterStyleOverrides) {
    console.warn('Found no characterStyleOverrides in textKey', textKey.name)
    return <FigmaText textKey={textKey} />
  }

  style.whiteSpace = 'pre'
  const newLinesplits = textKey.characters.split('\n')

  let index = -1
  return (
    <>
      {newLinesplits.map((text, i, arr) => {
        index++
        return (
          <Component key={i} style={undefined}>
            {text.split('').map((char, j) => {
              const characterOverridesStyleKey = characterStyleOverrides[index]
              index++
              const overrideStyle = styleOverrideTable[characterOverridesStyleKey]
              if (!!color && !!overrideStyle) overrideStyle.color = color
              const onClickStyleOverride =
                overrideStyle?.textDecoration === 'UNDERLINE' ? { cursor: 'pointer' } : undefined
              const _onClick = !!onClickStyleOverride ? onClick : undefined
              let _style = { ...style, ...overrideStyle, ...onClickStyleOverride }
              if (mapStyle) _style = mapStyle(_style)
              return (
                <Component key={j} style={_style} onClick={_onClick}>
                  {char}
                </Component>
              )
            })}
          </Component>
        )
      })}
    </>
  )
}

export default FigmaTextWithStyleOverrides
