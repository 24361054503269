import { SMALL_SPACING_PX, smallSpacing } from '../enums/Spacings'
import { INSTAGRAM_URL, LINKED_IN_URL, TWITTER_URL, YOUTUBE_URL } from '../../../../pure/libs/Consts'
import Images from '../../../../pure/libs/AppImages'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'

export function FooterSocialMediaIcons() {
  return (
    <Box direction="row" pointer align="center">
      <Box style={{ marginLeft: -SMALL_SPACING_PX / 2 }} onClick={() => window.open(LINKED_IN_URL, '_blank')}>
        <FigmaImageContainer imageKey={Images.iconLinkedinSmall} />
      </Box>
      <Box left spacing={smallSpacing} onClick={() => window.open(YOUTUBE_URL, '_blank')}>
        <FigmaImageContainer imageKey={Images.iconYouTubeSmall} />
      </Box>
      <Box left spacing={smallSpacing} onClick={() => window.open(INSTAGRAM_URL, '_blank')}>
        <FigmaImageContainer imageKey={Images.iconInstagramSmall} />
      </Box>
      <Box left spacing={smallSpacing} onClick={() => window.open(TWITTER_URL, '_blank')}>
        <FigmaImageContainer imageKey={Images.iconTwitterSmall} />
      </Box>
    </Box>
  )
}
