import { getStripeSubscriptions } from '../libs/CloudFunctionsApiHandler'
import useAppState from './useAppState'
import { useAsyncFunction } from './useAsyncFunction'

export const useStripeSubscriptions = () => {
  const { state } = useAppState()
  const uid = state.user._id
  const { data = [] } = useAsyncFunction(() => getStripeSubscriptions(state), { enabled: !!uid, key: uid })

  return data
}
