import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { useUser } from '../hooks/QueryHooks'
import useAppState from '../hooks/useAppState'
import useLoggedInConfig from '../hooks/useLoggedInConfig'
import { useDefaultSectionFormValues } from '../libs/AdCreatorFormHelper'
import { SectionInfoItem } from '../libs/SectionInfo'
import FigmaText from './FigmaText'
import Colors from '../../../../pure/libs/Colors'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../../pure/libs/AppTexts'
import { JaguarRequestForm } from '../../../../pure/types/types'

import { Shadows } from '../enums/Shadows'
import { Events, upsertListener } from '../../../../pure/libs/EventEmitter'
import { AdCreatorFormSectionAddKeywordsButton } from './AdCreatorFormSectionAddKeywordsButton'
import { AdCreatorFormSectionKeywordsMap } from './AdCreatorFormSectionKeywordsMap'
import { AdCreatorFormSectionLocationButton } from './AdCreatorFormSectionLocationButton'
import { AdCreatorFormSectionMoreOptions } from './AdCreatorFormSectionMoreOptions'
import { AdCreatorFormSectionPopperAndButtonHouseType } from './AdCreatorFormSectionPopperAndButtonHouseType'
import { AdCreatorFormSectionStatus, OnNewJaguarRequest } from './AdCreatorFormSectionStatus'
import { AdCreatorFormTextField } from './AdCreatorFormTextField'
import { AdCreatorPageProps } from './AdCreatorPageMobile'
import Box from './Box'
import * as KeywordsDrawerPkg from './KeywordsDrawer'
import { GreyLine } from './StyledComponents'

export function AdCreatorFormSection({
  sectionInfo,
  index,
  onNewJaguarRequest,
  adCreatorPageProps
}: {
  index: number
  sectionInfo: SectionInfoItem
  onNewJaguarRequest: OnNewJaguarRequest
  adCreatorPageProps?: AdCreatorPageProps
}): JSX.Element {
  const KeywordsDrawer = adCreatorPageProps?.KeywordsDrawer || KeywordsDrawerPkg.KeywordsDrawer
  const { showKeywords } = adCreatorPageProps || {}
  const validByServerContext = useState<boolean | undefined>()
  const [isValidByServer] = validByServerContext
  const { state, setState } = useAppState()
  const { data: user = state.user } = useUser(state.user._id)
  const config = useLoggedInConfig()

  const [openKeywords, setOpenKeywords] = useState(false)

  const defaultValues: JaguarRequestForm = useDefaultSectionFormValues({ sectionInfo, user, adCreatorPageProps, state })

  if (config.enableSampleData) defaultValues.propertyInfo = getFigmaText(sectionInfo.placeholderTextKey)

  const formProps = useForm({
    defaultValues
  })

  const { control } = formProps

  const fieldsToWatch = Object.keys(defaultValues).filter((k) => k !== 'propertyInfo') as any
  const propertyWatch = useWatch({ name: 'propertyInfo', control })
  const watches = useWatch({ name: fieldsToWatch, control })

  useEffect(() => {
    if (!formProps.formState.isDirty) return
    onNewJaguarRequest({ ...formProps.getValues(), isValidByServer })
  }, [watches, isValidByServer, propertyWatch])

  useEffect(() => {
    if (!formProps.formState.isDirty) return
    setState({ ...state, jaguarRequestForm: formProps.getValues() })
  }, [watches])

  const enableTargetAudience = sectionInfo.enableTargetAudienceInAdCreator

  useEffect(() => {
    // TODO WRITE TEST, should close keywords dialog when clicking create ad
    upsertListener(Events.ON_CREATE_AD_CTA_CLICKED, () => setOpenKeywords(false))
  }, [])

  const onCloseKeywordsDrawer = () => setOpenKeywords(false)
  const onOpenKeywordsDrawer = () => setOpenKeywords(true)
  return (
    <>
      <KeywordsDrawer onClose={onCloseKeywordsDrawer} formProps={formProps} open={openKeywords} />
      <Container fullWidth spacing={bigSpacing}>
        <InnerContainer fullWidth fullPadding>
          <FigmaText
            textKey={Texts.adCreatorFormSectionHeader}
            text={`${index}. ${getFigmaText(sectionInfo.sectionNameTextKey)}`}
            textTransform="uppercase"
          />
          <FigmaText
            styleTextKey={Texts.adCreatorFormSectionDescription}
            textKey={sectionInfo.typeOfAdDescriptionTextKey}
          />
          <Box top fullWidth spacing={smallSpacing}>
            <GreyLine />
          </Box>
          <Box fullWidth top spacing={smallSpacing}>
            <Box fullWidth direction="row" alignText="center">
              {sectionInfo.enableLocationInAdCreator && (
                <Box>
                  <AdCreatorFormSectionLocationButton formProps={formProps} />
                </Box>
              )}
              {sectionInfo.enablePropertyTypeInAdCreator && (
                <Box spacing={tinySpacing} left={sectionInfo.enableLocationInAdCreator}>
                  <AdCreatorFormSectionPopperAndButtonHouseType formProps={formProps} />
                </Box>
              )}
              {sectionInfo.enableKeywordsInAdCreator && (
                <Box spacing={tinySpacing} left={sectionInfo.enablePropertyTypeInAdCreator}>
                  <AdCreatorFormSectionAddKeywordsButton onClick={onOpenKeywordsDrawer} />
                </Box>
              )}
            </Box>
          </Box>
          <Box fullWidth top spacing={smallSpacing}>
            <Controller
              control={control}
              name="propertyInfo"
              render={({ field }) => (
                <AdCreatorFormTextField {...field} sectionInfo={sectionInfo} formProps={formProps} />
              )}
            />
          </Box>
          <Box top>
            <AdCreatorFormSectionStatus formProps={formProps} validByServerContext={validByServerContext} />
          </Box>
          {showKeywords && <AdCreatorFormSectionKeywordsMap formProps={formProps} />}
        </InnerContainer>
        <Box fullWidth fullPadding direction="row" align="center">
          <AdCreatorFormSectionMoreOptions formProps={formProps} enableTargetAudience={enableTargetAudience} />
        </Box>
      </Container>
    </>
  )
}

export const Container = styled(Box)`
  border-radius: ${BorderRadixes.moderate};
  background: ${Colors.white};
`

export const InnerContainer = styled(Container)`
  box-shadow: ${Shadows.small};
`
