import { useNavigate } from 'react-router';
import Images from '../../../../pure/libs/AppImages';
import RoutePath from '../../../../pure/libs/RoutePath';
import Box from './Box';
import FigmaImageContainer from './FigmaImageContainer';

export function MenuLogo() {
  const navigate = useNavigate();
  return (
    <Box pointer onClick={() => navigate(RoutePath.ROOT)}>
      <FigmaImageContainer imageKey={Images.companyMixedLogo} />
    </Box>
  );
}
