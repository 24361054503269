/* eslint-disable react/display-name */
import { useSnackbar } from 'notistack'
import React from 'react'
import { TextKey } from '../../../../pure/types/Antiloop'
import Notificaiton, { NotificationProps } from '../components/Notification'
import { ErrorNotificaiton, SuccessNotification } from '../components/Notifications'

export default function useNotification() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const send = (element: React.ReactNode) =>
    enqueueSnackbar('', {
      content: () => <div>{element}</div>
    })

  const error = (textKey?: TextKey, text?: string) =>
    send(<ErrorNotificaiton textKey={textKey} text={text} onClose={() => closeSnackbar()} />)

  const info = (props: Partial<NotificationProps> & { component?: React.FC<any> }) =>
    send(React.createElement(props.component || Notificaiton, { ...props, onClose: () => closeSnackbar() }))

  const success = (props?: Partial<NotificationProps>) =>
    send(<SuccessNotification onClose={() => closeSnackbar()} {...props} />)

  return { error, info, success }
}
