import { UseFormReturn } from 'react-hook-form'
import { SMALL_SPACING_PX, regularSpacing, smallSpacing } from '../enums/Spacings'
import { useIsDesktop } from '../hooks/useIsMobile'
import { KeywordDrawerWidth, KeywordDrawerWidthMobile, MENU_HEIGHT } from '../libs/HardCodedSizes'
import FigmaText from './FigmaText'
import { USER_WIDGET_MENU_ID } from '../../../../pure/libs/Consts'
import { Texts } from '../../../../pure/libs/AppTexts'
import { JaguarRequestForm } from '../../../../pure/types/types'
import Box from './Box'
import { ModalCloseIcon } from './Icons'
import { KeywordsDrawerRows } from './KeywordsDrawerRows'
import Popper from './Popper'
import { KeywordsDrawerInfoHeader } from './KeywordsDrawerInfoHeader'
import { ClickAwayListener } from '@mui/base'

export type KeywordsDrawerProps = {
  onClose: () => unknown
  formProps: UseFormReturn<JaguarRequestForm>
  open?: boolean
}

export function KeywordsDrawer({ onClose, formProps, open }: KeywordsDrawerProps) {
  const isDesktop = useIsDesktop()
  if (!open) return null

  const height = isDesktop ? window.innerHeight - MENU_HEIGHT : '100vh'
  const width = isDesktop ? KeywordDrawerWidth : KeywordDrawerWidthMobile

  return (
    <Popper anchorEl={document.getElementById(USER_WIDGET_MENU_ID)}>
      <ClickAwayListener onClickAway={onClose}>
        <Box fullPadding spacing={regularSpacing} style={{ width, height, overflowY: 'scroll' }}>
          {onClose && (
            <Box direction="row" fullWidth justify="space-between" align="center">
              <FigmaText textKey={Texts.addKeyWordsPopperHeader} />
              <Box fullPadding spacing={smallSpacing} onClick={onClose} pointer>
                <ModalCloseIcon />
              </Box>
            </Box>
          )}
          <KeywordsDrawerInfoHeader />

          <KeywordsDrawerRows formProps={formProps} />
        </Box>
      </ClickAwayListener>
    </Popper>
  )
}
