import { bigSpacing } from '../enums/Spacings'
import Box from './Box'
import Loader from './Loader'

export function LoadingPage() {
  return (
    <Box fullWidth fullPadding spacing={bigSpacing} align="center">
      <Loader />
    </Box>
  )
}
