import { JaguarKeywordMapItem } from '../types/JaguarTypes.js'
import { VitecNextEstate } from '../types/VitecNODerivedTypes.js'
import { KeywordItem } from './KeyWordsHelper.js'

export function getDefaultPromptForEstate(estate: VitecNextEstate) {
  return estate?.textFields?.aboutTheUnit || ''
}
export function getKeywordMapItemsForEstate(estate?: VitecNextEstate): JaguarKeywordMapItem[] {
  if (!estate) return []
  const {
    heading,
    noOfRooms,
    noOfBedRooms,
    noOfBathRooms,
    floor,
    constructionYear,
    energyLetter,
    partOwnership,
    plot,
    estateType,
    facilities,
    objectFacilities: _objectFacilities,
    textFields,
    address,

    estateSize
  } = estate

  const estateSizePrimaryRoomArea = estateSize?.primaryRoomArea
  const estateSizePrimaryRoomAreaDescription = estateSize?.primaryRoomAreaDescription
  const estateSizeGrossArea = estateSize?.grossArea
  const estateSizeUsableArea = estateSize?.usableArea

  const addressStreetAdress = address?.streetAdress
  const addressZipCode = address?.zipCode
  const addressCity = address?.city

  const plotSize = plot?.size
  const plotDescription = plot?.description
  const partOwnershipName = partOwnership?.partName

  const { skolekrets, offentligKommunikasjon, innhold, standard, renovert, parkering, oppvarming, energyMarking } =
    textFields || {}

  const object = {
    heading,
    noOfRooms,
    noOfBedRooms,
    noOfBathRooms,
    floor,
    constructionYear,
    energyLetter,
    estateSizePrimaryRoomArea,
    estateSizePrimaryRoomAreaDescription,
    estateSizeGrossArea,
    estateSizeUsableArea,
    addressStreetAdress,
    addressZipCode,
    addressCity,
    plotSize,
    plotDescription,
    partOwnershipName,
    skolekrets,
    offentligKommunikasjon,
    innhold,
    standard,
    renovert,
    parkering,
    oppvarming,
    energyMarking
  }

  if (object.energyLetter === -1) object.energyLetter = undefined
  if (estateType === 'Leilighet') object.plotSize = undefined
  if (estateType === 'Leilighet') object.plotDescription = undefined

  let res: JaguarKeywordMapItem[] = []

  res = res.concat(
    Object.entries(object)
      .map(([key, value]) => getKeywordItem(key, value))
      .filter((n) => n)
      .map((n) => n as JaguarKeywordMapItem)
  )

  res = res.concat(
    (_objectFacilities || [])
      .map(({ Id, Name }) => getKeywordItem(Id, Name))
      .filter((n) => !!n)
      .map((n) => n as JaguarKeywordMapItem)
  )

  res = res.concat(
    (facilities || [])
      .map((n) => {
        /*
         * 1 = Lift (Heis)
         *
         * 2 = Garage (Garasje)
         *
         * 3 = Veranda (Veranda)
         * */

        if (n === 1) return { key: 'estateFacilityLift', value: 'Lift' }

        if (n === 2) return { key: 'estateFacilityGarage', value: 'Garage' }
        if (n === 3) return { key: 'estateFacilityVeranda', value: 'Veranda' }
        return
      })
      .map(getKeywordItem)
      .filter((n) => n)
      .map((n) => n as JaguarKeywordMapItem)
  )

  const _estateSize = estateSize?.usableArea || estateSize?.grossArea
  const estateSizeText = _estateSize ? `estateSize: ${_estateSize} m2` : undefined
  if (estateSizeText) res.push({ id: estateSizeText, localizedName: estateSizeText })

  const estateTypeText = estateType ? `estateType: ${estateType}` : undefined
  if (estateTypeText) res.push({ id: estateTypeText, localizedName: estateTypeText })

  return res
}

const getKeywordItem = (key, value): JaguarKeywordMapItem | undefined => {
  if (!value) return undefined
  const id = `${key}:${value}`
  return { id, localizedName: id }
}
