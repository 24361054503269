export function getTextKeyIdForKeywordMapItem(key: string) {
  return `keyword${toPascalCase(key.replaceAll('_', ' '))}`
}

export function toPascalCase(str) {
  // Split the string into words
  const words = str.split(' ')

  // Capitalize the first letter of each word and concatenate them together
  const pascalCaseStr = words
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')

  return pascalCaseStr
}
